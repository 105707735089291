import { Theme } from "@mui/material";
import { grayColor } from "../appStyle";

const pagesStyle = (theme: Theme) => ({
   pageHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2)
   },
   pageTitle: {
      fontWeight: "bold",
      margin: 0,
      paddingLeft: theme.spacing(2),
   },
   pageBody: {
      padding: theme.spacing(2)
   },
   headerSearchBox: {
      marginRight: theme.spacing(2),
      float: "left",
   },
   listbox: {
      width: 380,
      margin: 0,
      padding: theme.spacing(1),
      zIndex: 1,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      maxHeight: 200,
      '& li:hover': {
         backgroundColor: grayColor[0],
         color: 'white',
         cursor: 'pointer',
      },
   },
   listItem: {
      padding: theme.spacing(1),
   },
   advanceFilterPanel: {
      border: "1px solid lightgray",
      padding: theme.spacing(2),
      borderRadius: 5,
      backgroundColor: theme.palette.action.hover
   }
});

export default pagesStyle;
