import React from "react";
import { Tooltip, Link, MenuItem, Divider, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Delete, Edit, History, Assignment, AssignmentInd, SpeakerNotes, Visibility, ContentCopy, InsertLink, Http, MobileFriendly, Event } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { IDeviceDataProps } from "../../../types/propType";
import WarningSign from "../../Common/WarningSign";
import { Common } from "../../../constants/common";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { allowDeviceAddEdit } from "../../../utils/PermissionHelpers";

const DeviceRow: React.FC<IDeviceDataProps> = ({ device, onEdit, onDelete, onShowAuditLog, onAssignEscalation, onAssignDeviceToPerson, showOnlyAssignment, showOnlyEscalation, actionPermission, copyEscalationText, copyEscalationLink, onSetResponseInstruction, onSelectPerson, showOnlyEdit, viewResponseInstruction, snackbarShowMessage, onTestDeviceClick }) => {
   const showAllActions: boolean = !showOnlyAssignment && !showOnlyEscalation && !showOnlyEdit;
   const { tooltip, disableDeviceAssingment } = Common.getDeviceWarningText(device);
   return (
      <StyledGridRow>
         <StyledGridCell>
            {tooltip.length > 0 &&
               <WarningSign text={tooltip} />
            }
         </StyledGridCell>
         <StyledGridCell>{device.deviceType}  </StyledGridCell>
         <StyledGridCell>{device.deviceId}  </StyledGridCell>
         <StyledGridCell>{device.deviceModel}  </StyledGridCell>
         <StyledGridCell>{device.platform}  </StyledGridCell>
         <StyledGridCell>{device.clientName}</StyledGridCell>
         <StyledGridCell>
            {actionPermission["Person:GET"] ? <Link onClick={() => onSelectPerson(device.personOid)}>{device.personName}</Link> : device.personName}
         </StyledGridCell>
         <StyledGridCell align='right'>
            {showOnlyAssignment && (actionPermission["Person:AssignDeviceToPerson"] && actionPermission["Person:GetClientSpecificPersonSelectOptions"]) &&
               <Button justIcon={true} onClick={() => onAssignDeviceToPerson(device)}>
                  <Tooltip title="Assign device to person">
                     <AssignmentInd />
                  </Tooltip>
               </Button>
            }
            {showOnlyEscalation && (actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && (actionPermission["Device:AssignDefaultEscalation"]) &&
               <Button color="rose" justIcon={true} onClick={() => onAssignEscalation(device)}>
                  <Tooltip title="Assign device escalation contact">
                     <Assignment />
                  </Tooltip>
               </Button>
            }
            {(showAllActions || showOnlyEdit) && (actionPermission["Device:POST"] || actionPermission["Device:GET"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(device)}>
                  {allowDeviceAddEdit(actionPermission)
                     ? <Edit /> : <Visibility />}
               </Button>
            }
            {showAllActions &&
               <ActionOptionMenu>
                  {(actionPermission["Audit:Device"]) &&
                     <MenuItem disableRipple onClick={() => { onShowAuditLog(device, "Device") }}>
                        <History color="primary" />
                        Audit history
                     </MenuItem>
                  }
                  {(actionPermission["Audit:DeviceEvent"]) &&
                     <MenuItem disableRipple onClick={() => { onShowAuditLog(device, "DeviceEvent") }}>
                        <Event color="primary" />
                        Event Logs
                     </MenuItem>
                  }
                  {(actionPermission["Person:AssignDeviceToPerson"] && actionPermission["Person:GetClientSpecificPersonSelectOptions"]) &&
                     <>
                        {!disableDeviceAssingment ?
                           <MenuItem disableRipple onClick={() => { onAssignDeviceToPerson(device) }}>
                              <AssignmentInd />
                              Assign device to person
                           </MenuItem>
                           :
                           <MenuItem disableRipple onClick={() => { snackbarShowMessage(tooltip, "warning") }}>
                              <ListItemIcon style={{ opacity: 0.5 }}>
                                 <AssignmentInd />
                              </ListItemIcon>
                              <ListItemText style={{ opacity: 0.5 }}>Assign device to person</ListItemText>
                              {tooltip.length > 0 &&
                                 <Typography variant="body2" color="text.secondary">
                                    <WarningSign text={tooltip} />
                                 </Typography>
                              }
                           </MenuItem>
                        }
                     </>
                  }
                  {(actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) &&
                     <>
                        {device.clientOid ?
                           <MenuItem disableRipple onClick={() => { onAssignEscalation(device) }}>
                              <Assignment />
                              {actionPermission["Device:AssignDefaultEscalation"] ? "Assign" : "View"} device escalation contact
                           </MenuItem>
                           :
                           <MenuItem disableRipple onClick={() => { snackbarShowMessage("Client is not configured for device.", "warning") }}>
                              <ListItemIcon style={{ opacity: 0.5 }}>
                                 <Assignment />
                              </ListItemIcon>
                              <ListItemText style={{ opacity: 0.5 }}>Assign device escalation contact</ListItemText>
                              <Typography variant="body2" color="text.secondary">
                                 <WarningSign text={"Client is not configured for device."} />
                              </Typography>
                           </MenuItem>
                        }
                     </>
                  }
                  {(actionPermission["Device:ResponseInstruction"]) &&
                     <MenuItem disableRipple onClick={() => { copyEscalationText(device.oid) }}>
                        <ContentCopy />
                        Copy escalation text
                     </MenuItem>
                  }
                  {(actionPermission["Device:EscalationDetails"]) &&
                     <MenuItem disableRipple onClick={() => { copyEscalationLink(device.oid) }}>
                        <InsertLink />
                        Copy escalation link
                     </MenuItem>
                  }
                  {(actionPermission["Device:EscalationDetails"]) &&
                     <MenuItem disableRipple onClick={() => { viewResponseInstruction(device.oid) }}>
                        <Http />
                        View Response Instructions
                     </MenuItem>
                  }
                  {actionPermission["ResponseInstruction:GET"] &&
                     <MenuItem disableRipple onClick={() => { onSetResponseInstruction(device) }}>
                        <SpeakerNotes />
                        {actionPermission["Device:SetResponseInstruction"] ? "Edit" : "View"}  response instruction
                     </MenuItem>
                  }
                  {actionPermission["DeviceEvent:POST"] &&
                     <MenuItem disableRipple onClick={() => { onTestDeviceClick(device) }}>
                        <MobileFriendly />
                        Add device test result
                     </MenuItem>
                  }
                  {(actionPermission["Device:DELETE"]) &&
                     <div>
                        <Divider />
                        <MenuItem disableRipple onClick={() => { onDelete(device, true) }}>
                           <Delete />
                           Delete
                        </MenuItem>
                     </div>
                  }
               </ActionOptionMenu>
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default DeviceRow;
