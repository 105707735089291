import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, CardHeader, Grid, Typography, MenuItem, Divider } from '@mui/material';
import { Delete, Edit, History, Security, PrivacyTip, MoreHoriz, Warning, ContactPage, ContactPhone, ImportContacts, Visibility } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IHazardLocationDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

const HazardLocationCard: React.FC<IHazardLocationDataProps> = ({ classes, hazardLocation, onEdit, onDelete, onShowAuditLog, actionPermission }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <ActionOptionMenu>
                     {(actionPermission["HazardLocation:GET"]) &&
                        <>
                           <MenuItem disableRipple onClick={() => { onEdit(hazardLocation) }}>
                              {(actionPermission["HazardLocation:POST"])
                                 ? <> <Edit /> Edit</> : <><Visibility /> View</>}
                           </MenuItem>
                           <Divider />
                        </>
                     }
                     {(actionPermission["Audit:HazardLocation"]) &&
                        <MenuItem disableRipple onClick={() => { onShowAuditLog(hazardLocation) }}>
                           <History />
                           Audit history
                        </MenuItem>
                     }
                     {(actionPermission["HazardLocation:DELETE"]) &&
                        <>
                           <Divider />
                           <MenuItem disableRipple onClick={() => { onDelete(hazardLocation, true) }}>
                              <Delete />
                              Delete
                           </MenuItem>
                        </>
                     }
                  </ActionOptionMenu>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {hazardLocation.type}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Security className={classes.beforeTextIcon} /> {hazardLocation.securityLevel}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <PrivacyTip className={classes.beforeTextIcon} />{hazardLocation.securityLevelDescription}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <MoreHoriz className={classes.beforeTextIcon} />{hazardLocation.status}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Warning className={classes.beforeTextIcon} />{hazardLocation.warning}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <ContactPage className={classes.beforeTextIcon} />{hazardLocation.onsiteContact}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <ContactPhone className={classes.beforeTextIcon} />{hazardLocation.onsiteContactPhoneNumber}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <ImportContacts className={classes.beforeTextIcon} />{hazardLocation.notes}
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(HazardLocationCard, gridTileStyle);
