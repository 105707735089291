import type { FC } from 'react';
import { dangerColor } from "../../../../assets/jss/appStyle"
import { Avatar, IconButton, CardHeader } from '@mui/material';
import { RestoreFromTrash } from '@mui/icons-material';
import Image from "./../../../Common/Image";
const style = {
   border: `2px solid ${dangerColor[0]}`,
   padding: '0.5rem 1rem',
   marginBottom: '.5rem',
   backgroundColor: 'white',
}

export interface IDeletedTextBlockCardProps {
   id: any;
   textBlock: any;
   index: number;
   restoreFromTrash: any;
}
export const DeletedTextBlockCard: FC<IDeletedTextBlockCardProps> = ({ id, textBlock, index, restoreFromTrash }) => {
   const getPlaceholderBlockText = () => {
      let text = textBlock.Text;
      const values = textBlock.Values ?? {};
      Object.keys(values).forEach((key) => {
         text = text.replace(`{{${key}}}`, values[key] ?? "");
      });
      return text;
   }
   return (
      <div style={{ ...style }}>
         <CardHeader
            avatar={
               <Avatar sx={{ bgcolor: dangerColor[0] }} aria-label="recipe">
                  {index + 1}
               </Avatar>
            }
            action={
               <IconButton color="success" onClick={restoreFromTrash} aria-label="Restore">
                  <RestoreFromTrash />
               </IconButton>
            }
            title={< div style={{ whiteSpace: "pre-line" }}>
               {(textBlock.Type === "Text" || textBlock.Type === "FreeText") && textBlock.Text}
               {textBlock.Type === "Image" &&
                  <>
                     {textBlock.Text}
                     <Image
                        src={textBlock.Text}
                        zoom={true}
                        style={{ height: 50, marginLeft: 25 }}
                     />
                  </>
               }
               {textBlock.Type === "Placeholder" &&
                  getPlaceholderBlockText()
               }
            </div>}
         />
      </div >
   )
}
