import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import { IAdvanceFilterProps } from '../../../types/propType';
import { StatusCode } from '../../../constants/constant';
import { Common } from '../../../constants/common';
import MonitoringStationService from '../../../services/monitoringstation.service';
import ClientService from '../../../services/client.service';
import SearchableSelect from '../../Common/SearchableSelect';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ISelectOption, ISearchableSelectParams } from '../../../types/dataTypes';

const SupportUserAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const [monitoringStations, setMonitoringStations] = useState([]);
   useEffect(() => {
      MonitoringStationService.getMonitoringStationOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setMonitoringStations(res?.data);
         }
      });
   }, []);
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, submitForm, setFieldValue }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Client Name"
                        id="ClientOid"
                        inputProps={{
                           name: "ClientOid",
                        }}
                        value={values.ClientOid}
                        onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("ClientOid", value?.value ?? Common.EmptyGuid);
                           values.ClientOid = value?.value ?? Common.EmptyGuid;
                           onAdvanceSearch(values);
                        }}
                        isClientSelection={true}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                     <SearchableSelect
                        labelText="Monitoring Station"
                        id="MonitoringStationOid"
                        inputProps={{
                           name: "MonitoringStationOid"
                        }}
                        value={values.MonitoringStationOid}
                        options={[{ label: "All", value: Common.EmptyGuid }].concat(monitoringStations)}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("MonitoringStationOid", value?.value);
                           setFieldValue("ClientOid", Common.EmptyGuid);
                           submitForm();
                        }}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default SupportUserAdvanceFilter;
