export const fixedBlocks = [{
   Type: "Text",
   Text: "Call police (or emergency service dispatched) to obtain outcome, details must be gathered. Advise escalations of outcome, and complete alarm. Send event report to client and copy support@guardianangelsafety.co",
   IsRequired: true,
   IsFixed: true
},
{
   Type: "Text",
   Text: `If in any doubt please don't hesitate to contact Guardian Angel Support
            NZ 09 553 9777
            AU 1800 491 666`,
   IsRequired: true,
   IsFixed: true
}]
