import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { ITextComparetorProps } from '../../types/propType';
import Button from './Button';
import HazardLocationGeometryAudit from './HazardLocationGeometryAudit';
import { Common } from '../../constants/common';
const HazardLocationGeometryComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const oldGeometry: any[] = oldValueJson?.HazardLocationGeometry ?? [];
   const newGeometry: any[] = changedValueJson.HazardLocationGeometry ?? [];
   const getBackgroundColor = (type: any) => {
      switch (type) {
         case "Added":
            return "#bcffbc";
         case "Updated":
            return "#fad7a3";
         case "Removed":
            return "#faa8a8";
         default:
            return "transparent";
      }
   }
   const geometries = [
      ...newGeometry.filter((obj) => oldGeometry.some((obj2) => obj.Oid === obj2.Oid && obj.CoOrdinates === obj2.CoOrdinates)).map((obj) => { return { ...obj, ChangeType: "No Change", backgroundColor: getBackgroundColor("No Change"), mapPolygonColor: Common.getMapPolygonColor("No Change") } }),
      ...oldGeometry.filter((obj) => !newGeometry.some((obj2) => obj.Oid === obj2.Oid)).map((obj) => { return { ...obj, ChangeType: "Removed", backgroundColor: getBackgroundColor("Removed"), mapPolygonColor: Common.getMapPolygonColor("Removed") } }),
      ...newGeometry.filter((obj) => !oldGeometry.some((obj2) => obj.Oid === obj2.Oid)).map((obj) => { return { ...obj, ChangeType: "Added", backgroundColor: getBackgroundColor("Added"), mapPolygonColor: Common.getMapPolygonColor("Added") } }),
      ...newGeometry.filter((obj) => oldGeometry.some((obj2) => obj.Oid === obj2.Oid && obj.CoOrdinates !== obj2.CoOrdinates)).map((obj) => { return { ...obj, ChangeType: "Updated", backgroundColor: getBackgroundColor("Updated"), mapPolygonColor: Common.getMapPolygonColor("Added") } }),
      ...oldGeometry.filter((obj) => newGeometry.some((obj2) => obj.Oid === obj2.Oid && obj.CoOrdinates !== obj2.CoOrdinates)).map((obj) => { return { ...obj, ChangeType: "Update Removed", backgroundColor: getBackgroundColor("Update Removed"), mapPolygonColor: Common.getMapPolygonColor("Removed") } }),
   ];
   const [open, setOpen] = useState(false);
   return (
      <>
         <TableRow>
            <TableCell><strong>Geometry:</strong></TableCell>
            <TableCell>
               {geometries.length > 0 &&
                  <Button size="sm" color="success" onClick={() => setOpen(true)}>View on map</Button>
               }
            </TableCell>
         </TableRow>
         {geometries.length > 0 ?
            <>
               {
                  geometries.filter((geometry) => geometry.ChangeType !== "Update Removed").map((geometry: any, index: number) => {
                     return (
                        <TableRow>
                           <TableCell key={index} style={{ width: '20%' }}>{"Geometry " + (index + 1)}: </TableCell>
                           <TableCell>
                              <span style={{ backgroundColor: geometry.backgroundColor }}>{geometry.ChangeType}</span>
                           </TableCell>
                        </TableRow>
                     );
                  })
               }
               {open &&
                  <HazardLocationGeometryAudit open={open} close={() => setOpen(false)} geometries={geometries} />
               }
            </>
            :
            <TableRow>
               <TableCell colSpan={2}>Don't have geometry.</TableCell>
            </TableRow>
         }
      </>
   );
}
export default HazardLocationGeometryComparetor;
