import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import { useState } from 'react';

function HelpText({ ...props }) {
   const [open, setOpen] = useState(false);

   const handleTooltipClose = () => {
      setOpen(false);
   };

   const handleTooltipOpen = () => {
      setOpen(true);
   };
   return (
      <>
         <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {props.label}
            <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{props.text}</div>}>
               <HelpIcon style={{ fontSize: "0.90em" }} />
            </Tooltip>
         </Box>
         <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            {props.label}
            <ClickAwayListener onClickAway={handleTooltipClose}>
               <Tooltip
                  onClose={handleTooltipClose}
                  open={open}
                  title={<div style={{ whiteSpace: 'pre-line' }}>{props.text}</div>}
               >
                  <button style={{ border: "none", color: "inherit", backgroundColor: "transparent", padding: "0" }} type="button" onClick={handleTooltipOpen}>
                     <HelpIcon style={{ fontSize: "inherit" }} />
                  </button>
               </Tooltip>
            </ClickAwayListener>
         </Box>
      </>
   )
}
HelpText.propTypes = {
   text: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired
};
export default (HelpText);
