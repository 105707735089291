import { DeletedTextBlockCard } from './DeletedTextBlockCard';

const style = {
   width: "100%",
}
interface IDeletedBlockContainer {
   deletedTextBlocks: any[];
   restoreFromTrash: any;
}
export const DeletedBlockContainer: React.FC<IDeletedBlockContainer> = ({ deletedTextBlocks, restoreFromTrash }) => {
      
   const renderCard =
      (card: any, index: number) => {
         return (
            <DeletedTextBlockCard
               key={index}
               index={index}
               id={index}
               textBlock={card}
               restoreFromTrash={() => restoreFromTrash(card)}
            />
         )
      }
   return (
      <>
         <div style={style}>{[...deletedTextBlocks].map((card, i) => renderCard(card, i))}</div>
      </>
   )
}
