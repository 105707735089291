import { useState, useCallback, useEffect, FC } from 'react'
import styles from './image.module.css'
export interface IImageProps {
   zoom: boolean;
   src: string;
   style: any
}
const Image: FC<IImageProps> = ({ zoom, src, style }) => {
   const [click, setClick] = useState(false)

   const setFlag = () => {
      setClick(true)
   }

   const unsetFlag = () => {
      setClick(false)
   }
   const escFunction = useCallback((event: any) => {
      if (event.key === 'Escape') {
         setClick(false)
         event.preventDefault();
         return;
      }
   }, [])

   useEffect(() => {
      document.addEventListener('keydown', escFunction, false)
      return () => {
         document.removeEventListener('keydown', escFunction, false)
      }
   })
   if (!zoom) return <img src={src} alt={"Invalid File Path"} style={{ ...style, cursor: "pointer" }} />
   else
      return (
         <>
            {click ? (
               <div onClick={unsetFlag} style={{ zIndex: 10000 }} className={`${styles.lightbox} ${styles.show} relative`}>
                  <img src={src} className={`${styles.show_image}`} alt={"Invalid File Path"}></img>
               </div>
            ) : (
               <img src={src} alt={"Invalid File Path"} style={{ ...style, cursor: "pointer" }} onClick={setFlag}></img>
            )}
         </>
      )
}

export default Image;
