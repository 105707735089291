import React from 'react';
import lodash from "lodash";
import AuditTextBlock from './AuditTextBlock';
import { successColor, dangerColor } from "./../../assets/jss/appStyle";
import { IAuditTextBlockList } from '../../types/propType';

const AuditTextBlockList: React.FC<IAuditTextBlockList> = ({ oldBlocks, changedBlocks, type }) => {
   const getPlaceholderBlockText = (text: string, values: any) => {
      let blockText = text;
      Object.keys(values).forEach((key) => {
         blockText = blockText.replace(`{{${key}}}`, values[key]);
      });
      return blockText;
   }
   return (
      <>
         {
            oldBlocks.map((item, i) => {
               let color = "#999";
               let borderColor = "#999"

               const textBlockType = item.Type;
               const oldValueText = textBlockType !== "Placeholder" ? item.Text : getPlaceholderBlockText(item.Text, item.Values);
               const existInNew = changedBlocks.filter(x => (x.Type !== "Placeholder" ? x.Text : getPlaceholderBlockText(x.Text, x.Values)) === oldValueText);
               if (existInNew.length === 0)
                  color = type === "old" ? dangerColor[0] : successColor[0];
               if (oldValueText !== (lodash.get(changedBlocks[i], "Type") !== "Placeholder" ? lodash.get(changedBlocks[i], "Text") : getPlaceholderBlockText(lodash.get(changedBlocks[i], "Text"), lodash.get(changedBlocks[i], "Values"))))
                  borderColor = type === "old" ? dangerColor[0] : successColor[0];
               return <AuditTextBlock key={`${type}_${i}`} textBlockType={textBlockType} index={i} text={oldValueText} color={color} borderColor={borderColor} />
            })
         }
      </>
   )
};

export default AuditTextBlockList;
