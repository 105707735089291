import moment from "moment-timezone";
import environment from '../environment';
import { getLoggedInKeycloakRole } from "../utils/LoginHelpers";
const baseURL: string = `${environment.apiUrl.replace(/^(.+?)\/*?$/, "$1")}/api/v1`;
export class Common {
   public static readonly MaxFileSize = 4; //in MB
   public static readonly EmptyGuid = "00000000-0000-0000-0000-000000000000";
   public static readonly DefaultPageSize = "100";
   public static GridPageSize = (): number => {
      return parseInt(localStorage.getItem("pageSize")?.toString() ?? Common.DefaultPageSize);
   }
   public static readonly SearchAfterCount = 2;
   public static readonly DateFormat = "DD MMM YYYY";
   public static readonly TimeFormat = "HH:mm";
   public static readonly DateTimeFormat = Common.DateFormat + " " + Common.TimeFormat;
   public static readonly PhoneRegex = /^[6][1|4][0-9\s]*$/;
   public static readonly PhoneValidationMessage = "Not a valid phone number. (Must start with 61 or 64 and contains digit and space only)";
   public static readonly MobileValidationMessage = "Not a valid mobile number. (Must start with 61 or 64 and contains digit and space only)";
   public static readonly PhoneNumberLength = 10;
   public static readonly PhoneNumberLengthValidationMessage = `Phone number must be at least 10 digits.`;
   public static readonly MobileNumberLengthValidationMessage = `Mobile number must be at least 10 digits.`;
   public static readonly MonitoringAccountNumberRegex = /^[0-9]{16}$|^[0-9]{4}[a-zA-Z0-9]{6}$/;
   public static readonly ValidGuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
   public static readonly ClientImagePath = `${baseURL}/ClientImage/GetImage`;
   public static readonly getFileNameFromPath = (path: string) => {
      return path.split('\\')?.pop()?.split('/')?.pop() ?? "filename";
   }
   public static readonly getFileNameFromPathWithOutExtension = (path: string | undefined | null) => {
      if (!path)
         return "";
      const filename = path.split('\\')?.pop()?.split('/')?.pop() ?? path;
      return filename.replace(/\.[^/.]+$/, "");
   }
   public static stringNullOrEmpty = (value: string | null | undefined): boolean => {
      return (value === undefined || value === "" || value === null);
   }

   public static getFormattedDateTime(dateTime: any) {
      if (dateTime)
         return `${moment.utc(dateTime).local().format(Common.DateTimeFormat)} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;
      return "";
   }
   public static getFormattedDate(dateTime: any) {
      return moment.utc(dateTime).local().format(Common.DateFormat);
   }
   public static getLocalToUTC(dateTime: any) {
      return moment.utc(dateTime).format(`YYYY-MM-DDT${Common.TimeFormat}`);
   }
   public static getUtcToLocal(dateTime: any) {
      return moment.utc(dateTime).local().toDate();
   }
   public static getFormattedDateForServer(dateTime: any) {
      return moment(dateTime).format(`YYYY-MM-DD`);
   }
   public static getFormattedDateOnly(dateTime: any) {
      return moment(dateTime).format(Common.DateFormat);
   }
   public static getUTCDateTimeForServer(dateTime: any) {
      return moment.utc(moment(dateTime)).format(`YYYY-MM-DDTHH:mm`);
   }
   public static readonly PortalRoles = {
      GASStaff: "GAS Staff",
      MonitoringStationAdmin: "Monitoring Station Admin",
      MonitoringStationOperator: "Monitoring Station Operator",
      ClientAdmin: "Client Admin",
      ClientUser: "Client User"
   };

   public static getAuditOnDesignClass = (auditOn: string | undefined) => {
      switch (auditOn) {
         case "Create":
         case "Add default escalation":
         case "Add vehicle information":
         case "Add report recipient":
         case "Set response instruction":
         case "Add image":
         case "Add person":
         case "Add geometry":
         case "Add location":
         case "Test":
            return "createdText";

         case "Update":
         case "Update default escalation":
         case "Update vehicle information":
         case "Update response instruction":
         case "Update geometry":
            return "updatedText";

         case "Delete":
         case "Remove report recipient":
         case "Delete image":
         case "Remove person":
         case "Remove location":
         case "Remove geometry":
         case "Remove escalation contacts":
            return "deleteText";

         case "Assign to client":
         case "Assign to another client":
         case "Assign device":
         case "Assign another device":
         case "Assign to another person":
         case "Assign to person":
         case "Password reset":
            return "assignText";

         case "Unassign client from device":
         case "Unassign device":
         case "Unassign from client":
         case "Unassign from person":
            return "unassignText";

         default:
            return "";

      }
   };
   public static formatPhoneNumber = (number: string | undefined | null) => {
      if (!number)
         return "";
      number = number.replace(/\D/g, '');
      return number.length >= 11 ?
         number.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '00$1 $2 $3 $4')
         : number.replace(/(\d{2})(\d{3})(\d{3})(\d{2})/, '00$1 $2 $3 $4')
   }
   public static getDeviceWarningText(device: any) {
      const monitoringStationOid = device.monitoringStationOid;
      const monitoredBy = device.monitoredBy;
      let disableDeviceAssingment = false;
      let text: string[] = [];
      if (device.isEscalationAssigned === false) {
         text.push("Default escalation pending");
      }
      if (Common.stringNullOrEmpty(monitoredBy)) {
         disableDeviceAssingment = true;
         text.push("Monitored by selection pending");
      }
      else if (monitoredBy === "Guardian Angel Safety" && (Common.stringNullOrEmpty(monitoringStationOid) || monitoringStationOid === Common.EmptyGuid)) {
         disableDeviceAssingment = true;
         text.push("Monitoring station selection pending");
      }
      if (monitoredBy === "Guardian Angel Safety" && Common.stringNullOrEmpty(device.monitoringAccountNumber)) {
         disableDeviceAssingment = true;
         text.push("Monitoring account number pending");
      }
      return {
         tooltip: text.join("\n"),
         disableDeviceAssingment
      }
   }

   public static editMonitoringAccountNumberOnly() {
      let disable = false;
      if (Common.IsInRole(this.PortalRoles.MonitoringStationAdmin) || Common.IsInRole(this.PortalRoles.MonitoringStationOperator)) {
         disable = true;
      }
      return disable;
   }

   public static getCenterFromLatLongArray = (latLongs: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral => {
      const longitudes = latLongs.map((i: google.maps.LatLngLiteral) => i.lng);
      const latitudes = latLongs.map((i: google.maps.LatLngLiteral) => i.lat);
      latitudes.sort();
      longitudes.sort();
      const lowX = latitudes.at(0) ?? 0;
      const highX = latitudes.at(-1) ?? 0;
      const lowy = longitudes.at(0) ?? 0;
      const highy = longitudes.at(-1) ?? 0;
      return {
         lat: lowX + ((highX - lowX) / 2),
         lng: lowy + ((highy - lowy) / 2)
      };
   }

   public static getMapPolygonColor = (type: any) => {
      switch (type) {
         case "Added":
            return "green";
         case "Removed":
            return "red";
         default:
            return "grey";
      }
   }
   public static DeviceTestResultOptions = (isForFilter: boolean = false, isPersonAssigned: boolean) => {
      const options = [{ value: "Pass", label: "Pass", disabled: false }, { value: "Fail", label: "Fail", disabled: false }, { value: "Person Unavailable", label: "Person Unavailable", disabled: false }, { value: "No Person Assigned", label: "No Person Assigned", disabled: isPersonAssigned }];
      if (isForFilter) {
         options.push({ value: "Pending", label: "Pending", disabled: false });
         options.unshift({ value: " ", label: "All", disabled: false });
      }
      return options;
   }
   public static ConvertServerErrorToFormError = (errors: any) => {
      const serverErrors: any = {};
      Object.keys(errors).forEach((field) => { const key = `${field[0].toUpperCase() + field.slice(1)}`; serverErrors[key] = errors[field]?.message; });
      return serverErrors;
   }

   public static AllowAssignEscalationContact = () => {
      return !Common.IsInRole(this.PortalRoles.ClientUser) || (Common.IsInRole(this.PortalRoles.ClientUser) && localStorage.getItem("allowDeviceAndEscalationAssignmentToSelf") === "true")
   }

   public static IsInRole = (roleName: string) => {
      const roles = localStorage.getItem("roles");
      const rolesArray = roles ? JSON.parse(roles) : []
      return rolesArray.includes(roleName);
   }

   public static IsArrayContainRoleWithClient() {
      const roles = localStorage.getItem("roles");
      var rolesArray = roles ? JSON.parse(roles) : []
      return Array.isArray(rolesArray) && rolesArray.some(role => role.indexOf("Client") > -1)
   }

   public static GetRolesAsString() {
      if (Boolean(Boolean(environment?.kcEnabled))) {
         return getLoggedInKeycloakRole().join(",");
      }
      else {
         const roles = localStorage.getItem("roles");
         var rolesArray = roles ? JSON.parse(roles) : [];
         return rolesArray.join(",");
      }
   }
}
