import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputLabel, MenuItem, FormControl } from "@mui/material";
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { withStyles } from 'tss-react/mui';
import { useField } from "formik";
function CustomSingleSelect(props: any) {
   const { items, label, ...rest } = props;
   const [field, meta, helper] = useField(props);
   const { value: selectedValue } = field;
   const { setValue } = helper;
   const isError = meta.touched && meta.error && true;
   const handleChange = (event: SelectChangeEvent<string[]>) => {
      const {
         target: { value },
      } = event;
      setValue(typeof value === "string" ? value.split(",") : value);
   };
   return (
      <FormControl {...rest} error={isError} fullWidth>
         <InputLabel>{label}</InputLabel>
         <Select
            label={label}
            {...field}
            value={selectedValue[0] || ""}
            onChange={handleChange}
         >
            {items.map((data: any) => (
               <MenuItem key={data.value} value={data.value}>
                  {data.label}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   );
}
export default withStyles(CustomSingleSelect, customInputStyle);
