import React from 'react';

const Loader: React.FC = () => {
   return (
      <div className="loader-wrapper">
         <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
         </div>
      </div>
   );
};

export default Loader;
