import { Box } from "@mui/material"
import logo_rev from "../../assets/img/logo-rev.svg";
import { getLoggedInPortalRole } from "../../utils/LoginHelpers";
import { getHomePageForRole } from "../../utils/PermissionHelpers";
import NavigationLink from "./NavigationLink";
interface IHeaderLogoProps {
   isAzureLogin: boolean;
};
const HeaderLogo: React.FC<IHeaderLogoProps> = ({ isAzureLogin }) => {
   const planName = localStorage.getItem("planName");
   return (
      <Box sx={{ flexGrow: 1, mr: 2, display: { xs: 'none', md: 'flex' }, maxWidth: 200 }}>
         <NavigationLink to={getHomePageForRole(getLoggedInPortalRole(isAzureLogin))}>
            <img style={{ width: 200, cursor: "pointer" }} src={logo_rev} alt="Gaurdian Angle Safety" />
         </NavigationLink>
         {planName && planName !== "Intergalactic" &&
            <span style={{ alignSelf: "center", color: "#c1932b", fontWeight: "bold" }}>{planName}</span>
         }
      </Box>
   )
}
export default HeaderLogo;
