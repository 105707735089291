import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '../../Common/Button';
import { IModalProps } from '../../../types/propType';
import { StatusCode } from '../../../constants/constant';
import { Wrapper } from '@googlemaps/react-wrapper';
import { mapApiDetails, mapInitialMapBound, useDeepCompareEffectForMaps, getInfoWindowContentForHazardLocation } from "./../../Common/MapCommon";
import { AutoComplete, GoogleMapSearchLocation } from '../../Common/GoogleMapSearchLocation';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import CustomClusterRenderer from '../../Common/CustomClusterRenderer';
import hazardLocationService from '../../../services/hazardLocation.service';
interface IHazardLocationMapViewModal extends IModalProps {
   searchText?: string;
   onEditHazardLocation: any;
   numberForMapRefresh: number;
}
const HazardLocationMapViewModal: React.FC<IHazardLocationMapViewModal> = ({ open, close, searchText, onEditHazardLocation, numberForMapRefresh }) => {
   const [hazardLocations, setHazardLocations] = useState<any[]>([]);
   const getHazardLocations = (values?: string) => {
      hazardLocationService.getHazardLocationsDetails({ searchText: values }).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const allHazardLocations: any[] = (res?.data ?? []).map((y: any) => {
               y.hazardLocationGeometry = y.hazardLocationGeometry.map((x: any) => {
                  const latLongs = JSON.parse(x.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
                  return {
                     ...x,
                     latLongs: latLongs
                  } as any;
               })
               return y;
            })
            setHazardLocations(allHazardLocations);
         }
      })
   }
   useEffect(() => {
      getHazardLocations(searchText);
   }, [searchText, numberForMapRefresh])
   return (
      <Dialog open={open} style={{ zIndex: 1 }} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle>
            <span>Hazard Location map view</span>
         </ DialogTitle>
         <DialogContent dividers>
            <GoogleMapSearchLocation inputId="locationSearch" />
            <br />
            <Wrapper {...mapApiDetails}>
               <MemoizedMap
                  zoom={10}
                  style={{ flexGrow: "1", height: "600px", width: "100%" }}
                  hazardLocations={hazardLocations}
                  onEditHazardLocation={onEditHazardLocation}
                  numberForMapRefresh={numberForMapRefresh}
               />

            </Wrapper>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Close</Button>
         </DialogActions>
      </Dialog >
   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: (map: google.maps.Map) => void;
   children?: React.ReactNode;
   hazardLocations: any[];
   onEditHazardLocation: any;
   numberForMapRefresh: number;
}
const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   hazardLocations,
   onEditHazardLocation,
   numberForMapRefresh,
   ...options
}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   const [markerCluster, setMarkerCluster] = React.useState<MarkerClusterer>(new MarkerClusterer({ renderer: new CustomClusterRenderer() }));
   const [mapPolygons, setMapPolygons] = React.useState<google.maps.Polygon[]>([]);
   // eslint-disable-next-line react-hooks/exhaustive-deps 
   const DrawMarkers = () => {
      if (!map)
         return;
      const currentMapBounds = map.getBounds();
      if (markerCluster) {
         markerCluster.setMap(null);
         markerCluster.clearMarkers();
      }
      if (mapPolygons.length > 0) {
         mapPolygons.forEach((poly) => {
            poly.setPaths([]);
            poly.setMap(null);
            poly.setVisible(false);
         });
      }
      const newMarkers: google.maps.Marker[] = [];
      const polygons: google.maps.Polygon[] = [];
      const bounds = new google.maps.LatLngBounds();
      let locationToBeRender: any[] = [];
      if (map && (map.getZoom() ?? 0) > 11) {
         locationToBeRender = hazardLocations.filter((hazardGeometry) => !currentMapBounds || hazardGeometry.hazardLocationGeometry.some((loc: any) => currentMapBounds.contains(loc.latLongs[0])));
      }
      else if (hazardLocations.length <= 30) {
         locationToBeRender = hazardLocations;
      }
      if (locationToBeRender && locationToBeRender.length > 0) {
         locationToBeRender.forEach((hazardLocation: any) => {
            hazardLocation.hazardLocationGeometry.forEach((location: any) => {
               let polygonPath: google.maps.LatLng[] = location.latLongs;
               const polygon = new google.maps.Polygon({
                  paths: polygonPath,
                  geodesic: true,
                  strokeColor: "#FFFF00",
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: "#FFFC00",
                  fillOpacity: 0.4,
                  map: map,
               });
               addInfoWindowForHazardLocation(polygon, map, hazardLocation, onEditHazardLocation);
               polygons.push(polygon);
               let polygonBounds = new google.maps.LatLngBounds();
               for (var i = 0; i < polygonPath.length; i++) {
                  polygonBounds.extend(polygonPath[i]);
               }
               const centerLatlong = polygonBounds.getCenter();
               bounds.extend(centerLatlong);
               const newMarker = new google.maps.Marker({
                  position: centerLatlong,
               });
               addInfoWindowForHazardLocation(newMarker, map, hazardLocation, onEditHazardLocation);
               newMarkers.push(newMarker);
            })
         });
      }
      setMapPolygons(polygons);
      markerCluster.setMap(map);
      markerCluster.addMarkers(newMarkers);
      setMarkerCluster(markerCluster);
   }
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
         setMapBound(mapInitialMapBound());
      }
      if (map) {
         AutoComplete(map, "locationSearch");
         DrawMarkers();
         if (hazardLocations.length <= 20) {
            const bounds = new google.maps.LatLngBounds();
            hazardLocations.forEach((hazardLocation: any) => {
               hazardLocation.hazardLocationGeometry.forEach((location: any) => {
                  bounds.extend(location.latLongs[0]);
               })
            });
            setMapBound(bounds);
         }
         else {
            setMapBound(mapInitialMapBound());
         }
      }
   }, [ref, map, hazardLocations, numberForMapRefresh]); // eslint-disable-line react-hooks/exhaustive-deps
   React.useEffect(() => {
      if (map) {
         google.maps.event.clearListeners(map, "idle");
         map.addListener("idle", () => {
            DrawMarkers();
         });
      }
   }, [map, hazardLocations, numberForMapRefresh, DrawMarkers]); // eslint-disable-next-line react-hooks/exhaustive-deps  
   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
      }
   }, [map, options]);

   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, onClick, mapBound]);

   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
const MemoizedMap = React.memo(Map);
const addInfoWindowForHazardLocation = (obj: any, map: google.maps.Map, hazardLocation: any, onEdit: any) => {
   const infowindow = new google.maps.InfoWindow();
   obj.addListener('click', function (event: any) {
      const contentString = getInfoWindowContentForHazardLocation(hazardLocation, true);
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.open(map);
   });
   infowindow.addListener('domready', () => {
      const editButton = document.getElementById(`edit_${hazardLocation.oid}`);
      if (editButton) {
         editButton.addEventListener('click',
            () => {
               infowindow.close();
               onEdit(hazardLocation, false);
            })
      }
      const deleteButton = document.getElementById(`delete_${hazardLocation.oid}`);
      if (deleteButton) {
         deleteButton.addEventListener('click',
            () => {
               infowindow.close();
               onEdit(hazardLocation, true);
            })
      }
   });
}
export default HazardLocationMapViewModal;


