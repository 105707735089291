import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import DeviceTestReportRow from "../../components/pages/DeviceTestReport/DeviceTestReportRow";
import DeviceTestReportCard from "../../components/pages/DeviceTestReport/DeviceTestReportCard";
import DeviceTestReportAdvanceFilter from "../../components/pages/DeviceTestReport/DeviceTestReportAdvanceFilter";
import { IDeviceTestReportPageState } from "../../types/stateType";
import { IDevicePageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IDeviceTestReportFilter } from "../../types/dataTypes";
import { TableHeader } from "../../constants/tableHeader";
import DeviceEventService from "../../services/deviceEvent.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import AddDeviceTest from "../../components/pages/DeviceEvents/AddDeviceTest";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
class DeviceTestReport extends React.Component<IDevicePageProps, IDeviceTestReportPageState> {
   date = new Date();
   firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
   filterOptions: IDeviceTestReportFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      ClientOid: Common.EmptyGuid,
      ClientName: "",
      sortString: "DeviceId",
      MonitoringStationOid: Common.EmptyGuid,
      StartDate: Common.getFormattedDateForServer(this.firstDay),
      EndDate: Common.getFormattedDateForServer(new Date()),
      TestResult: " "
   }

   state: IDeviceTestReportPageState = {
      deviceTestRecords: [],
      totalRecords: 0,
      openAuditLogs: false,
      openDeviceTestModal: false,
      selectedRow: null
   };

   componentDidMount() {
      if (!this.props.isFromSearch) {
         this.searchDeviceTestRecords();
      }
   }

   //#region pagination and searching
   searchDeviceTestRecords = () => {
      let newFilterOptions: IDeviceTestReportFilter = { ...this.filterOptions };
      newFilterOptions.StartDate = Common.getUTCDateTimeForServer(newFilterOptions.StartDate);
      newFilterOptions.EndDate = Common.getUTCDateTimeForServer(newFilterOptions.EndDate);
      this.setState({ ...this.state, deviceTestRecords: [] }, () => {
         DeviceEventService.searchDeviceTestReportRecords(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  deviceTestRecords: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.deviceTestRecords.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IDeviceTestReportFilter = { ...this.filterOptions };
      newFilterOptions.StartDate = Common.getUTCDateTimeForServer(newFilterOptions.StartDate);
      newFilterOptions.EndDate = Common.getUTCDateTimeForServer(newFilterOptions.EndDate);
      DeviceEventService.searchDeviceTestReportRecords(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let deviceList = [...this.state.deviceTestRecords];
            deviceList = deviceList.concat(res?.data);
            this.setState({
               ...this.state,
               deviceTestRecords: deviceList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchDeviceTestRecords();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchDeviceTestRecords();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      // if (val.length > Common.SearchAfterCount) {
      this.searchDeviceTestRecords();
      // }
   }

   onAdvanceSearch = (advanceFilter: any) => {
      this.filterOptions.ClientOid = advanceFilter.ClientOid ?? Common.EmptyGuid;
      this.filterOptions.ClientName = advanceFilter.ClientName;
      this.filterOptions.MonitoringStationOid = advanceFilter.MonitoringStationOid ?? Common.EmptyGuid;
      this.filterOptions.StartDate = advanceFilter.StartDate;
      this.filterOptions.EndDate = advanceFilter.EndDate;
      this.filterOptions.TestResult = advanceFilter.TestResult;
      this.filterOptions.page = 1;
      this.searchDeviceTestRecords();
   }

   //#endregion
   //#region Download   
   onDownload = (exportType: string) => {
      let newFilterOptions: IDeviceTestReportFilter = { ...this.filterOptions };
      newFilterOptions.StartDate = Common.getUTCDateTimeForServer(newFilterOptions.StartDate);
      newFilterOptions.EndDate = Common.getUTCDateTimeForServer(newFilterOptions.EndDate);
      newFilterOptions.exportType = exportType;
      DeviceEventService.exportDeviceTestReport(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `DevicesTestReport`;
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   onCancelDeviceTest = () => {
      this.setState({
         ...this.state, openDeviceTestModal: false, selectedRow: null
      });
   }
   onOpenDeviceTest = (selectedRow: any) => {
      this.setState({
         ...this.state, selectedRow, openDeviceTestModal: true
      });
   }
   onSuccessDeviceTest = () => {
      this.setState({ ...this.state, selectedRow: null, openDeviceTestModal: false }, () => this.searchDeviceTestRecords())
   }
   toggleEventLogModal = (entityName: string | null = null) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedRow: null });
   }

   openDeviceEventLogModal = (selectedRow: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedRow: selectedRow });
   }
   //#endregion
   render() {
      const { totalRecords, deviceTestRecords, openDeviceTestModal, selectedRow, openAuditLogs } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle="Device Test Report" searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={null}
            actionPermission={{
               download: actionPermission["DeviceEvent:Export"] && totalRecords > 0,
            }}
            onDownload={this.onDownload}
            advanceFilter={<DeviceTestReportAdvanceFilter initialValues={{ ClientOid: this.filterOptions.ClientOid, MonitoringStationOid: this.filterOptions.MonitoringStationOid, StartDate: this.filterOptions.StartDate, EndDate: this.filterOptions.EndDate, TestResult: this.filterOptions.TestResult }} onAdvanceSearch={this.onAdvanceSearch} />}
            showAdvanceFilerAlways={true}
         >
            {/* list web view */}
            < Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getDeviceTestReportTableHeaders()} pagingInfoText="Devices" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords ? totalRecords : deviceTestRecords?.length} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {deviceTestRecords && deviceTestRecords?.map((record: any, index: number) => {
                     return (
                        <DeviceTestReportRow
                           key={`row_${index}`}
                           record={record}
                           onOpenDeviceTest={this.onOpenDeviceTest}
                           openDeviceEventLogModal={this.openDeviceEventLogModal}
                           actionPermission={actionPermission}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={deviceTestRecords ? deviceTestRecords.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {deviceTestRecords && deviceTestRecords?.map((record: any, index: number) => {
                        return (
                           <DeviceTestReportCard
                              key={`card_${index}`}
                              record={record}
                              onOpenDeviceTest={this.onOpenDeviceTest}
                              openDeviceEventLogModal={this.openDeviceEventLogModal}
                              actionPermission={actionPermission}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {
               openDeviceTestModal &&
               <AddDeviceTest
                  open={openDeviceTestModal}
                  close={this.onCancelDeviceTest}
                  successCallback={this.onSuccessDeviceTest}
                  deviceOid={selectedRow?.deviceOid ?? ""}
                  isPersonAssigned={selectedRow?.isPersonAssigned}
                  deviceId={selectedRow?.deviceId ?? ""} />
            }
            {
               openAuditLogs && selectedRow !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleEventLogModal}
                  entityName={Controllers.DeviceEvent}
                  oid={selectedRow?.deviceOid ?? ""}
                  changedEntityTitle={selectedRow?.deviceId}
                  logType={"Event"} />
            }
         </PageLayout >
      )
   }
}

export default withParams(DeviceTestReport);
