import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { IDevicePersonReportDataProps } from "../../../types/propType";
const DevicePersonReportRow: React.FC<IDevicePersonReportDataProps> = ({ record }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {record.deviceId} </StyledGridCell>
         <StyledGridCell> {record.personName} </StyledGridCell>
      </StyledGridRow>
   )
}

export default DevicePersonReportRow;
