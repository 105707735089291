import React, { useEffect } from 'react';
import accessDeniedIcon from "../assets/img/AccessDenied.png";
import { withStyles } from 'tss-react/mui';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import unauthorizedPageStyle from '../assets/jss/pages/unauthorizedPageStyle';
import CommonAppbar from '../components/Common/CommonAppBar';
import { useNavigate } from "react-router-dom";
import authService from '../services/auth.service';
import { StatusCode } from '../constants/constant';
import environment from '../environment';
import { keycloakInstance } from '../utils/keycloak/keycloakHelpers';
const LoginProcessing: React.FC<any> = ({ classes }) => {
   const navigate = useNavigate();
   useEffect(() => {
      authService.getFeaturesPermissions().then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            localStorage.setItem("permissions", JSON.stringify(res.data));
            navigate("/");
         }
         else {
            localStorage.clear();
            if (Boolean(environment?.kcEnabled)) {
               keycloakInstance.clearToken();
            }
            navigate("/login");
         }
      });
   }, [navigate]);
   return (
      <>
         <CommonAppbar classes={classes} isAzureLogin={false} />
         <div className={classes.pageContent}>
            <Card sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: 650, p: 4 }}>
               <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="Access Denied" />
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography component="div" variant="h5">
                        Please wait we are logging you in
                     </Typography>
                  </CardContent>
               </Box>
            </Card>
            <Card sx={{ display: { xs: 'flex', md: 'none', flexDirection: 'column' }, maxWidth: 650, p: 4 }}>
               <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="Access Denied" />
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography component="div" variant="h5">
                        Please wait we are logging you in
                     </Typography>
                  </CardContent>
               </Box>
            </Card>
         </div>
      </>
   );
};

export default withStyles(LoginProcessing, unauthorizedPageStyle);
