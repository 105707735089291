import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Alert, Grid, Card, CardHeader, CardMedia, IconButton } from "@mui/material";
import Button from '../../Common/Button';
import { IClientImagesModalProps } from '../../../types/propType';
import CustomImageUploader from '../../Common/CustomImageUploader';
import { dangerColor } from '../../../assets/jss/appStyle';
import { StatusCode } from '../../../constants/constant';
import ClientImageService from "../../../services/clientimage.service";
import Image from '../../Common/Image';
import { withSnackbar } from '../../Common/Snackbar';
import ConfirmDialogBox from "../../Common/ConfirmDialogBox";
import { Common } from '../../../constants/common';
import { DeleteForever } from '@mui/icons-material';
const ClientImagesModal: React.FC<IClientImagesModalProps> = ({ open, close, successCallback, clientOid, clientName, allowSubmit, allowDelete, snackbarShowMessage }) => {
   const fileTypes: string[] = ["bmp", "jpeg", "jpg", "svg", "png"];
   const [error, setError] = useState<any>(null);
   const [fileList, setFileList] = useState<any[]>([]);
   const [showConfirmDialog, setShowConfirmDialog] = useState(false);
   const [selectedFileName, setSeletedFileName] = useState<string | null>(null);

   useEffect(() => {
      getImageList();
   }, [clientOid, clientName]); // eslint-disable-line react-hooks/exhaustive-deps
   const getImageList = () => {
      ClientImageService.getClientImages(clientOid).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            setFileList(res?.data ?? []);
         }
         else if (res?.status === StatusCode.BadRequest) {
            if (typeof res?.data === "string")
               setError(res?.data)
            else
               setError(JSON.stringify(res?.data));
         }
      });
   }
   const onUpload = (file: any) => {
      if (file !== null) {
         const formData = new FormData();
         formData.append("importedFile", file);
         formData.append("clientOid", clientOid);
         ClientImageService.saveImage(formData).then((res: any) => {
            if (res?.status === StatusCode.Success) {
               setError(null);
               getImageList();
               snackbarShowMessage("Image uploaded successfully.", "success");
            }
            else if (res?.status === StatusCode.BadRequest) {
               if (typeof res?.data === "string")
                  setError(res?.data)
               else
                  setError(JSON.stringify(res?.data));
            }
         });
      }
   }
   const onDeleteClick = (fileName: string) => {
      setSeletedFileName(fileName);
      setShowConfirmDialog(true);
   }
   const onCancelDelete = () => {
      setSeletedFileName(null);
      setShowConfirmDialog(false);
   }
   const onConfirmDelete = () => {
      if (selectedFileName)
         ClientImageService.deleteImage(clientOid, selectedFileName).then((res: any) => {
            if (res?.status === StatusCode.Success) {
               setError(null);
               getImageList();
               snackbarShowMessage("Image deleted successfully.", "success");
               onCancelDelete();
            }
            else if (res?.status === StatusCode.BadRequest) {
               onCancelDelete();
               if (typeof res?.data === "string")
                  setError(res?.data)
               else
                  setError(JSON.stringify(res?.data));
            }
         });
   }
   return (
      <Dialog open={open} onClose={close} disableEscapeKeyDown scroll="paper" fullWidth maxWidth="md">
         <DialogTitle>Response instruction images for client : {clientName}</ DialogTitle>
         <DialogContent dividers>
            {allowSubmit &&
               <CustomImageUploader
                  fileTypes={fileTypes}
                  onUploadClick={onUpload} />
            }
            <div style={{ marginTop: 20, color: dangerColor[0] }}>
               {
                  error !== null &&
                  <Alert severity="error">
                     {error}
                  </Alert>
               }
            </div>
            <Grid container spacing={2}>
               {fileList && fileList.length > 0 && fileList.map((item: any, id: number) => {
                  let imageName = item.label;
                  return <Grid key={`image_${id}`} item xs={12} md={6}>
                     <Card sx={{ maxWidth: 345 }}>

                        <CardHeader
                           action={
                              <>
                                 {allowDelete &&
                                    <IconButton aria-label="settings" onClick={() => onDeleteClick(item.value)}>
                                       <DeleteForever />
                                    </IconButton>
                                 }
                              </>
                           }
                           title={imageName}
                           titleTypographyProps={{ style: { wordBreak: "break-all" } }}
                        />

                        <CardMedia
                           component={(props) => <Image src={`${Common.ClientImagePath}/${item.value}`} zoom={true} style={{ height: "194px", width: "100%" }} />}
                           height="194"
                           image={`${Common.ClientImagePath}/${item.value}`}
                           alt={imageName}
                        />
                     </Card>
                  </Grid>
               })}
            </Grid>
            {showConfirmDialog &&
               <ConfirmDialogBox
                  dialogueText="Please make sure this image is not used in any response instruction."
                  show={showConfirmDialog}
                  onConfirmEvent={onConfirmDelete}
                  onCancel={onCancelDelete} />
            }
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
         </DialogActions>
      </Dialog>
   );
};

export default withSnackbar(ClientImagesModal);
