import React from "react";
import { withStyles } from 'tss-react/mui';
import { Assignment, Delete, Edit, Email, Phone, History, Contacts, AccountBox, SpeakerNotes, Aod, Image, PersonSearch } from "@mui/icons-material";
import { MenuItem, Divider, Card, CardHeader, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import Button from "../../Common/Button";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { warningColor } from "../../../assets/jss/appStyle";
import NavigationLink from "../../Common/NavigationLink";
import { IClientDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import WarningSign from "../../Common/WarningSign";

const ClientCard: React.FC<IClientDataProps> = ({ classes, client, onEdit, onDelete, onAssignEscalation, onShowAuditLog, onDownloadEscalation, showOnlyEdit, showOnlyEscalation, actionPermission, onSetResponseInstruction, openImageModal, openFindAndReplaceModal }) => {
   const showAllActions: boolean = !showOnlyEdit && !showOnlyEscalation;
   return (
      <Grid item xs={12} md={4}>
         <Card style={client.escalationOid === Common.EmptyGuid ? { border: `2px solid ${warningColor[0]}` } : {}}>
            <CardHeader
               action={
                  <>
                     {showOnlyEscalation && (actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && (actionPermission["Client:AssignDefaultEscalation"]) &&
                        <Button color="rose" justIcon={true} onClick={() => onAssignEscalation(client)}>
                           <Tooltip title="Assign client escalation contact">
                              <Assignment />
                           </Tooltip>
                        </Button>
                     }
                     {(showOnlyEdit) && (actionPermission["Client:POST"] && actionPermission["Person:GetClientSpecificAccountOwnerSelectOptions"] && actionPermission["Person:GetClientReportRecipientSelectOptions"]) &&
                        <Button color="info" justIcon={true} onClick={() => onEdit(client)}><Edit /></Button>
                     }
                     {showAllActions &&
                        <ActionOptionMenu>
                           {(actionPermission["Client:POST"]) &&
                              <>
                                 <MenuItem disableRipple onClick={() => { onEdit(client) }}>
                                    <Edit />
                                    Edit
                                 </MenuItem>
                                 <Divider />
                              </>
                           }
                           {(actionPermission["Audit:Client"]) &&
                              <MenuItem disableRipple onClick={() => { onShowAuditLog(client) }}>
                                 <History color="primary" />
                                 Audit history
                              </MenuItem>
                           }
                           {(actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) &&
                              <MenuItem disableRipple onClick={() => { onAssignEscalation(client) }}>
                                 <Assignment />
                                 {actionPermission["Client:AssignDefaultEscalation"] ? "Assign" : "View"} client escalation contact
                              </MenuItem>
                           }
                           {(actionPermission["Client:ResponseInstruction"]) &&
                              <MenuItem disableRipple onClick={() => { onDownloadEscalation(client) }}>
                                 <Contacts />
                                 Download escalation instruction
                              </MenuItem>
                           }
                           {actionPermission["ResponseInstruction:GET"] &&
                              <MenuItem disableRipple onClick={() => { onSetResponseInstruction(client) }}>
                                 <SpeakerNotes />
                                 {actionPermission["Client:SetResponseInstruction"] ? "Edit" : "View"} response instruction
                              </MenuItem>
                           }
                           {actionPermission["ClientImage:GetImageList"] &&
                              <MenuItem disableRipple onClick={() => { openImageModal(client) }}>
                                 <Image />
                                 {actionPermission["ClientImage:SaveImage"] ? "Add" : "View"} response instruction images
                              </MenuItem>
                           }
                           {actionPermission["Person:FindAndReplaceEscalationContact"] && actionPermission["Person:GetCountForPersonUsedInEscalations"] &&
                              <MenuItem disableRipple onClick={() => { openFindAndReplaceModal(client) }}>
                                 <PersonSearch />
                                 Find and Replace Escalation Contact
                              </MenuItem>
                           }
                           {(actionPermission["Device:Search"]) &&
                              <NavigationLink to={`/devices/client/${client.oid}`}>
                                 <MenuItem disableRipple>
                                    <Aod />
                                    Device list
                                 </MenuItem>
                              </NavigationLink>
                           }
                           {(actionPermission["Client:DELETE"]) &&
                              <>
                                 <Divider />
                                 <MenuItem disableRipple onClick={() => { onDelete(client, true) }}>
                                    <Delete />
                                    Delete
                                 </MenuItem>
                              </>
                           }
                        </ActionOptionMenu>
                     }
                  </>
               }
               title={
                  <>
                     {client.escalationOid === Common.EmptyGuid &&
                        <WarningSign text="Default escalation pending" />
                     }
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {client.name}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Email className={classes.beforeTextIcon} /> {client.email}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(client.phoneNumber)}
               </Typography>
               {client.accountOwnerName &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <AccountBox className={classes.beforeTextIcon} /> {client.accountOwnerName}
                  </Typography>
               }
            </CardContent>

         </Card>
      </Grid>
   )
}

export default withStyles(ClientCard, gridTileStyle);
