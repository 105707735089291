import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from './../../../Common/Button';
import deviceService from '../../../../services/device.service';
import { StatusCode } from '../../../../constants/constant';
import { Common } from '../../../../constants/common';
import { ISearchableSelectParams, ISelectOption } from '../../../../types/dataTypes';
import SearchableServerSearch from '../../../Common/SearchableServerSearch';
import { Form, Formik } from 'formik';
import CustomCheckbox from '../../../Common/CustomCheckbox';
import TagsSelect from '../../../Common/TagsSelect';

interface IDeviceResponseInsturctionApplyAllDialogueProps {
   show: boolean,
   onConfirmEvent: any,
   onCancel: any,
   deviceType: string,
   deviceModel?: string,
   deviceOid: string,
   isClientUser: boolean,
   type: "all" | "specific",
   deviceID: string,
   clientName: string | null | undefined
}

const DeviceResponseInsturctionApplyAllDialogue: React.FC<IDeviceResponseInsturctionApplyAllDialogueProps> = ({ show, onConfirmEvent, onCancel, deviceType, deviceModel, deviceOid, isClientUser, type, deviceID, clientName }) => {
   const [deviceCount, setDeviceCount] = useState(0);
   const [isForAllClient, setIsForAllClient] = useState(false);
   const [deviceOidList, setDeviceOidList] = useState<ISelectOption[]>([]);
   const [formValues, setFormValues] = useState<any>({
      isForAllClient: isForAllClient,
      device: "",
      selectedDevices: []
   });
   useEffect(() => {
      if (type !== "all") {
         deviceOidList.push({ value: deviceOid, label: deviceID });
         setDeviceCount(deviceOidList.length);
         setDeviceOidList([...deviceOidList]);
         formValues.selectedDevices = [deviceOid];
         setFormValues({ ...formValues });
      }
   }, [type]); // eslint-disable-line react-hooks/exhaustive-deps
   useEffect(() => {
      if (type === "all") {
         deviceService.getDeviceCountByTypeAndModel({
            deviceType, deviceModel, deviceOid: isForAllClient ? Common.EmptyGuid : deviceOid
         }).then((res: any) => {
            if (res?.status === StatusCode.Success) {
               setDeviceCount(res?.data);
            }
         })
      }
   }, [deviceType, deviceModel, deviceOid, isForAllClient, type]);
   const onSubmit = (values: any) => {
      if (type === "all") {
         onConfirmEvent(values.isForAllClient ? Common.EmptyGuid : deviceOid, []);
      }
      else {
         onConfirmEvent(values.isForAllClient ? Common.EmptyGuid : deviceOid, values.selectedDevices);
      }
   }
   const getDeviceSelectionOptionByTypeAndModel = (param: ISearchableSelectParams, signal: any) => {
      const params: any = { ...param };
      params.deviceOid = isForAllClient ? Common.EmptyGuid : deviceOid;
      params.deviceType = deviceType;
      params.deviceModel = deviceModel;
      return deviceService.getDeviceSelectionOptionByTypeAndModel(params, signal);
   }
   const onlyUnique = (value: any, index: number, self: any) => {
      return self.indexOf(value) === index;
   }
   return (
      <Dialog open={show} onClose={onCancel}>
         <Formik enableReinitialize={true} initialValues={formValues} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <DialogTitle>{`Are you sure you want to apply ${type} devices with following client, type${deviceType === "Wearable" ? " and model" : ""}?`} </DialogTitle>
                  <DialogContent dividers>
                     <strong>Client: {`${values.isForAllClient ? "Any" : (clientName ? clientName : "Not set")}`}</strong><br />
                     <strong>Device Type: {deviceType}</strong><br />
                     {deviceType === "Wearable" &&
                        <>
                           <strong>Device Model: {deviceModel}</strong><br />
                        </>
                     }

                     {isClientUser &&
                        <>
                           <CustomCheckbox
                              labelText={"Apply for all client's device"}
                              id="isForAllClient"
                              inputProps={{
                                 required: true,
                                 name: "isForAllClient",
                                 onChange: (e: any) => {
                                    setIsForAllClient(e.target.checked);
                                    setFieldValue("isForAllClient", e.target.checked);
                                 }
                              }}
                              checked={values.isForAllClient}
                           />
                           <br />
                        </>
                     }
                     {type !== "all" &&
                        <>
                           <SearchableServerSearch
                              labelText="Search Device ID"
                              id="device"
                              inputProps={{
                                 name: "device",
                              }}
                              value={values.device}
                              onSearchTextChange={getDeviceSelectionOptionByTypeAndModel}
                              setValue={(value: ISelectOption) => {
                                 if (value?.value) {
                                    if (deviceOidList.filter((x) => x.value === value?.value).length === 0) {
                                       deviceOidList.push(value);
                                       setDeviceOidList([...deviceOidList]);
                                       setFieldValue("selectedDevices", deviceOidList.map((item) => item.value));
                                       setFieldValue("device", null);
                                       setDeviceCount(deviceOidList.length);
                                    }
                                 }
                              }}
                           />
                           <TagsSelect
                              labelText="Selected Devices"
                              id="selectedDevices"
                              inputProps={{
                                 required: false,
                                 name: "selectedDevices"
                              }}
                              value={values.selectedDevices}
                              options={deviceOidList}
                              setValue={(value: string[]) => {
                                 if (value.indexOf(deviceOid) < 0) {
                                    value.push(deviceOid);
                                 }
                                 value = value.filter((x) => !Common.stringNullOrEmpty(x)).filter(onlyUnique);
                                 setFieldValue("selectedDevices", value);
                                 let deviceList = deviceOidList.filter(x => value.indexOf(x.value) > -1);
                                 setDeviceOidList([...deviceList]);
                                 setDeviceCount(deviceList.length);
                                 setFieldValue("device", null);
                              }}
                           />
                        </>
                     }
                     <strong>Number of Device Response Instruction will be updated: <strong>{deviceCount}</strong></strong>
                     <DialogContentText style={{ color: "red" }}>You can’t undo this action.</DialogContentText >
                  </DialogContent >
                  <DialogActions>
                     <Button color="danger" type="submit">Yes</Button>
                     <Button onClick={onCancel} >No</Button>
                  </DialogActions>
               </Form >
            )
            }
         </Formik >
      </Dialog >
   );
};

export default DeviceResponseInsturctionApplyAllDialogue;





