import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { mapApiDetails, useDeepCompareEffectForMaps } from "./MapCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid } from "@mui/material";
import Button from "./Button";
interface IHazardLocationGeometryPicker {
   geometries: any[];
   open: boolean,
   close: any,
}
const legend = (label: string, color: string) => <> <Box mr={1} component="span" sx={{ bgcolor: color, width: 20, height: 20 }} /><strong style={{ alignSelf: "center" }}>{label}</strong></>;
const HazardLocationGeometryAudit: React.FC<IHazardLocationGeometryPicker> = ({ geometries, open, close }) => {
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <DialogTitle id="scroll-dialog-title">Hazard Location Geometry Changes</DialogTitle>
         <DialogContent dividers>

            <Grid container mb={1} spacing={2}>
               <Grid item style={{ display: "flex" }} xs={4}>
                  {legend("No change", "grey")}
               </Grid>
               <Grid item style={{ display: "flex" }} xs={4}>
                  {legend("Added", "green")}
               </Grid>
               <Grid item style={{ display: "flex" }} xs={4}>
                  {legend("Removed", "red")}
               </Grid>
            </Grid>
            <div style={{ display: "flex", height: "500px" }}>
               <Wrapper {...mapApiDetails} >
                  <Map
                     geometries={geometries}
                     zoom={10}
                     style={{ flexGrow: "1", height: "100%" }}
                  >
                  </Map>
               </Wrapper>
            </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
         </DialogActions>
      </Dialog>
   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: (map: google.maps.Map) => void;
   children?: React.ReactNode;
   geometries: any[];
}

const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   geometries,
   ...options
}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
      }
      const bounds = new google.maps.LatLngBounds();
      const polygons: google.maps.Polyline[] = [];
      geometries.forEach((geometry) => {
         if (geometry.CoOrdinates && geometry.CoOrdinates.length > 0) {
            if (geometry.Type === "Polygon") {
               let polygonPath: google.maps.LatLng[] = JSON.parse(geometry.CoOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
               const polygon = new google.maps.Polygon({
                  paths: polygonPath,
                  geodesic: true,
                  strokeColor: geometry.mapPolygonColor,
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: geometry.mapPolygonColor,
                  fillOpacity: 0.5,
                  map: map,
               });
               const infowindow = new google.maps.InfoWindow();
               google.maps.event.addListener(polygon, 'click', function (event: any) {
                  const contentString = `<div>${geometry.ChangeType}</div>`;
                  infowindow.setContent(contentString);
                  infowindow.setPosition(event.latLng);
                  infowindow.open(map);
               });
               polygons.push(polygon);
            }
            else if (geometry.Type === "Point") {
               let position: google.maps.LatLng = JSON.parse(geometry.CoOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) })[0];
               new google.maps.Marker({
                  position: position,
                  map: map,
                  icon: {
                     url: `http://maps.google.com/mapfiles/ms/icons/${geometry.mapPolygonColor}-dot.png`
                  }
               });
               bounds.extend(position);
            }
         }
      });
      if (polygons.length > 0) {
         for (let j = 0; j < polygons.length; j++) {
            for (let i = 0; i < polygons[j].getPath().getLength(); i++) {
               bounds.extend(polygons[j].getPath().getAt(i));
            }
         }
      }
      setMapBound(bounds);
   }, [ref, map, geometries]); // eslint-disable-line react-hooks/exhaustive-deps

   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
      }
   }, [map, options]);

   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, onClick, mapBound]);

   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
export default HazardLocationGeometryAudit;
