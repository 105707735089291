
export class HelperFunctions {

   static mergeObj(source: any, value: any) {
      for (const propName in value) {
         if (value[propName] !== '') {
            if (typeof value[propName] === 'object' && source.hasOwnProperty(propName)) {
               source[propName] = this.mergeObj(source[propName], value[propName]);
            } else {
               if (value[propName] === 'true') {
                  source[propName] = true;
               } else if (value[propName] === 'false') {
                  source[propName] = false;
               } else {
                  source[propName] = value[propName];
               }
            }
         }
      }
      return source;
   }

}
