import React from "react";
import { IErrorLogViewModelProps } from "../../../types/propType";
import Button from "../../Common/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Common } from "../../../constants/common";

const ErrorLogViewModel: React.FC<IErrorLogViewModelProps> = ({ open, close, errorLog }) => {
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle id="scroll-dialog-title">View Error Log Detail</DialogTitle>
         <DialogContent dividers>
            <Grid container spacing={2}>
               <Grid item xs={12} md={12}>
                  <Table>
                     <TableBody>
                        <TableRow>
                           <TableCell>
                              Timestamp
                           </TableCell>
                           <TableCell>
                              {Common.getFormattedDateTime(errorLog.createdOn)}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Function / URL
                           </TableCell>
                           <TableCell>
                              {errorLog.functionOrURL}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              File Name
                           </TableCell>
                           <TableCell>
                              {errorLog.fileName}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Request Data
                           </TableCell>
                           <TableCell>
                              {errorLog.requestData}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Response Data
                           </TableCell>
                           <TableCell>
                              {errorLog.responseData}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell style={{ backgroundColor: "#faa8a8" }}>
                              Error
                           </TableCell>
                           <TableCell style={{ backgroundColor: "#faa8a8" }}>
                              {errorLog.error}
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
         </DialogActions>
      </Dialog>
   )
}

export default ErrorLogViewModel;
