import { CardHeader, Avatar } from "@mui/material";
import Image from "../../components/Common/Image";
import { Common } from "../../constants/common";
const style = {
   border: `2px solid #999`,
   padding: '0.5rem 1rem',
   marginBottom: '.5rem',
   backgroundColor: 'white',
}

interface IEscalaltionDetailInstructionBlockList {
   blocks: any[];
}
export const EscalaltionDetailInstructionBlockList: React.FC<IEscalaltionDetailInstructionBlockList> = ({ blocks }) => {
   const getPlaceholderBlockText = (card: any) => {
      let text = card.Text;
      const values = card.Values;
      Object.keys(values).forEach((key) => {
         text = text.replace(`{{${key}}}`, values[key]);
      });
      return text;
   }
   const renderCard =
      (card: any, index: number) => {
         return (
            <CardHeader style={style}
               key={`EDIB_${index}`}
               avatar={
                  <Avatar sx={{ bgcolor: "#999" }}>
                     {index + 1}
                  </Avatar>
               }
               title={
                  < div style={{ whiteSpace: "pre-line" }}>
                     {(card.Type === "Text" || card.Type === "FreeText") && card.Text}
                     {card.Type === "Image" &&
                        <Image zoom={true} style={{ width: "90%" }}
                           src={card.Text.indexOf("http") === 0 ? card.Text : `${Common.ClientImagePath}/${card.Text}`}
                        />
                     }
                     {card.Type === "Placeholder" &&
                        getPlaceholderBlockText(card)
                     }
                  </div>
               }
            />
         )
      }
   return (
      <>
         <div>{[...blocks].map((card, i) => renderCard(card, i))}</div>
      </>
   )
}
