import { Theme } from "@mui/material";
import { primaryColor } from "../appStyle";

const loginStyle = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "center",
    margin: `${theme.spacing(0)} auto`,
  },
  loginBtn: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: `${primaryColor} !important`,
    color: '#fff'
  },
  header: {
    textAlign: 'center',
    background: '#212121',
    color: '#fff'
  },
  card: {
    marginTop: theme.spacing(20),
    padding: 10,
    width: 350
  }
});

export default loginStyle;
