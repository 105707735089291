import { useEffect, useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormControl, FormHelperText, TextField, Autocomplete } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { ISelectOption } from "../../types/dataTypes";

function SearchableSelect({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      options,
      setValue,
      setTouched,
      disabled
   } = props;

   const [selectedValue, setSelectedValue] = useState(null);
   useEffect(() => {
      if (props.value && props.value !== "") {
         setSelectedValue(options.filter((x: ISelectOption) => x.value === props.value)[0]);
      } else {
         setSelectedValue(null);
      }
   }, [options, props.value]);

   const [field, meta] = useField(inputProps);
   return (
      <FormControl variant="standard"
         {...formControlProps}
         fullWidth
         className={formControlProps?.className + " " + classes.formControl}
      >
         <Autocomplete
            id={id}
            {...inputProps}
            options={options}
            getOptionLabel={(option: ISelectOption) => option.label}
            value={selectedValue}
            disabled={disabled}
            onChange={(event: any, newValue: ISelectOption | null) => {
               setValue(newValue);
            }}
            onBlur={() => { if (setTouched) setTouched(); }}
            renderInput={(params) => (
               <TextField variant="standard" {...params} required={inputProps?.required} label={labelText} name={field.name} disabled={disabled} />
            )}
            error={meta.touched && meta.error ? meta.error : ""}
         />
         {meta.touched && meta.error && meta.error.length > 0 &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
      </FormControl>
   );
}

SearchableSelect.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   labelProps: PropTypes.object,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object
};

export default withStyles(SearchableSelect, customInputStyle);
