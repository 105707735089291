// http status codes
export const StatusCode = {
   Success: 200,
   BadRequest: 400,
   Unauthorized: 401,
   Forbidden: 403,
   Conflict: 409,
   InternalServer: 500,
   ServiceUnavailable: 503
};

export const Pages = {
   Clients: "Client",
   Devices: "Device",
   Persons: "Person",
   Search: "Search",
   Alerts: "Alert",
   Users: "User",
   MonitoringStation: "MonitoringStation",
   Escalation: "Escalation",
   ClientApiKey: "ClientApiKey",
   ResponseInstruction: "ResponseInstruction",
   Audit: "Audit",
   EscalationReport: "EscalationReport",
   UserSupport: "UserSupport",
   ErrorLog: "ErrorLog",
   DevicePersonReport: "DevicePersonReport",
   SftpImportLogs: "SftpImportLogs",
   ClientImage: "ClientImage",
   Trips: "Trip",
   Locations: "Location",
   TripDetail: "TripDetail",
   HazardLocations: "HazardLocation",
   DeviceEvent: "DeviceEvent",
   DeviceTestReport: "DeviceTest",
   SftpMonitoringLogs: "SftpMonitoringLogs"
}

export const URLs = {
   Client: "",
   Device: "devices",
   Person: "persons",
   Alert: "alerts",
   User: "users",
   MonitoringStation: "monitoringstation",
   ClientApiKey: "apikeys",
   Audit: "Audit",
   EscalationReport: "escalationreport",
   SftpImportLogs: "SftpImportLogs"
}

export const Controllers = {
   Auth: "Auth",
   Client: "Client",
   Device: "Device",
   Person: "Person",
   Escalation: "Escalation",
   Audit: "Audit",
   PersonClientRelation: "PersonClientRelation",
   Vehicle: "Vehicle",
   ClientReportRecipient: "ClientReportRecipient",
   Alert: "Alert",
   User: "User",
   MonitoringStation: "MonitoringStation",
   ClientApiKey: "ClientApiKey",
   ResponseInstruction: "ResponseInstruction",
   EscalationReport: "EscalationReport",
   ErrorLog: "ErrorLog",
   DevicePersonReport: "DevicePersonReport",
   SftpImportLogs: "SftpImportLogs",
   ClientImage: "ClientImage",
   Trip: "Trip",
   Location: "Location",
   HazardLocation: "HazardLocation",
   DeviceEvent: "DeviceEvent",
   SftpMonitoringLogs: "SftpMonitoringLogs",
}
