import { Controllers } from "../constants/constant";
import { ILogin, IResetPassword } from "../types/dataTypes";
import request from "../utils/request";
class AuthService {

   userPermission: Promise<any> | undefined;
   userProfileDetails: {} | undefined;
   login = (data: ILogin) => {
      return request({
         url: `${Controllers.Auth}/Login`,
         method: 'post',
         data,
      });
   }
   getLinkDetail = (params: any) => {
      return request({
         url: `${Controllers.Auth}/LinkDetail`,
         method: 'get',
         params
      });
   }
   resetPassword = (data: IResetPassword) => {
      return request({
         url: `${Controllers.Auth}/ResetPassword`,
         method: 'post',
         data
      });
   }
   logOut = () => {
      const data = {
         Token: localStorage.getItem("token"),
         RefreshToken: localStorage.getItem("refreshToken")
      }
      localStorage.clear();
      request({
         url: `${Controllers.Auth}/Logout`,
         method: 'post',
         data
      });
      window.location.href = "/login";
   }
   getFeaturesPermissions = async () => {
      return request({
         url: `${Controllers.Auth}/GetFeaturesPermissions`,
         method: 'get',
      });
   }
   backToGASStaffLogin = () => {
      const data = {
         Token: localStorage.getItem("token"),
         RefreshToken: localStorage.getItem("refreshToken")
      }
      return request({
         url: `${Controllers.Auth}/Logout`,
         method: 'post',
         data
      }).then(() => {
         localStorage.removeItem("userName");
         localStorage.removeItem("roles");
         localStorage.removeItem("token");
         localStorage.removeItem("clientName");
         localStorage.removeItem("allowDeviceAndEscalationAssignmentToSelf");
         localStorage.removeItem("IsApiFailing");
         localStorage.removeItem("isWialonWebhookHealthy");
      });
   }
}

export default new AuthService();
