import { Pages, URLs } from "../constants/constant";

const getPermissions = (): any[] => {
   const permissions = localStorage.getItem("permissions");
   return permissions ? JSON.parse(permissions) : [];
}

export const checkViewPermission = (resource: string) => {
   const userPermissions = getPermissions();
   if (!userPermissions) return {};
   if (resource === Pages.Search) {
      return userPermissions.filter(x => (["Client", "Device", "Person"].indexOf(x.feature) > -1) && (x.action.indexOf("Search") > -1)).length === 3;
   }
   else if (resource === Pages.Escalation) {
      return userPermissions.filter(x => x.feature === "Device" && (x.action.indexOf("EscalationDetails") > -1)).length > 0;
   }
   else if (resource === Pages.UserSupport) {
      return userPermissions.filter(x => x.feature === "User" && (x.action.indexOf("SearchSupportUsers") > -1)).length > 0;
   }
   else if (resource === Pages.TripDetail) {
      return userPermissions.filter(x => x.feature === "Trip" && (x.action.indexOf("GetTripDetails") > -1)).length > 0;
   }
   else if (resource === Pages.DeviceTestReport) {
      return userPermissions.filter(x => x.feature === Pages.DeviceEvent && (x.action.indexOf("Search") > -1)).length > 0;
   }
   else {
      return userPermissions.filter(x => (x.feature === resource) && (x.action.indexOf("Search") > -1)).length > 0;
   }
}
export const getMenuPermissions = () => {
   const userPermissions = getPermissions();
   if (!userPermissions) return {};
   let permissions = userPermissions.reduce((a, v) => {
      if (v.action.indexOf("Search") > -1)
         return { ...a, [v.feature]: true }
      else
         return a;
   }, {});
   if (userPermissions.filter(x => x.feature === "User" && (x.action.indexOf("SearchSupportUsers") > -1)).length > 0) {
      permissions = { ...permissions, [Pages.UserSupport]: true };
   }
   if (userPermissions.filter(x => x.feature === Pages.DeviceEvent && (x.action.indexOf("Search") > -1)).length > 0) {
      permissions = { ...permissions, [Pages.DeviceTestReport]: true };
   }
   return permissions;
}
export const getActionPermission = (resource: string) => {
   let featureList: string[] = [];
   switch (resource) {
      case Pages.Clients:
         featureList = [Pages.Clients, Pages.Escalation, Pages.ResponseInstruction, Pages.Audit, Pages.Persons, Pages.Devices, Pages.ClientImage]
         break;
      case Pages.Devices:
         featureList = [Pages.Devices, Pages.Clients, Pages.Escalation, Pages.ResponseInstruction, Pages.Audit, Pages.Persons, Pages.MonitoringStation, Pages.DeviceEvent]
         break;
      case Pages.Persons:
         featureList = [Pages.Devices, Pages.Clients, Pages.Escalation, Pages.ResponseInstruction, Pages.Audit, Pages.Persons, Pages.Users]
         break;
      case Pages.Users:
         featureList = [Pages.Clients, Pages.Audit, Pages.Users]
         break;
      case Pages.MonitoringStation:
         featureList = [Pages.MonitoringStation, Pages.Audit, Pages.Users]
         break;
      case Pages.UserSupport:
         featureList = [Pages.Users, "Auth"]
         break;
      case Pages.TripDetail:
         featureList = [Pages.Trips, Pages.Audit]
         break;
      case Pages.DeviceTestReport:
         featureList = [Pages.DeviceEvent, Pages.Audit]
         break;
      default:
         featureList = [resource, Pages.Audit];
         break;
   }
   const userPermissions = getPermissions();
   if (!userPermissions) return {};
   const actionPermissions = userPermissions.filter(x => (featureList.indexOf(x.feature)) > -1);
   if (actionPermissions.length > 0) {
      return actionPermissions.reduce((accumulator: any, item: any) => {
         const featurePemission = item.action.reduce((a: any, v: any) => {
            return { ...a, [item.feature + ":" + v]: true };
         }, {});
         return { ...accumulator, ...featurePemission };
      }, {});
   }
   else {
      return {};
   }
}

export const getHomePageForRole = (roles: string[]) => {
   if (!roles || roles.length === 0) {
      let url = window.location.href;
      let rediectURL = url.replace(window.location.origin, '');
      localStorage.setItem("PriviousURL", rediectURL.toString());
      return "/unauthorized";
   }

   if (roles.includes("GAS Staff")) {
      let priviousURL = localStorage.getItem("PriviousURL");
      if (priviousURL != "" && priviousURL != null) {
         localStorage.setItem("PriviousURL", "");
         return priviousURL.toString();
      } else {
         return "/";
      }
   }

   if (roles.includes("Monitoring Station Admin") || roles.includes("Monitoring Station Operator")) {
      return "/alerts";
   }

   if (roles.length === 1 && roles.includes("Client Location Editor")) {
      return "/trips"
   }

   if (roles.includes("Client Admin") || roles.includes("Client Audit User") || roles.includes("Client User")) {
      if (checkViewPermission(Pages.Persons)) {
         return "/persons";
      } else {
         let pagePermissions = getMenuPermissions();
         pagePermissions = Object.keys(pagePermissions);
         if (pagePermissions.length > 0) {
            type ObjectKey = keyof typeof URLs;
            const page = pagePermissions[0] as ObjectKey;
            return "/" + URLs[page];
         } else {
            return "/";
         }
      }
   }
   return "/unauthorized";
};

export const allowDeviceAddEdit = (actionPermission: any) => {
   if (actionPermission == null) {
      actionPermission = getActionPermission(Pages.Devices);
   }
   return (actionPermission["Device:POST"] && actionPermission["Device:GET"] &&
      actionPermission["Device:GetDeviceModelSelectOption"] && actionPermission["Device:GetPlatformSelectOption"] &&
      actionPermission["MonitoringStation:GetMonitoringStationOptions"] && actionPermission["Client:GetClientsSelectOptionsForDeviceScreen"] &&
      actionPermission["Device:GetDeviceTypeSelectOption"] && actionPermission["Device:GetVehicleModelSuggestions"] &&
      actionPermission["Device:GetVehicleMakeSuggestions"] && actionPermission["Device:GetGasTagSelectOptions"] &&
      actionPermission["Device:GetMonitoredByOptions"]) === true;
}

export const allowDeviceView = (actionPermission: any) => {
   if (actionPermission == null) {
      actionPermission = getActionPermission(Pages.Devices);
   }
   return (actionPermission["Device:GET"] &&
      actionPermission["Device:GetDeviceModelSelectOption"] && actionPermission["Device:GetPlatformSelectOption"] &&
      actionPermission["MonitoringStation:GetMonitoringStationOptions"] && actionPermission["Client:GetClientsSelectOptionsForDeviceScreen"] &&
      actionPermission["Device:GetDeviceTypeSelectOption"] && actionPermission["Device:GetVehicleModelSuggestions"] &&
      actionPermission["Device:GetVehicleMakeSuggestions"] && actionPermission["Device:GetGasTagSelectOptions"] &&
      actionPermission["Device:GetMonitoredByOptions"]) === true;
}

export const allowPersonAddEdit = (actionPermission: any) => {
   if (actionPermission == null) {
      actionPermission = getActionPermission(Pages.Persons);
   }
   return (actionPermission["Person:POST"] && actionPermission["Person:GET"] &&
      actionPermission["Client:GetClientsSelectOptionsForPersonScreen"] &&
      actionPermission["Person:GetPersonRelationOptions"] && actionPermission["User:GetPersonPortalRoleOptions"]) === true;
}
