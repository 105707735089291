import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { IEscalationReportDataProps } from "../../../types/propType";
import EscalationDetailTable from "./EscalationDetailTable";
import { Common } from "../../../constants/common";
import EscalationDetailResponseInstruction from "../../../Pages/EscalationDetail/EscalationDetailResponseInstruction";
const EscalationReportRow: React.FC<IEscalationReportDataProps> = ({ record, tab }) => {
   return (
      <StyledGridRow>
         <StyledGridCell style={{ verticalAlign: "top", minWidth: "120px" }}> {record.name} </StyledGridCell>
         {tab === "Person" &&
            <StyledGridCell style={{ verticalAlign: "top", minWidth: "120px" }}> {
               record.devices && record.devices.length > 0 && record.devices.map((device: any, index: number) => {
                  return <>{device}<br /></>
               })
            } </StyledGridCell>
         }
         {tab === "Device" &&
            <StyledGridCell style={{ verticalAlign: "top", minWidth: "120px" }}> {record.personName} </StyledGridCell>
         }
         <StyledGridCell style={{ maxWidth: "80%", padding: "2px" }}>
            {record.escalationDetails && record.escalationDetails.length > 0 &&
               <EscalationDetailTable data={record.escalationDetails} />
            }
            {!Common.stringNullOrEmpty(record.responseInstruction) &&
               <>
                  <p><b>Response instruction:</b></p>
                  <EscalationDetailResponseInstruction responseInstruction={record.responseInstruction} />
               </>
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default EscalationReportRow;
