import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { IDeletedAuditLogAccrodianProps } from '../../../types/propType';
import EntityAuditLogsContents from './EntityAuditLogsContents';
const DeletedAuditLogAccrodian: React.FC<IDeletedAuditLogAccrodianProps> = ({ index, entityName, log }) => {
   const [expanded, setExpanded] = useState(false);
   return (
      <Accordion expanded={expanded} key={index} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
               <strong>{log.changedEntityValue}</strong>
            </Typography>
         </AccordionSummary>
         <AccordionDetails>
            {expanded &&
               <EntityAuditLogsContents entityName={entityName} oid={log.changedValueOid} isFromBlob={log.isFromBlob} />
            }
         </AccordionDetails>
      </Accordion >
   );
};

export default DeletedAuditLogAccrodian;


