import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import Button from "../../Common/Button";
import { MoreVert } from "@mui/icons-material";
import ExportOptions from "../../Common/ExportOptions";
import { Common } from "../../../constants/common";
interface IDevicePersonReportDownloadOptions {
   onClick: any;
   label: string;
   record: any;
}
const DevicePersonReportDownloadOptions: React.FC<IDevicePersonReportDownloadOptions> = ({ label, onClick, record }) => {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false);
   const onClickDownloadOptions = (event: any) => {
      setAnchorEl(event.currentTarget);
      setShowDownloadOptions(true);
   }
   const closeDownloadOptions = () => {
      setAnchorEl(null);
      setShowDownloadOptions(false);
   }
   return (
      <React.Fragment>
         <Button color="success" endIcon={<MoreVert />} onClick={(e: any) => onClickDownloadOptions(e)}>
            {label}
         </Button>
         <Menu
            id="download-menu"
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={showDownloadOptions}
            onClose={() => closeDownloadOptions()}
            PaperProps={{
               style: {
                  maxHeight: 50 * 4.5,
                  // width: '20ch',
               },
            }}
         >
            <ExportOptions onDownload={(exportType: string) => { closeDownloadOptions(); onClick(record, "AllColumns", exportType) }} children={(bindTrigger: any) =>
               <MenuItem {...bindTrigger}>
                  Device list
               </MenuItem>
            } />
            {(!Common.IsArrayContainRoleWithClient()) &&
               <ExportOptions onDownload={(exportType: string) => { closeDownloadOptions(); onClick(record, "PegasusColumns", exportType) }} children={(bindTrigger: any) =>
                  <MenuItem {...bindTrigger}>
                     Pegasus & Persons Columns
                  </MenuItem>
               } />
            }
            {(!Common.IsArrayContainRoleWithClient()) &&
               <ExportOptions onDownload={(exportType: string) => { closeDownloadOptions(); onClick(record, "Standard", exportType) }} children={(bindTrigger: any) =>
                  <MenuItem {...bindTrigger}>
                     Standard Report
                  </MenuItem>
               } />
            }
         </Menu>
      </React.Fragment>
   )
}

export default DevicePersonReportDownloadOptions;
