import React from "react";
import { useField } from "formik";
import {
   Checkbox,
   FormControl,
   InputLabel,
   ListItemText,
   MenuItem,
   OutlinedInput,
   Select,
   SelectChangeEvent,
} from "@mui/material";
function MultiSelect(props: any) {
   const { label, options, name, value, ...rest } = props;
   const [field, meta, helpers] = useField(name);
   const selectedValue = field.value || [];
   const isError = meta.touched && meta.error;
   const handleChange = (event: SelectChangeEvent<any>) => {
      const selectedValues = event.target.value as string[];
      helpers.setValue(selectedValues);
   };
   return (
      <FormControl {...rest} error={isError} fullWidth>
         <InputLabel>{label}</InputLabel>
         <Select
            {...field}
            multiple
            value={selectedValue}
            onChange={handleChange}
            input={<OutlinedInput label={label} />}
            renderValue={(selectedValue) => {
               let selectedList = selectedValue.map((value: string) => {
                  return options.find((item: any) => item.value === value)?.label;
               });
               return selectedList.join(", ");
            }}
         >
            {options.map((data: any) => (
               <MenuItem key={data.value} value={data.value}>
                  <Checkbox checked={selectedValue.indexOf(data.value) > -1} />
                  <ListItemText primary={data.label} />
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   );
}
export default MultiSelect;





