import React from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import CustomSelect from '../../Common/CustomSelect';
import CustomDatePicker from '../../Common/CustomDatePicker';
import { IAdvanceFilterProps } from '../../../types/propType';
import ClientService from '../../../services/client.service';
import { ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { Common } from '../../../constants/common';

const DevicePersonReportAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const assignmentOptions = [
      { label: "All", value: "All" },
      { label: "Assigned", value: "Assigned" },
      { label: "Unassigned", value: "Unassigned" }
   ];
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   return (
      <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, handleChange, submitForm, setFieldValue, setFieldTouched }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Client Name"
                        id="ClientOid"
                        inputProps={{
                           required: true,
                           name: "ClientOid",
                        }}
                        value={values.ClientOid}
                        onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("ClientOid", value?.value);
                           setFieldValue("ClientName", value?.label);
                           values.ClientOid = value?.value;
                           values.ClientName = value?.label;
                           onAdvanceSearch(values);
                        }}
                        setTouched={() => {
                           setFieldTouched("ClientOid", true);
                        }}
                        isClientSelection={true}
                     />
                  </Grid>
                  {initialValues.showCurrent &&
                     <Grid item xs={12} sm={6} md={4}>
                        <CustomSelect
                           labelText="Assignment"
                           id="Assignment"
                           inputProps={{
                              name: "Assignment",
                              onChange: (e: any) => {
                                 handleChange(e);
                                 submitForm();
                              }
                           }}
                           value={values.Assignment}
                           options={assignmentOptions}
                        />
                     </Grid>
                  }
                  {!initialValues.showCurrent &&
                     <Grid item xs={12} sm={6} md={4}>
                        <CustomDatePicker
                           views={['year', 'month']}
                           inputFormat="MMM-yyyy"
                           openTo="month"
                           labelText="Month - Year"
                           id="Date"
                           disableFuture={true}
                           inputProps={{
                              name: "Date",
                              onChange: (e: any) => {
                                 if (e) {
                                    setFieldValue("Date", Common.getLocalToUTC(e));
                                    submitForm();
                                 }
                              }
                           }}
                           value={Common.getUtcToLocal(values.Date)}
                        />
                     </Grid>
                  }
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default DevicePersonReportAdvanceFilter;
