import { Controllers } from "../constants/constant";
import request from "../utils/request";

class DevicePersonReportService {
   searchDevicePersonReportRecords = (params: any) => {
      return request({
         url: `${Controllers.DevicePersonReport}/Search`,
         method: 'get',
         params
      });
   }

   exportDevicePersonRecords = (params: any) => {
      return request({
         url: `${Controllers.DevicePersonReport}/Export`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }

   searchPreviousReportFiles = (params: any) => {
      return request({
         url: `${Controllers.DevicePersonReport}/SearchPreviousReportFiles`,
         method: 'get',
         params
      });
   }

   exportPreviousReport = (params: any) => {
      return request({
         url: `${Controllers.DevicePersonReport}/ExportPreviousReport`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }
}

export default new DevicePersonReportService();
