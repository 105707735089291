import { Controllers } from "../constants/constant";
import request from "../utils/request";
import { ITripLocation, IDeleteParams } from "../types/dataTypes";

class LocationService {
   searchLocations = (params: any) => {
      return request({
         url: `${Controllers.Location}/Search`,
         method: 'get',
         params
      });
   }
   saveLocation = (data: ITripLocation) => {
      return request({
         url: `${Controllers.Location}`,
         method: 'post',
         data,
      })
   }
   deleteLocation = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.Location}`,
         method: 'delete',
         params
      })
   }
   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.Location}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }

   getTripLocationSelectOptions = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Location}/GetLocationOptionsWithGeoMetry`,
         method: 'get',
         params,
         signal
      })
   }

   getLocationTypeSuggestion = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Location}/GetLocationTypeSuggestion`,
         method: 'get',
         params,
         signal
      })
   }

   getLocation = (params: any) => {
      return request({
         url: `${Controllers.Location}`,
         method: 'get',
         params
      })
   }

   import(data: FormData) {
      return new Promise(function (resolve, reject) {
         request({
            url: `${Controllers.Location}/Import`,
            method: 'post',
            data,
            headers:
            {
               'Content-Type': 'multipart/form-data'
            }
         }).then(res => {
            return resolve(res);
         }).catch(err => {
            return reject(err);
         });
      })
   }

   getLocationForMapViewPort = () => {
      return request({
         url: `${Controllers.Location}/GetLocationForMapViewPort`,
         method: 'get',
      })
   }

   getLocationsDetails = (params: any) => {
      return request({
         url: `${Controllers.Location}/GetLocationsDetails`,
         method: 'get',
         params
      });
   }
}

export default new LocationService();
