import React from 'react'
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
interface IGoogleMapSearchLocation {
   inputId: string;
}
export const GoogleMapSearchLocation: React.FC<IGoogleMapSearchLocation> = ({ inputId }) => {
   return (
      <Paper
         component="div"
         style={{ zIndex: 200000 }}
         sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
      >
         <IconButton disabled={true} sx={{ p: '10px' }} aria-label="menu">
            <SearchIcon />
         </IconButton>
         <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Google Maps"
            inputProps={{
               id: `pac-input-${inputId}`,
               'aria-label': 'search google maps',
               onKeyDown: (event: any) => {
                  if (event.key === "Enter") {
                     if (event.target.type !== 'submit') {
                        event.preventDefault();
                        return false;
                     }
                  }
               }
            }}
         />
         <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
         <IconButton disabled={true} color="primary" sx={{ p: '10px' }} aria-label="directions">
            <DirectionsIcon />
         </IconButton>
      </Paper>
   );
}
export const AutoComplete = (map: google.maps.Map | undefined, inputId: string) => {
   if (map) {
      const input = document.getElementById(`pac-input-${inputId}`) as HTMLInputElement;

      // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      const searchBox = new google.maps.places.SearchBox(input);
      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
         searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });
      let markers: google.maps.Marker[] = [];
      searchBox.addListener("places_changed", () => {
         const places = searchBox.getPlaces();

         if (!places || places.length === 0) {
            return;
         }

         // Clear out the old markers.
         markers.forEach((marker) => {
            marker.setMap(null);
         });
         markers = [];

         // For each place, get the icon, name and location.
         const bounds = new google.maps.LatLngBounds();

         places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
               return;
            }

            const icon = {
               url: place.icon as string,
               size: new google.maps.Size(71, 71),
               origin: new google.maps.Point(0, 0),
               anchor: new google.maps.Point(17, 34),
               scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
               new google.maps.Marker({
                  map,
                  icon,
                  title: place.name,
                  position: place.geometry.location,
               })
            );

            if (place.geometry.viewport) {
               // Only geocodes have viewport.
               bounds.union(place.geometry.viewport);
            } else {
               bounds.extend(place.geometry.location);
            }
         });
         map.fitBounds(bounds);
      });
   }
}
