import { Grid, FormHelperText } from "@mui/material";
import React from "react";
import ValidationSummary from './ValidationSummary';
interface ICustomValidationSummaryProps {
   serverErrors: any;
   formikErrors: any;
}
const CustomValidationSummary: React.FC<ICustomValidationSummaryProps> = ({ serverErrors, formikErrors }) => {
   return (
      <>
         {(serverErrors["errors"] || serverErrors["message"] || (formikErrors && Object.values(formikErrors).length > 0)) &&
            <Grid item xs={12} md={12}>
               <div style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "5px" }}>
                  <strong style={{ color: "#d32f2f" }}>List of errors</strong>
                  <ValidationSummary errors={serverErrors["errors"] ?? serverErrors["message"]} />
                  {formikErrors && Object.values(formikErrors).map((validationError: any, index: number) => (
                     <React.Fragment key={`error-${index}`}>
                        {
                           !validationError ? <></> :
                              typeof validationError === "string" ?
                                 <FormHelperText id={`error-${index}`} error>{validationError}</FormHelperText>
                                 :
                                 Object.values(validationError).map((childValidationError: any, index2: number) => (
                                    <FormHelperText id={`error-${index}-${index2}`} error>{childValidationError}</FormHelperText>
                                 ))
                        }
                     </React.Fragment>
                  ))}
               </div>
            </Grid>
         }
      </>
   )
}
export default CustomValidationSummary;
