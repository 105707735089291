import { Theme } from "@mui/material";

const headerStyle = (theme:Theme) => ({
   appBar: {
      padding: theme.spacing(1)
   },
   menubar: {
      marginLeft: "auto",
      alignItems: "center",
      marginRight: theme.spacing(2)
   },
   logInAsUser: {
      marginLeft: "auto",
      alignItems: "center",
      marginRight: theme.spacing(2)
   },
});

export default headerStyle;
