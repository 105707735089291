import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { withStyles } from 'tss-react/mui';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import fileUploadStyle from '../../assets/jss/pages/fileUploadStyle';
import { Common } from '../../constants/common';
import { ICustomImageUploaderProps } from '../../types/propType';
import Image from "./../Common/Image";
import Button from './Button';

const CustomImageUploader: React.FC<ICustomImageUploaderProps> = ({ classes, fileTypes, onUploadClick }) => {
   const [error, setError] = useState<string>("");
   const [file, setFile] = useState<any>(null);

   const handleChange = (file: any) => {
      setError("");
      setFile(file);
   };

   const onSizeError = (error: any) => {
      setError(error + `. Maximum ${Common.MaxFileSize} MB is allowed.`);
      setFile(null);
   }

   return (
      <div className={classes.container}>
         <FileUploader handleChange={handleChange} maxSize={Common.MaxFileSize} onSizeError={onSizeError} types={fileTypes}>
            <label className={classes.fileUploadBlock}>
               <UploadFileIcon style={{ height: 45, width: 45, color: "#666" }} />
               <div className={classes.content}>
                  {file !== null ?
                     <p>
                        <span className={classes.success}>File selected</span>
                        {!Common.stringNullOrEmpty(file?.name) &&
                           <div> <strong>File name:</strong> <span>{file?.name}</span></div>
                        }
                     </p>
                     : <p>
                        {
                           !Common.stringNullOrEmpty(error) &&
                           <span className={classes.error}>{error}</span>
                        }
                        <div><span>Upload or drop a file right here</span></div>
                     </p>
                  }

                  <span className={classes.fileTypes}>{fileTypes.join()}</span>
               </div>
            </label>
         </FileUploader>
         {file &&
            <>
               <Image
                  src={URL.createObjectURL(file)}
                  zoom={true}
                  style={{ height: "100px", maxWidth: "100px", marginLeft: "1em", marginTop: "auto", marginBottom: "auto" }}
               />
               <Button color="primary" style={{ marginLeft: "1em", marginTop: "auto", marginBottom: "auto" }} onClick={() => { onUploadClick(file); setFile(null); }}>Upload</Button>
            </>
         }
      </div>
   );
};

export default withStyles(CustomImageUploader, fileUploadStyle);
