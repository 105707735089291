import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import { Grid } from '@mui/material';

import CustomSelect from '../../Common/CustomSelect';
import { IAdvanceFilterProps } from '../../../types/propType';

import clientService from '../../../services/client.service';
import { StatusCode } from '../../../constants/constant';
import { Common } from '../../../constants/common';

const ClientAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const [plans, setPlans] = useState([]);
   useEffect(() => {
      clientService.getPlansSelectOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setPlans(res?.data);
         }
      });
   }, []);
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, handleChange, submitForm }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                     <CustomSelect
                        labelText="Plan"
                        id="Plan"
                        inputProps={{
                           name: "Plan",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        value={values.Plan}
                        options={[{ label: "All", value: Common.EmptyGuid }].concat(plans)}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                     <CustomSelect
                        labelText="Country"
                        id="Country"
                        inputProps={{
                           name: "Country",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        value={values.Country}
                        options={[{ label: "All", value: "All" }].concat(clientService.getCountrySelectOption())}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default ClientAdvanceFilter;
