import { Theme } from "@mui/material";
import { grayColor } from "../appStyle";

const paginationStyle = (theme: Theme) => ({
   gridFooter: {
      padding: "12px 0",
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      borderTop:`2px solid ${grayColor[0]}`
   },
   pageSelectOption: { marginTop:theme.spacing(1),paddingBottom: theme.spacing(3), textAlign: "center" },
   pageSelectOptionLabel: { marginRight: theme.spacing(1) },
   rightBlock: { marginLeft: "auto" }
});

export default paginationStyle;
