import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import DevicePersonReportRow from "../../components/pages/DevicePersonReport/DevicePersonReportRow";
import DevicePersonReportCard from "../../components/pages/DevicePersonReport/DevicePersonReportCard";
import DevicePersonReportAdvanceFilter from "../../components/pages/DevicePersonReport/DevicePersonReportAdvanceFilter";
import { IDevicePersonReportPageState } from "../../types/stateType";
import { IDevicePageProps, IDownloadOption } from "../../types/propType";
import { StatusCode } from "../../constants/constant";
import { IDevicePersonReportFilter } from "../../types/dataTypes";
import { TableHeader } from "../../constants/tableHeader";
import DevicePersonReportService from "../../services/devicePersonReport.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import Button from "../../components/Common/Button";
import { ManageHistory } from "@mui/icons-material";
import DevicePersonReportDownloadOptions from "../../components/pages/DevicePersonReport/DevicePersonReportDownloadOptions";
class DevicePersonReport extends React.Component<IDevicePageProps, IDevicePersonReportPageState> {
   filterOptions: IDevicePersonReportFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      Assignment: "All",
      ClientOid: Common.EmptyGuid,
      ClientName: "",
      frontendURL: window.location.origin,
      Date: Common.getFormattedDate(new Date()),
      ReportType: "All",
      sortString: "DeviceId"
   }

   state: IDevicePersonReportPageState = {
      devicesPersonRecords: [],
      totalRecords: 0,
      showCurrent: true,
      openAuditLogs: false,
   };

   // componentDidMount() {
   //    if (!this.props.isFromSearch) {
   //       this.searchDevicePersonRecords();
   //    }
   // }

   //#region pagination and searching
   searchDevicePersonRecords = () => {
      let newFilterOptions: IDevicePersonReportFilter = { ...this.filterOptions };
      if (!newFilterOptions.ClientOid || newFilterOptions.ClientOid === Common.EmptyGuid) {
         this.setState({ ...this.state, devicesPersonRecords: [] });
         return;
      }
      const { showCurrent } = this.state;
      this.setState({ ...this.state, devicesPersonRecords: [] }, () => {
         if (showCurrent) {
            DevicePersonReportService.searchDevicePersonReportRecords(newFilterOptions).then((res: any) => {
               if (res?.status === StatusCode.Success && res?.data) {
                  this.setState({
                     ...this.state,
                     devicesPersonRecords: res?.data,
                     totalRecords: res?.data[0]?.totalRecord,
                  });
               }
            });
         }
         else {
            let param: any = {
               ClientOid: this.filterOptions.ClientOid,
               Date: Common.getFormattedDate(this.filterOptions.Date),
            }
            DevicePersonReportService.searchPreviousReportFiles(param).then((res: any) => {
               if (res?.status === StatusCode.Success && res?.data) {
                  this.setState({
                     ...this.state,
                     devicesPersonRecords: res?.data,
                     totalRecords: res?.data?.length ?? 0,
                  });
               }
            });
         }
      });
   }

   fetchMoreData = () => {
      if (this.state.devicesPersonRecords.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IDevicePersonReportFilter = { ...this.filterOptions };
      if (!newFilterOptions.ClientOid || newFilterOptions.ClientOid === Common.EmptyGuid) {
         this.setState({ ...this.state, devicesPersonRecords: [] });
         return;
      }
      DevicePersonReportService.searchDevicePersonReportRecords(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let deviceList = [...this.state.devicesPersonRecords];
            deviceList = deviceList.concat(res?.data);
            this.setState({
               ...this.state,
               devicesPersonRecords: deviceList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchDevicePersonRecords();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchDevicePersonRecords();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      // if (val.length > Common.SearchAfterCount) {
      this.searchDevicePersonRecords();
      // }
   }

   onAdvanceSearch = (advanceFilter: any) => {
      this.filterOptions.Assignment = advanceFilter.Assignment;
      this.filterOptions.ClientOid = advanceFilter.ClientOid;
      this.filterOptions.ClientName = advanceFilter.ClientName;
      this.filterOptions.Date = advanceFilter.Date;
      this.filterOptions.page = 1;
      this.searchDevicePersonRecords();
   }
   //#endregion
   //#region Download   
   onDownload = (reportType: string, exportType: string) => {
      let newFilterOptions: IDevicePersonReportFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      newFilterOptions.ReportType = reportType;
      DevicePersonReportService.exportDevicePersonRecords(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `DevicesListReport_${reportType}_${this.filterOptions.ClientName}`;
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   onDownloadAll = (exportType: string) => {
      this.onDownload("AllColumns", exportType);
   }
   onDownloadPegasus = (exportType: string) => {
      this.onDownload("PegasusColumns", exportType);
   }
   onDownloadStandard = (exportType: string) => {
      this.onDownload("Standard", exportType);
   }
   ExportPreviousReport = (fileName: string, reportType: string, exportType: string) => {
      let newFilterOptions: IDevicePersonReportFilter = { ...this.filterOptions };

      let params: any = {
         ClientOid: newFilterOptions.ClientOid,
         FileName: fileName,
         frontendURL: newFilterOptions.frontendURL,
         ReportType: reportType,
         exportType: exportType
      };
      DevicePersonReportService.exportPreviousReport(params).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `DevicePersonReport_${reportType}_${this.filterOptions.ClientName}_${Common.getFileNameFromPathWithOutExtension(fileName)}`;
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   //#endregion

   toggleCurrentPrevious = () => {
      const { showCurrent } = this.state;
      this.setState({
         ...this.state,
         showCurrent: !showCurrent,
         devicesPersonRecords: [],
         totalRecords: 0
      }, () => {
         this.filterOptions.page = 1;
         this.searchDevicePersonRecords();
      });

   }
   render() {
      const { totalRecords, devicesPersonRecords, showCurrent } = this.state;
      const { actionPermission } = this.props;
      const { ClientOid } = this.filterOptions;
      const downloadOptions: IDownloadOption[] = [];
      if (actionPermission["DevicePersonReport:Export"] && totalRecords > 0 && showCurrent)
         downloadOptions.push({ name: "Device list", onClick: this.onDownloadAll });

      if (!Common.IsArrayContainRoleWithClient()) {
         if (actionPermission["DevicePersonReport:Export"] && totalRecords > 0 && showCurrent) {
            downloadOptions.push({ name: "Pegasus & Persons Columns", onClick: this.onDownloadPegasus });
            downloadOptions.push({ name: "Standard Report", onClick: this.onDownloadStandard });
         }
      }
      return (
         <PageLayout pageTitle="Device Person Report" searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={null}
            actionPermission={{
               download: actionPermission["DevicePersonReport:Export"] && totalRecords > 0 && showCurrent,
            }}
            downloadOptions={downloadOptions}
            advanceFilter={<DevicePersonReportAdvanceFilter initialValues={{ ClientOid: this.filterOptions.ClientOid, Assignment: this.filterOptions.Assignment, showCurrent: showCurrent }} onAdvanceSearch={this.onAdvanceSearch} />}
            showAdvanceFilerAlways={true}
            customAction={
               actionPermission["DevicePersonReport:SearchPreviousReportFiles"] && actionPermission["DevicePersonReport:ExportPreviousReport"] ?
                  <Button color={showCurrent ? "info" : "success"} startIcon={<ManageHistory />} onClick={this.toggleCurrentPrevious}>
                     {showCurrent ? "Previous" : "Current"}
                  </Button>
                  : null
            }
         >
            {showCurrent &&
               <>
                  {/* list web view */}
                  < Box sx={{ display: { xs: 'none', md: 'block' } }}>
                     <CustomGrid headers={TableHeader.getDevicePersonReportTableHeaders()} pagingInfoText="Devices" onSortingChange={this.onSortingChange}
                        totalRecord={totalRecords ? totalRecords : devicesPersonRecords?.length} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                        {devicesPersonRecords && devicesPersonRecords?.map((record: any, index: number) => {
                           return (
                              <DevicePersonReportRow
                                 key={`row_${index}`}
                                 record={record}
                              />
                           );
                        })}
                     </CustomGrid>
                  </Box>

                  {/* list mobile view */}
                  <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                     <CustomInfiniteScroll dataLength={devicesPersonRecords ? devicesPersonRecords.length : 0} next={this.fetchMoreData} hasMore={true}>
                        <Grid container spacing={2}>
                           {devicesPersonRecords && devicesPersonRecords?.map((record: any, index: number) => {
                              return (
                                 <DevicePersonReportCard
                                    key={`card_${index}`}
                                    record={record}
                                 />
                              );
                           })}
                        </Grid>
                     </CustomInfiniteScroll>
                  </Box>
               </>
            }
            {!showCurrent &&
               <>
                  {(!ClientOid || ClientOid === Common.EmptyGuid) &&
                     <div className="empty-state page-size">
                        <h6 className="text-lg">Select client name to view files.</h6>
                     </div>
                  }
                  {
                     (!totalRecords || totalRecords === 0) &&
                     <div className="empty-state page-size">
                        <h5 className="text-dark medium">No record(s) found</h5>
                        <p className="text-lg">We can’t find any results which match your search criteria</p>
                     </div>
                  }
                  <Box>
                     <Grid container spacing={2}>
                        {devicesPersonRecords && devicesPersonRecords?.map((record: any, index: number) => {
                           return (
                              <Grid key={`file_${index}`} item xs={6} md={3}>
                                 <DevicePersonReportDownloadOptions
                                    label={Common.getFileNameFromPathWithOutExtension(record)}
                                    onClick={this.ExportPreviousReport}
                                    record={record}
                                 />
                              </Grid>
                           );
                        })}
                     </Grid>
                  </Box>
               </>
            }
         </PageLayout>
      )
   }
}

export default withParams(DevicePersonReport);
