import * as React from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormHelperText, FormControl, FormLabel } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { Common } from "../../constants/common";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

function CustomRichInput({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      customErrorMessage,
      setValue,
      value,
      disabled
   } = props;
   const buttonsList = [
      [
         "undo",
         "redo",
         "font",
         "fontSize",
         "formatBlock",
         "align",
         "horizontalRule",
         "list",
         "bold",
         "underline",
         "italic",
         "strike",
         "subscript",
         "superscript",
         "removeFormat",
         "fontColor",
         "hiliteColor",
         "outdent",
         "indent",
         "image",
         "table",
         "fullScreen",
         "preview"
      ],
   ];
   const [field, meta] = useField(inputProps);
   return (
      <FormControl {...formControlProps} fullWidth className={formControlProps?.className + " " + classes.formControl}>
         <FormLabel id={`richtText_${id}`} required={inputProps.required}>{labelText} </FormLabel>
         <SunEditor
            {...field}
            setContents={value}
            height="200"
            setOptions={{
               buttonList: buttonsList,
               showPathLabel: false,
               className: "richText"
            }}
            onChange={setValue}
            disable={disabled} />
         {meta.touched && meta.error && meta.error.length > 0 &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
         {
            !Common.stringNullOrEmpty(customErrorMessage) &&
            <FormHelperText id={`error-${inputProps.name}`} error>{customErrorMessage}</FormHelperText>
         }
      </FormControl>
   );
}

CustomRichInput.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   rows: PropTypes.number,
   type: PropTypes.string,
   defaultValue: PropTypes.string,
};
export default React.memo(withStyles(CustomRichInput, customInputStyle));
