export const GeoJsonLocationPoint = `{
   "geometry": {
     "type": "Point",
     "coordinates": [
       176.126037046875,
       -38.3546860477432
     ]
   },
   "properties": {
     "id": "24435",
     "title": "72_11251 - Bores (Waikato Region)@Opus International Consultants Limited",
     "description": "Bores (Waikato Region)",
     "location_type": "Security Register",
     "physical_address": "Opus International Consultants Limited"
   }
 }`;
export const GeoJsonLocationPolygon = `{
   "geometry": {
     "type": "Polygon",
     "coordinates": [
         [
            [174.7698076341776,-36.857598230177764],
            [174.77504330617467,-36.853134185551326],
            [174.7796781633524,-36.85752955454279],
            [174.78165226918736,-36.86206201408316],
            [174.7759874437479,-36.8641908038114],
            [174.77289753896275,-36.86453415143922],
            [174.77023678761998,-36.861649983414715],
            [174.77298336965123,-36.85910907853475],
            [174.7698076341776,-36.857598230177764]
         ]
      ]
   },
   "properties": {
     "id": "11111",
     "title": "Auckland war Memorial Museum upload",
     "description": "Auckland war Memorial Museum",
     "location_type": "Museum",
     "physical_address": "Auckland war Memorial Museum"
   }
 }`;
export const GeoJsonHazardPoint = `{
   "geometry": {
     "type": "Polygon",
     "coordinates": [
       [
         [
           175.263248119827,
           -37.7691886274024
         ],
         [
           175.263358005019,
           -37.7692317782237
         ],
         [
           175.26345731418,
           -37.7692956095575
         ],
         [
           175.263542202736,
           -37.7693776502891
         ],
         [
           175.263609384381,
           -37.76947472436
         ],
         [
           175.263656258299,
           -37.7695830737232
         ],
         [
           175.26368100985,
           -37.7696985038292
         ],
         [
           175.263682680823,
           -37.7698165460102
         ],
         [
           175.26366120653,
           -37.7699326304768
         ],
         [
           175.263617418309,
           -37.7700422632288
         ],
         [
           175.263553011341,
           -37.7701412000328
         ],
         [
           175.263470479026,
           -37.7702256107292
         ],
         [
           175.263373016454,
           -37.7702922275108
         ],
         [
           175.263233368236,
           -37.7703687056672
         ],
         [
           175.263120434905,
           -37.7704162848692
         ],
         [
           175.26300019014,
           -37.7704399262298
         ],
         [
           175.262877650055,
           -37.7704386435286
         ],
         [
           175.26275792652,
           -37.7704124902747
         ],
         [
           175.262646013906,
           -37.770362557474
         ],
         [
           175.262546580748,
           -37.7702909281176
         ],
         [
           175.262463774987,
           -37.7702005902871
         ],
         [
           175.262401050939,
           -37.7700953125049
         ],
         [
           175.262361025193,
           -37.7699794865265
         ],
         [
           175.262331884748,
           -37.7698552662079
         ],
         [
           175.262316546484,
           -37.7697431954437
         ],
         [
           175.262322514956,
           -37.7696302375052
         ],
         [
           175.262349578032,
           -37.769520407143
         ],
         [
           175.262396773836,
           -37.7694176079475
         ],
         [
           175.262462424935,
           -37.7693254936071
         ],
         [
           175.262544197959,
           -37.7692473380495
         ],
         [
           175.26263918653,
           -37.7691859190791
         ],
         [
           175.262744014565,
           -37.7691434196488
         ],
         [
           175.262854956266,
           -37.769121350273
         ],
         [
           175.262968068545,
           -37.7691204953415
         ],
         [
           175.263079331165,
           -37.7691408852402
         ],
         [
           175.263248119827,
           -37.7691886274024
         ]
       ]
     ]
   },
   "properties": {
     "id": "465656",
     "notes": "Do not access without contacting onsite contact",
     "status": "Current",
     "warning": "Dangerous chemicals are stored on site",
     "hazard_type": "Chemicals Hazard",
     "display_flag": 2,
     "onsite_contact": "Alison Burg",
     "security_level": 2,
     "security_level_description": "Mild risk of harm to council staff. Wear protective gear",
     "onsite_contact_phone_number": "021 444 999"
   }
 }`;
export const GeoJsonHazardMultiPolygon = `{   
   "geometry": {
     "type": "MultiPolygon",
     "coordinates": [
       [
         [
            [170.80053491303718,-45.06875914848053],
            [170.87743920991218,-45.06875914848053],
            [170.72637719819343,-45.12110960961299],
            [170.71539087006843,-45.07263860619128]
         ],
         [
            [170.7715241403321,-45.058089282008744],
            [170.8154694528321,-45.05469390687855],
            [170.79040689179695,-45.04935791005769]
         ]
       ]
     ]
   },
   "properties": {
     "id": "466082",
     "notes": "Do not access without contacting onsite contact",
     "status": "Current",
     "warning": "Dangerous chemicals are stored on site",
     "hazard_type": "Chemicals Hazard",
     "display_flag": 2,
     "onsite_contact": "Alison Burg",
     "security_level": 2,
     "security_level_description": "Mild risk of harm to council staff. Wear protective gear",
     "onsite_contact_phone_number": "021 444 999"
   }
 }`;
