import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { IAddEditHazardLocationProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import { IGetParams, IHazardLocation, ISearchableSelectParams } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { StatusCode } from '../../../constants/constant';
import HazardLocationService from '../../../services/hazardLocation.service';
import { mapHazardLocationObject } from '../../../utils/commonMaps';
import HazardLocationGeometryPicker from '../../Common/HazardLocationGeometryPicker';
import CustomSelect from '../../Common/CustomSelect';
import SearchableServerInput from '../../Common/SearchableServerInput';
import { ConnectedFocusError } from '../../Common/FocusError';
import { Common } from '../../../constants/common';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
const AddEditHazardLocation: React.FC<IAddEditHazardLocationProps> = ({ open, close, successCallback, hazardLocationOid, allowSubmit }) => {
   const [error, setError] = useState<any>({});
   let initialValues: IHazardLocation = {
      Type: "",
      SecurityLevel: null,
      SecurityLevelDescription: "",
      Status: "",
      Warning: "",
      OnsiteContact: "",
      OnsiteContactPhoneNumber: "",
      Notes: "",
      Id: null,
      GeometryType: "Polygon",
      HazardLocationGeometry: []
   }
   const [formValues, setFormValues] = useState<IHazardLocation>(initialValues);
   const validationSchema = Yup.object().shape({
      Type: Yup.string().trim(Resources.Error_HazardLocationType_Required).required(Resources.Error_HazardLocationType_Required),
      SecurityLevel: Yup.number().typeError(Resources.Error_NotValidNumber).required(Resources.Error_HazardLocationSecurityLevel_Required),
      SecurityLevelDescription: Yup.string().trim(Resources.Error_HazardLocationSecurityLevelDescription_Required).required(Resources.Error_HazardLocationSecurityLevelDescription_Required),
      Status: Yup.string().trim(Resources.Error_HazardLocationStatus_Required).required(Resources.Error_HazardLocationStatus_Required),
      Warning: Yup.string().trim(Resources.Error_HazardLocationWarning_Required).required(Resources.Error_HazardLocationWarning_Required),
      OnsiteContact: Yup.string().trim(Resources.Error_HazardLocationOnsiteContact_Required).required(Resources.Error_HazardLocationOnsiteContact_Required),
      OnsiteContactPhoneNumber: Yup.string().trim(Resources.Error_HazardLocationOnsiteContactPhoneNumber_Required).required(Resources.Error_HazardLocationOnsiteContactPhoneNumber_Required),
      Notes: Yup.string().trim(Resources.Error_HazardLocationNotes_Required).required(Resources.Error_HazardLocationNotes_Required),
      HazardLocationGeometry: Yup.array().min(1, Resources.Error_HazardLocationGeometry_Required)
   });
   useEffect(() => {
      if (hazardLocationOid) {
         const getMethodParams: IGetParams = {
            oid: hazardLocationOid
         };
         HazardLocationService.getHazardLocation(getMethodParams).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setFormValues(mapHazardLocationObject(res?.data));
            }
         })
      }
   }, [hazardLocationOid]);
   const onSubmit = (values: any, setServerError: any) => {
      HazardLocationService.saveHazardLocation({ ...values }).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }
   const getHazardTypeSuggestion = (params: ISearchableSelectParams, signal: any) => {
      return HazardLocationService.getHazardTypeSuggestion(params, signal);
   }
   return (
      <Dialog style={{ zIndex: 1 }} open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <Formik enableReinitialize={true} initialValues={formValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, setFieldValue, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{hazardLocationOid ? "Edit" : "Add"} Hazard Location</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <input type="hidden" name="Oid" value={values.Oid} />
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                           <SearchableServerInput
                              labelText="Hazard Type"
                              id="Type"
                              inputProps={{
                                 required: true,
                                 name: "Type"
                              }}
                              customErrorMessage={error["type"]?.message}
                              value={values.Type}
                              setValue={(value: string) => {
                                 setFieldValue("Type", value)
                              }}
                              onTextChange={getHazardTypeSuggestion}
                           />
                        </Grid>

                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Security Level"
                              id="SecurityLevel"
                              inputProps={{
                                 required: true,
                                 name: "SecurityLevel",
                                 type: "number",
                                 InputLabelProps: { shrink: values.SecurityLevel && values.SecurityLevel >= 0 ? true : false }
                              }}
                              customErrorMessage={error["securityLevel"]?.message}
                              value={values.SecurityLevel}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Security Level Description"
                              id="SecurityLevelDescription"
                              inputProps={{
                                 required: true,
                                 name: "SecurityLevelDescription"
                              }}
                              customErrorMessage={error["securityLevelDescription"]?.message}
                              value={values.SecurityLevelDescription}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Status"
                              id="Status"
                              inputProps={{
                                 required: true,
                                 name: "Status"
                              }}
                              customErrorMessage={error["status"]?.message}
                              value={values.Status}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Warning"
                              id="Warning"
                              inputProps={{
                                 required: true,
                                 name: "Warning"
                              }}
                              customErrorMessage={error["warning"]?.message}
                              value={values.Warning}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Onsite Contact"
                              id="OnsiteContact"
                              inputProps={{
                                 required: true,
                                 name: "OnsiteContact"
                              }}
                              customErrorMessage={error["onsiteContact"]?.message}
                              value={values.OnsiteContact}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Onsite Contact Phone Number"
                              id="OnsiteContactPhoneNumber"
                              inputProps={{
                                 required: true,
                                 name: "OnsiteContactPhoneNumber"
                              }}
                              customErrorMessage={error["onsiteContactPhoneNumber"]?.message}
                              value={values.OnsiteContactPhoneNumber}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Notes"
                              id="Notes"
                              inputProps={{
                                 required: true,
                                 name: "Notes"
                              }}
                              customErrorMessage={error["notes"]?.message}
                              value={values.Notes}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Id"
                              id="Id"
                              inputProps={{
                                 required: false,
                                 name: "Id",
                                 disabled: true,
                                 InputLabelProps: { shrink: values.Id ? true : false }
                              }}
                              customErrorMessage={error["id"]?.message}
                              value={values.Id}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomSelect
                              labelText="Geometry Type"
                              id={`GeometryType`}
                              inputProps={{
                                 required: true,
                                 name: `GeometryType`,
                                 onChange: (e: any) => {
                                    setFieldValue("GeometryType", e.target.value);
                                    if (values.HazardLocationGeometry.length > 0)
                                       setFieldValue("HazardLocationGeometry", [values.HazardLocationGeometry[0]]);
                                    else
                                       setFieldValue("HazardLocationGeometry", []);
                                 }
                              }}
                              value={values.GeometryType}
                              options={[{ label: "Polygon", value: "Polygon" }, { label: "MultiPolygon", value: "MultiPolygon" }]}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <HazardLocationGeometryPicker
                              value={values.HazardLocationGeometry}
                              setValue={(value: any) => {
                                 setFieldValue("HazardLocationGeometry", value);
                              }}
                              selectionTypes={["Polygon"]}
                              defaultType={"Polygon"}
                              geometryType={values.GeometryType}
                           />
                        </Grid>
                        <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     {
                        allowSubmit &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddEditHazardLocation;
