import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import Button from '../../Common/Button';
import SearchableSelect from '../../Common/SearchableSelect';
import SearchableServerSearch from '../../Common/SearchableServerSearch';

import { IAssignDeviceToPersonProps } from '../../../types/propType';
import { IAssignDeviceToPerson, ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import { Common } from '../../../constants/common';
import { Resources } from '../../../constants/resources';

import PersonService from '../../../services/person.service';

const AssignDeviceToPerson: React.FC<IAssignDeviceToPersonProps> = ({ open, close, successCallback, deviceOid, clientOid, deviceId, personOid, deleteOnSuccess }) => {
   const [devices] = useState<ISelectOption[]>([{ label: deviceId ? deviceId : "", value: deviceOid ? deviceOid : "" }]);

   let initialValues: IAssignDeviceToPerson = {
      ClientOid: clientOid,
      PersonOid: personOid,
      DeviceOid: deviceOid
   };

   const validationSchema = Yup.object().shape({
      PersonOid: Yup.string().trim(Resources.Error_Person_Required).required(Resources.Error_Person_Required),
   });
   const searchPersonSelectionOptions = (params: ISearchableSelectParams, signal: any) => {
      return PersonService.getClientSpecificPersonSelectOptions(clientOid, deviceOid, false, params, signal);
   }
   const onSubmit = (values: IAssignDeviceToPerson) => {
      if (!Common.stringNullOrEmpty(clientOid) && clientOid !== Common.EmptyGuid) {
         PersonService.assignDeviceToPerson(values).then(() => {
            successCallback()
         });
      }
   }

   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">
                     {deleteOnSuccess ?
                        "Unassign device to delete" : "Assign device to person"
                     }</DialogTitle>
                  <DialogContent dividers>
                     <input type="hidden" name="ClientOid" value={values.ClientOid} />

                     <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <SearchableSelect
                              labelText="Device"
                              id="DeviceOid"
                              inputProps={{
                                 required: true,
                                 name: "DeviceOid",
                                 disableClearable: true,
                                 disabled: true,
                              }}
                              value={values.DeviceOid}
                              options={devices}
                              setValue={(value: ISelectOption) => {
                                 setFieldValue("DeviceOid", value?.value)
                              }}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <SearchableServerSearch
                              labelText="Person"
                              id="PersonOid"
                              inputProps={{
                                 required: true,
                                 name: "PersonOid",
                                 disableClearable: true
                              }}
                              value={values.PersonOid}
                              onSearchTextChange={searchPersonSelectionOptions}
                              setValue={(value: ISelectOption) => {
                                 setFieldValue("PersonOid", value?.value)
                              }}
                           />
                        </Grid>
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     {
                        personOid && <Button color="danger" onClick={() => { onSubmit({ ...values, PersonOid: null }) }}>Unassign Device</Button>
                     }
                     {!deleteOnSuccess &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AssignDeviceToPerson;
