import React from "react";

import { Divider, MenuItem } from "@mui/material";
import { Delete, Edit, History, Visibility } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { IHazardLocationDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

const HazardLocationRow: React.FC<IHazardLocationDataProps> = ({ hazardLocation, onEdit, onDelete, onShowAuditLog, actionPermission }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {hazardLocation.type}  </StyledGridCell>
         <StyledGridCell> {hazardLocation.securityLevel}  </StyledGridCell>
         <StyledGridCell style={{ maxWidth: "200px" }}> {hazardLocation.securityLevelDescription}  </StyledGridCell>
         <StyledGridCell> {hazardLocation.status}  </StyledGridCell>
         <StyledGridCell style={{ maxWidth: "150px" }}> {hazardLocation.warning}</StyledGridCell>
         <StyledGridCell> {hazardLocation.onsiteContact}</StyledGridCell>
         <StyledGridCell> {hazardLocation.onsiteContactPhoneNumber}</StyledGridCell>
         <StyledGridCell style={{ maxWidth: "200px" }}> {hazardLocation.notes}</StyledGridCell>
         <StyledGridCell align='right'>
            {(actionPermission["HazardLocation:GET"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(hazardLocation)}>
                  {(actionPermission["HazardLocation:POST"])
                     ? <Edit /> : <Visibility />}
               </Button>
            }
            <ActionOptionMenu>
               {(actionPermission["Audit:HazardLocation"]) &&
                  <MenuItem disableRipple onClick={() => { onShowAuditLog(hazardLocation) }}>
                     <History color="primary" />
                     Audit history
                  </MenuItem>
               }
               {(actionPermission["HazardLocation:DELETE"]) &&
                  <>
                     <Divider />
                     <MenuItem disableRipple onClick={() => { onDelete(hazardLocation, true) }}>
                        <Delete />
                        Delete
                     </MenuItem>
                  </>
               }
            </ActionOptionMenu>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default HazardLocationRow;
