import React from "react";

import { Tooltip, Link, MenuItem, Divider } from "@mui/material";
import { AssignmentInd, Delete, Edit, History, Assignment, Visibility, ForwardToInbox } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { Common } from "../../../constants/common";
import WarningSign from "../../Common/WarningSign";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

import { IPersonDataProps } from "../../../types/propType";
import { allowPersonAddEdit } from "../../../utils/PermissionHelpers";

const PersonRow: React.FC<IPersonDataProps> = ({ person, onEdit, onDelete, onAssignDeviceToPerson, onShowAuditLog, onAssignEscalation, showOnlyEscalation, showOnlyEdit, actionPermission, onSetResponseInstruction, onSelectDevice, sendPasswordResetMail }) => {
   const showAllActions: boolean = !showOnlyEdit && !showOnlyEscalation;
   const deviceList = (deviceIds: any[]) => {
      return (
         <>
            {deviceIds && deviceIds.length > 0 && deviceIds.map((device: any, index: number) => {
               if (onSelectDevice != null)
                  return <Link key={index} onClick={() => onSelectDevice(device.deviceOid)}>{device.deviceId}<br /></Link>
               else
                  return <>{device.deviceId}<br /></>
            })
            }
         </>
      )
   }
   const isEscalationContact = person.personClientRelation?.relationshipToClient === "Escalation Contact";
   const allowAssignEscalationContact = Common.AllowAssignEscalationContact();
   return (
      <StyledGridRow>
         <StyledGridCell>
            {person.isEscalationAssigned === false && !isEscalationContact &&
               <WarningSign text={"Default escalation pending"} />
            }
         </StyledGridCell>
         <StyledGridCell> {person.firstname} {person.lastname}  </StyledGridCell>
         <StyledGridCell> {person.email}  </StyledGridCell>
         <StyledGridCell> {Common.formatPhoneNumber(person.phoneNumber1)}  </StyledGridCell>
         <StyledGridCell> {deviceList(person.deviceIds)}  </StyledGridCell>
         <StyledGridCell>{person.personClientRelation.clientName}</StyledGridCell>
         <StyledGridCell align='right'>
            {showOnlyEscalation && (actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && (actionPermission["Person:AssignDefaultEscalation"]) &&
               <Button color="rose" justIcon={true} onClick={() => onAssignEscalation(person)}>
                  <Tooltip title="Assign person escalation contact">
                     <Assignment />
                  </Tooltip>
               </Button>
            }
            {(showAllActions || showOnlyEdit) && (actionPermission["Person:POST"] || actionPermission["Person:GET"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(person)}>
                  {allowPersonAddEdit(actionPermission) ? <Edit /> : <Visibility />}
               </Button>
            }
            {showAllActions &&
               <ActionOptionMenu>
                  {(actionPermission["Audit:Person"]) &&
                     <MenuItem disableRipple onClick={() => { onShowAuditLog(person) }}>
                        <History color="primary" />
                        Audit history
                     </MenuItem>
                  }
                  {(actionPermission["Person:AssignMultipleDevicesToPerson"] && actionPermission["Device:GetClientSpecificDeviceSelectOptions"]) &&
                     (!Common.IsInRole("Client User") || person.oid === localStorage.getItem("personOid")) &&
                     <MenuItem disabled={isEscalationContact} disableRipple onClick={() => { onAssignDeviceToPerson(person) }}>
                        <AssignmentInd />
                        Assign device to person
                     </MenuItem>
                  }
                  {(actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && allowAssignEscalationContact &&
                     <MenuItem disabled={isEscalationContact} disableRipple onClick={() => { onAssignEscalation(person) }}>
                        <Assignment />
                        {actionPermission["Person:AssignDefaultEscalation"] ? "Assign" : "View"} person escalation contact
                     </MenuItem>
                  }
                  {/* {(actionPermission["Person:SetResponseInstruction"] && actionPermission["ResponseInstruction:GET"]) &&
                        <MenuItem disabled={isEscalationContact} disableRipple onClick={() => { handleClose(); onSetResponseInstruction(person) }}>
                           <SpeakerNotes />
                           Edit response instruction
                        </MenuItem>
                     } */}
                  {(actionPermission["User:SendPasswordResetLink"]) && !Common.stringNullOrEmpty(person.userOid) &&
                     <MenuItem disableRipple onClick={() => { sendPasswordResetMail(person.userOid) }}>
                        <ForwardToInbox />
                        Send password reset link
                     </MenuItem>
                  }
                  {(actionPermission["Person:DELETE"]) &&
                     <div>
                        <Divider />
                        <MenuItem disableRipple onClick={() => { onDelete(person, true) }}>
                           <Delete />
                           Delete
                        </MenuItem>
                     </div>
                  }
               </ActionOptionMenu>
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default PersonRow;
