import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Pagination, MenuItem, Select, Typography } from '@mui/material';
import { Common } from '../../../constants/common';
import paginationStyle from '../../../assets/jss/components/paginationStyle';
import { ICustomPaginationProps } from '../../../types/propType';

const CustomPagination: React.FC<ICustomPaginationProps> = ({ classes, totalRecord, onPageChange, currentPage, pageSize }) => {

   const onPageSizeChange = (e: any) => {
      const size = e.target.value;
      localStorage.setItem("pageSize", size);
      onPageChange(1, size);
   }

   const onChange = (e: any, pageNumber: number) => {
      onPageChange(pageNumber, pageSize);
   }

   const pageCount = Math.ceil(totalRecord / (pageSize ? pageSize : Common.GridPageSize()))
   return (
      <div className={classes.gridFooter}>
         <Typography variant="body1" component="span" className={classes.pageSelectOption}>
            <Typography variant="body2" component="span" className={classes.pageSelectOptionLabel}>
               Total Records: {totalRecord}
            </Typography>
            {onPageChange &&
               <>
                  <Typography variant="body2" component="span" className={classes.pageSelectOptionLabel}>
                     Rows per page:
                  </Typography>

                  <Select variant='standard' id="page-selection" value={pageSize} onChange={onPageSizeChange}>
                     <MenuItem value={5}>5</MenuItem>
                     <MenuItem value={10}>10</MenuItem>
                     <MenuItem value={25}>25</MenuItem>
                     <MenuItem value={50}>50</MenuItem>
                     <MenuItem value={100}>100</MenuItem>
                  </Select>
               </>
            }
         </Typography>
         {
            onPageChange && pageCount > 1 &&
            <div className={classes.rightBlock}>
               <Pagination count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={onChange} page={currentPage} />
            </div>
         }
      </div>
   );
}

export default withStyles(CustomPagination, paginationStyle);
