import React, { useState, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import AuditLogs from './AuditLogs';
import { IAuditLogAccrodianProps } from '../../../types/propType';
import { Common } from '../../../constants/common';
import auditService from '../../../services/audit.service';
import { StatusCode } from '../../../constants/constant';
const AuditLogAccrodian: React.FC<IAuditLogAccrodianProps> = ({ classes, index, log }) => {
   const [expanded, setExpanded] = useState(false);
   const [auditLog, setAuditLog] = useState<any>(null);

   useEffect(() => {
      if (auditLog == null && expanded === true) {
         auditService.getSingleAuditLogs(log.entityName ?? "", log.auditOn ?? "", log.oid ?? "", log?.isFromBlob ?? false).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setAuditLog(res?.data);
            }
         });
      }
   }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps
   return (
      <Accordion expanded={expanded} key={index} onChange={() => setExpanded(!expanded)}>
         <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
               <strong>{log.changedEntityValue} </strong>
               <span className={classes[Common.getAuditOnDesignClass(log.auditOn)]}>{log.auditOn}</span>
               <span> {log.auditBy_Fullname ? "by" : log.auditOn === "Password reset" ? "via reset link" : "by"} {log.auditBy_Fullname} on {Common.getFormattedDateTime(log.createdOn)}</span>
            </Typography>
         </AccordionSummary>
         <AccordionDetails>
            {expanded && auditLog &&
               <AuditLogs auditLog={auditLog} />
            }
         </AccordionDetails>
      </Accordion >
   );
};

export default AuditLogAccrodian;


