import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import { IAdvanceFilterProps } from '../../../types/propType';
import ClientService from '../../../services/client.service';
import MonitoringStationService from '../../../services/monitoringstation.service';
import { ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { Common } from '../../../constants/common';
import CustomDatePicker from '../../Common/CustomDatePicker';
import SearchableSelect from '../../Common/SearchableSelect';
import { StatusCode } from '../../../constants/constant';
import CustomSelect from '../../Common/CustomSelect';

const DeviceTestReportAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const userRole = Common.GetRolesAsString();
   const [monitoringStations, setMonitoringStations] = useState([]);
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   useEffect(() => {
      if (!Common.IsInRole("Monitoring Station Operator") &&
         !Common.IsInRole("Monitoring Station Admin") &&
         !Common.IsInRole("Client Admin") &&
         !Common.IsInRole("Client User") &&
         !Common.IsInRole("Client Audit User") &&
         !Common.IsInRole("Client Location Editor")
      ) {
         MonitoringStationService.getMonitoringStationOptions().then((res: any) => {
            if (res?.status === StatusCode.Success && res.data) {
               setMonitoringStations(res?.data);
            }
         });
      }
   }, [userRole]);
   const deviceTestResultOption = Common.DeviceTestResultOptions(true, false);
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, submitForm, setFieldValue, handleChange }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Client Name"
                        id="ClientOid"
                        inputProps={{
                           name: "ClientOid",
                        }}
                        value={values.ClientOid}
                        onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("ClientOid", value?.value);
                           setFieldValue("ClientName", value?.label);
                           setFieldValue("MonitoringStationOid", Common.EmptyGuid);
                           submitForm();
                        }}
                        isClientSelection={true}
                     />
                  </Grid>
                  {!Common.IsInRole("Monitoring Station Operator") &&
                     !Common.IsInRole("Monitoring Station Admin") &&
                     !Common.IsInRole("Client Admin") &&
                     !Common.IsInRole("Client User") &&
                     !Common.IsInRole("Client Audit User") &&
                     !Common.IsInRole("Client Location Editor") &&
                     <>
                        <Grid item xs={12} md={4}>
                           <SearchableSelect
                              labelText="Monitoring Station"
                              id="MonitoringStationOid"
                              inputProps={{
                                 name: "MonitoringStationOid"
                              }}
                              value={values.MonitoringStationOid}
                              options={[{ label: "All", value: Common.EmptyGuid }].concat(monitoringStations)}
                              setValue={(value: ISelectOption) => {
                                 setFieldValue("MonitoringStationOid", value?.value);
                                 setFieldValue("ClientOid", Common.EmptyGuid);
                                 submitForm();
                              }}
                           />
                        </Grid>
                     </>
                  }
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"Start Date"}
                        id="StartDate"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           required: true,
                           name: "StartDate",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("StartDate", Common.getFormattedDateForServer(e));
                                 submitForm();
                              }
                           }
                        }}
                        value={values.StartDate}
                        openTo="day"
                        disableFuture
                        shouldDisableTime={() => true}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"End Date"}
                        id="EndDate"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           required: true,
                           name: "EndDate",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("EndDate", Common.getFormattedDateForServer(e));
                                 if (new Date(Common.getFormattedDateForServer(e)) < new Date(values.StartDate)) {
                                    return;
                                 }
                                 else {
                                    submitForm();
                                 }
                              }
                           }
                        }}
                        value={values.EndDate}
                        openTo="day"
                        disableFuture
                        shouldDisableTime={() => true}
                        customErrorMessage={new Date(values.EndDate) < new Date(values.StartDate) ? "End date can't be older than Start date." : null}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomSelect
                        labelText="Test Result"
                        id="TestResult"
                        inputProps={{
                           required: true,
                           name: "TestResult",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        value={values.TestResult}
                        options={deviceTestResultOption}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default DeviceTestReportAdvanceFilter;
