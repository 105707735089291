import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '../../Common/Button';
import { StatusCode } from '../../../constants/constant';
import { IAuditLogs } from '../../../types/dataTypes';
import { ICommonAuditLogModalProps } from '../../../types/propType';

import AuditService from '../../../services/audit.service';
import DeletedAuditLogAccrodian from './DeletedAuditLogAccrodian';

const CommonAuditLogsModal: React.FC<ICommonAuditLogModalProps> = ({ open, close, entityName }) => {
   const [skip, setSkip] = useState(0);
   const [continuationToken, setContinuationToken] = useState<string | null>(null);
   const [auditLogs, setAuditLogs] = useState<IAuditLogs[]>([]);
   // const [searchLogs, setSearchLogs] = useState<string>("");
   const [hideShowMore, setHideShowMore] = useState(false);
   const fetchAuditLogs = () => {
      AuditService.getAllDeletedEntityLogs(entityName, skip, continuationToken).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const auditLogsList = res?.data?.deletedEntities;
            const totalRecords = res?.data?.totalRecords ?? 0;
            if (totalRecords > 0)
               setSkip(s => s + (auditLogsList.length ?? 0));
            const combinedList = auditLogs.concat(auditLogsList);
            setAuditLogs(combinedList);
            const continuationToken = res?.data?.continuationToken;
            setContinuationToken(continuationToken);
            if (skip > -1 && (combinedList.length === totalRecords || totalRecords === -1))
               setSkip(-1);
            if ((skip === -1 || totalRecords === -1) && continuationToken === null)
               setHideShowMore(true);
         }
      });
   };
   useEffect(() => {
      fetchAuditLogs();
   }, [entityName]); // eslint-disable-line react-hooks/exhaustive-deps
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <DialogTitle>
            <span>Audit logs</span>
            {/* <Paper sx={{ display: 'flex', width: 400, marginLeft: "auto" }}>
               <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  autoComplete={"off"}
                  inputProps={{ name: "searchText" }}
                  value={searchLogs}
                  onChange={(event: any) => { setSearchLogs(event.target.value) }}
               />
               {!Common.stringNullOrEmpty(searchLogs) &&
                  <Button color="transparent" justIcon={true} onClick={() => setSearchLogs("")}><Clear /></Button>
               }
               <Button color="transparent" justIcon={true}><Search /></Button>
            </Paper> */}
         </ DialogTitle>
         <DialogContent dividers>
            {auditLogs.length > 0 ?
               auditLogs.map((log: any, index: number) => {
                  return (
                     <DeletedAuditLogAccrodian key={index} index={index} entityName={entityName} log={log} />
                  );
               })
               :
               <span>No records found.</span>
            }
            {!hideShowMore && auditLogs.length > 0 &&
               <div style={{ textAlign: "center", padding: "5px" }}>
                  <Button color="primary" onClick={() => fetchAuditLogs()}>Load More ...</Button>
               </div>
            }
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Close</Button>
         </DialogActions>
      </Dialog>
   );
};

export default CommonAuditLogsModal;


