import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import SftpMonitoringLogsRow from "../../components/pages/SftpMonitoringLogs/SftpMonitoringLogsRow";
import SftpMonitoringLogsCard from "../../components/pages/SftpMonitoringLogs/SftpMonitoringLogsCard";
import SftpMonitoringLogsViewModel from "../../components/pages/SftpMonitoringLogs/SftpMonitoringLogsViewModel";
import SftpMonitoringLogsFilter from "../../components/pages/SftpMonitoringLogs/SftpMonitoringLogsFilter";
import { ISftpMonitoringLogsPageState } from "../../types/stateType";
import { ISftpMonitoringLogsPageProps } from "../../types/propType";
import { StatusCode } from "../../constants/constant";
import { IPageWiseContinuation, ISftpMonitoringLogsFilter } from "../../types/dataTypes";
import { TableHeader } from "../../constants/tableHeader";
import SftpMonitoringLogsService from "../../services/sftpMonitoringLogs.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";

class SftpMonitoringLogs extends React.Component<ISftpMonitoringLogsPageProps, ISftpMonitoringLogsPageState> {
   filterOptions: ISftpMonitoringLogsFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      FromDate: Common.getFormattedDateForServer(new Date()),
      ToDate: Common.getFormattedDateForServer(new Date()),
      Type:"All",
      continuationToken: null,
      pageWiseContinuationTokens: []
   }

   state: ISftpMonitoringLogsPageState = {
      totalRecords: 0,
      sftpMonitoringLogs: [],
      openAuditLogs: false,
      openViewModal: false,
      selectedMonitoringLog: {}
   };

   componentDidMount() {
      this.searchSftpMonitoringLogs()
   }
   //#region pagination and searching
   searchSftpMonitoringLogs = () => {
      let newFilterOptions: ISftpMonitoringLogsFilter = { ...this.filterOptions };
      newFilterOptions.FromDate = Common.getUTCDateTimeForServer(newFilterOptions.FromDate);
      newFilterOptions.ToDate = Common.getUTCDateTimeForServer(newFilterOptions.ToDate);
      this.setState({ ...this.state, sftpMonitoringLogs: [] }, () => {
         SftpMonitoringLogsService.searchSftpMonitoringLogs(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               let { totalRecords } = this.state;
               let continuationToken = res?.data?.continuationToken;
               if (continuationToken) {
                  const { pageWiseContinuationTokens, page } = this.filterOptions;
                  if (pageWiseContinuationTokens.length < page) {
                     const { pageSize } = this.filterOptions;
                     totalRecords = (page * pageSize) + 1;
                     const pageWiseToken: IPageWiseContinuation = { pageNo: this.filterOptions.page, continuationToken: continuationToken };
                     pageWiseContinuationTokens.push(pageWiseToken);
                     this.filterOptions.pageWiseContinuationTokens = pageWiseContinuationTokens;
                  }
               } else {
                  totalRecords = (totalRecords > 0 ? totalRecords - 1 : 0) + res?.data?.values?.length;
               }
               this.setState({
                  ...this.state,
                  sftpMonitoringLogs: res?.data?.values,
                  totalRecords: totalRecords
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.sftpMonitoringLogs.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }
      const { pageWiseContinuationTokens, pageSize } = this.filterOptions;
      const pageNumber = this.filterOptions.page + 1;
      this.filterOptions.page = pageNumber;
      if (this.filterOptions.pageSize !== pageSize)
         this.filterOptions.pageWiseContinuationTokens = [];
      this.filterOptions.pageSize = pageSize;
      if (pageNumber === 1)
         this.filterOptions.continuationToken = null;
      else {
         const previousPageDetail = pageWiseContinuationTokens.filter((x) => { return x.pageNo === pageNumber - 1 });
         if (previousPageDetail && previousPageDetail.length > 0)
            this.filterOptions.continuationToken = previousPageDetail[0].continuationToken;
         else
            return;
      }
      let newFilterOptions: ISftpMonitoringLogsFilter = { ...this.filterOptions };
      newFilterOptions.FromDate = Common.getUTCDateTimeForServer(newFilterOptions.FromDate);
      newFilterOptions.ToDate = Common.getUTCDateTimeForServer(newFilterOptions.ToDate);
      SftpMonitoringLogsService.searchSftpMonitoringLogs(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let { totalRecords } = this.state;
            let continuationToken = res?.data?.continuationToken;
            if (continuationToken) {
               const { pageWiseContinuationTokens, page } = this.filterOptions;
               if (pageWiseContinuationTokens.length < page) {
                  const { pageSize } = this.filterOptions;
                  totalRecords = (page * pageSize) + 1;
                  const pageWiseToken: IPageWiseContinuation = { pageNo: this.filterOptions.page, continuationToken: continuationToken };
                  pageWiseContinuationTokens.push(pageWiseToken);
                  this.filterOptions.pageWiseContinuationTokens = pageWiseContinuationTokens;
               }
            } else {
               totalRecords = (totalRecords > 0 ? totalRecords - 1 : 0) + res?.data?.values?.length;
            }
            let sftpMonitoringLogsList = [...this.state.sftpMonitoringLogs];
            sftpMonitoringLogsList = sftpMonitoringLogsList.concat(res?.data?.values);
            this.setState({
               ...this.state,
               sftpMonitoringLogs: sftpMonitoringLogsList,
               totalRecords: totalRecords
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      const { pageWiseContinuationTokens } = this.filterOptions;
      this.filterOptions.page = pageNumber;
      if (this.filterOptions.pageSize !== pageSize)
         this.filterOptions.pageWiseContinuationTokens = [];
      this.filterOptions.pageSize = pageSize;
      if (pageNumber === 1)
         this.filterOptions.continuationToken = null;
      else {
         const previousPageDetail = pageWiseContinuationTokens.filter((x) => { return x.pageNo === pageNumber - 1 });
         if (previousPageDetail && previousPageDetail.length > 0)
            this.filterOptions.continuationToken = previousPageDetail[0].continuationToken;
      }
      this.searchSftpMonitoringLogs();
   }

   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchSftpMonitoringLogs();
      // }
   }
   onView = (errorRecord: any) => {
      this.setState({
         ...this.state,
         openViewModal: true,
         selectedMonitoringLog: errorRecord
      })
   }
   onCloseSftpMonitoringLogsViewModel = () => {
      this.setState({
         ...this.state,
         openViewModal: false,
         selectedMonitoringLog: {}
      })
   }
   onAdvanceSearch = (advanceFilter: any) => {      
      this.filterOptions.Type = advanceFilter.Type;
      this.filterOptions.FromDate = advanceFilter.FromDate;
      this.filterOptions.ToDate = advanceFilter.ToDate;
      this.filterOptions.page = 1;
      this.filterOptions.pageWiseContinuationTokens = [];
      this.filterOptions.continuationToken = null;
      this.setState({
         ...this.state,
         totalRecords: 0
      }, () =>
         this.searchSftpMonitoringLogs()
      );
   }
   onDownload = (exportType: string) => {
      let newFilterOptions: ISftpMonitoringLogsFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      newFilterOptions.FromDate = Common.getUTCDateTimeForServer(newFilterOptions.FromDate);
      newFilterOptions.ToDate = Common.getUTCDateTimeForServer(newFilterOptions.ToDate);
      SftpMonitoringLogsService.exportSftpMonitoringLogs(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "SFTPMonitoringLogs";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   //#endregion
   render() {
      const { totalRecords, sftpMonitoringLogs, openViewModal, selectedMonitoringLog } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle={`SFTP Monitoring Logs`} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={null}
            actionPermission={{
               add: false,
               auditlog: false,
               download: actionPermission["SftpMonitoringLogs:Export"] && totalRecords > 0,
            }}
            onDownload={this.onDownload}
            advanceFilter={<SftpMonitoringLogsFilter initialValues={{ FromDate: this.filterOptions.FromDate,ToDate: this.filterOptions.ToDate, Type: this.filterOptions.Type }} onAdvanceSearch={this.onAdvanceSearch} />}
            showAdvanceFilerAlways={true}
         >
            {/* view error log modal */}
            {openViewModal &&
               <SftpMonitoringLogsViewModel
                  open={openViewModal}
                  close={this.onCloseSftpMonitoringLogsViewModel}
                  successCallback={() => { }}
                  record={selectedMonitoringLog}
               />
            }

            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getSftpMonitoringLogsTableHeaders()} pagingInfoText="Users"
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {sftpMonitoringLogs && sftpMonitoringLogs?.map((hRDataMonitoringIssue: any, index: number) => {
                     return (
                        <SftpMonitoringLogsRow
                           key={`row_${index}`}
                           record={hRDataMonitoringIssue}
                           actionPermission={actionPermission}
                           onView={this.onView}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={sftpMonitoringLogs ? sftpMonitoringLogs.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {sftpMonitoringLogs && sftpMonitoringLogs?.map((hRDataMonitoringIssue: any, index: number) => {
                        return (
                           <SftpMonitoringLogsCard
                              key={`card_${index}`}
                              record={hRDataMonitoringIssue}
                              actionPermission={actionPermission}
                              onView={this.onView}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>
         </PageLayout>
      )
   }
}

export default withParams(SftpMonitoringLogs);
