import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
function NavigationLink({ ...props }) {
   const navigate = useNavigate();
   return (
      <div style={{ width: "100%" }} {...props} onClick={() => navigate(props.to)}>
         {props.children}
      </div>
   )
}
NavigationLink.propTypes = {
   to: PropTypes.string.isRequired || PropTypes.number.isRequired
};
export default (NavigationLink);
