import React from 'react';
import { IEscalationDetailResponseInstruction } from '../../types/propType';

const EscalationDetailResponseInstructionForPrint: React.FC<IEscalationDetailResponseInstruction> = ({ responseInstruction }) => {
   let instructions: any[] = [];
   let deviceInstruction: any[] = [];
   let clientInstruction: any[] = [];
   const getPlainText = (textBlock: any) => {
      let text = textBlock.Text;
      const values = textBlock.Values;
      Object.keys(values).forEach((key) => {
         text = text.replace(`{{${key}}}`, values[key]);
      });
      return text;
   }
   if (responseInstruction) {
      try {
         instructions = JSON.parse(responseInstruction);
         if (instructions.length > 0) {
            if (instructions[0].hasOwnProperty("DeviceType")) {
               deviceInstruction = instructions;
            }
            else if (instructions[0].hasOwnProperty("Text")) {
               clientInstruction = instructions;
            }
         }
      } catch (ex) {
      }
   }
   else {
      return null;
   }
   return (
      <>
         {deviceInstruction.length > 0 && deviceInstruction.map((deviceTypes: any, i1: number) =>
            deviceTypes.InstructionTypes.map((instructionTypes: any, i2: number) => {
               return (
                  <>
                     <div style={{ fontSize: "25px" }}>{instructionTypes.InstructionFor} {deviceTypes.DeviceType}</div>
                     <br />
                     {[...instructionTypes.TextBlocks].map((card, i) => {
                        return <>{card.Type !== "Placeholder" ? <div key={`print_responseinstruction${i}`}>{`${i + 1}. ${card.Text}`}<br /></div> : <div style={{ whiteSpace: "pre-line" }} key={`print_responseinstruction${i}`}>{`${i + 1}. ${getPlainText(card)}`}<br /></div>}</>
                     })
                     }
                     <br />
                  </>
               )
            })
         )}
         {clientInstruction.length > 0 &&
            [...clientInstruction].map((card, i) => {
               return <div key={`print_clientresponseinstruction${i}`}>{`${i + 1} ${card.Text}`}<br /></div>
            })
         }
         {deviceInstruction.length === 0 && clientInstruction.length === 0 && responseInstruction &&
            <strong>{responseInstruction}</strong>
         }
      </>
   );
}
export default EscalationDetailResponseInstructionForPrint;
