import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { mapApiDetails, mapInitialMapBound, useDeepCompareEffectForMaps } from "./MapCommon";
import { Grid } from "@mui/material";
import Button from "./Button";
import { RestartAlt } from "@mui/icons-material";
import { GoogleMapSearchLocation, AutoComplete } from "./GoogleMapSearchLocation";
interface IMapLocationPicker {
   setValue: any;
   value: any;
   geometryType: string;
}
const MapLocationPicker: React.FC<IMapLocationPicker> = ({ value, setValue, geometryType }) => {
   const [coOrdinates, setCoOrdinates] = React.useState<any[]>([]);
   React.useEffect(() => {
      setCoOrdinates(JSON.parse(value));
   }, [value, geometryType]);
   const onDrawingComplete = (latLngs: any, type: string) => {
      if (type === google.maps.drawing.OverlayType.POLYGON) {
         let newCoOrdinates = latLngs.map((latLong: any) => {
            let latlon = latLong!.toJSON();
            return [latlon.lng, latlon.lat]
         });
         newCoOrdinates.push(newCoOrdinates[0]);
         setValue(JSON.stringify(newCoOrdinates));
      }
      else if (type === google.maps.drawing.OverlayType.MARKER) {
         const newCoOrdinates = [[latLngs.lng, latLngs.lat]];
         setValue(JSON.stringify(newCoOrdinates));
      }
   }
   const reset = () => {
      setValue("[]");
   }
   return (
      <Grid container spacing={2}>
         {geometryType === "Polygon" &&
            <Grid item xs={12} md={6}>
               {/* <Button color="info" startIcon={<Undo />} onClick={redo}>
                  Undo
               </Button> */}
               <Button color="danger" startIcon={<RestartAlt />} onClick={reset}>
                  Reset
               </Button>
            </Grid>
         }
         <Grid item xs={12} md={12}>
            <GoogleMapSearchLocation inputId="mapLocationPicker" />
         </Grid>
         <Grid item xs={12} md={12}>
            <div style={{ display: "flex", height: "500px" }}>
               <Wrapper {...mapApiDetails}>
                  <Map
                     coOrdinates={coOrdinates}
                     zoom={10}
                     style={{ flexGrow: "1", height: "100%" }}
                     geometryType={geometryType}
                     onDrawingComplete={onDrawingComplete}
                  >
                  </Map>
               </Wrapper>
            </div>
         </Grid>
      </Grid>
   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: (map: google.maps.Map) => void;
   children?: React.ReactNode;
   coOrdinates: any[];
   geometryType: string;
   onDrawingComplete: any;
}

const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   coOrdinates,
   geometryType,
   onDrawingComplete,
   ...options
}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapPolygons, setMapPolygons] = React.useState<google.maps.Polyline[]>([]);
   const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager | null>(null);
   const [mapMarker, setMapMarker] = React.useState<google.maps.Marker>();
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
         setMapBound(mapInitialMapBound());
      }
      AutoComplete(map, "mapLocationPicker");
      const bounds = new google.maps.LatLngBounds();
      const polygons: google.maps.Polyline[] = [];
      if (mapMarker) {
         mapMarker.setMap(null);
      }
      if (drawingManager) {
         drawingManager.setMap(null);
      }
      if (mapPolygons.length > 0) {
         mapPolygons.forEach((poly) => {
            poly.setMap(null);
         });
      }
      // if (geometryType === "Polygon") {
      if (coOrdinates && coOrdinates.length > 0) {
         if (geometryType === "Polygon") {
            let polygonPath: google.maps.LatLng[] = coOrdinates.map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
            const polygon = new google.maps.Polyline({
               path: polygonPath,
               geodesic: true,
               strokeColor: "#FF0000",
               strokeOpacity: 0.8,
               strokeWeight: 3,
               map: map,
            });
            polygons.push(polygon);
            setDrawingManager(null);
         }
         else if (geometryType === "Point") {
            let latLong = new google.maps.LatLng(coOrdinates[0][1], coOrdinates[0][0]);
            let newMarker = new google.maps.Marker({
               position: latLong,
               map: map
            });
            setMapMarker(newMarker);
            bounds.extend(latLong);
            setMapBound(bounds);
         }
      }
      if (map) {
         let drawingManagerObj = new google.maps.drawing.DrawingManager({
            drawingMode: geometryType === "Polygon" ? google.maps.drawing.OverlayType.POLYGON : google.maps.drawing.OverlayType.MARKER,
            drawingControl: true,
            drawingControlOptions: {
               position: google.maps.ControlPosition.TOP_CENTER,
               drawingModes: [geometryType === "Polygon" ? google.maps.drawing.OverlayType.POLYGON : google.maps.drawing.OverlayType.MARKER]
            },
            polygonOptions: {
               editable: geometryType === "Polygon"
            }

         });
         drawingManagerObj.setMap(map);
         google.maps.event.addListener(drawingManagerObj, 'overlaycomplete', function (event: any) {
            event.overlay.set('editable', false);
            onDrawingComplete(google.maps.drawing.OverlayType.POLYGON === event.type ? event.overlay.getPath().getArray() : event.overlay.getPosition()!.toJSON(), event.type);
            event.overlay.setMap(null);
            drawingManagerObj.setDrawingMode(null);
            drawingManagerObj.setMap(null);
         });
         setDrawingManager(drawingManagerObj);
      }
      if (polygons.length > 0) {
         for (let j = 0; j < polygons.length; j++) {
            for (let i = 0; i < polygons[j].getPath().getLength(); i++) {
               bounds.extend(polygons[j].getPath().getAt(i));
            }
         }
         setMapBound(bounds);
      }
      setMapPolygons(polygons);
   }, [ref, map, coOrdinates]); // eslint-disable-line react-hooks/exhaustive-deps

   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
      }
   }, [map, options]);

   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, onClick, mapBound]);

   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
export default MapLocationPicker;
