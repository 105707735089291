import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { IUserSupportDataProps } from "../../../types/propType";
import LogInAsButton from "./LogInAsButton";

const UserSupportRow: React.FC<IUserSupportDataProps> = ({ user, actionPermission }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {user.email}  </StyledGridCell>
         <StyledGridCell> {user.firstname} {user.lastname}  </StyledGridCell>
         <StyledGridCell> {user.roles.map((role: any) => role.name).join()} </StyledGridCell>
         <StyledGridCell> {user.clientName}  </StyledGridCell>
         <StyledGridCell> {user.monitoringStationName}  </StyledGridCell>
         <StyledGridCell align='right'>
            {actionPermission["Auth:LogInAsUser"] &&
               <LogInAsButton userOid={user.oid} />
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default UserSupportRow;
