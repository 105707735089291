import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { withStyles } from 'tss-react/mui';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import fileUploadStyle from '../../assets/jss/pages/fileUploadStyle';
import { Common } from '../../constants/common';
import { ICustomFileUploaderProps } from '../../types/propType';

const CustomFileUploader: React.FC<ICustomFileUploaderProps> = ({ classes, file, setFile, fileTypes, multiple }) => {
   const [error, setError] = useState<string>("");

   const handleChange = (file: any) => {
      setError("");
      setFile(file);
   };

   const onSizeError = (error: any) => {
      setError(error + `. Maximum ${Common.MaxFileSize} MB is allowed.`);
      setFile(null);
   }

   return (
      <div className={classes.container}>
         <FileUploader multiple={multiple === true} handleChange={handleChange} maxSize={Common.MaxFileSize} onSizeError={onSizeError} types={fileTypes}>
            <label className={classes.fileUploadBlock}>
               <UploadFileIcon style={{ height: 45, width: 45, color: "#666" }} />
               <div className={classes.content}>
                  {file !== null ?
                     <p>
                        {!multiple &&
                           <>
                              <span className={classes.success}>Selected successfully</span>
                              {!Common.stringNullOrEmpty(file?.name) &&
                                 <div> <strong>File name:</strong> <span>{file?.name}</span></div>
                              }
                           </>
                        }
                        {multiple &&
                           <>
                              <span className={classes.success}><strong>{file.length}{" "}</strong> file(s) Selected successfully</span>
                           </>
                        }
                     </p>
                     : <p>
                        {
                           !Common.stringNullOrEmpty(error) &&
                           <span className={classes.error}>{error}</span>
                        }
                        <div><span>Upload or drop a file right here</span></div>
                     </p>
                  }

                  <span className={classes.fileTypes}>{fileTypes.join()}</span>
               </div>
            </label>
         </FileUploader>
      </div>
   );
};

export default withStyles(CustomFileUploader, fileUploadStyle);
