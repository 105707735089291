import React from 'react';
import logo from "../../assets/img/logo192.png";

const LazyLoaderProgress: React.FC = () => {
   return (
      <div
         style={{
            position: "relative",
            height: "100%",
            width: "100%"
         }}
      >
         <img
            style={{
               position: "fixed",
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               margin: "auto"
            }}
            src={logo}
            alt={"Loading...."}
         />
      </div>
   );
};

export default LazyLoaderProgress;
