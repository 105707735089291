import React from "react";

import { Divider, MenuItem } from "@mui/material";
import { Delete, Edit, History, ManageAccounts } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { IMonitoringStationDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { Common } from "../../../constants/common";
import NavigationLink from "../../Common/NavigationLink";

const MonitoringStationRow: React.FC<IMonitoringStationDataProps> = ({ monitoringStation, onEdit, onDelete, onShowAuditLog, actionPermission }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {monitoringStation.name}  </StyledGridCell>
         <StyledGridCell> {monitoringStation.url}  </StyledGridCell>
         <StyledGridCell> {monitoringStation.username}  </StyledGridCell>
         <StyledGridCell> {Common.formatPhoneNumber(monitoringStation.phoneNumber)} </StyledGridCell>
         <StyledGridCell align='right'>
            {(actionPermission["MonitoringStation:POST"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(monitoringStation)}><Edit /></Button>
            }
            <ActionOptionMenu>
               {(actionPermission["Audit:MonitoringStation"]) &&
                  <MenuItem disableRipple onClick={() => { onShowAuditLog(monitoringStation) }}>
                     <History color="primary" />
                     Audit history
                  </MenuItem>
               }
               {(actionPermission["User:Search"]) &&
                  <NavigationLink to={`/users/${monitoringStation.oid}`}>
                     <MenuItem disableRipple>
                        <ManageAccounts color="primary" />Manage Users
                     </MenuItem>
                  </NavigationLink>
               }
               {(actionPermission["MonitoringStation:DELETE"]) &&
                  <>
                     <Divider />
                     <MenuItem disableRipple onClick={() => { onDelete(monitoringStation, true) }}>
                        <Delete />
                        Delete
                     </MenuItem>
                  </>
               }
            </ActionOptionMenu>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default MonitoringStationRow;
