import React from "react";
import { warningColor } from "../../assets/jss/appStyle";
import { withStyles } from 'tss-react/mui';
import { Phone, Notes, Person, Escalator, Monitor, Aod } from "@mui/icons-material";
import pagesStyle from '../../assets/jss/pages/escalationDetailStyle';
import { IEscalationDetailPageState } from "../../types/stateType";
import { IEscalationDetailPageProps } from "../../types/propType";
import { Card, CardHeader, CardContent, Grid, Typography, FormHelperText } from "@mui/material";
import { StatusCode } from "../../constants/constant";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";

import deviceService from "../../services/device.service";
import EscalationDetailResponseInstruction from "./EscalationDetailResponseInstruction";
class EscalationDetail extends React.Component<IEscalationDetailPageProps, IEscalationDetailPageState> {
   state: IEscalationDetailPageState = {
      escalationDetails: null,
      error: null
   };
   componentDidMount() {
      const { deviceid } = this.props.params;
      deviceService.getEscalationDetails(deviceid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            this.setState({ ...this.state, escalationDetails: res?.data, error: null });
         }
         else {
            this.setState({ ...this.state, escalationDetails: null, error: res?.data?.message });
         }
      });
   }
   renderEscalationDetail = (person: any, escalationNumber: number) => {
      const { classes } = this.props;
      if (!person)
         return <></>
      return (
         <Grid item xs={12} sm={6} md={4}>
            <Card style={{ border: `2px solid ${warningColor[0]}`, height: "100%" }}>
               <CardHeader
                  title={
                     <>
                        <Typography variant="h5" component="h4" className={classes.cardHeader}>
                           Escalation {escalationNumber}
                        </Typography>
                     </>
                  }
               />
               <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                     <Person className={classes.beforeTextIcon} /> {person.firstname} {person.lastname}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                     <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(person.phoneNumber1)}
                  </Typography>
                  {!Common.stringNullOrEmpty(person.phoneNumber2) && <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                     <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(person.phoneNumber2)}
                  </Typography>}
                  {!Common.stringNullOrEmpty(person.notes) && <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                     <Notes className={classes.beforeTextIcon} /> {person.notes}
                  </Typography>}
               </CardContent>
            </Card>
         </Grid>
      )
   }
   render() {
      const { classes } = this.props;
      const { escalationDetails, error } = this.state;
      return (
         <>
            {escalationDetails &&
               <>
                  <div className={classes.pageBody} data-testid={`escalationdetail_body`}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                           <Card style={{ border: `2px solid ${warningColor[0]}`, height: "100%" }}>
                              <CardHeader
                                 title={
                                    <>
                                       <Typography variant="h5" component="h4" className={classes.cardHeader}>
                                          Device ID : {escalationDetails.deviceId}
                                       </Typography>
                                    </>
                                 }
                              />
                              <CardContent>
                                 <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                                    <Monitor className={classes.beforeTextIcon} /> Monitoring Account Number: <b>{escalationDetails.monitoringAccountNumber}</b>
                                 </Typography>
                                 <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                                    <Aod className={classes.beforeTextIcon} /> Device Mobile Number: {escalationDetails.deviceMobileNumber}
                                 </Typography>
                              </CardContent>
                           </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Card style={{ border: `2px solid ${warningColor[0]}`, height: "100%" }}>
                              <CardHeader
                                 title={
                                    <>
                                       <Typography variant="h5" component="h4" className={classes.cardHeader}>
                                          Assigned Person
                                       </Typography>
                                    </>
                                 }
                              />
                              <CardContent>
                                 <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                                    <Person className={classes.beforeTextIcon} /> Assigned Person Name: {escalationDetails.assignedPersonName}
                                 </Typography>
                                 <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                                    <Phone className={classes.beforeTextIcon} /> Assigned Person Phone Number: {Common.formatPhoneNumber(escalationDetails.assignedPersonPhoneNumber)}
                                 </Typography>
                                 <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                                    <Phone className={classes.beforeTextIcon} /> Assigned Person Alternate Phone Number: {Common.formatPhoneNumber(escalationDetails.assignedPersonAlternateNumber)}
                                 </Typography>
                              </CardContent>
                           </Card>

                        </Grid>
                     </Grid>
                  </div>
                  <div className={classes.pageBody} data-testid={`escalationdetail_body`}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                           <Typography variant="h5" gutterBottom>
                              <Escalator className={classes.beforeTextIcon} /> Escalation details
                           </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                           {escalationDetails.escalationDetails ?

                              <Grid container spacing={2}>
                                 {this.renderEscalationDetail(escalationDetails.escalationDetails.person1, 1)}
                                 {this.renderEscalationDetail(escalationDetails.escalationDetails.person2, 2)}
                                 {this.renderEscalationDetail(escalationDetails.escalationDetails.person3, 3)}
                              </Grid>
                              : <b>Escalation details not found for this device.</b>
                           }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                           <Typography variant="h5" gutterBottom>
                              <Notes className={classes.beforeTextIcon} />Response instruction
                           </Typography>
                           {
                              !Common.stringNullOrEmpty(escalationDetails.responseInstruction) ?
                                 <EscalationDetailResponseInstruction responseInstruction={escalationDetails.responseInstruction} />
                                 : <b>Response instruction not found for this device.</b>

                           }
                        </Grid>
                     </Grid>
                  </div>
               </>
            }
            {
               error &&
               <FormHelperText id="error-login" error>{error}</FormHelperText>
            }
         </>
      )
   }
}

export default withParams(withStyles(EscalationDetail, pagesStyle));
