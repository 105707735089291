import { Box, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import { WarningRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { warningColor } from "./../../assets/jss/appStyle";
import { useState } from 'react';

function WarningSign({ ...props }) {
   const [open, setOpen] = useState(false);

   const handleTooltipClose = () => {
      setOpen(false);
   };

   const handleTooltipOpen = () => {
      setOpen(true);
   };
   return (
      <>
         <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{props.text.trim()}</div>}>
               <WarningRounded style={{ color: warningColor[0] }} />
            </Tooltip>
         </Box>
         <Typography style={{float:"left"}} sx={{ display: { xs: 'block', md: 'none' } }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
               <Tooltip
                  onClose={handleTooltipClose}
                  open={open}
                  title={<div style={{ whiteSpace: 'pre-line' }}>{props.text.trim()}</div>}
               >
                  <button style={{ border: "none", color: "inherit", backgroundColor: "transparent", padding: "0" }} type="button" onClick={handleTooltipOpen}>
                     <WarningRounded style={{ color: warningColor[0] }} />
                  </button>
               </Tooltip>
            </ClickAwayListener>
         </Typography>
      </>
   )
}
WarningSign.propTypes = {
   text: PropTypes.string.isRequired
};
export default (WarningSign);
