import React from 'react';
import lodash from "lodash";

import { ITextComparetorProps } from '../../types/propType';

const HtmlComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   return (
      <div style={{ display: "flex" }}>
         {oldValue &&
            <div style={{ marginRight: "1em" }}>
               <span><strong>Old</strong></span>
               <div style={{ border: "1px solid #804343", padding: "1em" }} dangerouslySetInnerHTML={{ __html: oldValue }}></div>
            </div>
         }
         {changedValue &&
            <div>
               <span><strong>Changed</strong></span>
               <div style={{ border: "1px solid #1c6421", padding: "1em" }} dangerouslySetInnerHTML={{ __html: changedValue }}></div>
            </div>
         }
      </div>
   );
};

export default HtmlComparetor;
