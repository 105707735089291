import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Box, Typography, Collapse, Tooltip, IconButton, Badge } from '@mui/material';
import Button from '../../Common/Button';
import { IModalProps } from '../../../types/propType';
import { Common } from '../../../constants/common';
import { Grid } from "@mui/material";
import tripService from '../../../services/trip.service';
import { StatusCode } from '../../../constants/constant';
import TripMap from './TripMap';
import { ExpandLess, ExpandMore, Warning } from '@mui/icons-material';
import TripAdvanceFilter from './TripAdvanceFilter';
interface ITripMapViewModal extends IModalProps {
   mapFilters: any;
}
const TripMapViewModal: React.FC<ITripMapViewModal> = ({ open, close, mapFilters }) => {
   const [trips, setTrips] = useState<any[]>([]);
   const [hazardLocation, setHazardLocation] = useState<any[]>([]);
   const [selectedTripOid, setSelectedTripOid] = useState<string | null>(null);
   const [hazardLocationMapping, setHazardLocationMapping] = useState<any[]>([]);
   const getTrips = (values: any) => {
      tripService.getFilteredTripDetails(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            setTrips(res?.data?.trips);
            setHazardLocation(res?.data?.hazardLocations ?? []);
            setSelectedTripOid(null);
            setHazardLocationMapping(res?.data?.locationOidHazardLocationGeometryOidMap ?? []);
         }
      })
   }
   useEffect(() => {
      if (!Object.values(mapFilters).every(el => el === undefined)) {
         getTrips(mapFilters);
      }
   }, [mapFilters])
   interface ITripDetails {
      item: any;
      index: number;
   }
   const getHazardCountForTrip = (tripOid: string) => {
      const locations = trips.filter(x => x.oid === tripOid).map((y: any) => y.tripLocations.map((z: any) => z.value)).flat();
      const hazardLocationGeometry = hazardLocationMapping.filter(x => locations.indexOf(x.locationOid) > -1).map(x => x.hazardLocationGeometryOid);
      return hazardLocation.filter((x: any) => x.hazardLocationGeometry.filter((y: any) => hazardLocationGeometry.indexOf(y.oid) > -1).length > 0).length;
   }
   const TripDetails: React.FC<ITripDetails> = ({ item, index }) => {
      const hazardCountForLocation = getHazardCountForTrip(item.oid);
      const [showMore, setShowMore] = useState(false);
      return <React.Fragment key={index}>
         <ListItemButton
            key={index}
            selected={item.oid === selectedTripOid}
            onClick={() => setSelectedTripOid(item.oid)}
         >
            <ListItemText
               primary={
                  <Typography
                     sx={{ display: 'inline' }}
                     component="span"
                     variant="body1"
                     color="text.primary"
                  >
                     <strong>{item.title}</strong>
                     {hazardCountForLocation > 0 &&
                        <Tooltip style={{ float: "right" }} title="No Of Hazardous Location">

                           <Badge badgeContent={hazardCountForLocation} color="error" >
                              <Warning color="primary" />
                           </Badge>

                        </Tooltip>
                     }
                     <br />
                  </Typography>
               }
               secondary={
                  <Typography
                     sx={{ display: 'inline' }}
                     component="span"
                     variant="body2"
                     color="text.primary"
                  >
                     <strong>Start Time:</strong> {Common.getFormattedDateTime(item.startTime)}
                     <br />
                     <strong>Status:</strong> {item.status}
                     <br />
                     <strong>Organizer:</strong> {item.organizerName}
                     <br />
                  </Typography>
               }
            />
            <Tooltip title={showMore ? "Show less" : "Show more"}>
               <IconButton edge="end" aria-label="view" onClick={() => setShowMore(!showMore)}>
                  {showMore ? <ExpandLess /> : <ExpandMore />}
               </IconButton>
            </Tooltip>
         </ListItemButton>
         <Collapse in={showMore} timeout="auto" unmountOnExit>
            <List sx={{ pl: 4 }} component="div" disablePadding>
               <ListItemText primary={<strong>Trip Persons</strong>} />
               {item.tripPersons && item.tripPersons.map((person: any, personIndex: number) =>
                  <ListItemText secondary={`${personIndex + 1} ${person.label}`} />
               )}
               <ListItemText primary={<strong>Trip Locations</strong>} />
               {item.tripLocations && item.tripLocations.map((location: any, locationIndex: number) =>
                  <ListItemText secondary={`${locationIndex + 1} ${location.label}`} />
               )}
               <ListItemText secondary={<a style={{ color: "blue" }} rel="noreferrer" href={`${window.location.origin}/tripdetail/${item.oid}`} target="_blank">More details</a>} />
            </List>
         </Collapse>
      </React.Fragment>
   }
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle>
            <span>Trip map view</span>
         </ DialogTitle>
         <DialogContent dividers>
            <TripAdvanceFilter initialValues={mapFilters} onAdvanceSearch={getTrips} />
            <br />
            <Grid container spacing={2}>
               <Grid item xs={12} md={4}>
                  <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', border: "1px solid grey", height: "500px", overflow: "auto" }}>
                     <List component="div" >
                        {trips && trips.map((item: any, index: number) => {
                           return <TripDetails item={item} index={index} />
                        })}
                     </List>
                  </Box>
               </Grid>
               <Grid item xs={12} md={8}>
                  <TripMap trips={trips} hazardLocations={hazardLocation} selectedTripOid={selectedTripOid} />
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Close</Button>
         </DialogActions>
      </Dialog >
   );
};

export default TripMapViewModal;


