import { CardHeader, Avatar } from '@mui/material';
import type { FC } from 'react';
import { successColor } from "../../../../assets/jss/appStyle";
import { Common } from '../../../../constants/common';
import Image from "./../../../Common/Image";
const style = {
   border: `2px solid ${successColor[0]}`,
   padding: '0.5rem 1rem',
   marginBottom: '.5rem',
   backgroundColor: 'white',
}

export interface IPreviewTextBlockCardProps {
   index: number;
   textBlock: any;
}
export const PreviewTextBlockCard: FC<IPreviewTextBlockCardProps> = ({ index, textBlock }) => {
   return (
      <div style={{ ...style }}>
         <CardHeader
            avatar={
               <Avatar sx={{ bgcolor: successColor[0] }} aria-label="Sequence">
                  {index + 1}
               </Avatar>
            }
            title={
               <>
                  {textBlock.Type === "Image" ?
                     <div style={{ display: "flex" }}>
                        <div
                           style={{ width: "70%" }}
                        >
                           {Common.getFileNameFromPathWithOutExtension(textBlock.Text)}
                        </div>
                        <Image
                           src={textBlock.Text.indexOf("http") === 0 ? textBlock.Text : `${Common.ClientImagePath}/${textBlock.Text}`}
                           zoom={true}
                           style={{ height: 50, marginLeft: 25 }}
                        />
                     </div>
                     :
                     textBlock.Text
                  }
               </>
            }
         />
      </div >
   )
}
