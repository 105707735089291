import { Wrapper } from "@googlemaps/react-wrapper";
import { mapApiDetails, addInfoWindowForHazardLocation, mapInitialMapBound, useDeepCompareEffectForMaps } from "./../../Common/MapCommon";
import React, { useState, useEffect } from "react";
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMapSearchLocation, AutoComplete } from "./../../../components/Common/GoogleMapSearchLocation";
import { FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import CustomClusterRenderer from '../../Common/CustomClusterRenderer';
import { IMapLocations } from "../../../types/dataTypes";
interface IMapLocationPicker {
   allLocations: IMapLocations[];
   hazardLocations: any[];
   addTripLocation: any;
   removeTripLocation: any;
   selectedLocations: any[];
   tripOid?: string | null;
   disabled: any;
}
const selectedIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAmCAYAAAA1MOAmAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsklEQVRYhb3XvU7DMBAA4JCkf6hIDGHgVl6Ap4CFgbEzK0shC+KlkACpO6/AxIBYGAxLxYSSNj7kElOnqZ1zYjOcVLVKv9z5Yl8CRNxpiLCM1WdI2RJShiJe2GJIuP4vAsOPfUTclX9MCUTcU26MjMVHNx/cBoIqOqBivbYIVMH+xlLUMKuyEcADXWahSwjW4LiG8Twb+cBAKanEIl8Q/GKxxML57fTV5uJk8tAGjFYYpIxTkW0BrjEdRAWFQ8KaoISAZUUeC2zwHxikDIPv2f3YBZYQQJFZXO7kXIc6yowH5V64UEAfGJ/Mro4FNoSUZSVUeOpGLrsxUg7EwtNzxiUm1uxTfknpKj5/InUfrCOXWOsd//CaUbPqqRvxoKmEHaIQS1U7PH1gqJzYgetxAOpZhdoZhNokNmuFpunKAcgRcZ88N7osHzZgYcesRrYTcatyYtnm1phthlgdTM3Y6fQsENEGPH+8XDbcOAmLupYPdWXcgsnseNvyoQnTgNrHAQ2vSV2wQjftomvs7eu9lh0VqmASMWGbnUktH5ow0wVKo6yOeq9Y+SjkJ3cXz5R2V7Ef7nhjrE440PwAAAAASUVORK5CYII=";
const MapLocations: React.FC<IMapLocationPicker> = ({ allLocations, hazardLocations, addTripLocation, selectedLocations, removeTripLocation, tripOid, disabled }) => {
   const [newLocations, setNewlocations] = useState<any[]>([]);
   const [newHazardLocations, setNewHazardLocations] = useState<any[]>([]);
   const [showSelectedLocationsOnly, setShowSelectedLocationOnly] = useState(false);
   useEffect(() => {
      setNewlocations(allLocations);
      setNewHazardLocations(hazardLocations);
   }, [allLocations, hazardLocations])

   return (
      <>
         <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
               <GoogleMapSearchLocation inputId="locationSearch" />
            </Grid>
            <Grid item xs={12} md={4}>
               <FormGroup>
                  <FormControlLabel
                     control={<Switch checked={showSelectedLocationsOnly} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowSelectedLocationOnly(event.target.checked)} />}
                     label="Show only trip locations"
                     disabled={disabled} />
               </FormGroup>
            </Grid>
         </Grid>
         <div style={{ display: "flex", height: "600px", marginTop: 5 }}>
            <Wrapper {...mapApiDetails} >
               <MemoizedMap
                  zoom={10}
                  style={{ flexGrow: "1", height: "100%" }}
                  allLocations={newLocations}
                  hazardLocations={newHazardLocations}
                  addTripLocation={addTripLocation}
                  selectedLocations={selectedLocations}
                  removeTripLocation={removeTripLocation}
                  showSelectedLocationsOnly={showSelectedLocationsOnly}
                  tripOid={tripOid}
               />
            </Wrapper>
         </div >
      </>
   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: any;
   children?: React.ReactNode;
   allLocations: IMapLocations[];
   hazardLocations: any[],
   addTripLocation: any,
   removeTripLocation: any,
   selectedLocations: IMapLocations[],
   showSelectedLocationsOnly: boolean;
   tripOid?: string | null;
}
const getInfoWindowContent = (location: any, isSelected: boolean): string => {
   let buttoncolor = isSelected ? "#f44336" : "#4CAF50";
   return `<div style="font-size:16px;text-align: center;"><b>Title: </b>${location.label}
   <br/>
   <button style="background-color:${buttoncolor} ;  border: none;  color: white;  padding:5px 10px;  text-align: center;  text-decoration: none;  display: inline-block;  font-size: 12px;  margin: 4px 2px;  cursor: pointer;" type="button" id="${location.value}">${isSelected ? "Remove from trip" : "Add to trip"}</button></div>`;
}
const addInfoWindow = (obj: any, map: google.maps.Map, location: any, addTripLocation: any, removeTripLocation: any, index: number = -1) => {
   const infowindow = new google.maps.InfoWindow();
   obj.addListener('click', function (event: any) {
      const contentString = getInfoWindowContent(location, index > -1);
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.open(map);
   });
   infowindow.addListener('domready', () => {
      const someButton = document.getElementById(location.value);
      if (someButton) {
         someButton.addEventListener('click',
            () => {
               infowindow.close();
               if (index > -1) {
                  removeTripLocation(index);
               }
               else {
                  addTripLocation(location);
               }
            })
      }
   });
}
const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   allLocations,
   hazardLocations,
   addTripLocation,
   selectedLocations,
   removeTripLocation,
   showSelectedLocationsOnly,
   tripOid,
   ...options

}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   // const [mapMarkers, setMapMarkers] = React.useState<google.maps.Marker[]>([]);
   const [mapPolygons, setMapPolygons] = React.useState<google.maps.Polygon[]>([]);
   const [markerCluster, setMarkerCluster] = React.useState<any>(new MarkerClusterer({ renderer: new CustomClusterRenderer() }));
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
         setMapBound(mapInitialMapBound());
      }
      AutoComplete(map, "locationSearch");
      if (map) {
         DrawMarkers();
         if (tripOid) {
            const bounds = new google.maps.LatLngBounds();
            selectedLocations.forEach((x: IMapLocations) => {
               const latLongs = JSON.parse(x.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
               bounds.extend(latLongs[0]);
            })
            setMapBound(bounds);
         }
      }
   }, [ref, map, allLocations, hazardLocations, selectedLocations, showSelectedLocationsOnly]); // eslint-disable-line react-hooks/exhaustive-deps
   // eslint-disable-next-line
   const DrawMarkers = () => {
      if (!map)
         return;
      const currentMapBounds = map.getBounds();
      if (mapPolygons.length > 0) {
         mapPolygons.forEach((poly) => {
            poly.setMap(null);
         });
      }
      // if (mapMarkers.length > 0) {
      //    mapMarkers.forEach((marker) => {
      //       marker.setMap(null);
      //    });
      // }
      if (markerCluster) {
         markerCluster.setMap(null);
         markerCluster.clearMarkers();
      }
      // let bounds = new google.maps.LatLngBounds();
      const newMarkers: google.maps.Marker[] = [];
      const polygons: google.maps.Polygon[] = [];
      const selectedLocationLatLongs: google.maps.LatLng[] = [];
      let locationToBeRender: IMapLocations[] = [];
      if (map && (map.getZoom() ?? 0) <= 11) {
         selectedLocations.forEach(x => {
            const findSelectedLocation = allLocations.find(y => y.value === x.value);
            if (findSelectedLocation)
               locationToBeRender.push(findSelectedLocation);
         })
      }
      else {
         if (showSelectedLocationsOnly) {
            selectedLocations.forEach(x => {
               const findSelectedLocation = allLocations.find(y => y.value === x.value);
               if (findSelectedLocation)
                  locationToBeRender.push(findSelectedLocation);
            })
         }
         else
            locationToBeRender = allLocations;
      }
      locationToBeRender = locationToBeRender.filter((loc) => !currentMapBounds || currentMapBounds.contains(loc.latLongs[0]));
      locationToBeRender.forEach((location: any) => {
         const isSelectedIndex = selectedLocations.findIndex((x: any) => x.value === location.value);
         if ((showSelectedLocationsOnly === false || isSelectedIndex > -1) && location.coOrdinates) {
            if (location.geometryType === "Polygon") {
               const polygon = new google.maps.Polygon({
                  paths: location.latLongs,
                  geodesic: true,
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillOpacity: 0.3,
                  map: map,
               });
               addInfoWindow(polygon, map, location, addTripLocation, removeTripLocation, isSelectedIndex);
               polygons.push(polygon);
               if (isSelectedIndex > -1) {
                  const polygonBounds = new google.maps.LatLngBounds();
                  for (var i = 0; i < location.latLongs.length; i++) {
                     polygonBounds.extend(location.latLongs[i]);
                  }
                  const polygonCenter = polygonBounds.getCenter();
                  const mapLabelMarker = new google.maps.Marker({
                     position: polygonCenter,
                     map: map,
                     label: {
                        text: `${isSelectedIndex + 1}`,
                        color: 'white',
                        fontSize: "14px",
                        fontWeight: "600"
                     },
                     icon: selectedIcon
                  });
                  addInfoWindow(mapLabelMarker, map, location, addTripLocation, removeTripLocation, isSelectedIndex);
                  newMarkers.push(mapLabelMarker);
                  selectedLocationLatLongs.push(polygonCenter);
               }
            }
            else if (location.geometryType === "Point") {
               const merkerLatLong = location.latLongs[0];
               if (merkerLatLong) {
                  if (!currentMapBounds || currentMapBounds.contains(merkerLatLong) || isSelectedIndex > -1) {
                     const position: google.maps.LatLng = merkerLatLong;

                     const newMarker = isSelectedIndex > -1 ?
                        new google.maps.Marker({
                           position: position,
                           label: {
                              text: `${isSelectedIndex + 1}`,
                              color: 'white',
                              fontSize: "14px",
                              fontWeight: "600"
                           },
                           clickable: true,
                           title: location.value,
                           icon: selectedIcon
                        }) : new google.maps.Marker({
                           position: position,
                           // map: map

                           clickable: true,
                           title: location.value
                        });
                     if (isSelectedIndex > -1) {
                        selectedLocationLatLongs.push(position);
                     }
                     //addInfoWindow(newMarker, map, location, addTripLocation, removeTripLocation, isSelectedIndex);
                     newMarkers.push(newMarker);
                  }
               }
            }
         }
      });
      markerCluster.setMap(map);
      markerCluster.addMarkers(newMarkers);
      markerCluster.clusters.filter((x: any) => x.markers?.length === 1).forEach((cluster: any) => {
         //addInfoWindow(cluster.marker, map, location, addTripLocation, removeTripLocation, isSelectedIndex);
         addInfoFromTitle(cluster.marker);
      })
      // setMapMarkers(newMarkers);
      setMarkerCluster(markerCluster);
      // onMapBoundChange(map, markerCluster, locationToBeRender, selectedLocations, addTripLocation, removeTripLocation);
      // onMapBoundChange2();

      if (hazardLocations && hazardLocations.length > 0) {
         hazardLocations.forEach((hazardLocation: any) => {
            hazardLocation?.hazardLocationGeometry.forEach((location: any) => {
               let polygonPath: google.maps.LatLng[] = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
               const polygon = new google.maps.Polygon({
                  paths: polygonPath,
                  geodesic: true,
                  strokeColor: "#FFFF00",
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: "#FFFC00",
                  fillOpacity: 0.4,
                  map: map,
               });
               addInfoWindowForHazardLocation(polygon, map, hazardLocation);
               polygons.push(polygon);
            })
         });
      }
      setMapPolygons(polygons);
   }
   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
      }
   }, [map, options]);
   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, mapBound])
   const addInfoFromTitle = (marker: any) => {
      if (map) {
         const location = allLocations.find(x => x.value === marker.title);
         const isSelectedIndex = selectedLocations.findIndex(x => x.value === marker.title);
         addInfoWindow(marker, map, location, addTripLocation, removeTripLocation, isSelectedIndex);
      }
   }

   React.useEffect(() => {
      if (map) {
         google.maps.event.clearListeners(map, "idle");
         map.addListener("idle", () => {
            DrawMarkers();
         });
      }
   }, [map, allLocations, selectedLocations, showSelectedLocationsOnly, DrawMarkers]);  // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
const MemoizedMap = Map;
export default MapLocations;
