import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from './../../../Common/Button';
import deviceService from '../../../../services/device.service';
import { StatusCode } from '../../../../constants/constant';
import { Common } from '../../../../constants/common';
import { ISearchableSelectParams, ISelectOption } from '../../../../types/dataTypes';
import SearchableServerSearch from '../../../Common/SearchableServerSearch';
import { Form, Formik } from 'formik';
import TagsSelect from '../../../Common/TagsSelect';
import personService from '../../../../services/person.service';

interface IEscalationApplyAllDialogueProps {
   show: boolean,
   onConfirmEvent: any,
   onCancel: any,
   type: "all" | "specific",
   clientOid: string,
   clientName: string | null | undefined,
   entity: string;
   entityOid: string;
   entityName: string;
}

const EscalationApplyAllDialogue: React.FC<IEscalationApplyAllDialogueProps> = ({ show, onConfirmEvent, onCancel, type, clientName, clientOid, entity, entityOid, entityName }) => {
   const [count, setCount] = useState(0);
   const [applyToOids, setApplyToOids] = useState<ISelectOption[]>([]);
   const [formValues, setFormValues] = useState<any>({
      selectedApplyToOids: []
   });
   useEffect(() => {
      if (type !== "all") {
         applyToOids.push({ value: entityOid, label: entityName });
         setCount(applyToOids.length);
         setApplyToOids([...applyToOids]);
         formValues.selectedApplyToOids = [entityOid];
         setFormValues({ ...formValues });
      }
   }, [type]); // eslint-disable-line react-hooks/exhaustive-deps
   useEffect(() => {
      if (type === "all") {
         if (entity === "Device") {
            deviceService.getDeviceCountByClientOid({ clientOid }).then((res: any) => {
               if (res?.status === StatusCode.Success) {
                  setCount(res?.data);
               }
            });
         }
         else if (entity === "Person") {
            personService.getPersonCountByClientOid({ clientOid }).then((res: any) => {
               if (res?.status === StatusCode.Success) {
                  setCount(res?.data);
               }
            });
         }
      }
   }, [clientOid, type, entity]);
   const onSubmit = (values: any) => {
      if (type === "all") {
         onConfirmEvent([]);
      }
      else {
         onConfirmEvent(values.selectedApplyToOids);
      }
   }
   const getDeviceSelectOptionsForClient = (param: ISearchableSelectParams, signal: any) => {
      const params: any = { ...param };
      params.ClientOid = clientOid;
      return deviceService.getDeviceSelectOptionsForClient(params, signal);
   }
   const searchPersonSelectionOptions = (params: ISearchableSelectParams, signal: any) => {
      return personService.getPersonSelectOptions(clientOid, params, signal);
   }
   const onlyUnique = (value: any, index: number, self: any) => {
      return self.indexOf(value) === index;
   }
   const addSelectedEntityOid = (value: ISelectOption, setFieldValue: any) => {
      if (value?.value) {
         if (applyToOids.filter((x) => x.value === value?.value).length === 0) {
            applyToOids.push(value);
            setApplyToOids([...applyToOids]);
            setFieldValue("selectedApplyToOids", applyToOids.map((item) => item.value));
            setFieldValue("device", null);
            setFieldValue("person", null);
            setCount(applyToOids.length);
         }
      }
   }
   const removeSelectedEntity = (value: string[], setFieldValue: any) => {
      if (value.indexOf(entityOid) < 0) {
         value.push(entityOid);
      }
      value = value.filter((x) => !Common.stringNullOrEmpty(x)).filter(onlyUnique);
      setFieldValue("selectedApplyToOids", value);
      let entityOidList = applyToOids.filter(x => value.indexOf(x.value) > -1);
      setApplyToOids([...entityOidList]);
      setCount(entityOidList.length);
      setFieldValue("device", null);
      setFieldValue("person", null);
   }

   return (
      <Dialog open={show} onClose={onCancel}>
         <Formik enableReinitialize={true} initialValues={formValues} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <DialogTitle>{`Are you sure you want to apply escalation contact to ${type === "specific" ? "specific selected" : type} ${entity?.toLowerCase()}s with following client?`} </DialogTitle>
                  <DialogContent dividers>
                     <strong>Client: {`${values.isForAllClient ? "Any" : (clientName ? clientName : "Not set")}`}</strong><br />
                     <br />
                     {type !== "all" && entity === "Device" &&
                        <>
                           <SearchableServerSearch
                              labelText="Search device id to add"
                              id="device"
                              inputProps={{
                                 name: "device",
                              }}
                              value={values.device}
                              onSearchTextChange={getDeviceSelectOptionsForClient}
                              setValue={(value: ISelectOption) => addSelectedEntityOid(value, setFieldValue)}
                           />
                           <TagsSelect
                              labelText="Selected Devices"
                              id="selectedApplyToOids"
                              inputProps={{
                                 required: false,
                                 name: "selectedApplyToOids"
                              }}
                              value={values.selectedApplyToOids}
                              options={applyToOids}
                              setValue={(value: string[]) => removeSelectedEntity(value, setFieldValue)}
                           />
                        </>
                     }
                     {type !== "all" && entity === "Person" &&
                        <>
                           <SearchableServerSearch
                              labelText="Search person to add"
                              id="person"
                              inputProps={{
                                 name: "person",
                              }}
                              value={values.person}
                              onSearchTextChange={searchPersonSelectionOptions}
                              setValue={(value: ISelectOption) => addSelectedEntityOid(value, setFieldValue)}
                           />
                           <TagsSelect
                              labelText="Selected Persons"
                              id="selectedApplyToOids"
                              inputProps={{
                                 required: false,
                                 name: "selectedApplyToOids"
                              }}
                              value={values.selectedApplyToOids}
                              options={applyToOids}
                              setValue={(value: string[]) => removeSelectedEntity(value, setFieldValue)}
                           />
                        </>
                     }
                     <strong>Number of {entity}s Escalation Contact will be updated: <strong>{count}</strong></strong>
                     {entity === "Person" &&
                        <p>
                           <span style={{ fontSize: "14px", color: "#000dce" }}><strong>Note:</strong> This will not apply to persons who are selected as escalation contact in previous step.</span>
                        </p>
                     }
                     <DialogContentText style={{ color: "red" }}>You can’t undo this action.</DialogContentText >
                  </DialogContent >
                  <DialogActions>
                     <Button color="danger" type="submit">Yes</Button>
                     <Button onClick={onCancel} >No</Button>
                  </DialogActions>
               </Form >
            )
            }
         </Formik >
      </Dialog >
   );
};

export default EscalationApplyAllDialogue;





