import { Controllers } from "../constants/constant";
import request from "../utils/request";
import { ITrip } from "../types/dataTypes";

class TripService {
   searchTrips = (params: any) => {
      return request({
         url: `${Controllers.Trip}/Search`,
         method: 'get',
         params
      });
   }
   saveTrip = (data: ITrip) => {
      return request({
         url: `${Controllers.Trip}`,
         method: 'post',
         data,
      })
   }
   deleteTrip = (params: any) => {
      return request({
         url: `${Controllers.Trip}`,
         method: 'delete',
         params
      })
   }
   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.Trip}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }
   getTrip = (params: any) => {
      return request({
         url: `${Controllers.Trip}`,
         method: 'get',
         params
      })
   }

   cancelTrip = (params: any) => {
      return request({
         url: `${Controllers.Trip}/Cancel`,
         method: 'get',
         params
      })
   }

   getTripDetails = (tripOid: string) => {
      return request({
         url: `${Controllers.Trip}/GetTripDetails/${tripOid}`,
         method: 'get'
      })
   }

   getTripStatusSelectOptions = () => {
      return request({
         url: `${Controllers.Trip}/GetTripStatusSelectOptions`,
         method: 'get'
      })
   }

   getFilteredTripDetails = (params: any) => {
      return request({
         url: `${Controllers.Trip}/GetFilteredTripDetails`,
         method: 'get',
         params
      })
   }
}

export default new TripService();
