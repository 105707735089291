import { Controllers } from "../constants/constant";
import request from "../utils/request";

class EscalationReportService {
   escalationReport = (params: any, entity: string, clientOid: string) => {
      return request({
         url: `${Controllers.EscalationReport}/Search/${entity}/${clientOid}`,
         method: 'get',
         params
      });
   }
}

export default new EscalationReportService();
