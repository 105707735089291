import React from 'react';
import lodash from "lodash";

import { IBoolComparetorProps } from '../../types/propType';

const BoolComparetor: React.FC<IBoolComparetorProps> = ({ oldValueJson, changedValueJson, compareKey, texts }) => {
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   const changed = texts === "enableddisabled" ? (changedValue ? "Disabled" : "Enabled") : (changedValue ? "Yes" : "No");
   const old = texts === "enableddisabled" ? (oldValue ? "Disabled" : "Enabled") : (oldValue ? "Yes" : "No");
   return (
      <>
         {
            oldValueJson !== null ?
               <>
                  {
                     changedValue === oldValue ?
                        <span>{changed}</span> :
                        <span style={{ backgroundColor: "#faa8a8" }}>
                           <del>{old}</del> <span style={{ backgroundColor: "#bcffbc" }}>{changed}</span>
                        </span>
                  }
               </>
               :
               <span>{changed}</span>
         }
      </>
   );
};

export default BoolComparetor;
