import React from "react";

import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import { Map } from "@mui/icons-material";
import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import HazardLocationRow from "../../components/pages/HazardLocations/HazardLocationRow";
import HazardLocationCard from "../../components/pages/HazardLocations/HazardLocationCard";
import AddEditHazardLocation from "../../components/pages/HazardLocations/AddEditHazardLocation";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";

import { IHazardLocationPageState } from "../../types/stateType";
import { IImportOption, IHazardLocationPageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IDeleteParams, IHazardLocationFilter } from "../../types/dataTypes";

import { TableHeader } from "../../constants/tableHeader";
import HazardLocationService from "../../services/hazardLocation.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import { withSnackbar } from "../../components/Common/Snackbar";
import ImportGeoJSONModal from "../../components/pages/HazardLocations/ImportGeoJSONModal";
import HazardLocationMapViewModal from "../../components/pages/HazardLocations/HazardLocationMapViewModal";
import Button from "../../components/Common/Button";

class HazardLocations extends React.Component<IHazardLocationPageProps, IHazardLocationPageState> {
   filterOptions: IHazardLocationFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      sortString: "Type"
   }

   state: IHazardLocationPageState = {
      hazardLocations: [],
      totalRecords: 0,
      openAddEditHazardLocationModal: false,
      selectedHazardLocation: null,
      showConfirmDialog: false,
      openAuditLogs: false,
      openImportModal: false,
      openMapView: false,
      numberForMapRefresh: Math.random()
   };

   componentDidMount() {
      this.searchHazardLocation();
   }

   //#region pagination and searching
   searchHazardLocation = () => {
      let newFilterOptions: IHazardLocationFilter = { ...this.filterOptions };
      this.setState({ ...this.state, hazardLocations: [] }, () => {
         HazardLocationService.searchHazardLocations(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  hazardLocations: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.hazardLocations.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IHazardLocationFilter = { ...this.filterOptions };
      HazardLocationService.searchHazardLocations(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let tripList = [...this.state.hazardLocations];
            tripList = tripList.concat(res?.data);
            this.setState({
               ...this.state,
               hazardLocations: tripList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchHazardLocation();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchHazardLocation();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchHazardLocation();
      // }
   }
   //#endregion

   //#region Add/Edit/Delete HazardLocation

   onToggleAddEditHazardLocation = () => {
      this.setState({ ...this.state, openAddEditHazardLocationModal: !this.state.openAddEditHazardLocationModal, selectedHazardLocation: null });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditHazardLocationModal: false, numberForMapRefresh: Math.random() }, () => this.searchHazardLocation());
   }

   onSelectHazardLocation = (selectedHazardLocation: any, isForDelete: boolean = false) => {
      if (isForDelete) {
         this.setState({ ...this.state, selectedHazardLocation: selectedHazardLocation, showConfirmDialog: true });
      }
      else
         this.setState({ ...this.state, selectedHazardLocation: selectedHazardLocation, openAddEditHazardLocationModal: true });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedHazardLocation?.oid };
      HazardLocationService.deleteHazardLocation(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedHazardLocation: null, showConfirmDialog: false, numberForMapRefresh: Math.random() }, () => this.searchHazardLocation());
         }
         else {
            this.setState({ ...this.state, selectedHazardLocation: null, showConfirmDialog: false });
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }

   onCancelDelete = () => {
      this.setState({ ...this.state, selectedHazardLocation: null, showConfirmDialog: false });
   }

   //#endregion
   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedHazardLocation: null });
   }

   openHazardLocationAuditLogModal = (selectedHazardLocation: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedHazardLocation: selectedHazardLocation });
   }

   toggleImportModal = (name: string) => {
      this.setState({ ...this.state, openImportModal: !this.state.openImportModal, importModelName: name });
   }
   onImportHazardLocation = () => {
      this.setState({ ...this.state, openImportModal: false, importModelName: undefined }, () => {
         this.searchHazardLocation();
      });
   }
   openMapView = () => {
      this.setState({ ...this.state, openMapView: true });
   }
   closeMapView = () => {
      this.setState({ ...this.state, openMapView: false });
   }
   //#endregion
   render() {
      const { totalRecords, openAddEditHazardLocationModal, selectedHazardLocation, showConfirmDialog, hazardLocations, openAuditLogs, openImportModal, importModelName, openMapView, numberForMapRefresh } = this.state;
      const { actionPermission } = this.props;
      const importOptions: IImportOption[] = [];
      if (actionPermission["HazardLocation:Import"])
         importOptions.push({ name: "Hazard Location", displayName: "Geo JSON", onClick: this.toggleImportModal });
      return (
         <PageLayout pageTitle="Hazard Locations" onCreate={this.onToggleAddEditHazardLocation} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["HazardLocation:SearchSuggestions"] ? HazardLocationService.getSearchSuggestions : null}
            auditLogs={this.toggleAuditLogModal}
            importOptions={importOptions}
            actionPermission={{
               add: actionPermission["HazardLocation:POST"],
               auditlog: actionPermission["Audit:HazardLocation"],
               import: importOptions.length > 0,
            }}
            customAction={
               actionPermission["HazardLocation:GetHazardLocationsDetails"] ?
                  <Button color={"success"} startIcon={<Map />} onClick={this.openMapView} >
                     Map View
                  </Button>
                  : null
            }
         >
            {/* add edit user modal */}
            {openAddEditHazardLocationModal &&
               <AddEditHazardLocation
                  open={openAddEditHazardLocationModal}
                  close={this.onToggleAddEditHazardLocation}
                  successCallback={this.onSave}
                  hazardLocationOid={selectedHazardLocation?.oid}
                  allowSubmit={actionPermission["HazardLocation:POST"]} />
            }
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getHazardLocationTableHeaders()} pagingInfoText="HazardLocation" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {hazardLocations && hazardLocations?.map((hazardLocation: any, index: number) => {
                     return (
                        <HazardLocationRow
                           key={`row_${index}`}
                           hazardLocation={hazardLocation}
                           onEdit={this.onSelectHazardLocation}
                           onDelete={this.onSelectHazardLocation}
                           onShowAuditLog={this.openHazardLocationAuditLogModal}
                           actionPermission={actionPermission}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={hazardLocations ? hazardLocations.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {hazardLocations && hazardLocations?.map((hazardLocation: any, index: number) => {
                        return (
                           <HazardLocationCard
                              key={`card_${index}`}
                              hazardLocation={hazardLocation}
                              onEdit={this.onSelectHazardLocation}
                              onDelete={this.onSelectHazardLocation}
                              onShowAuditLog={this.openHazardLocationAuditLogModal}
                              actionPermission={actionPermission}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete user */}
            {showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete}
               />
            }
            {
               openAuditLogs && selectedHazardLocation === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.HazardLocation} />
            }

            {
               openAuditLogs && selectedHazardLocation !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.HazardLocation}
                  oid={selectedHazardLocation?.oid ?? ""}
                  changedEntityTitle={selectedHazardLocation?.securityLevelDescription} />
            }

            {
               openImportModal &&
               <ImportGeoJSONModal
                  open={openImportModal}
                  close={this.toggleImportModal}
                  successCallback={this.onImportHazardLocation}
                  name={importModelName} />
            }
            {
               openMapView &&
               <HazardLocationMapViewModal
                  open={openMapView}
                  close={this.closeMapView}
                  searchText={this.filterOptions.SearchText}
                  onEditHazardLocation={this.onSelectHazardLocation}
                  numberForMapRefresh={numberForMapRefresh} />
            }
         </PageLayout>
      )
   }
}

export default withSnackbar(withParams(HazardLocations));
