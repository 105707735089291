import React, { useEffect, useState } from 'react';
import { Formik, Form, yupToFormErrors } from 'formik';
import * as Yup from "yup";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { IAddEditUserProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import CustomSelect from '../../Common/CustomSelect';
import ValidationSummary from '../../Common/ValidationSummary';
import { IUser } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { StatusCode } from '../../../constants/constant';
import UserService from '../../../services/user.service';
import { ConnectedFocusError } from '../../Common/FocusError';
import { Common } from '../../../constants/common';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import CustomSingleSelect from '../../Common/CustomSingleSelect';
const AddEditUser: React.FC<IAddEditUserProps> = ({ open, close, successCallback, user, monitoringStationOid, monitoringStationName }) => {
   const [error, setError] = useState<any>({});
   const [portalRoles, setPortalRoles] = useState([]);
   const [initialValues] = useState<IUser>({
      Email: "",
      Firstname: "",
      Lastname: "",
      RoleNames: [],
      MonitoringStationOid: monitoringStationOid
   });
   const validationSchema = Yup.object().shape({
      Email: Yup.string().trim(Resources.Error_UserEmail_Required).email(Resources.Error_Email).required(Resources.Error_UserEmail_Required),
      Firstname: Yup.string().trim(Resources.Error_UserFirstName_Required).required(Resources.Error_UserFirstName_Required),
      RoleNames: Yup.array().min(1, Resources.Error_PortalRole_Required).required(Resources.Error_PortalRole_Required)
   });

   useEffect(() => {
      UserService.getPortalRoleOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setPortalRoles(res?.data);
         }
      });
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const onSubmit = (values: any, setServerError: any) => {
      UserService.saveUser(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }

   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <Formik enableReinitialize={true} initialValues={user !== null ? user : initialValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{user !== null ? "Edit" : "Add"} User for {monitoringStationName}</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <input type="hidden" name="Oid" value={values.Oid} />
                     <input type="hidden" name="MonitoringStationOid" value={values.MonitoringStationOid} />
                     <ValidationSummary errors={error["errors"]} />
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <CustomInput
                              labelText="Email"
                              id="email"
                              inputProps={{
                                 required: true,
                                 name: "Email"
                              }}
                              customErrorMessage={error["email"]?.message}
                              value={values.Email}
                           />
                           <CustomInput
                              labelText="First Name"
                              id="Firstname"
                              inputProps={{
                                 required: true,
                                 name: "Firstname"
                              }}
                              value={values.Firstname}
                           />
                           <CustomInput
                              labelText="Last Name"
                              id="Lastname"
                              inputProps={{
                                 required: false,
                                 name: "Lastname"
                              }}
                              value={values.Lastname}
                           />
                           <CustomSingleSelect
                              items={portalRoles}
                              label="Portal Role"
                              name="RoleNames"
                           />
                        </Grid>
                        <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     <Button color="primary" type="submit">Save</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddEditUser;
