import React from "react";
import { Box, Grid, AppBar, Tabs, Tab } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import EscalationReportRow from "../../components/pages/EscalationReport/EscalationReportRow";
import EscalationReportCard from "../../components/pages/EscalationReport/EscalationReportCard";
import { IEscalationReportPageState } from "../../types/stateType";
import { IEscalationReportPageProps } from "../../types/propType";
import { StatusCode } from "../../constants/constant";
import { IEscalationReportFilter, ISearchableSelectParams } from "../../types/dataTypes";
import { TableHeader } from "../../constants/tableHeader";
import escalationReportService from "../../services/escalationReport.service";
import ClientService from "../../services/client.service";
import { Common } from "../../constants/common";
import { ISelectOption } from '../../types/dataTypes';
import { Formik } from "formik";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Button from "../../components/Common/Button";
import { Print } from "@mui/icons-material";
import EscalationDetailResponseInstructionForPrint from "../EscalationDetail/EscalationDetailResponseInstructionForPrint";
import { primaryColor } from '../../assets/jss/appStyle';
import SearchableServerSearch from "../../components/Common/SearchableServerSearch";
import CustomCheckbox from "../../components/Common/CustomCheckbox";
function a11yProps(tabName: string) {
   return {
      id: `full-width-tab-${tabName}`,
      'aria-controls': `full-width-tabpanel-${tabName}`,
   };
}

class EscalationReport extends React.Component<IEscalationReportPageProps, IEscalationReportPageState> {
   filterOptions: IEscalationReportFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      includeResponseInstruction: false
   }

   state: IEscalationReportPageState = {
      records: [],
      totalRecords: 0,
      openAuditLogs: false,
      clientSelectOptions: [],
      clientOid: Common.EmptyGuid,
      tabName: "Default",
      clientName: "",
      exportRecords: [],
      dateTime: "",
      includeResponseInstruction: false
   };
   //#region pagination and searching
   searchEscalationReport = () => {
      let newFilterOptions: IEscalationReportFilter = { ...this.filterOptions };
      const { tabName, clientOid, includeResponseInstruction } = this.state;
      newFilterOptions.includeResponseInstruction = includeResponseInstruction;
      this.setState({ ...this.state, records: [] }, () => {
         escalationReportService.escalationReport(newFilterOptions, tabName, clientOid).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data?.reportData) {
               this.setState({
                  ...this.state,
                  records: res?.data?.reportData,
                  totalRecords: res?.data?.reportData[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.records.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      const { tabName, clientOid, includeResponseInstruction } = this.state;
      let newFilterOptions: IEscalationReportFilter = { ...this.filterOptions };
      newFilterOptions.includeResponseInstruction = includeResponseInstruction;
      escalationReportService.escalationReport(newFilterOptions, tabName, clientOid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data?.reportData) {
            let recordList = [...this.state.records];
            recordList = recordList.concat(res?.data?.reportData);
            this.setState({
               ...this.state,
               records: recordList,
               totalRecords: res?.data?.reportData[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchEscalationReport();
   }

   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchEscalationReport();
      // }
   }
   handleChange = (event: React.SyntheticEvent, newValue: string) => {
      this.setState({
         ...this.state,
         tabName: newValue,
      }, () => {
         this.filterOptions.page = 1;
         this.searchEscalationReport();
      });
   };
   //#endregion
   getExportTable = (records: any[]) => {
      const { tabName } = this.state;
      return <table style={{ display: "none" }} id="table1">
         <thead>
            <tr>
               <th>{this.getNameColumnLabel(this.state.tabName)}</th>
               {tabName === "Person" && <th>Assinged Device(s)</th>}
               {tabName === "Device" && <th>Assigned Person</th>}
               <th colSpan={5}>Escalation / Response Instruction Details</th>
            </tr>
         </thead>
         <tbody>
            {records.map((record: any, index: number) => {
               const escalations = record.escalationDetails;
               const responseInstruction = record.responseInstruction;
               const escalationCount = escalations.length > 0 ? escalations.length + 1 : 0;
               const rowSpan = escalationCount + (responseInstruction && escalationCount > 0 ? 1 : 0);
               return (
                  <>
                     <tr>
                        <td rowSpan={rowSpan}>{record.name}</td>
                        {tabName === "Person" &&
                           <td rowSpan={rowSpan}> {
                              record.devices && record.devices.length > 0 && record.devices.map((device: any, index: number) => {
                                 return <>{device}<br /></>
                              })
                           } </td>
                        }
                        {tabName === "Device" &&
                           <td rowSpan={rowSpan}> {record.personName} </td>
                        }
                        {escalationCount > 0 &&
                           <>
                              <td>Person</td>
                              <td>Person Name</td>
                              <td>Phone 1</td>
                              <td>Phone 2</td>
                              <td>Notes</td>
                           </>
                        }
                        {escalationCount === 0 && responseInstruction &&
                           <>
                              <td>Response Instruction</td>
                              <td colSpan={4}>
                                 <EscalationDetailResponseInstructionForPrint responseInstruction={responseInstruction} />
                              </td>
                           </>
                        }
                     </tr>
                     {escalationCount > 0 &&
                        escalations.map((person: any, index: number) => {
                           return (
                              <tr>
                                 <td>{index + 1}</td>
                                 <td>{`${person.firstname} ${person.lastname ? person.lastname : ""}`}</td>
                                 <td>{Common.formatPhoneNumber(person.phoneNumber1)}</td>
                                 <td>{Common.formatPhoneNumber(person.phoneNumber2)}</td>
                                 <td>{person.notes}</td>
                              </tr>
                           )
                        })
                     }
                     {escalationCount > 0 && responseInstruction &&
                        <tr>
                           <td>Response Instruction</td>
                           <td colSpan={4}>
                              <EscalationDetailResponseInstructionForPrint responseInstruction={responseInstruction} />
                           </td>
                        </tr>
                     }
                  </>)
            })
            }
         </tbody>
      </table>
   }
   generatePDF = (title: string) => {
      let doc = new jsPDF('l', 'pt');
      const { dateTime } = this.state;
      const timeStamp = `As on ${Common.getFormattedDateTime(dateTime)}`;
      doc.setDocumentProperties({
         title: title,
         subject: title,
         author: "GAS Portal",
         keywords: "Escalation Response Instruction",
         creator: "GAS Portal"
      });
      autoTable(doc, {
         startY: 40,
         html: "#table1",
         theme: 'grid',
         columnStyles: {
            7: { cellWidth: 200 }
         },
         didParseCell: function (data) {
            if (data.cell.section !== "head" && ["Person", "Person Name", "Phone 1", "Phone 2", "Notes"].indexOf(data.cell.text.toString()) > -1) {
               data.cell.styles.fillColor = [128, 128, 128];
               data.cell.styles.textColor = [255, 255, 255];
            }
            if (data.cell.section !== "head" && data.cell.text.toString() === "Response Instruction") {
               data.cell.styles.fontStyle = "bold";
            }
         },
         showHead: "everyPage",
         rowPageBreak: "avoid",
         didDrawPage: function (data) {
            // Header
            doc.setFontSize(15);
            doc.setTextColor(40);
            doc.text(title, data.settings.margin.left, 22);
            doc.setFontSize(10);
            doc.text(timeStamp, data.settings.margin.left, 34);
            // Footer
            const str = "Page " + (doc.internal.pages.length - 1);

            doc.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
               ? pageSize.height
               : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
         },
         headStyles: { fillColor: [0, 0, 0] },
         bodyStyles: { valign: "top" }
      });
      window.open(doc.output('bloburl'), '_blank');
      this.setState({
         ...this.state,
         exportRecords: [],
      });
   }
   onPrintClick = () => {
      const { tabName, clientName, clientOid, totalRecords, includeResponseInstruction } = this.state;
      let newFilterOptions: IEscalationReportFilter = { ...this.filterOptions };
      newFilterOptions.page = 1;
      newFilterOptions.pageSize = totalRecords;
      newFilterOptions.includeResponseInstruction = includeResponseInstruction;
      escalationReportService.escalationReport(newFilterOptions, tabName, clientOid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data?.reportData) {
            this.setState({
               ...this.state,
               exportRecords: res?.data?.reportData,
               dateTime: res?.data?.dateTime,
            }, () => {
               this.generatePDF(`${tabName} escalation details for client: ${clientName}`);
            });
         }
      });
   }
   getNameColumnLabel = (tabName: string) => {
      switch (tabName) {
         case "Default":
            return "Client Name";
         case "Person":
            return "Person Name";
         case "Device":
            return "Device ID";
         default:
            return "Name";
      }
   }
   getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   onChangeClient = (client: any) => {
      this.setState({
         ...this.state,
         clientOid: client?.value ?? Common.EmptyGuid,
         clientName: client?.label ?? ""
      }, () => {
         this.searchEscalationReport();
      });
   }
   onChangeIncludeResponseInstruction = (value: boolean) => {
      this.setState({
         ...this.state,
         includeResponseInstruction: value
      }, () => {
         this.searchEscalationReport();
      });
   }
   //#endregion
   render() {
      const { totalRecords, records, tabName, clientOid, exportRecords, includeResponseInstruction } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle="Escalation Report" searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={null}
            actionPermission={actionPermission}
         >
            <Grid container spacing={2}>
               <Formik enableReinitialize={true} initialValues={{ ClientOid: clientOid, IncludeResponseInstruction: includeResponseInstruction }} onSubmit={() => { }} >
                  {({ setFieldValue, values }) => (
                     <>
                        <Grid item xs={9} md={4}>
                           <SearchableServerSearch
                              labelText="Client Name"
                              id="ClientOid"
                              inputProps={{
                                 name: "ClientOid",
                              }}
                              value={values.ClientOid}
                              onSearchTextChange={this.getClientsSelectOptionsForPersonScreen}
                              setValue={(value: ISelectOption) => {
                                 setFieldValue("ClientOid", value?.value);
                                 this.onChangeClient(value);
                              }}
                              isClientSelection={true}
                           />
                        </Grid>
                        <Grid item xs={9} md={4}>
                           <CustomCheckbox
                              labelText={"Show Response Instruction"}
                              id="IncludeResponseInstruction"
                              inputProps={{
                                 required: true,
                                 name: "IncludeResponseInstruction",
                                 onChange: (e: any) => {
                                    const isChecked = e.target.checked;
                                    setFieldValue("IncludeResponseInstruction", isChecked);
                                    this.onChangeIncludeResponseInstruction(isChecked);
                                 }
                              }}
                              checked={includeResponseInstruction}
                           />
                        </Grid>
                     </>
                  )}
               </Formik>
               {(totalRecords && totalRecords > 0) ?
                  <Grid item xs={3} md={4}>
                     <Button size="sm" style={{ float: "right", marginTop: "10px" }} color="primary" startIcon={<Print />} onClick={this.onPrintClick}>
                        Print
                     </Button>
                  </Grid>
                  : null
               }
            </Grid>
            <Box sx={{ bgcolor: 'background.paper', marginBottom: "5px" }}>
               <AppBar style={{ marginBottom: "5px", background: primaryColor[0] }} position="sticky">
                  <Tabs
                     value={tabName}
                     onChange={this.handleChange}
                     indicatorColor="secondary"
                     textColor="inherit"
                     variant="fullWidth"
                  >
                     <Tab style={{ color: "white", fontWeight: "bolder" }} label="Default" value={"Default"} {...a11yProps("Default")} />
                     <Tab style={{ color: "white", fontWeight: "bolder" }} label="Person" value={"Person"} {...a11yProps("Person")} />
                     <Tab style={{ color: "white", fontWeight: "bolder" }} label="Device" value={"Device"} {...a11yProps("Device")} />
                  </Tabs>
               </AppBar>
               {clientOid === Common.EmptyGuid &&
                  <div className="empty-state page-size">
                     <h5 className="text-dark medium">Select client to see escalations.</h5>
                  </div>
               }
               {clientOid !== Common.EmptyGuid &&
                  <>
                     {/* list web view */}
                     < Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <CustomGrid headers={TableHeader.getEscalationReportTableHeaders(this.getNameColumnLabel(tabName))} pagingInfoText="EscalationReport"
                           totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                           {records && records?.map((record: any, index: number) => {
                              return (
                                 <EscalationReportRow
                                    key={`row_${index}`}
                                    record={record}
                                    tab={tabName}
                                 />
                              );
                           })}
                        </CustomGrid>
                     </Box>

                     {/* list mobile view */}
                     <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <CustomInfiniteScroll dataLength={records ? records.length : 0} next={this.fetchMoreData} hasMore={true}>
                           <Grid container spacing={2}>
                              {records && records?.map((record: any, index: number) => {
                                 return (
                                    <EscalationReportCard
                                       key={`row_${index}`}
                                       record={record}
                                       tab={tabName}
                                    />
                                 );
                              })}
                           </Grid>
                        </CustomInfiniteScroll>
                     </Box>
                  </>
               }
            </Box>
            {exportRecords && exportRecords.length > 0 &&
               this.getExportTable(exportRecords)
            }
         </PageLayout>
      )
   }
}

export default EscalationReport;
