import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import Button from '../../Common/Button';
import { IFileUploadModalProps } from '../../../types/propType';
import { Common } from '../../../constants/common';
import CustomFileUploader from '../../Common/CustomFileUploader';
import { dangerColor } from '../../../assets/jss/appStyle';
import { StatusCode } from '../../../constants/constant';
import PersonService from "../../../services/person.service";
import ExportOptions from '../../Common/ExportOptions';

const ImportPersonModal: React.FC<IFileUploadModalProps> = ({ open, close, successCallback, name }) => {
   const fileTypes: string[] = ["CSV"];
   const [file, setFile] = useState<any>(null);
   const [error, setError] = useState<any>(null);

   const onUpload = () => {
      if (file !== null) {
         const formData = new FormData();
         formData.append("importedFile", file);
         formData.append("frontendURL", window.location.origin)
         PersonService.import(formData).then((res: any) => {
            if (res?.status === StatusCode.Success) {
               setError(null);
               successCallback();
            }
            else if (res?.status === StatusCode.BadRequest) {
               setError(res?.data)
            }
         });
      }
   }
   //#region Download
   const onDownload = (exportType: string) => {
      PersonService.exportUploadFormat({ exportType }).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `Persons_Template`;
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });

   }
   //#endregion
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <DialogTitle>Import Persons</ DialogTitle>
         <DialogContent dividers>
            <CustomFileUploader
               setFile={setFile}
               file={file}
               fileTypes={fileTypes} />

            <div style={{ marginTop: 20, color: dangerColor[0] }}>
               {
                  error !== null &&
                  <Alert severity="error">
                     {error?.invalidFile ?
                        <span>Invalid file</span> :
                        <span>Some records could not be imported. </span>
                     }

                     {error?.invalidPersonOId && error?.invalidPersonOId.length > 0 &&
                        <>
                           <p>The following Person OId's are not valid:</p>
                           <ul>
                              {
                                 error?.invalidPersonOId?.map((item: any, index: number) => {
                                    return <li key={`invalidPersonOId_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }

                     {
                        error?.nonExistingClients && error?.nonExistingClients.length > 0 &&
                        <>
                           <p>The following clients do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingClients?.map((item: any, index: number) => {
                                    return <li key={`nonExistingClients_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.invalidPhoneNumbers && error?.invalidPhoneNumbers.length > 0 &&
                        <>
                           <p>The following phone numbers are invalid:</p>
                           <ul>
                              {
                                 error?.invalidPhoneNumbers?.map((item: any, index: number) => {
                                    return <li key={`invalidPhoneNumbers_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.invalidRowNumbers && error?.invalidRowNumbers.length > 0 &&
                        <>
                           <p>The following record number data are insufficient:</p>
                           <ul>
                              {
                                 error?.invalidRowNumbers?.map((item: any, index: number) => {
                                    return <li key={`invalidRowNumbers_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.duplicateEmailAddress && error?.duplicateEmailAddress.length > 0 &&
                        <>
                           <p>The following email address are already exists:</p>
                           <ul>
                              {
                                 error?.duplicateEmailAddress?.map((item: any, index: number) => {
                                    return <li key={`duplicateEmailAddress_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.invalidEmailAddress && error?.invalidEmailAddress.length > 0 &&
                        <>
                           <p>The following email address are invalid:</p>
                           <ul>
                              {
                                 error?.invalidEmailAddress?.map((item: any, index: number) => {
                                    return <li key={`invalidEmailAddress_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.otherErrors && error?.otherErrors.length > 0 &&
                        <>
                           <p>The following errors occurs while importing file:</p>
                           <ul>
                              {
                                 error?.otherErrors?.map((item: any, index: number) => {
                                    return <li key={`otherErrors_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.notValidRelationshipToClient && error?.notValidRelationshipToClient.length > 0 &&
                        <>
                           <p>The following relationship to client are invalid:</p>
                           <ul>
                              {
                                 error?.notValidRelationshipToClient?.map((item: any, index: number) => {
                                    return <li key={`notValidRelationshipToClient_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.notValidTimeZone && error?.notValidTimeZone.length > 0 &&
                        <>
                           <p>The following time zone are invalid:</p>
                           <ul>
                              {
                                 error?.notValidTimeZone?.map((item: any, index: number) => {
                                    return <li key={`notValidTimeZone_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                  </Alert>
               }
            </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <ExportOptions onDownload={onDownload} children={(bindTrigger: any) =>
               <Button color="info" {...bindTrigger} >Download Template</Button>
            } />
            <Button color="primary" onClick={onUpload} disabled={!Common.stringNullOrEmpty(error)}>Upload</Button>
         </DialogActions>
      </Dialog>
   );
};

export default ImportPersonModal;
