import { useState } from 'react';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ISelectOption } from '../../../types/dataTypes';
import { AddBox, Delete } from "@mui/icons-material";
import Button from '../../Common/Button';
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
function MultipleDeviceSelection({ ...props }) {
   const { value, onSearchTextChange, setValue } = props;
   const [deviceList, setDeviceList] = useState<string[]>(value && value.length > 0 ? value : [""]);
   const addNewDevice = () => {
      setDeviceList([...deviceList, ""]);
      setValue(deviceList);
   }
   const removeDevice = (index: number) => {
      deviceList.splice(index, 1);
      setDeviceList([...deviceList]);
      setValue(deviceList);
   }
   const setDevice = (index: number, value: string) => {
      deviceList[index] = value;
      setValue(deviceList);
   }
   return (
      <Grid container>
         <Grid item xs={12} md={12}>
            {deviceList && <Button color="success" justIcon={true} onClick={() => addNewDevice()}><AddBox /></Button>}
         </Grid>
         {deviceList && deviceList.map((deviceOid, index) => {
            return (
               <Grid key={index} item xs={12} md={12}>
                  <Grid container spacing={1} >
                     <Grid item xs={10} md={10}>
                        <SearchableServerSearch
                           labelText={`Device ${index + 1}`}
                           id={`Device${index + 1}`}
                           inputProps={{
                              required: false,
                              name: `Device${index + 1}`,
                              disableClearable: false,
                              getOptionDisabled: (option: any) => option.disabled
                           }}
                           value={deviceOid}
                           onSearchTextChange={onSearchTextChange}
                           setValue={(value: ISelectOption) => {
                              setDevice(index, value?.value)
                           }}
                        />
                     </Grid>
                     <Grid item xs={2} md={2}>
                        <Button color="danger" justIcon={true} onClick={() => removeDevice(index)}><Delete /></Button>
                     </Grid>
                  </Grid>
               </Grid>
            )
         })
         }
      </Grid>
   )
}
MultipleDeviceSelection.propTypes = {
   value: PropTypes.array,
   setValue: PropTypes.any
};
export default MultipleDeviceSelection;
