import React from 'react';
import lodash from "lodash";

import { ITextComparetorProps } from '../../types/propType';

const TextComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   return (
      <>
         {
            oldValueJson !== null ?
               <>
                  {
                     changedValue === oldValue ?
                        <span>{changedValue}</span> :
                        <span style={{ backgroundColor: "#faa8a8" }}>
                           <del>{oldValue}</del> <span style={{ backgroundColor: "#bcffbc" }}>{changedValue}</span>
                        </span>
                  }
               </>
               :
               <span>{changedValue}</span>
         }
      </>
   );
};

export default TextComparetor;
