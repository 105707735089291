import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, CardHeader, Grid, Typography, MenuItem, Divider } from '@mui/material';
import { Delete, Edit, History, Place, MyLocation, Info, Visibility } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { ILocationDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

const LocationCard: React.FC<ILocationDataProps> = ({ classes, location, onEdit, onDelete, onShowAuditLog, actionPermission }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <ActionOptionMenu>
                     {(actionPermission["Location:GET"]) &&
                        <>
                           <MenuItem disableRipple onClick={() => { onEdit(location) }}>
                              {(actionPermission["Location:POST"])
                                 ? <> <Edit /> Edit</> : <><Visibility /> View</>}
                           </MenuItem>
                           <Divider />
                        </>
                     }
                     {(actionPermission["Audit:Location"]) &&
                        <MenuItem disableRipple onClick={() => { onShowAuditLog(location) }}>
                           <History />
                           Audit history
                        </MenuItem>
                     }
                     {(actionPermission["Location:DELETE"]) &&
                        <>
                           <Divider />

                           <MenuItem disableRipple onClick={() => { onDelete(location, true) }}>
                              <Delete />
                              Delete
                           </MenuItem>
                        </>
                     }
                  </ActionOptionMenu>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {location.title}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <MyLocation className={classes.beforeTextIcon} /> {location.type}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Info className={classes.beforeTextIcon} /> {location.description}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Place className={classes.beforeTextIcon} /> {location.physicalAddress}
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(LocationCard, gridTileStyle);
