import { Controllers } from "../constants/constant";
import { IDevice, IDeleteParams, IEscalation, IResponseInstruction } from "../types/dataTypes";
import request from "../utils/request";

class DeviceService {

   searchDevices = (params: any) => {
      return request({
         url: `${Controllers.Device}/Search`,
         method: 'get',
         params
      });
   }

   saveDevice = (data: IDevice) => {
      return request({
         url: `${Controllers.Device}`,
         method: 'post',
         data,
      })
   }

   deleteDevice = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.Device}`,
         method: 'delete',
         params
      })
   }

   getDeviceModelSelectOption = () => {
      return request({
         url: `${Controllers.Device}/GetDeviceModelSelectOption`,
         method: 'get',
      })
   }

   getPlatformSelectOption = () => {
      return request({
         url: `${Controllers.Device}/GetPlatformSelectOption`,
         method: 'get',
      })
   }

   getClientSpecificDeviceSelectOptions = (clientOid: string | undefined, personOid: string, params: any, signal: any) => {
      return request({
         url: `${Controllers.Device}/GetClientSpecificDeviceSelectOptions/${clientOid}/${personOid}`,
         method: 'get',
         params,
         signal
      })
   }

   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.Device}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }

   assignDefaultEscalation = (data: IEscalation, deviceOid?: string) => {
      return request({
         url: `${Controllers.Device}/AssignDefaultEscalation/${deviceOid}`,
         method: 'post',
         data,
      })
   }

   import(data: FormData, entityName?: string) {
      return request({
         url: `${Controllers.Device}/Import${entityName}`,
         method: 'post',
         data,
         headers:
         {
            'Content-Type': 'multipart/form-data'
         }
      });
   }

   exportDeviceListByMonitoringStation(params: any) {
      return request({
         url: `${Controllers.Device}/ExportDeviceListByMonitoringStation`,
         method: 'get',
         responseType: 'blob',
         params
      })
   }

   getDeviceTypeSelectOption = () => {
      return request({
         url: `${Controllers.Device}/GetDeviceTypeSelectOption`,
         method: 'get',
      })
   }

   getVehicleModelSuggestions = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Device}/GetVehicleModelSuggestions`,
         method: 'get',
         params,
         signal
      })
   }

   exportUploadFormat(params: any, entityName?: string) {
      return request({
         url: `${Controllers.Device}/ExportTemplate${entityName}`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }

   getVehicleMakeSuggestions = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Device}/GetVehicleMakeSuggestions`,
         method: 'get',
         params,
         signal
      })
   }

   getGasTagSelectOptions = () => {
      return request({
         url: `${Controllers.Device}/GetGasTagSelectOptions`,
         method: 'get',
      })
   }

   getResponseInstruction = (deviceOid: string) => {
      return request({
         url: `${Controllers.Device}/ResponseInstruction/${deviceOid}`,
         method: 'get',
      })
   }

   getEscalationDetails = (deviceOid: string) => {
      return request({
         url: `${Controllers.Device}/EscalationDetails/${deviceOid}`,
         method: 'get',
      })
   }

   getMonitoredByOptions = () => {
      return request({
         url: `${Controllers.Device}/GetMonitoredByOptions`,
         method: 'get',
      })
   }

   setResponseInstruction = (data: IResponseInstruction, clientOid?: string) => {
      return request({
         url: `${Controllers.Device}/SetResponseInstruction/${clientOid}`,
         method: 'post',
         data,
      })
   }

   getDevice = (params: any) => {
      return request({
         url: `${Controllers.Device}`,
         method: 'get',
         params
      })
   }

   getDeviceStatusSelectOptions = () => {
      return request({
         url: `${Controllers.Device}/GetDeviceStatusSelectOptions`,
         method: 'get',
      })
   }

   getDeviceMakeSelectOptions = () => {
      return request({
         url: `${Controllers.Device}/GetDeviceMakeSelectOptions`,
         method: 'get',
      })
   }

   validateMonitoringAccountNumber(params: any) {
      return request({
         url: `${Controllers.Device}/ValidateMonitoringAccountNumber`,
         method: 'get',
         responseType: 'blob',
         params
      })
   }

   exportDevicePersonMapping(params: any) {
      return request({
         url: `${Controllers.Device}/ExportDevicePersonMapping`,
         method: 'get',
         responseType: 'blob',
         params
      })
   }

   getDeviceForTripSelectOptions = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Device}/GetDeviceForTripSelectOptions`,
         method: 'get',
         params,
         signal
      })
   }

   getDeviceCountByTypeAndModel = (params: any) => {
      return request({
         url: `${Controllers.Device}/GetDeviceCountByTypeAndModel`,
         method: 'get',
         params,
      })
   }

   setResponseInstructionApplyAll = (data: IResponseInstruction, deviceOid?: string, deviceType?: string, deviceModel?: string) => {
      return request({
         url: `${Controllers.Device}/SetResponseInstructionApplyAll/${deviceOid}/${deviceType}/${deviceModel}`,
         method: 'post',
         data,
      })
   }

   getDeviceSelectionOptionByTypeAndModel = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Device}/GetDeviceSelectionOptionByTypeAndModel`,
         method: 'get',
         params,
         signal
      })
   }

   getDeviceCountByClientOid = (params: any) => {
      return request({
         url: `${Controllers.Device}/GetDeviceCountByClientOid`,
         method: 'get',
         params,
      })
   }
   getDeviceSelectOptionsForClient = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Device}/GetDeviceSelectOptionsForClient`,
         method: 'get',
         params,
      })
   }

   assignDefaultEscalationApplyAll = (data: any, clientOid?: string) => {
      return request({
         url: `${Controllers.Device}/AssignDefaultEscalationApplyAll/${clientOid}`,
         method: 'post',
         data,
      })
   }

   getFixedSelectOptions = (params: any) => {
      return request({
         url: `${Controllers.Device}/GetFixedSelectOptions`,
         method: 'get',
         params
      })
   }

}

export default new DeviceService();
