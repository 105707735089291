import { fixedBlocks } from "./FixedResponseInstuctionBlocks";

export const TextBlocks: {
   Type: string,
   Text: string,
   IsRequired?: boolean,
   IsFixed?: boolean,
}[] = [

      {
         Type: "Text",
         Text: "Call Escalations for 10 minutes"
      },
      {
         Type: "Text",
         Text: `if no answer, Call Emergency services`
      },      
      ...fixedBlocks
   ];
