import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, FormHelperText } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { Common } from "../../constants/common";

function CustomRadioButtonGroup({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      value,
      options,
      setValue,
      customErrorMessage
   } = props;

   const [field, meta] = useField(inputProps);

   return (
      <FormControl {...formControlProps} fullWidth className={formControlProps?.className + " " + classes.formControl}>
         <FormLabel id={id}>{labelText} </FormLabel>
         <RadioGroup
            row
            {...field}
            {...inputProps}
            value={value}
            onChange={(e: any) => {
               setValue(e.target.value);
            }}
         >
            {options.map((option: any, index: number) => <FormControlLabel key={index} value={option.value} control={<Radio disabled={inputProps.disabled === true || option.disabled === true} size="small" />} label={option.label} />)}
         </RadioGroup>
         {meta.touched && meta.error && meta.error.length > 0 &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
         {
            !Common.stringNullOrEmpty(customErrorMessage) &&
            <FormHelperText id={`error-${inputProps.name}`} error>{customErrorMessage}</FormHelperText>
         }
      </FormControl>
   );
}

CustomRadioButtonGroup.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   value: PropTypes.any
};
export default withStyles(CustomRadioButtonGroup, customInputStyle);
