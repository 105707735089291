import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment } from '@mui/material';

import { IAddEditClientProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import CustomSelect from '../../Common/CustomSelect';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import ReportRecipients from "../../../components/pages/Clients/ReportRecipients";

import { IClient, IGetParams, ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { StatusCode } from '../../../constants/constant';
import { Common } from '../../../constants/common';
import { mapClientObject } from "../../../utils/commonMaps";
import ClientService from "../../../services/client.service";
import PersonService from "../../../services/person.service";
import HelpText from '../../Common/HelpText';
import CustomCheckbox from '../../Common/CustomCheckbox';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ConnectedFocusError } from '../../Common/FocusError';

const AddEditClient: React.FC<IAddEditClientProps> = ({ open, close, successCallback, clientOid }) => {
   const [countries, setCountries] = useState<ISelectOption[]>([]);
   const [error, setError] = useState<any>({});
   const [plans, setPlans] = useState<ISelectOption[]>([]);

   let initialValues: IClient = {
      Name: "",
      Email: "",
      PhoneNumber: "",
      Country: "",
      EscalationOid: null,
      AccountOwnerOid: null,
      ReportRecipients: [],
      PlanOid: "",
      NetworkKey: "",
      ShowDeviceOptionalFields: false,
      ShowPersonOptionalFields: false,
      SendDeviceTestReminder: false,
      DayOfMonthToSendReport: undefined,
      IsPrivacyBlurbRequired: false,
      PrivacyBlurb: undefined,
      AllowDeviceAndEscalationAssignmentToSelf: false,
      BlockAllMails: false,
      EnableTripIntention: false,
      AllowSystemNotification: true
   }
   const [formValues, setFormValues] = useState<IClient>(initialValues);
   const validationSchema = Yup.object().shape({
      Name: Yup.string().trim(Resources.Error_ClientName_Required).required(Resources.Error_ClientName_Required),
      Email: Yup.string().trim(Resources.Error_ClientEmail_Required).email(Resources.Error_Email).required(Resources.Error_ClientEmail_Required),
      PhoneNumber: Yup.string().trim(Resources.Error_ClientPhoneNumber_Required)
         .required(Resources.Error_ClientPhoneNumber_Required)
         .matches(Common.PhoneRegex, Common.PhoneValidationMessage)
         .min(Common.PhoneNumberLength, Common.PhoneNumberLengthValidationMessage)
         .test('LengthWithoutSpaces', Common.PhoneNumberLengthValidationMessage, val => !val || val!.replace(/\s/g, "").length >= Common.PhoneNumberLength)
      ,
      Country: Yup.string().trim(Resources.Error_ClientCountry_Required).required(Resources.Error_ClientCountry_Required),
      PlanOid: Yup.string().trim(Resources.Error_ClientPlan_Required).required(Resources.Error_ClientPlan_Required),
      DayOfMonthToSendReport: Yup.number().nullable().when("SendDeviceTestReminder", {
         is: (value: boolean) => value === true,
         then: Yup.number().nullable().required(Resources.Error_DayOfMonthToSendReport_Required),
      }),
      PrivacyBlurb: Yup.string().nullable().when("IsPrivacyBlurbRequired", {
         is: (value: boolean) => value === true,
         then: Yup.string().nullable().trim(Resources.Error_PrivacyBlurb_Required).required(Resources.Error_PrivacyBlurb_Required),
      }),
      ReportRecipients: Yup.array().when("SendDeviceTestReminder", {
         is: (value: boolean) => value === true,
         then: Yup.array().min(1, Resources.Error_ReportRecipients_Required).test('IsValidRecepients', Resources.Error_ReportRecipients_Required, function (reportRecipients) {
            const validRecepients = reportRecipients?.filter((x: string) => x && x.trim() !== "");
            if (validRecepients && validRecepients?.length > 0) {
               return true;
            }
            return false;
         }),
      }),
   });

   useEffect(() => {
      if (clientOid) {
         const getMethodParams: IGetParams = {
            oid: clientOid
         };
         ClientService.getClient(getMethodParams).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setFormValues(mapClientObject(res?.data));
            }
         })
      }
      setCountries(ClientService.getCountrySelectOption());
      ClientService.getPlansSelectOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            setPlans(res?.data);
         }
      });
   }, [clientOid]);

   const onSubmit = (values: any, setServerError: any) => {
      values.ReportRecipients = values.ReportRecipients.filter((x: string) => x && x.trim() !== "");
      ClientService.saveClient(values).then((res) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }
   const GetClientSpecificAccountOwnerSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return PersonService.GetClientSpecificAccountOwnerSelectOptions(clientOid ?? Common.EmptyGuid, params, signal);
   }
   const GetClientReportRecipientSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return PersonService.GetClientReportRecipientSelectOptions(clientOid ?? Common.EmptyGuid, params, signal);
   }
   const daySelection = Array.from({ length: 28 }, (_, x) => { const indext = x + 1; return { label: indext, value: indext } })
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <Formik enableReinitialize={true} initialValues={formValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, setFieldValue, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{clientOid ? "Edit" : "Add"} Client</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <input type="hidden" name="Oid" value={values.Oid} />
                     <input type="hidden" name="EscalationOid" value={values.EscalationOid?.toString()} />
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Name"
                              id="name"
                              inputProps={{
                                 required: true,
                                 name: "Name"
                              }}
                              customErrorMessage={error["name"]?.message}
                              value={values.Name}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Email"
                              id="email"
                              inputProps={{
                                 required: true,
                                 name: "Email"
                              }}
                              value={values.Email}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Phone number"
                              id="phoneNumber"
                              inputProps={{
                                 required: true,
                                 name: "PhoneNumber"
                              }}
                              value={values.PhoneNumber}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomSelect
                              labelText="Country"
                              id="Country"
                              inputProps={{
                                 required: true,
                                 name: "Country"
                              }}
                              value={values.Country}
                              options={countries}
                           />
                        </Grid>
                        {values.Oid != null &&
                           <Grid item xs={12} md={6}>
                              <SearchableServerSearch
                                 labelText="Account Owner"
                                 id="AccountOwnerOid"
                                 inputProps={{
                                    required: false,
                                    name: "AccountOwnerOid",
                                    disableClearable: false
                                 }}
                                 value={values.AccountOwnerOid}
                                 onSearchTextChange={GetClientSpecificAccountOwnerSelectOptions}
                                 setValue={(value: ISelectOption) => {
                                    setFieldValue("AccountOwnerOid", value?.value)
                                 }}
                              />
                           </Grid>
                        }
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText={<HelpText label="Network Key" text="One Network Key per Client using Blackline." />}
                              id="NetworkKey"
                              inputProps={{
                                 required: false,
                                 name: "NetworkKey"
                              }}
                              value={values.NetworkKey}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomSelect
                              labelText="Plan"
                              id="PlanOid"
                              inputProps={{
                                 required: true,
                                 name: "PlanOid"
                              }}
                              value={values.PlanOid}
                              options={plans}
                           />
                        </Grid>
                        {values.Oid != null &&
                           <Grid item xs={12} md={6}>
                           </Grid>
                        }
                        <Grid item xs={12} md={6}>
                           <CustomCheckbox
                              labelText={"Show Device Optional Fields"}
                              id="ShowDeviceOptionalFields"
                              inputProps={{
                                 required: true,
                                 name: "ShowDeviceOptionalFields"
                              }}
                              checked={values.ShowDeviceOptionalFields}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomCheckbox
                              labelText={"Show Person Optional Fields"}
                              id="ShowPersonOptionalFields"
                              inputProps={{
                                 required: true,
                                 name: "ShowPersonOptionalFields"
                              }}
                              checked={values.ShowPersonOptionalFields}
                           />
                        </Grid>
                        <Grid item xs={12} md={values.SendDeviceTestReminder ? 6 : 12}>
                           <CustomCheckbox
                              labelText={"Send Device Test Reminder"}
                              id="SendDeviceTestReminder"
                              inputProps={{
                                 required: true,
                                 name: "SendDeviceTestReminder"
                              }}
                              checked={values.SendDeviceTestReminder}
                           />
                        </Grid>
                        {values.SendDeviceTestReminder &&
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 labelText="Day Of Month To Send Report"
                                 id="DayOfMonthToSendReport"
                                 inputProps={{
                                    required: true,
                                    name: "DayOfMonthToSendReport"
                                 }}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment style={{ marginRight: "20px", marginTop: "5px" }} position="end">
                                          <HelpText label="" text={`Day of month on which report recipients will receive device test report`} />
                                       </InputAdornment>
                                    )
                                 }}
                                 value={values.DayOfMonthToSendReport}
                                 options={daySelection}
                              />
                           </Grid>
                        }
                        <Grid item xs={12} md={values.IsPrivacyBlurbRequired ? 6 : 12}>
                           <CustomCheckbox
                              labelText={"Is Privacy Blurb Required"}
                              id="IsPrivacyBlurbRequired"
                              inputProps={{
                                 required: true,
                                 name: "IsPrivacyBlurbRequired"
                              }}
                              checked={values.IsPrivacyBlurbRequired}
                           />
                        </Grid>
                        {values.IsPrivacyBlurbRequired &&
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Privacy Blurb"
                                 id="PrivacyBlurb"
                                 inputProps={{
                                    required: true,
                                    name: "PrivacyBlurb"
                                 }}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment style={{ marginRight: "20px", marginTop: "5px" }} position="end">
                                          <HelpText label="" text={`This privacy blurb will be sent to user when device assigned to them`} />
                                       </InputAdornment>
                                    )
                                 }}
                                 value={values.PrivacyBlurb}
                              />
                           </Grid>
                        }
                        {values.PlanOid === "5ed9c63e-ca80-44d5-bf51-a326bfb56edb" &&
                           <Grid item xs={12} md={6}>
                              <CustomCheckbox
                                 labelText={"Allow Users to Assign Device And Person escalation contact to them self"}
                                 id="AllowDeviceAndEscalationAssignmentToSelf"
                                 inputProps={{
                                    required: true,
                                    name: "AllowDeviceAndEscalationAssignmentToSelf"
                                 }}
                                 checked={values.AllowDeviceAndEscalationAssignmentToSelf}
                              />
                           </Grid>
                        }
                        {values.PlanOid === "5ed9c63e-ca80-44d5-bf51-a326bfb56edb" &&
                           <Grid item xs={12} md={6}>
                              <CustomCheckbox
                                 labelText={"Enable Safe Trips"}
                                 id="EnableTripIntention"
                                 inputProps={{
                                    required: true,
                                    name: "EnableTripIntention"
                                 }}
                                 checked={values.EnableTripIntention}
                              />
                           </Grid>
                        }
                        <Grid item xs={12} md={6}>
                           <CustomCheckbox
                              labelText={"Block All Mails"}
                              id="BlockAllMails"
                              inputProps={{
                                 required: true,
                                 name: "BlockAllMails",
                                 onChange: (event: any) => {
                                    const isChecked = event.target.checked;
                                    setFieldValue("BlockAllMails", isChecked);
                                    if (isChecked) {
                                       setFieldValue("AllowSystemNotification", true);
                                    }
                                 }
                              }}
                              checked={values.BlockAllMails}
                           />
                        </Grid>
                        {values.BlockAllMails === true &&
                           <Grid item xs={12} md={6}>
                              <CustomCheckbox
                                 labelText={"Allow System Notification"}
                                 id="AllowSystemNotification"
                                 inputProps={{
                                    name: "AllowSystemNotification"
                                 }}
                                 checked={values.AllowSystemNotification}
                              />
                           </Grid>
                        }
                        {values.Oid != null &&
                           <Grid item xs={12} md={12}>
                              <ReportRecipients
                                 id={"ReportRecipients"}
                                 inputProps={{
                                    required: true,
                                    name: "ReportRecipients"
                                 }}
                                 onSearchTextChange={GetClientReportRecipientSelectOptions}
                                 value={values.ReportRecipients}
                                 setValue={(value: string[]) => {
                                    setFieldValue("ReportRecipients", value)
                                 }}
                                 customErrorMessage={error["ReportRecipients"]?.message}
                              />
                           </Grid>
                        }
                        <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     <Button color="primary" type="submit">Save</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddEditClient;
