import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { IErrorLogDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";
import { Visibility } from "@mui/icons-material";

const ErrorLogRow: React.FC<IErrorLogDataProps> = ({ errorLog, onView }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {Common.getFormattedDateTime(errorLog.createdOn)}  </StyledGridCell>
         <StyledGridCell> {errorLog.functionOrURL}  </StyledGridCell>
         <StyledGridCell> {errorLog.fileName}</StyledGridCell>
         <StyledGridCell> {errorLog.requestData ? errorLog.requestData.substring(0, 50) : ""}  </StyledGridCell>
         <StyledGridCell> {errorLog.responseData ? errorLog.responseData.substring(0, 50) : ""}  </StyledGridCell>
         <StyledGridCell> {errorLog.error ? errorLog.error.substring(0, 50) : ""}  </StyledGridCell>
         <StyledGridCell>
            <Button color="info" justIcon={true} onClick={() => onView(errorLog)}>
               <Visibility />
            </Button>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default ErrorLogRow;
