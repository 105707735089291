import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { withStyles } from 'tss-react/mui';
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { Add, Search, FilterList, History, UploadFile, MoreVert, FileDownload } from "@mui/icons-material";
import Button from '../components/Common/Button';
import pagesStyle from '../assets/jss/pages/pagesStyle';
import { IPageLayoutProps } from '../types/propType';
import SearchBar from '../components/Common/SearchBar';
import ExportOptions from '../components/Common/ExportOptions';
const PageLayout: React.FC<IPageLayoutProps> = ({ children, classes, pageTitle, onCreate, searchText, onSearch,
   advanceFilter, auditLogs, onImport, importOptions, onDownload, isFromSerach, isFromDependenciesDialogue, actionPermission, onSearchTextChange, downloadOptions, showAdvanceFilerAlways, customAction, showClientFilter = false }) => {
   const [showAdvanceFilter, setShowAdvanceFilter] = useState<boolean>(showAdvanceFilerAlways === true ? true : false);
   const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
   const [showImportOptions, setShowImportOptions] = useState<boolean>(false);
   const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const onClickImportOptions = (event: any) => {
      setAnchorEl(event.currentTarget);
      setShowImportOptions(true);
   }
   const closeImportOptions = () => {
      setAnchorEl(null);
      setShowImportOptions(false);
   }
   const onClickDownloadOptions = (event: any) => {
      setAnchorEl(event.currentTarget);
      setShowDownloadOptions(true);
   }
   const closeDownloadOptions = () => {
      setAnchorEl(null);
      setShowDownloadOptions(false);
   }
   const downloadOptionMenu = () => {
      return <Menu
         id="download-menu"
         MenuListProps={{
            'aria-labelledby': 'long-button',
         }}
         anchorEl={anchorEl}
         open={showDownloadOptions}
         onClose={() => closeDownloadOptions()}
         PaperProps={{
            style: {
               maxHeight: 50 * 4.5,
               // width: '20ch',
            },
         }}
      >
         {downloadOptions?.map((option, index) => (
            <ExportOptions key={index} onDownload={(exportType: string) => { closeDownloadOptions(); option.onClick(exportType) }} children={(bindTrigger: any) =>
               <MenuItem  {...bindTrigger}>
                  {option.name}
               </MenuItem>
            }
            />
         ))}
      </Menu>
   }
   useEffect(() => {
      if (showClientFilter) setShowAdvanceFilter(showClientFilter);
   }, [showClientFilter])
   return (
      <>
         <Formik initialValues={{ searchText: searchText }} onSubmit={(values) => { onSearch(values.searchText); }}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <div className={classes.pageHeader} data-testid={`${pageTitle}_header`}>
                     {!isFromDependenciesDialogue &&
                        <Typography variant="h5" component="h2" className={classes.pageTitle}>
                           {pageTitle}
                        </Typography>
                     }
                     {!isFromSerach &&
                        <>
                           {/* button web view */}
                           <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                              {onSearch &&
                                 <div className={classes.headerSearchBox}>
                                    <SearchBar
                                       sx={{ display: 'flex', width: 400 }}
                                       value={values.searchText}
                                       id="searchText"
                                       inputProps={{
                                          required: false,
                                          name: "searchText",
                                          disableClearable: false
                                       }}
                                       onSearchTextChange={onSearchTextChange}
                                       setFieldValue={setFieldValue}
                                       onSearch={onSearch} />
                                 </div>
                              }
                              {onCreate && (actionPermission["add"]) && <Button color="primary" startIcon={<Add />} onClick={onCreate}>
                                 New {pageTitle.indexOf("Users for") > -1 ? "User" : pageTitle.slice(0, -1)}
                              </Button>}
                              {onImport && (actionPermission["import"]) && <Button color="info" startIcon={<UploadFile />} onClick={onImport}>
                                 Import {pageTitle}
                              </Button>}
                              {importOptions && (actionPermission["import"]) && importOptions.length > 0 &&
                                 <React.Fragment>
                                    <Button color="info" startIcon={<MoreVert />} onClick={(e: any) => onClickImportOptions(e)}>
                                       Import
                                    </Button>
                                    <Menu
                                       id="import-menu"
                                       MenuListProps={{
                                          'aria-labelledby': 'long-button',
                                       }}
                                       anchorEl={anchorEl}
                                       open={showImportOptions}
                                       onClose={() => closeImportOptions()}
                                       PaperProps={{
                                          style: {
                                             maxHeight: 50 * 4.5,
                                             // width: '20ch',
                                          },
                                       }}
                                    >
                                       {importOptions.map((option, index) => (
                                          <MenuItem key={index} onClick={() => { closeImportOptions(); option.onClick(option.name) }}>
                                             {option.displayName ?? option.name}
                                          </MenuItem>
                                       ))}
                                    </Menu>
                                 </React.Fragment>
                              }
                              {downloadOptions && downloadOptions.length > 0 &&
                                 <React.Fragment>
                                    <Button color="danger" startIcon={<MoreVert />} onClick={(e: any) => onClickDownloadOptions(e)}>
                                       Download
                                    </Button>
                                    {downloadOptionMenu()}
                                 </React.Fragment>
                              }
                              {onDownload && (actionPermission["download"]) &&
                                 <ExportOptions onDownload={onDownload} children={(bindTrigger: any) =>
                                    <Button color="danger" startIcon={<FileDownload />} {...bindTrigger} >
                                       Download {pageTitle}
                                    </Button>
                                 }
                                 />
                              }
                              {customAction &&
                                 <>
                                    {customAction}
                                 </>
                              }
                              {advanceFilter && showAdvanceFilerAlways !== true && <Button justIcon={true} onClick={() => { setShowAdvanceFilter(!showAdvanceFilter) }}>
                                 <FilterList />
                              </Button>}
                              {auditLogs && (actionPermission["auditlog"]) && <Button color="success" justIcon={true} onClick={auditLogs}>
                                 <History />
                              </Button>}
                           </Box>
                           {/* button mobile view */}
                           <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                              {onSearch && <Button color="white" justIcon={true} onClick={() => { setShowMobileSearch(!showMobileSearch) }}>
                                 <Search />
                              </Button>}
                              {onCreate && (actionPermission["add"]) && <Button color="primary" justIcon={true} onClick={onCreate}>
                                 <Add />
                              </Button>}
                              {onImport && (actionPermission["import"]) && <Button color="info" justIcon={true} onClick={auditLogs}>
                                 <UploadFile />
                              </Button>}
                              {importOptions && (actionPermission["import"]) && importOptions.length > 0 && <React.Fragment>
                                 <Button color="info" justIcon={true} onClick={(e: any) => onClickImportOptions(e)}>
                                    <MoreVert />
                                 </Button>
                                 <Menu
                                    id="import-menu"
                                    MenuListProps={{
                                       'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={showImportOptions}
                                    onClose={() => closeImportOptions()}
                                    PaperProps={{
                                       style: {
                                          maxHeight: 50 * 4.5,
                                          // width: '20ch',
                                       },
                                    }}
                                 >
                                    {importOptions.map((option, index) => (
                                       <MenuItem key={index} onClick={() => { closeImportOptions(); option.onClick(option.name, option.displayName) }}>
                                          {option.displayName ?? option.name}
                                       </MenuItem>
                                    ))}
                                 </Menu>
                              </React.Fragment>}
                              {downloadOptions && downloadOptions.length > 0 &&
                                 <React.Fragment>
                                    <Button color="danger" justIcon={true} onClick={(e: any) => onClickDownloadOptions(e)}>
                                       <MoreVert />
                                    </Button>
                                    {downloadOptionMenu()}
                                 </React.Fragment>
                              }
                              {onDownload && (actionPermission["download"]) &&
                                 <ExportOptions onDownload={onDownload} children={(bindTrigger: any) =>
                                    <Button color="danger" justIcon={true} {...bindTrigger}>
                                       <FileDownload />
                                    </Button>
                                 }
                                 />
                              }
                              {customAction &&
                                 <>
                                    {customAction}
                                 </>
                              }
                              {advanceFilter && showAdvanceFilerAlways !== true && <Button justIcon={true} onClick={() => { setShowAdvanceFilter(!showAdvanceFilter) }}>
                                 <FilterList />
                              </Button>}
                              {auditLogs && (actionPermission["auditlog"]) && <Button color="success" justIcon={true} onClick={auditLogs}>
                                 <History />
                              </Button>}
                           </Box>
                        </>
                     }
                  </div>
                  {!isFromSerach &&
                     <>
                        {/* search panel mobile view */}
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                           {showMobileSearch &&
                              <SearchBar
                                 sx={{ width: 1 }}
                                 value={values.searchText}
                                 id="searchText"
                                 inputProps={{
                                    required: false,
                                    name: "searchText",
                                    disableClearable: false
                                 }}
                                 onSearchTextChange={onSearchTextChange}
                                 setFieldValue={setFieldValue}
                                 onSearch={onSearch} />
                           }
                        </Box>
                     </>
                  }
               </Form>
            )}
         </Formik>
         {showAdvanceFilter &&
            <div className={classes.advanceFilterPanel}>
               {advanceFilter}
            </div>}
         <div className={classes.pageBody} data-testid={`${pageTitle}_body`}>
            {children}
         </div>
      </>
   );
};

export default withStyles(PageLayout, pagesStyle);
