import { Fragment, useEffect, ComponentType } from "react";
import { connect } from "formik";
import { getIn, FormikContextType, FormikValues } from "formik";
// import flattenToLodashFormat from "./flattenToLodashFormat";

/**
 * This component focus the first error in the Formik form after the validation.
 * Note: The first is not necessary the first on the screen, it's just the first
 * key in the touched object, order is not guaranteed.
 * */

interface FocusErrorProps {
   /**
    * Values from Formik provider.
    */
   formik: Pick<
      FormikContextType<FormikValues>,
      "isSubmitting" | "touched" | "isValidating" | "errors"
   >;
   /**
    * Time in ms to execute the focus in the component with the error, by default 100ms.
    */
   focusDelay?: number;
   onFocus?: () => void
}

function FocusError({
   formik: { isSubmitting, touched, isValidating, errors },
   focusDelay = 100,
   onFocus
}: FocusErrorProps) {
   useEffect(() => {
      if (isSubmitting && !isValidating) {
         // Flatten touched as a way to check nested keys easily and
         // fix the depth problem when the error text is created
         // by a React component like react-intl.
         const flattedTouched = flattenToLodashFormat(touched);

         const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
            if (getIn(errors, key)) {
               prev.push(key);
            }
            return prev;
         }, [] as string[]);

         if (errorNames.length && typeof document !== "undefined") {
            let errorElement: HTMLElement | null;

            errorNames.forEach((errorKey) => {
               const selector = `[id="${errorKey}"]`;
               if (!errorElement) {
                  errorElement = document.querySelector(selector);
                  return;
               }
            });

            // This is to avoid the other components autofocus when submitting
            setTimeout(() => {
               errorElement && errorElement.focus();
               onFocus && onFocus()
            }, focusDelay);
         }
      }
   }, [isSubmitting, isValidating, errors, touched, focusDelay, onFocus]);

   return <Fragment />;
}

const OBJECT_DELIMITERS = ".";
const ARRAY_START_DELIMITER = "[";
const ARRAY_END_DELIMITER = "]";
function flattenToLodashFormat(target: {}): {} {
   const output = {} as { [key: string]: string };

   function step(value: any, prev = "") {
      const type = Object.prototype.toString.call(value);
      const isObject = type === "[object Object]" || type === "[object Array]";
      const isArray = Array.isArray(value);

      if (!isArray && isObject) {
         Object.keys(value).forEach(function (key: string) {
            let newKey = prev ? prev + OBJECT_DELIMITERS + key : key;

            step(value[key], newKey);
         });
      } else if (isArray) {
         value.forEach((arrayValue: any, index: number) => {
            const arrayKey =
               prev + ARRAY_START_DELIMITER + index + ARRAY_END_DELIMITER;

            step(arrayValue, arrayKey);
         });
      } else {
         output[prev] = value;
      }
   }

   step(target);

   return output;
}
export const ConnectedFocusError = formikConnect(FocusError);

function formikConnect<C extends ComponentType<{ formik: any }>>(
   component: C
) {
   return connect(component as any) as ComponentType<
      Omit<GetProps<C>, "formik">
   >;
}

type GetProps<T> = T extends ComponentType<infer P> ? P : never;
