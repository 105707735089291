import React from "react";
import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Tooltip, Typography, Link, MenuItem, Divider } from "@mui/material";
import { AssignmentInd, Delete, Edit, Email, Phone, History, Devices, Assignment, Visibility, ForwardToInbox, Apartment } from "@mui/icons-material";
import Button from "../../Common/Button";
import { IPersonDataProps } from "../../../types/propType";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { warningColor } from "../../../assets/jss/appStyle";
import WarningSign from "../../Common/WarningSign";
import { Common } from "../../../constants/common";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { allowPersonAddEdit } from "../../../utils/PermissionHelpers";

const PersonCard: React.FC<IPersonDataProps> = ({ classes, person, onEdit, onDelete, onAssignDeviceToPerson, onShowAuditLog, onAssignEscalation, showOnlyEscalation, showOnlyEdit, actionPermission, onSetResponseInstruction, onSelectDevice, sendPasswordResetMail }) => {
   const showAllActions: boolean = !showOnlyEdit && !showOnlyEscalation;
   const deviceList = (deviceIds: any[]) => {
      return (
         <>
            {deviceIds && deviceIds.length > 0 && deviceIds.map((device: any, index: number) => {
               if (onSelectDevice != null)
                  return <Link key={index} onClick={() => onSelectDevice(device.deviceOid)}>{device.deviceId}<br /></Link>
               else
                  return <>{device.deviceId}<br /></>
            })
            }
         </>
      )
   }
   const isEscalationContact = person.personClientRelation?.relationshipToClient === "Escalation Contact";
   const allowAssignEscalationContact = Common.AllowAssignEscalationContact();
   return (
      <Grid item xs={12} sm={6} md={4}>
         <Card style={(person.isEscalationAssigned === false && !isEscalationContact) === true ? { border: `2px solid ${warningColor[0]}` } : {}}>
            <CardHeader
               action={
                  <>
                     {showOnlyEscalation && (actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && (actionPermission["Person:AssignDefaultEscalation"]) &&
                        <Button color="rose" justIcon={true} onClick={() => onAssignEscalation(person)}>
                           <Tooltip title="Assign person escalation contact">
                              <Assignment />
                           </Tooltip>
                        </Button>
                     }
                     {showOnlyEdit && allowPersonAddEdit(actionPermission) &&
                        <Button color="info" justIcon={true} onClick={() => { onEdit(person) }}>
                           <Tooltip title="Edit">
                              <Edit />
                           </Tooltip>
                        </Button>
                     }
                     {showAllActions &&
                        <ActionOptionMenu>
                           {(actionPermission["Person:POST"] || actionPermission["Person:GET"]) &&
                              <>
                                 <MenuItem disableRipple onClick={() => { onEdit(person) }}>
                                    {allowPersonAddEdit(actionPermission) ? <> <Edit /> Edit </> : <> <Visibility /> View </>}
                                 </MenuItem>
                                 <Divider />
                              </>
                           }
                           {(actionPermission["Audit:Person"]) &&
                              <MenuItem disableRipple onClick={() => { onShowAuditLog(person) }}>
                                 <History color="primary" />
                                 Audit history
                              </MenuItem>
                           }
                           {(actionPermission["Person:AssignMultipleDevicesToPerson"] && actionPermission["Device:GetClientSpecificDeviceSelectOptions"]) &&
                              (!Common.IsInRole("Client User") || person.oid === localStorage.getItem("personOid")) &&
                              <MenuItem disabled={isEscalationContact} disableRipple onClick={() => { onAssignDeviceToPerson(person) }}>
                                 <AssignmentInd />
                                 Assign device to person
                              </MenuItem>
                           }
                           {(actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && allowAssignEscalationContact &&
                              <MenuItem disabled={isEscalationContact} disableRipple onClick={() => { onAssignEscalation(person) }}>
                                 <Assignment />
                                 {actionPermission["Person:AssignDefaultEscalation"] ? "Assign" : "View"} person escalation contact
                              </MenuItem>
                           }
                           {/* {(actionPermission["Person:SetResponseInstruction"] && actionPermission["ResponseInstruction:GET"]) &&
                                 <MenuItem disabled={isEscalationContact} disableRipple onClick={() => { onSetResponseInstruction(person) }}>
                                    <SpeakerNotes />
                                    Edit response instruction
                                 </MenuItem>
                              } */}
                           {(actionPermission["User:SendPasswordResetLink"]) && !Common.stringNullOrEmpty(person.userOid) &&
                              <MenuItem disableRipple onClick={() => { sendPasswordResetMail(person.userOid) }}>
                                 <ForwardToInbox />
                                 Send password reset link
                              </MenuItem>
                           }
                           {(actionPermission["Person:DELETE"]) &&
                              <div>
                                 <Divider />
                                 <MenuItem disableRipple onClick={() => { onDelete(person, true) }}>
                                    <Delete />
                                    Delete
                                 </MenuItem>
                              </div>
                           }
                        </ActionOptionMenu>
                     }
                  </>
               }
               title={
                  <>
                     {person.isEscalationAssigned === false && !isEscalationContact &&
                        <WarningSign text={"Default escalation pending"} />
                     }
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {person.firstname} {person.lastname}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Email className={classes.beforeTextIcon} /> {person.email}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(person.phoneNumber1)}
               </Typography>
               {!Common.stringNullOrEmpty(person.phoneNumber2) && <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(person.phoneNumber2)}
               </Typography>}
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Devices className={classes.beforeTextIcon} /> {deviceList(person.deviceIds)}
               </Typography>
               {!Common.stringNullOrEmpty(person.personClientRelation.clientName) && <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Apartment className={classes.beforeTextIcon} /> {person.personClientRelation.clientName}
               </Typography>}
            </CardContent>
         </Card>
      </Grid>
   )
}

export default withStyles(PersonCard, gridTileStyle);
