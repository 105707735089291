import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { mapApiDetails, mapInitialMapBound, useDeepCompareEffectForMaps } from "./MapCommon";
import { Badge, BadgeProps, Grid, Tooltip } from "@mui/material";
import Button from "./Button";
import { DeleteOutlineTwoTone } from "@mui/icons-material";
import { IHazardLocationGeometry } from "../../types/dataTypes";
import { GoogleMapSearchLocation, AutoComplete } from "./GoogleMapSearchLocation";
import { styled } from '@mui/material/styles';
const StyledBadge = styled(Badge)<BadgeProps>(() => ({
   '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      //   border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
   },
}));
interface IHazardLocationGeometryPicker {
   setValue: any;
   value: IHazardLocationGeometry[];
   selectionTypes: string[];
   defaultType: string;
   geometryType: string;
}
const HazardLocationGeometryPicker: React.FC<IHazardLocationGeometryPicker> = ({ value, setValue, selectionTypes, defaultType, geometryType }) => {
   const [hazardLocationGeometry, setHazardLocationGeometry] = React.useState<IHazardLocationGeometry[]>([]);
   React.useEffect(() => {
      setHazardLocationGeometry(value);
   }, [value, geometryType]);
   const TypeSelectionOptions: any[] = [];
   if (selectionTypes.indexOf("Point") > -1)
      TypeSelectionOptions.push({ label: "Point", value: "Point" });
   if (selectionTypes.indexOf("Polygon") > -1)
      TypeSelectionOptions.push({ label: "Polygon", value: "Polygon" });

   const removeArea = (index: number) => {
      hazardLocationGeometry.splice(index, 1);
      setValue([...hazardLocationGeometry]);
   }
   const onDrawingComplete = (latLngs: any) => {
      let newCoOrdinates = latLngs.map((latLong: any) => {
         let latlon = latLong!.toJSON();
         return [latlon.lng, latlon.lat]
      });
      const geometry = {
         Type: "Polygon",
         CoOrdinates: newCoOrdinates
      };
      if (!IsMultiPolygon) {
         const newHazardLocationGeometry = [geometry];
         setValue([...newHazardLocationGeometry]);
      }
      else {
         hazardLocationGeometry.push(geometry);
         setValue([...hazardLocationGeometry]);
      }
   }
   const IsMultiPolygon = geometryType === "MultiPolygon";
   return (
      <Grid container spacing={2}>
         <Grid item xs={12} md={12}>
            Hazard Areas
         </Grid>
         {IsMultiPolygon && hazardLocationGeometry.map((item: any, index: number) => {
            return (
               <React.Fragment key={index}>
                  <Grid style={{ display: "flex" }} item xs={4} md={2}>
                     <Tooltip title="Remove this area number from map">
                        <StyledBadge badgeContent={index + 1} color="error">
                           <Button color="white" style={{ color: "#f44336" }} justIcon={true} onClick={() => removeArea(index)}><DeleteOutlineTwoTone /></Button>
                        </StyledBadge>
                     </Tooltip>
                  </Grid>
               </React.Fragment>)
         })
         }
         <Grid item xs={12} md={12}>
            <GoogleMapSearchLocation inputId="locationSearch" />
         </Grid>
         <Grid item xs={12} md={12}>
            <div style={{ display: "flex", height: "500px" }}>
               <Wrapper {...mapApiDetails} >
                  <Map
                     hazardLocationGeometry={hazardLocationGeometry}
                     zoom={10}
                     style={{ flexGrow: "1", height: "100%" }}
                     onDrawingComplete={onDrawingComplete}
                     isMultiPolygon={IsMultiPolygon}
                  >
                  </Map>
               </Wrapper>
            </div>
         </Grid>
      </Grid >

   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: (map: google.maps.Map) => void;
   children?: React.ReactNode;
   onDrawingComplete: any;
   hazardLocationGeometry: IHazardLocationGeometry[];
   isMultiPolygon: boolean;
}

const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   hazardLocationGeometry,
   onDrawingComplete,
   isMultiPolygon,
   ...options
}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapPolygons, setMapPolygons] = React.useState<google.maps.Polyline[]>([]);
   const [mapMarkerLabels, setMapMarkerLabels] = React.useState<google.maps.Marker[]>([]);
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager | null>(null);
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
         setMapBound(mapInitialMapBound());
      }
      const bounds = new google.maps.LatLngBounds();
      const polygons: google.maps.Polyline[] = [];
      const markerLabels: google.maps.Marker[] = [];
      if (mapPolygons.length > 0) {
         mapPolygons.forEach((poly) => {
            poly.setMap(null);
         });
      }
      if (mapMarkerLabels.length > 0) {
         mapMarkerLabels.forEach((marker) => {
            marker.setMap(null);
         });
      }
      if (drawingManager) {
         drawingManager.setMap(null);
      }
      hazardLocationGeometry.forEach((geometry, index: number) => {
         if (geometry.Type === "Polygon" && geometry.CoOrdinates && geometry.CoOrdinates.length > 0) {
            let polygonPath: google.maps.LatLng[] = geometry.CoOrdinates.map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
            const polygon = new google.maps.Polygon({
               paths: polygonPath,
               geodesic: true,
               strokeColor: "#FF0000",
               strokeOpacity: 0.8,
               strokeWeight: 3,
               map: map,
            });
            polygons.push(polygon);
            let polygonBounds = new google.maps.LatLngBounds();
            for (var i = 0; i < polygonPath.length; i++) {
               polygonBounds.extend(polygonPath[i]);
            }
            if (isMultiPolygon) {
               let myLatlng = polygonBounds.getCenter();
               let mapLabel2 = new google.maps.Marker({
                  position: myLatlng,
                  map: map,
                  label: {
                     text: `${index + 1}`,
                     color: 'white',
                     fontSize: "12px"
                  },
               });
               markerLabels.push(mapLabel2);
            }
         }
         else {
            bounds.extend({ lat: -37.455799, lng: 175.189407 });
            setMapBound(bounds);
         }
      });
      if (polygons.length > 0) {
         for (let j = 0; j < polygons.length; j++) {
            for (let i = 0; i < polygons[j].getPath().getLength(); i++) {
               bounds.extend(polygons[j].getPath().getAt(i));
            }
         }
         setMapBound(bounds);
      }
      if (map) {
         let drawingManagerObj = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
               position: google.maps.ControlPosition.TOP_CENTER,
               drawingModes: [google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
               editable: true
            }

         });
         drawingManagerObj.setMap(map);
         google.maps.event.addListener(drawingManagerObj, 'overlaycomplete', function (event: any) {
            onDrawingComplete(event.overlay.getPath().getArray());
            event.overlay.setMap(null);
            drawingManagerObj.setDrawingMode(null);
            drawingManagerObj.setMap(null);
         });
         setDrawingManager(drawingManagerObj);
      }
      setMapPolygons(polygons);
      setMapMarkerLabels(markerLabels);
   }, [ref, map, hazardLocationGeometry]); // eslint-disable-line react-hooks/exhaustive-deps

   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
         AutoComplete(map, "locationSearch");
      }
   }, [map, options]);

   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, onClick, mapBound]);

   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
export default HazardLocationGeometryPicker;
