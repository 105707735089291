import type { Identifier, XYCoord } from "dnd-core"
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from "react-dnd"
import { primaryColor } from "../../../../assets/jss/appStyle"
import { ArrowCircleUp, Delete, ArrowCircleDown } from '@mui/icons-material';
import { CardHeader, TextField, Avatar, IconButton } from "@mui/material";
import Image from "./../../../Common/Image";
import ImageSelect from "../../../Common/ImageSelect";
import { Common } from "../../../../constants/common"
import { PlaceholderTextBlockCardDetailsDetails } from "./PlaceholerTextBlockCardDetails"
const style = {
   border: `2px solid ${primaryColor[0]}`,
   padding: '0.5rem 1rem',
   marginBottom: '.5rem',
   backgroundColor: 'white',
}

export interface ITextBlockCard {
   id: any;
   textBlock: any;
   index: number;
   moveCard: (dragIndex: number, hoverIndex: number) => void;
   removeTextBlock: any;
   restrictMoveDown: boolean;
   handleTextChange: any;
   clientOid: string;
   readOnly: boolean;
}

interface DragItem {
   index: number;
   id: string;
   type: string;
}

export const TextBlockCard: FC<ITextBlockCard> = ({ id, textBlock, index, moveCard, removeTextBlock, restrictMoveDown, handleTextChange, clientOid, readOnly }) => {
   const ref = useRef<HTMLDivElement>(null)
   const [{ handlerId }, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
   >({
      accept: "card",
      collect(monitor: any) {
         return {
            handlerId: monitor.getHandlerId(),
         }
      },
      hover(item: DragItem, monitor: any) {
         if (!ref.current) {
            return
         }
         const dragIndex = item.index
         const hoverIndex = index

         // Don't replace items with themselves
         if (dragIndex === hoverIndex) {
            return
         }

         // Determine rectangle on screen
         const hoverBoundingRect = ref.current?.getBoundingClientRect()

         // Get vertical middle
         const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

         // Determine mouse position
         const clientOffset = monitor.getClientOffset()

         // Get pixels to the top
         const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

         // Only perform the move when the mouse has crossed half of the items height
         // When dragging downwards, only move when the cursor is below 50%
         // When dragging upwards, only move when the cursor is above 50%

         // Dragging downwards
         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
         }

         // Dragging upwards
         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
         }

         // Time to actually perform the action
         moveCard(dragIndex, hoverIndex)

         // Note: we're mutating the monitor item here!
         // Generally it's better to avoid mutations,
         // but it's good here for the sake of performance
         // to avoid expensive index searches.
         item.index = hoverIndex
      },
   })

   const [{ isDragging }, drag] = useDrag({
      type: "card",
      item: () => {
         return { id, index }
      },
      collect: (monitor: any) => ({
         isDragging: monitor.isDragging(),
      }),
   })
   const opacity = isDragging ? 0 : 1
   drag(drop(ref))
   const isDraggable = readOnly ? false : !textBlock.IsFixed;
   const canDelete = readOnly ? false : !textBlock.IsRequired;
   return (
      <div ref={isDraggable ? ref : null} draggable={isDraggable} style={{ ...style, opacity, cursor: !isDraggable ? "no-drop" : "move" }} data-handler-id={isDraggable ? handlerId : null}>
         <CardHeader
            avatar={
               <Avatar sx={{ bgcolor: primaryColor[0] }} aria-label="recipe">
                  {index + 1}
               </Avatar>
            }
            action={
               <div style={{ display: "flex" }}>
                  {canDelete &&
                     <IconButton color="primary" aria-label="Remove Block" onClick={removeTextBlock}>
                        <Delete />
                     </IconButton>
                  }
                  <div style={{ display: "grid" }}>
                     {isDraggable &&
                        <>
                           {index !== 0 &&
                              <IconButton color="primary" aria-label="Move Up" onClick={() => { moveCard(index, index - 1) }}>
                                 <ArrowCircleUp />
                              </IconButton>
                           }
                           {!restrictMoveDown &&
                              <IconButton color="primary" aria-label="Move Down" onClick={() => { moveCard(index, index + 1) }}>
                                 <ArrowCircleDown />
                              </IconButton>
                           }
                        </>
                     }
                  </div>
               </div>
            }
            title={<div style={{ whiteSpace: "pre-line" }}>
               {textBlock.Type === "Text" && textBlock.Text}
               {textBlock.Type === "FreeText" &&
                  <TextField
                     style={{ height: "100%", width: "100%" }}
                     id="outlined-name"
                     value={textBlock.Text}
                     onChange={(event) => {
                        const text = event.target.value;
                        handleTextChange(index, text);
                     }}
                     variant="outlined"
                     multiline={true}
                     placeholder="Enter instruction here ..."
                  />
               }
               {textBlock.Type === "Placeholder" &&
                  <PlaceholderTextBlockCardDetailsDetails
                     textBlock={textBlock}
                     handleTextChange={handleTextChange}
                     blockIndex={index}
                  />
               }
               {textBlock.Type === "Image" &&
                  <>
                     <ImageSelect
                        style={{ height: "75%", width: "70%" }}
                        id="imageselect-name"
                        value={textBlock.Text}
                        onChange={(event: any) => {
                           const text = event.target.value;
                           handleTextChange(index, text);
                        }}
                        variant="outlined"
                        placeholder="Enter image path here ..."
                        clientOid={clientOid}
                     />

                     <Image
                        src={textBlock.Text.indexOf("http") === 0 ? textBlock.Text : `${Common.ClientImagePath}/${textBlock.Text}`}
                        zoom={true}
                        style={{ height: 50, marginLeft: 25 }}
                     />
                  </>
               }
            </div>}
         // subheader={}
         />
      </div>
   )
}
