import { Controllers } from "../constants/constant";
import request from "../utils/request";

class AlertService {
   searchAlerts = (params: any) => {
      return request({
         url: `${Controllers.Alert}/Search`,
         method: 'get',
         params
      });
   }
}

export default new AlertService();
