import React from "react";
import { ISftpImportLogsViewModelProps } from "../../../types/propType";
import Button from "../../Common/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Common } from "../../../constants/common";

const SftpImportLogsViewModel: React.FC<ISftpImportLogsViewModelProps> = ({ open, close, record }) => {
   let personDetails: any = JSON.parse(record.details);
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle id="scroll-dialog-title">View Error Log Detail</DialogTitle>
         <DialogContent dividers>
            <Grid container spacing={2}>
               <Grid item xs={12} md={12}>
                  <Table>
                     <TableBody>
                        <TableRow>
                           <TableCell>
                              Timestamp
                           </TableCell>
                           <TableCell>
                              {Common.getFormattedDateTime(record.modifiedOn)}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Name
                           </TableCell>
                           <TableCell>
                              {personDetails?.Firstname + " " + personDetails?.Lastname}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Email
                           </TableCell>
                           <TableCell>
                              {personDetails?.Email}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Phone Number
                           </TableCell>
                           <TableCell>
                              {personDetails?.PhoneNumber1}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Employee Id
                           </TableCell>
                           <TableCell>
                              {personDetails?.EmployeeID}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              All Details
                           </TableCell>
                           <TableCell>
                              {record.details}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Type
                           </TableCell>
                           <TableCell>
                              {record.type}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Error
                           </TableCell>
                           <TableCell style={{ backgroundColor: record.type === "Error" ? "#faa8a8" : "#FFD700" }}>
                              {record.error}
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
         </DialogActions>
      </Dialog>
   )
}

export default SftpImportLogsViewModel;
