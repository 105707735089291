import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormHelperText } from "@mui/material";

import { IChangePasswordProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import { IChangePassword } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { StatusCode } from '../../../constants/constant';
import UserService from '../../../services/user.service';

const ChangePassword: React.FC<IChangePasswordProps> = ({ open, close, successCallback }) => {
   const [error, setError] = useState(null);
   let initialValues: IChangePassword = {
      OldPassword: "",
      NewPassword: "",
      ConfirmPassword: ""
   }

   const validationSchema = Yup.object().shape({
      OldPassword: Yup.string().trim(Resources.Error_OldPassword_Required).required(Resources.Error_OldPassword_Required),
      NewPassword: Yup.string().trim(Resources.Error_NewPassword_Required).required(Resources.Error_NewPassword_Required).min(6, Resources.Error_PasswordLengthValidation_Message),
      ConfirmPassword: Yup.string().trim(Resources.Error_ConfirmPassword_Required).required(Resources.Error_ConfirmPassword_Required).oneOf([Yup.ref('NewPassword'), null], Resources.Error_NewPassword_ConfirmPassword_NotMatching),     
   });
   const onSubmit = (values: any) => {
      UserService.changePassword(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            setError(res?.data?.message);
         }
      });
   }

   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">Change Password</DialogTitle>
                  <DialogContent dividers>
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <CustomInput
                              labelText="Old Password"
                              id="OldPassword"
                              type="password"
                              inputProps={{
                                 required: true,
                                 name: "OldPassword",
                                 type:"Password"
                              }}
                              value={values.OldPassword}
                           />
                           <CustomInput
                              labelText="New Password"
                              id="NewPassword"
                              type="password"
                              inputProps={{
                                 required: true,
                                 name: "NewPassword",
                                 type:"Password"
                              }}
                              value={values.NewPassword}
                           />

                           <CustomInput
                              labelText="Confirm Password"
                              id="ConfirmPassword"
                              type="password"
                              inputProps={{
                                 required: true,
                                 name: "ConfirmPassword",
                                 type:"Password"
                              }}
                              value={values.ConfirmPassword}
                           />
                        </Grid>
                        <FormHelperText id="error-login" error>{error}</FormHelperText>
                     </Grid>                     
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     <Button color="primary" type="submit">Change</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default ChangePassword;
