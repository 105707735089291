import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { MenuItem, TextField } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { ISelectOption } from "../../types/dataTypes";
import { useEffect, useState } from "react";
import ClientImageService from "../../services/clientimage.service";
import { StatusCode } from "../../constants/constant";
function ImageSelect({ ...props }) {
   const {
      labelText,
      id,
      inputProps,
      InputProps,
      clientOid,
      onChange,
      style,
      value
   } = props;
   const [options, setOptions] = useState<ISelectOption[]>([]);
   useEffect(() => {
      ClientImageService.getClientImages(clientOid).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            setOptions(res?.data);
         }
         else if (res?.status === StatusCode.BadRequest) {
            setOptions([]);
         }
      });
   }, [clientOid]);
   return (

      <TextField
         style={style}
         variant="standard"
         id={id}
         {...inputProps}
         InputProps={InputProps}
         select
         label={labelText}
         onChange={onChange}
         value={value}
      >
         {options.map((option: ISelectOption) => (
            <MenuItem key={option.value} value={option.value} >
               {option.label}
            </MenuItem>
         ))}
      </TextField>

   );
}

ImageSelect.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   labelProps: PropTypes.object,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object
};

export default withStyles(ImageSelect, customInputStyle);
