import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormControl, FormHelperText, MenuItem, TextField } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { ISelectOption } from "../../types/dataTypes";

function CustomSelect({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      options,
      InputProps,
      disabled
   } = props;

   const [field, meta] = useField(inputProps);
   return (
      <FormControl
         {...formControlProps}
         fullWidth
         className={formControlProps?.className + " " + classes.formControl}
         disabled={disabled}
      >
         <TextField
            variant="standard"
            id={id}
            {...field}
            {...inputProps}
            InputProps={InputProps}
            select
            label={labelText}
            error={meta.touched && meta.error}
            disabled={disabled}
         >
            {options.map((option: ISelectOption) => (
               <MenuItem key={option.value} value={option.value}>
                  {option.label}
               </MenuItem>
            ))}
         </TextField>
         {meta.touched && meta.error &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
      </FormControl>
   );
}

CustomSelect.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   labelProps: PropTypes.object,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object
};

export default withStyles(CustomSelect, customInputStyle);
