import React from "react";
import { withStyles } from 'tss-react/mui';
import { Delete, Edit, DeviceUnknown, DevicesOther, History, Assignment, DeviceHub, AssignmentInd, SpeakerNotes, Visibility, ContentCopy, InsertLink, Apartment, Person, Http, MobileFriendly, Event } from "@mui/icons-material";
import { MenuItem, Divider, Card, CardHeader, CardContent, Grid, Tooltip, Typography, Link, ListItemIcon, ListItemText } from '@mui/material';
import { warningColor } from "../../../assets/jss/appStyle";
import Button from "../../Common/Button";
import { IDeviceDataProps } from "../../../types/propType";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { Common } from "../../../constants/common";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { allowDeviceAddEdit } from "../../../utils/PermissionHelpers";
import WarningSign from "../../Common/WarningSign";

const DeviceCard: React.FC<IDeviceDataProps> = ({ classes, device, onEdit, onDelete, onShowAuditLog, onAssignEscalation, onAssignDeviceToPerson, showOnlyAssignment, showOnlyEscalation, actionPermission, copyEscalationText, copyEscalationLink, onSetResponseInstruction, onSelectPerson, showOnlyEdit, viewResponseInstruction, snackbarShowMessage, onTestDeviceClick }) => {
   const showAllActions: boolean = !showOnlyAssignment && !showOnlyEscalation && !showOnlyEdit;
   const { tooltip, disableDeviceAssingment } = Common.getDeviceWarningText(device);
   const allowEdit = allowDeviceAddEdit(actionPermission);
   return (
      <Grid item xs={12} sm={6} md={4}>
         <Card style={tooltip.length > 0 ? { border: `2px solid ${warningColor[0]}` } : {}}>
            <CardHeader
               action={
                  <>
                     {showOnlyAssignment && (actionPermission["Person:AssignDeviceToPerson"] && actionPermission["Person:GetClientSpecificPersonSelectOptions"]) &&
                        <Button justIcon={true} onClick={() => onAssignDeviceToPerson(device)}>
                           <Tooltip title="Assign device to person">
                              <AssignmentInd />
                           </Tooltip>
                        </Button>
                     }
                     {(showOnlyEdit) && (actionPermission["Device:POST"] || actionPermission["Device:GET"]) &&
                        <Button color="info" justIcon={true} onClick={() => { onEdit(device) }}>
                           <Tooltip title={allowEdit ? "Edit" : "View"}>
                              {allowEdit ? <> <Edit /> Edit </> : <> <Visibility /> View </>}
                           </Tooltip>
                        </Button>
                     }
                     {showOnlyEscalation && (actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && (actionPermission["Device:AssignDefaultEscalation"]) &&
                        <Button color="rose" justIcon={true} onClick={() => onAssignEscalation(device)}>
                           <Tooltip title="Assign device escalation contact">
                              <Assignment />
                           </Tooltip>
                        </Button>
                     }
                     {showAllActions &&
                        <ActionOptionMenu>
                           <MenuItem disableRipple onClick={() => { onEdit(device) }}>
                              {allowEdit ? <> <Edit /> Edit </> : <> <Visibility /> View </>}
                           </MenuItem>
                           <Divider />
                           {(actionPermission["Audit:Device"]) &&
                              <MenuItem disableRipple onClick={() => { onShowAuditLog(device) }}>
                                 <History color="primary" />
                                 Audit history
                              </MenuItem>
                           }
                           {(actionPermission["Audit:DeviceEvent"]) &&
                              <MenuItem disableRipple onClick={() => { onShowAuditLog(device, "DeviceEvent") }}>
                                 <Event color="primary" />
                                 Event Logs
                              </MenuItem>
                           }
                           {(actionPermission["Person:AssignDeviceToPerson"] && actionPermission["Person:GetClientSpecificPersonSelectOptions"]) &&
                              <>
                                 {!disableDeviceAssingment ?
                                    <MenuItem disableRipple onClick={() => { onAssignDeviceToPerson(device) }}>
                                       <AssignmentInd />
                                       Assign device to person
                                    </MenuItem>
                                    :
                                    <MenuItem disableRipple onClick={() => { snackbarShowMessage(tooltip, "warning") }}>
                                       <ListItemIcon style={{ opacity: 0.5 }}>
                                          <AssignmentInd />
                                       </ListItemIcon>
                                       <ListItemText style={{ opacity: 0.5 }}>Assign device to person</ListItemText>
                                       {tooltip.length > 0 &&
                                          <Typography variant="body2" color="text.secondary">
                                             <WarningSign text={tooltip} />
                                          </Typography>
                                       }
                                    </MenuItem>
                                 }
                              </>
                           }
                           {(actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) &&
                              <>
                                 {device.clientOid ?
                                    <MenuItem disableRipple onClick={() => { onAssignEscalation(device) }}>
                                       <Assignment />
                                       {actionPermission["Device:AssignDefaultEscalation"] ? "Assign" : "View"} device escalation contact
                                    </MenuItem>
                                    :
                                    <MenuItem disableRipple onClick={() => { snackbarShowMessage("Client is not configured for device.", "warning") }}>
                                       <ListItemIcon style={{ opacity: 0.5 }}>
                                          <Assignment />
                                       </ListItemIcon>
                                       <ListItemText style={{ opacity: 0.5 }}>Assign device escalation contact</ListItemText>
                                       <Typography variant="body2" color="text.secondary">
                                          <WarningSign text={"Client is not configured for device."} />
                                       </Typography>
                                    </MenuItem>
                                 }
                              </>
                           }
                           {(actionPermission["Device:ResponseInstruction"]) &&
                              <MenuItem disableRipple onClick={() => { copyEscalationText(device.oid) }}>
                                 <ContentCopy />
                                 Copy escalation Text
                              </MenuItem>
                           }
                           {(actionPermission["Device:EscalationDetails"]) &&
                              <MenuItem disableRipple onClick={() => { copyEscalationLink(device.oid) }}>
                                 <InsertLink />
                                 Copy escalation link
                              </MenuItem>
                           }
                           {(actionPermission["Device:EscalationDetails"]) &&
                              <MenuItem disableRipple onClick={() => { viewResponseInstruction(device.oid) }}>
                                 <Http />
                                 View Response Instructions
                              </MenuItem>
                           }
                           {(actionPermission["ResponseInstruction:GET"]) &&
                              <MenuItem disableRipple onClick={() => { onSetResponseInstruction(device) }}>
                                 <SpeakerNotes />
                                 {actionPermission["Device:SetResponseInstruction"] ? "Edit" : "View"} response instruction
                              </MenuItem>
                           }
                           {actionPermission["DeviceEvent:POST"] &&
                              <MenuItem disableRipple onClick={() => { onTestDeviceClick(device) }}>
                                 <MobileFriendly />
                                 Add device test result
                              </MenuItem>
                           }
                           {(actionPermission["Device:DELETE"]) &&
                              <div>
                                 <Divider />
                                 <MenuItem disableRipple onClick={() => { onDelete(device, true) }}>
                                    <Delete />
                                    Delete
                                 </MenuItem>
                              </div>
                           }
                        </ActionOptionMenu>
                     }
                  </>
               }
               title={
                  <>
                     {tooltip.length > 0 &&
                        <WarningSign text={tooltip} />}
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {device.deviceId}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <DeviceHub className={classes.beforeTextIcon} /> {device.deviceType}
               </Typography>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <DeviceUnknown className={classes.beforeTextIcon} /> {device.deviceModel}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <DevicesOther className={classes.beforeTextIcon} /> {device.platform}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Apartment className={classes.beforeTextIcon} /> {device.clientName}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Person className={classes.beforeTextIcon} /> <Link onClick={() => onSelectPerson(device.personOid)}>{device.personName}</Link>
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
};

export default withStyles(DeviceCard, gridTileStyle);
