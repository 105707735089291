import { styled, TableCell } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';

const StyledGridCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.action.hover,
      // color: theme.palette.common.black,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
   },
}));

export default StyledGridCell;
