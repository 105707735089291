import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { AlertColor } from '@mui/material/Alert';

export const withSnackbar = (WrappedComponent: any) => {
   return (props: any) => {
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState("I'm a custom snackbar");
      const [duration, setDuration] = useState(3000);
      const [severity, setSeverity] = useState<AlertColor>(
         "success"
      ); /** error | warning | info */

      const showMessage = (message: string, severity: AlertColor = "success", duration = 3000) => {
         setMessage(message);
         setSeverity(severity);
         setDuration(duration);
         setOpen(true);
      };

      const handleClose = (event: any, reason: string) => {
         if (reason === "clickaway") {
            return;
         }
         setOpen(false);
      };

      return (
         <>
            <WrappedComponent {...props} snackbarShowMessage={showMessage} />
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} autoHideDuration={duration} onClose={handleClose}>
               <Alert style={{ whiteSpace: "pre" }} onClose={() => { setOpen(false); }} severity={severity} sx={{ width: '100%' }}>
                  {message}
               </Alert>
            </Snackbar>
         </>
      );
   };
};
