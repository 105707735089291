import React from "react";
import Button from "./Button";
import Menu from "@mui/material/Menu";
import PopupState, { bindMenu, bindHover } from "material-ui-popup-state";
interface IHeaderMenuProps {
   label: string;
   children?: any;
   navigate: any;
}
const HeaderMenu: React.FC<IHeaderMenuProps> = ({ label, children, navigate }) => {
   const handleMenuClick = (event: any) => {
      navigate(event.target.id);
   }
   if (Array.isArray(children)) {
      if (children.filter(x => !x).length === children.length) {
         return null;
      }
   }
   return (
      <React.Fragment>
         {children && children.length > 0 &&
            <>
               <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                     <>
                        <Button
                           id={`menuid-${label}`}
                           aria-haspopup="true"
                           {...bindHover(popupState)}
                           style={{ backgroundColor: "black", boxShadow: "none" }}
                        >
                           {label}
                        </Button>
                        <Menu
                           id={`menu-${label}`}
                           {...bindMenu(popupState)}
                           MenuListProps={{ onMouseLeave: popupState.close, onClick: (event: any) => { popupState.close(); handleMenuClick(event) } }}
                           anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                           }}
                           transformOrigin={{
                              vertical: "top",
                              horizontal: "center"
                           }}
                        >
                           {children}
                        </Menu>
                     </>
                  )}
               </PopupState>
            </>
         }
      </React.Fragment>
   )
};
export default HeaderMenu;
