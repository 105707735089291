import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormControlLabel, Checkbox, FormControl, FormHelperText } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";

function CustomCheckbox({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      checked
   } = props;

   const [field, meta] = useField(inputProps);
   return (
      <FormControl {...formControlProps} fullWidth className={formControlProps?.className + " " + classes.formControl}>
         <FormControlLabel
            label={labelText}
            control={
               <Checkbox
                  id={id}
                  {...field}
                  {...inputProps}
                  checked={checked}
               />
            }
         />
         {meta.touched && meta.error && meta.error.length > 0 &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
      </FormControl>
   );
}

CustomCheckbox.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   checked: PropTypes.bool
};
export default withStyles(CustomCheckbox, customInputStyle);
