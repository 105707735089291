import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import PersonCard from "../../components/pages/Persons/PersonCard";
import PersonRow from "../../components/pages/Persons/PersonRow";
import PersonAdvanceFilter from "../../components/pages/Persons/PersonAdvanceFilter";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import AddEditPerson from "../../components/pages/Persons/AddEditPerson";
import AssignDevice from "../../components/pages/Persons/AssignDevice";
import ImportPersonModal from "../../components/pages/Persons/ImportPersonModal";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
import PersonDependenciesModal from "../../components/pages/Persons/PersonDependenciesModal";
import AddEditDevice from "../../components/pages/Devices/AddEditDevice";

import { IPersonPageState } from "../../types/stateType";
import { IPersonPageProps } from "../../types/propType";
import { IDeleteParams, IPersonFilter, IPasswordResetMail, IPerson } from "../../types/dataTypes";
import { Controllers, StatusCode } from "../../constants/constant";
import { TableHeader } from "../../constants/tableHeader";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";

import PersonService from "../../services/person.service";
import AssignEscalation from "../../components/pages/Common/AssignEscalation";
// import SetPersonResponseInstruction from "../../components/pages/Common/ResponseInstruction/SetPersonResponseInstruction";
import { mapPersonObject } from "../../utils/commonMaps";
import UserService from "../../services/user.service";
import { allowDeviceAddEdit, allowDeviceView, allowPersonAddEdit } from "../../utils/PermissionHelpers";
import { withSnackbar } from "../../components/Common/Snackbar";
class Persons extends React.Component<IPersonPageProps, IPersonPageState> {
   filterOptions: IPersonFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      sortString: "PersonClientRelation.Client.Name asc,Firstname+Lastname asc",
      ClientOid: Common.EmptyGuid,
      RelationshipToClient: "All",
   }

   state: IPersonPageState = {
      persons: [],
      openAddEditPersonModal: false,
      selectedPerson: null,
      showConfirmDialog: false,
      openAssignDeviceModal: false,
      totalRecords: 0,
      openAuditLogs: false,
      openImportModal: false,
      importModelName: undefined,
      openAssignEscalationModal: false,
      openSetResponseInstructionModal: false,
      openPersonDependenciesModal: false,
      deleteDependencies: null,
      openAddEditDeviceModal: false,
      deviceOid: null
   };

   componentDidMount() {
      const searchParams = new URLSearchParams(document.location.search)
      const clientOid = searchParams.get('ClientOid');
      if (clientOid) {
         this.filterOptions.ClientOid = clientOid;
      }
      if (!this.props.isFromSearch) {
         this.searchPersons();
      }
   }
   //#region search and pagination

   searchPersons = () => {
      let newFilterOptions: IPersonFilter = { ...this.filterOptions };
      this.setState({ ...this.state, persons: [] }, () => {
         PersonService.searchPersons(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  persons: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }
   onAdvanceSearch = (advanceFilter: any) => {
      this.filterOptions.RelationshipToClient = advanceFilter.RelationshipToClient;
      this.filterOptions.ClientOid = advanceFilter.ClientOid;
      this.filterOptions.page = 1;
      this.searchPersons();
   }
   fetchMoreData = () => {
      if (this.state.persons.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IPersonFilter = { ...this.filterOptions };
      PersonService.searchPersons(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let personList = [...this.state.persons];
            personList = personList.concat(res?.data);
            this.setState({
               ...this.state,
               persons: personList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchPersons();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchPersons();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      // if (val.length > Common.SearchAfterCount) {
      this.searchPersons();
      // }
   }

   //#endregion

   //#region Add/Edit/Delete Persons
   onToggleAddEditPerson = () => {
      this.setState({ ...this.state, openAddEditPersonModal: !this.state.openAddEditPersonModal, selectedPerson: null });
   }

   onSavePerson = () => {
      this.setState({ ...this.state, openAddEditPersonModal: false, selectedPerson: null }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchPersons();
      });
   }

   onSelectPerson = (selectedPerson: any, isForDelete: boolean = false) => {
      const person: IPerson = mapPersonObject(selectedPerson);

      if (isForDelete)
         this.setState({ ...this.state, selectedPerson: person }, () => this.onChangeDependencies());
      else
         this.setState({ ...this.state, selectedPerson: person, openAddEditPersonModal: true });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedPerson?.Oid };
      PersonService.deletePerson(params).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            this.setState({ ...this.state, selectedPerson: null, showConfirmDialog: false, openPersonDependenciesModal: false }, () => this.searchPersons());

         } else if (res?.status === StatusCode.BadRequest) {
            this.setState({ ...this.state, showConfirmDialog: false, openPersonDependenciesModal: true, deleteDependencies: res?.data });
         }
      });
   }
   onChangeDependencies = () => {
      const params: IDeleteParams = { oid: this.state.selectedPerson?.Oid };
      PersonService.getPersonDependencies(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, showConfirmDialog: true, openPersonDependenciesModal: false });
         } else if (res?.status === StatusCode.BadRequest) {
            this.setState({ ...this.state, showConfirmDialog: false, openPersonDependenciesModal: true, deleteDependencies: res?.data });
         }
      });
   }
   onCancelDelete = () => {
      this.setState({ ...this.state, selectedPerson: null, showConfirmDialog: false });
   }

   closePersonDependenciesModal = () => {
      this.setState({ ...this.state, openPersonDependenciesModal: false });
   }
   //#endregion

   //#region Assign device

   openAssignDeviceModal = (selectedPerson: any) => {
      this.setState({ ...this.state, selectedPerson: mapPersonObject(selectedPerson), openAssignDeviceModal: true });
   }

   onCloseAssignDeviceModal = () => {
      this.setState({
         ...this.state,
         openAssignDeviceModal: !this.state.openAssignDeviceModal,
         selectedPerson: null
      });
   }

   onSaveDeviceToPerson = () => {
      this.setState({ ...this.state, openAssignDeviceModal: false, selectedPerson: null }, () => {
         this.searchPersons();
      });
   }

   //#endregion

   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedPerson: null });
   }

   openPersonAuditLogModal = (selectedPerson: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedPerson: mapPersonObject(selectedPerson) });
   }
   //#endregion
   //#region Import
   toggleImportModal = () => {
      this.setState({ ...this.state, openImportModal: !this.state.openImportModal });
   }
   onImportPersons = () => {
      this.setState({ ...this.state, openImportModal: false }, () => {
         this.searchPersons();
      });
   }
   //#endregion
   //#region Download
   onDownload = (exportType: string) => {
      let newFilterOptions: IPersonFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      PersonService.export(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "Persons";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   //#endregion

   //#region Assign person escalation
   openAssignEscalation = (selectedPerson: any) => {
      const person: IPerson = mapPersonObject(selectedPerson);
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedPerson: person
      });
   }

   onSaveEscalation = () => {
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedPerson: null
      }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchPersons();
      });
   }

   onCloseAssignEscalationModal = () => {
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedPerson: null
      });
   }

   //#endregion

   //#region set response instruction
   openResponseInstructionModel = (selectedPerson: any) => {
      const person: IPerson = mapPersonObject(selectedPerson);
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedPerson: person
      });
   }

   onSaveResponseInstruction = () => {
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedPerson: null
      }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchPersons();
      });
   }

   onCloseResponseInstructionModal = () => {
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedPerson: null
      });
   }

   //#endregion
   //#region person dialogue
   onSaveDevice = () => {
      this.setState({ ...this.state, openAddEditDeviceModal: false, deviceOid: null }, () => this.searchPersons());
   }
   onToggleAddEditDevice = () => {
      this.setState({ ...this.state, openAddEditDeviceModal: !this.state.openAddEditDeviceModal, deviceOid: null });
   }
   onSelectDevice = (deviceOid: string) => {
      this.setState({ ...this.state, openAddEditDeviceModal: true, deviceOid: deviceOid });
   }
   //#endregion
   //#Send Reset Mail
   sendPasswordResetMail = (userOid: any) => {
      const params: IPasswordResetMail = {
         UserOid: userOid
      }
      UserService.sendPasswordResetLink(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.props.snackbarShowMessage(res?.data?.message);
         } else if (res?.status === StatusCode.BadRequest) {
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }

      });
   }
   render() {

      const { totalRecords, openAddEditPersonModal, selectedPerson, showConfirmDialog,
         openAssignDeviceModal, openAuditLogs, openImportModal, openAssignEscalationModal, openPersonDependenciesModal, deleteDependencies, openAddEditDeviceModal, deviceOid } = this.state;
      const { isFromSearch, isFromDependenciesDialogue, showOnlyEscalation, showOnlyEdit, actionPermission } = this.props;
      const { persons } = isFromSearch ? this.props : this.state;
      const { searchText } = this.props.params;
      if (searchText && searchText.trim().length > 0) {
         this.filterOptions.SearchText = searchText;
         this.props.params.searchText = null;
         window.history.pushState(null, "", window.location.href.replace("%20", " ").replace(searchText, ""));
      }
      const allowEditViewDevice = allowDeviceAddEdit(null) || allowDeviceView(null);
      return (
         <PageLayout pageTitle="Persons" onCreate={this.onToggleAddEditPerson} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} auditLogs={this.toggleAuditLogModal} onImport={this.toggleImportModal} onDownload={this.onDownload}
            isFromSerach={isFromSearch} isFromDependenciesDialogue={isFromDependenciesDialogue}
            actionPermission={{
               add: allowPersonAddEdit(actionPermission),
               import: actionPermission["Person:Import"] && actionPermission["Person:ExportTemplate"],
               download: actionPermission["Person:Export"],
               auditlog: actionPermission["Audit:Person"],
            }}
            onSearchTextChange={actionPermission["Person:SearchSuggestions"] ? PersonService.getSearchSuggestions : null}
            advanceFilter={actionPermission["Person:GetPersonRelationOptions"] ? <PersonAdvanceFilter initialValues={{ ClientOid: this.filterOptions.ClientOid, RelationshipToClient: this.filterOptions.RelationshipToClient }} onAdvanceSearch={this.onAdvanceSearch} /> : null}
            showClientFilter={this.filterOptions.ClientOid !== Common.EmptyGuid ? true : false}
         >

            {openAssignEscalationModal &&
               <AssignEscalation
                  open={openAssignEscalationModal}
                  close={this.onCloseAssignEscalationModal}
                  successCallback={this.onSaveEscalation}
                  escalationOid={selectedPerson?.EscalationOid}
                  oid={selectedPerson?.Oid}
                  escalationOn={Controllers.Person}
                  selectedEntityName={`${selectedPerson?.Firstname} ${selectedPerson?.Lastname ? selectedPerson?.Lastname : ""}`}
                  clientOid={selectedPerson?.PersonClientRelation?.ClientOid}
                  allowSubmit={actionPermission["Person:AssignDefaultEscalation"] === true}
                  allowApplyAll={actionPermission["Person:AssignDefaultEscalationApplyAll"] === true && actionPermission["Person:GetPersonCountByClientOid"]}
                  clientName={selectedPerson?.PersonClientRelation?.ClientName} />
            }
            {/* {openSetResponseInstructionModal &&
               <SetPersonResponseInstruction
                  open={openSetResponseInstructionModal}
                  close={this.onCloseResponseInstructionModal}
                  successCallback={this.onSaveResponseInstruction}
                  responseInstructionOid={selectedPerson?.ResponseInstructionOid}
                  oid={selectedPerson?.Oid}
                  responseInstructionFor={Controllers.Person}
                  selectedEntityName={`${selectedPerson?.Firstname} ${selectedPerson?.Lastname}`}
               />
            } */}
            {openAddEditPersonModal &&
               <AddEditPerson
                  open={openAddEditPersonModal}
                  close={this.onToggleAddEditPerson}
                  successCallback={this.onSavePerson}
                  personOid={selectedPerson?.Oid}
                  allowEdit={allowPersonAddEdit(actionPermission)}
               />
            }

            {openAssignDeviceModal &&
               <AssignDevice
                  open={openAssignDeviceModal}
                  close={this.onCloseAssignDeviceModal}
                  successCallback={this.onSaveDeviceToPerson}
                  clientOid={selectedPerson?.PersonClientRelation?.ClientOid}
                  personOid={selectedPerson?.Oid ?? Common.EmptyGuid}
                  deviceOids={selectedPerson?.DeviceOids}
                  personName={`${selectedPerson?.Firstname} ${selectedPerson?.Lastname ? selectedPerson?.Lastname : ""}`}
               />
            }

            {
               openAuditLogs && selectedPerson === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Person} />
            }

            {
               openAuditLogs && selectedPerson !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Person}
                  oid={selectedPerson?.Oid ?? ""}
                  changedEntityTitle={`${selectedPerson?.Firstname} ${selectedPerson?.Lastname}`} />
            }

            {/* web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getPersonTableHeaders()} pagingInfoText="Persons" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords ? totalRecords : persons?.length} onPageChange={isFromSearch ? null : this.onPageChange} filerOptions={this.filterOptions}>
                  {persons && persons?.map((person: any, index: number) => {
                     return (
                        <PersonRow
                           key={`row_${index}`}
                           person={person}
                           onEdit={this.onSelectPerson}
                           onDelete={this.onSelectPerson}
                           onAssignDeviceToPerson={this.openAssignDeviceModal}
                           onShowAuditLog={this.openPersonAuditLogModal}
                           onAssignEscalation={this.openAssignEscalation}
                           showOnlyEscalation={showOnlyEscalation}
                           showOnlyEdit={showOnlyEdit}
                           actionPermission={actionPermission}
                           onSetResponseInstruction={this.openResponseInstructionModel}
                           onSelectDevice={allowEditViewDevice ? this.onSelectDevice : null}
                           sendPasswordResetMail={this.sendPasswordResetMail}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={persons ? persons.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {persons && persons.map((person: any, index: number) => {
                        return <PersonCard
                           key={`row_${index}`}
                           person={person}
                           onEdit={this.onSelectPerson}
                           onDelete={this.onSelectPerson}
                           onAssignDeviceToPerson={this.openAssignDeviceModal}
                           onShowAuditLog={this.openPersonAuditLogModal}
                           onAssignEscalation={this.openAssignEscalation}
                           showOnlyEscalation={showOnlyEscalation}
                           showOnlyEdit={showOnlyEdit}
                           actionPermission={actionPermission}
                           onSetResponseInstruction={this.openResponseInstructionModel}
                           onSelectDevice={allowEditViewDevice ? this.onSelectDevice : null}
                           sendPasswordResetMail={this.sendPasswordResetMail}
                        />
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete} />
            }
            {
               openImportModal &&
               <ImportPersonModal
                  open={openImportModal}
                  close={this.toggleImportModal}
                  successCallback={this.onImportPersons} />
            }
            {
               openPersonDependenciesModal &&
               <PersonDependenciesModal
                  open={openPersonDependenciesModal}
                  close={this.closePersonDependenciesModal}
                  onChangeDependencies={this.onChangeDependencies}
                  person={selectedPerson ? selectedPerson : null}
                  dependencies={deleteDependencies}
                  onConfirmDelete={this.onConfirmDelete}
               />
            }
            {
               openAddEditDeviceModal &&
               <AddEditDevice
                  open={openAddEditDeviceModal}
                  close={this.onToggleAddEditDevice}
                  deviceOid={deviceOid}
                  allowEdit={allowDeviceAddEdit(null)} />
            }
         </PageLayout>
      )
   }
}

export default withSnackbar(withParams(Persons));
