import { Theme } from "@mui/material";
const pagesStyle = (theme: Theme) => ({
   pageHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2)
   },
   pageTitle: {
      fontWeight: "bold",
      margin: 0,
      paddingLeft: theme.spacing(2),
   },
   pageBody: {
      padding: theme.spacing(2)
   },
   cardHeader: {
      fontWeight: "bold"
   },
   cardText: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      color: "	#28282B",
      fontSize: "1.25em"
   },
   detailText: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      color: "	#28282B",
      fontSize: "1em"
   },
   beforeTextIcon: {
      marginRight: theme.spacing(2)
   }
});

export default pagesStyle;
