import React, { useEffect, useState } from 'react';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ISelectOption } from '../../../types/dataTypes';
import { AddBox, Delete, DeleteOutlineTwoTone } from "@mui/icons-material";
import Button from '../../Common/Button';
import { FormHelperText, Grid, Tooltip } from '@mui/material';
import PropTypes from "prop-types";
import { Common } from '../../../constants/common';
import { useField } from "formik";
import ConfirmDialogBox from '../../Common/ConfirmDialogBox';
function TripPersons({ ...props }) {
   const { value, onSearchTextChange, setValue, inputProps, customErrorMessage, disabled } = props;
   const [tripPersonsList, setTripPersonsList] = useState<string[]>([]);
   const [showConfirmDialog, setShowConfirmDialog] = useState(false);
   useEffect(() => {
      setTripPersonsList(value && value.length > 0 ? value : [""])
   }, [value])
   const addNewTripPersons = () => {
      setTripPersonsList([...tripPersonsList, ""]);
      setValue([...tripPersonsList, ""]);
   }
   const removeTripPersons = (index: number) => {
      tripPersonsList.splice(index, 1);
      setTripPersonsList([...tripPersonsList]);
      setValue([...tripPersonsList]);
   }
   const setTripPersons = (index: number, value: string) => {
      tripPersonsList[index] = value;
      setValue([...tripPersonsList]);
   }
   const removeAllTripPersons = () => {
      setShowConfirmDialog(false);
      setValue([""]);
   }
   const [field, meta] = useField(inputProps);
   return (
      <Grid container>
         <Grid item xs={12} md={12}>
            Persons: {tripPersonsList &&
               <Tooltip title="Add person">
                  <Button color="success" justIcon={true} onClick={() => addNewTripPersons()} disabled={disabled}>
                     <AddBox />
                  </Button>
               </Tooltip>}
            {tripPersonsList.length > 1 &&
               <Tooltip title="Remove all selected persons">
                  <Button color="danger" justIcon={true} onClick={() => setShowConfirmDialog(true)} disabled={disabled}>
                     <Delete />
                  </Button>
               </Tooltip>
            }
            {meta.touched && meta.error && meta.error.length > 0 &&
               <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
            }
            {
               !Common.stringNullOrEmpty(customErrorMessage) &&
               <FormHelperText id={`error-${inputProps.name}`} error>{customErrorMessage}</FormHelperText>
            }
            <input {...field} type="hidden"  {...inputProps} />
         </Grid>
         {tripPersonsList && tripPersonsList.map((tripPerson, index) => {
            return (
               <Grid key={index} item xs={12} md={6}>
                  <Grid container spacing={1} >
                     <Grid item xs={10} md={10}>
                        <SearchableServerSearch
                           labelText={`Person ${index + 1}`}
                           id={`Person${index + 1}`}
                           inputProps={{
                              required: false,
                              name: `Person${index + 1}`,
                              disableClearable: false
                           }}
                           value={tripPerson}
                           onSearchTextChange={onSearchTextChange}
                           setValue={(value: ISelectOption) => {
                              setTripPersons(index, value?.value)
                           }}
                           disabled={disabled}
                        />
                     </Grid>
                     {index > 0 &&
                        <Grid item xs={2} md={2}>
                           <Tooltip title="Remove this person">
                              <Button color="white" style={{ color: "#f44336" }} justIcon={true}
                                    onClick={() => removeTripPersons(index)}
                                    disabled={disabled}>
                                 <DeleteOutlineTwoTone />
                              </Button>
                           </Tooltip>
                        </Grid>
                     }
                  </Grid>
               </Grid>
            )
         })
         }
         {showConfirmDialog &&
            <ConfirmDialogBox
               show={showConfirmDialog}
               onConfirmEvent={() => removeAllTripPersons()}
               onCancel={() => setShowConfirmDialog(false)}
            />
         }
      </Grid>
   )
}
TripPersons.propTypes = {
   value: PropTypes.array,
   setValue: PropTypes.any,
   inputProps: PropTypes.object,
};
export default React.memo(TripPersons);
