import React from "react";

import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";

import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import MonitoringStationRow from "../../components/pages/MonitoringStation/MonitoringStationRow";
import MonitoringStationCard from "../../components/pages/MonitoringStation/MonitoringStationCard";
import AddEditMonitoringStation from "../../components/pages/MonitoringStation/AddEditMonitoringStation";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";

import { IMonitoringStationPageState } from "../../types/stateType";
import { IMonitoringStationPageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IMonitoringStation, IDeleteParams, IMonitoringStationFilter } from "../../types/dataTypes";

import { TableHeader } from "../../constants/tableHeader";
import MonitoringStationService from "../../services/monitoringstation.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import MonitoringStationDependenciesModal from "../../components/pages/MonitoringStation/MonitoringStationDependenciesModal";

class MonitoringStation extends React.Component<IMonitoringStationPageProps, IMonitoringStationPageState> {
   filterOptions: IMonitoringStationFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      sortString: "Name"
   }

   state: IMonitoringStationPageState = {
      monitoringStation: [],
      totalRecords: 0,
      openAddEditMonitoringStationModal: false,
      selectedMonitoringStation: null,
      showConfirmDialog: false,
      openAuditLogs: false,
      openMonitoringStationDependenciesModal: false,
      deleteDependencies: null,
   };

   componentDidMount() {
      this.searchMonitoringStation();
   }

   mapMonitoringStationObject = (selectedMonitoringStation: any): IMonitoringStation => {
      return {
         Oid: selectedMonitoringStation.oid,
         Name: selectedMonitoringStation.name,
         URL: selectedMonitoringStation.url,
         Username: selectedMonitoringStation.username,
         Password: selectedMonitoringStation.password,
         PhoneNumber: selectedMonitoringStation.phoneNumber,
      };
   }

   //#region pagination and searching
   searchMonitoringStation = () => {
      let newFilterOptions: IMonitoringStationFilter = { ...this.filterOptions };
      this.setState({ ...this.state, monitoringStation: [] }, () => {
         MonitoringStationService.searchMonitoringStations(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  monitoringStation: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.monitoringStation.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IMonitoringStationFilter = { ...this.filterOptions };
      MonitoringStationService.searchMonitoringStations(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let userList = [...this.state.monitoringStation];
            userList = userList.concat(res?.data);
            this.setState({
               ...this.state,
               monitoringStation: userList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchMonitoringStation();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchMonitoringStation();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchMonitoringStation();
      // }
   }
   //#endregion

   //#region Add/Edit/Delete MonitoringStation

   onToggleAddEditMonitoringStation = () => {
      this.setState({ ...this.state, openAddEditMonitoringStationModal: !this.state.openAddEditMonitoringStationModal, selectedMonitoringStation: null });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditMonitoringStationModal: false }, () => this.searchMonitoringStation());
   }

   onSelectMonitoringStation = (selectedMonitoringStation: any, isForDelete: boolean = false) => {
      if (isForDelete) {
         this.setState({ ...this.state, selectedMonitoringStation: this.mapMonitoringStationObject(selectedMonitoringStation) }, () => this.onChangeDependencies());
      }
      else
         this.setState({ ...this.state, selectedMonitoringStation: this.mapMonitoringStationObject(selectedMonitoringStation), openAddEditMonitoringStationModal: true });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedMonitoringStation?.Oid };
      MonitoringStationService.deleteMonitoringStation(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedMonitoringStation: null, showConfirmDialog: false, openMonitoringStationDependenciesModal: false }, () => this.searchMonitoringStation());
         } else if (res?.status === StatusCode.BadRequest) {
            this.setState({ ...this.state, showConfirmDialog: false, openMonitoringStationDependenciesModal: true, deleteDependencies: res?.data });
         }

      });
   }
   onChangeDependencies = () => {
      const params: IDeleteParams = { oid: this.state.selectedMonitoringStation?.Oid };
      MonitoringStationService.getMonitoringStationDependencies(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, showConfirmDialog: true, openMonitoringStationDependenciesModal: false });
         } else if (res?.status === StatusCode.BadRequest) {
            this.setState({ ...this.state, showConfirmDialog: false, openMonitoringStationDependenciesModal: true, deleteDependencies: res?.data });
         }
      });
   }
   closeMonitoringStationDependenciesModal = () => {
      this.setState({ ...this.state, openMonitoringStationDependenciesModal: false });
   }
   onCancelDelete = () => {
      this.setState({ ...this.state, selectedMonitoringStation: null, showConfirmDialog: false });
   }

   //#endregion
   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedMonitoringStation: null });
   }

   openMonitoringStationAuditLogModal = (selectedMonitoringStation: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedMonitoringStation: this.mapMonitoringStationObject(selectedMonitoringStation) });
   }
   //#endregion
   render() {
      const { totalRecords, openAddEditMonitoringStationModal, selectedMonitoringStation, showConfirmDialog, monitoringStation, openAuditLogs, openMonitoringStationDependenciesModal, deleteDependencies } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle="Monitoring Stations" onCreate={this.onToggleAddEditMonitoringStation} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["MonitoringStation:SearchSuggestions"] ? MonitoringStationService.getSearchSuggestions : null}
            auditLogs={this.toggleAuditLogModal}
            actionPermission={{
               add: actionPermission["MonitoringStation:POST"],
               auditlog: actionPermission["Audit:MonitoringStation"],
            }}
         >
            {/* add edit user modal */}
            {openAddEditMonitoringStationModal &&
               <AddEditMonitoringStation
                  open={openAddEditMonitoringStationModal}
                  close={this.onToggleAddEditMonitoringStation}
                  successCallback={this.onSave}
                  monitoringStation={selectedMonitoringStation} />
            }
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getMonitoringStationTableHeaders()} pagingInfoText="Monitoring Station" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {monitoringStation && monitoringStation?.map((monitoringStation: any, index: number) => {
                     return (
                        <MonitoringStationRow
                           key={`row_${index}`}
                           monitoringStation={monitoringStation}
                           onEdit={this.onSelectMonitoringStation}
                           onDelete={this.onSelectMonitoringStation}
                           onShowAuditLog={this.openMonitoringStationAuditLogModal}
                           actionPermission={actionPermission}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={monitoringStation ? monitoringStation.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {monitoringStation && monitoringStation?.map((monitoringStation: any, index: number) => {
                        return (
                           <MonitoringStationCard
                              key={`card_${index}`}
                              monitoringStation={monitoringStation}
                              onEdit={this.onSelectMonitoringStation}
                              onDelete={this.onSelectMonitoringStation}
                              onShowAuditLog={this.openMonitoringStationAuditLogModal}
                              actionPermission={actionPermission}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete user */}
            {showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete}
                  dialogueText={"Device using this monitoring station will be unassigned."} />
            }
            {
               openAuditLogs && selectedMonitoringStation === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.MonitoringStation} />
            }

            {
               openAuditLogs && selectedMonitoringStation !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.MonitoringStation}
                  oid={selectedMonitoringStation?.Oid ?? ""}
                  changedEntityTitle={selectedMonitoringStation?.Name} />
            }
            {
               openMonitoringStationDependenciesModal &&
               <MonitoringStationDependenciesModal
                  open={openMonitoringStationDependenciesModal}
                  close={this.closeMonitoringStationDependenciesModal}
                  onChangeDependencies={this.onChangeDependencies}
                  monitoringStationName={selectedMonitoringStation?.Name}
                  dependencies={deleteDependencies}
                  onConfirmDelete={this.onConfirmDelete}
               />
            }
         </PageLayout>
      )
   }
}

export default withParams(MonitoringStation);
