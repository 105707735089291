import { Navigate } from 'react-router-dom';
import { Pages } from '../constants/constant';
import { checkViewPermission, getActionPermission } from '../utils/PermissionHelpers';
import MainLayout from './MainLayout';
import CommonAppbar from '../components/Common/CommonAppBar';
type privateRouteProps = {
   component: React.ComponentType;
   path?: string;
   page: string
}

export const PrivateRoute: React.FC<privateRouteProps> = ({ component: RouteComponent, page }) => {
   if (checkViewPermission(page)) {
      const actionPermission = getActionPermission(page);
      return <MainLayout component={RouteComponent} actionPermission={actionPermission} />
   }
   else if (page === Pages.Escalation) {
      return <>
         <CommonAppbar classes={{}} isAzureLogin={false} />
         <MainLayout component={RouteComponent} actionPermission={{}} />
      </>
   }
   return <Navigate to="/unauthorized" />
}
