import React from "react";

import { Divider, Link, MenuItem } from "@mui/material";
import { Delete, Edit, History, ContentCopy, WrongLocation, ModeOfTravel, CopyAll, Visibility } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { ITripDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { Common } from "../../../constants/common";

const TripRow: React.FC<ITripDataProps> = ({ trip, onEdit, onDelete, onShowAuditLog, actionPermission, onViewTripDetail, onClickCopyTripEditLink, edit }) => {
   return (
      <StyledGridRow>
         <StyledGridCell><Link> <p onClick={() => { (actionPermission["Trip:GetTripDetails"]) && onViewTripDetail(trip.oid) }}>{trip.title}</p> </Link> </StyledGridCell>
         <StyledGridCell> {trip.status}  </StyledGridCell>
         <StyledGridCell> {Common.getFormattedDateTime(trip.startTime)} </StyledGridCell>
         <StyledGridCell> {Common.getFormattedDateTime(trip.endTime)}</StyledGridCell>
         <StyledGridCell> {trip.organizer}</StyledGridCell>
         <StyledGridCell> {trip.deviceId}</StyledGridCell>
         <StyledGridCell> {trip.vehicleRegistration}</StyledGridCell>
         <StyledGridCell align='right'>
            {(actionPermission["Trip:GET"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(trip, false, false, false, /*edit*/true)}>
                  {(actionPermission["Trip:POST"])
                     ? <Edit /> : <Visibility />}
               </Button>
            }
            <ActionOptionMenu>
               {(actionPermission["Trip:POST"]) &&
                  <MenuItem disableRipple onClick={() => { onEdit(trip, false, true) }}>
                     <CopyAll color="primary" />
                     Copy
                  </MenuItem>
               }
               {(actionPermission["Trip:Cancel"]) &&
                  <MenuItem disabled={trip.status === "Cancelled"} disableRipple onClick={() => { onEdit(trip, false, false, true) }}>
                     <WrongLocation color="primary" />
                     Cancel Trip
                  </MenuItem>
               }
               {(actionPermission["Audit:Trip"]) &&
                  <MenuItem disableRipple onClick={() => { onShowAuditLog(trip) }}>
                     <History color="primary" />
                     Audit history
                  </MenuItem>
               }
               {(actionPermission["Trip:GetTripDetails"]) &&
                  <MenuItem disableRipple onClick={() => { onViewTripDetail(trip.oid) }}>
                     <ModeOfTravel />
                     View trip details
                  </MenuItem>
               }
               {(actionPermission["Trip:GetTripDetails"] && actionPermission["Trip:POST"]) &&
                  <MenuItem disableRipple onClick={() => { onClickCopyTripEditLink(trip.oid) }}>
                     <ContentCopy />
                     Copy edit trip link
                  </MenuItem>
               }
               {(actionPermission["Trip:DELETE"]) &&
                  <>
                     <Divider />
                     <MenuItem disableRipple onClick={() => { onDelete(trip, true) }}>
                        <Delete />
                        Delete
                     </MenuItem>
                  </>
               }
            </ActionOptionMenu>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default TripRow;
