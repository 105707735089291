import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import update from 'immutability-helper';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, IconButton, Tooltip, Badge, FormHelperText, Alert } from "@mui/material";

import { IAddEditTripProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import { IGetParams, ILocationSelectOption, IMapLocations, ISearchableSelectParams, ISelectOption, ITrip } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { Common } from '../../../constants/common';
import { StatusCode } from '../../../constants/constant';
import TripService from '../../../services/trip.service';
import PersonService from '../../../services/person.service';
import DeviceService from '../../../services/device.service';
import LocationService from '../../../services/location.service';
import CustomDateTimePicker from '../../Common/CustomDateTimePicker';
import CustomRichInput from '../../Common/CustomRichInput';
import CustomSelect from '../../Common/CustomSelect';
import TripPersons from './TripPersons';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { mapTripObject } from '../../../utils/commonMaps';
import MapLocations from './MapLocations';
import hazardLocationService from '../../../services/hazardLocation.service';
import locationService from '../../../services/location.service';
import { ArrowCircleDown, ArrowCircleUp, Delete, Security, Warning } from '@mui/icons-material';
import { ConnectedFocusError } from '../../Common/FocusError';
import HazardDetailModal from './HazardDetailModal';
import ConfirmDialogBox from '../../Common/ConfirmDialogBox';
import CloseIcon from '@mui/icons-material/Close';

const AddEditTrip: React.FC<IAddEditTripProps> = ({ open, close, successCallback, tripOid, copy, allowSubmit, edit }) => {
   const [error, setError] = useState<any>({});
   const [tripStatusOptions, setTripStatusOptions] = useState<ISelectOption[]>([]);
   const [allLocations, setAllLocations] = useState<IMapLocations[]>([]);
   const [showHazardDetails, setShowHazardDetails] = useState(false);
   const [selectedHazardType, setSelectedHazardType] = useState<string | null>(null);
   const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
   const [showHazardDetailModelTitle, setShowHazardDetailModelTitle] = useState<string | null>(null);
   const [locationHazardLocations, setLocationHazardLocations] = useState<any>({
      hazardLocations: [],
      locationOidHazardLocationGeometryOidMap: []
   });
   const [tripLocations, setTripLocations] = useState<any[]>([]);
   const [hazardsCounts, setHazardCounts] = useState<any[]>([]);
   const [showCancelDialog, setShowCancelDialog] = useState(false);
   const [showSaveDialog, setShowSaveDialog] = useState(false);
   const initialTripPerson: string[] = [];
   const personOid = localStorage.getItem("personOid");
   if (personOid) {
      initialTripPerson.push(personOid);
   }
   let initialValues: ITrip = {
      Title: "",
      Status: "Planned",
      Description: "",
      StartTime: null,
      EndTime: null,
      VehicleRegistration: "",
      DeviceOid: "",
      CellPhoneNumber: "",
      TripPersons: initialTripPerson,
      TripLocations: [],
      AssessmentOfHazardsAndSafety: ""
   }
   const [formValues, setFormValues] = useState<ITrip>(initialValues);


   const validationSchema = Yup.object().shape({
      Title: Yup.string().trim(Resources.Error_TripTitle_Required).required(Resources.Error_TripTitle_Required),
      Status: Yup.string().trim(Resources.Error_TripStatus_Required).required(Resources.Error_TripStatus_Required),
      StartTime: Yup.date().required(Resources.Error_TripStartTime_Required).typeError(Resources.Error_TripStartTime_Invalid),
      EndTime: Yup.date().required(Resources.Error_TripEndTime_Required).typeError(Resources.Error_TripEndTime_Invalid)
         .min(
            Yup.ref('StartTime'),
            "End Time can't be before Start Time"
         ),
      Description: Yup.string().trim(Resources.Error_TripDescription_Required).required(Resources.Error_TripDescription_Required).test('IsValidHTML', Resources.Error_TripDescription_Required, (description) => {
         return validateHTMLContent(description);
      }),
      VehicleRegistration: Yup.string().trim(Resources.Error_TripVehicleRegistration_Required).required(Resources.Error_TripVehicleRegistration_Required),
      DeviceOid: Yup.string().trim(Resources.Error_TripDeviceOid_Required).required(Resources.Error_TripDeviceOid_Required),
      CellPhoneNumber: Yup.string().trim(Resources.Error_TripCellPhoneNumber_Required).required(Resources.Error_TripCellPhoneNumber_Required),
      TripPersons: Yup.array().required(Resources.Error_TripPersons_Required).min(1, Resources.Error_TripPersons_Required).test('IsValid', Resources.Error_TripPersons_Required, (tripPersons) => {
         const validTripPersons = tripPersons?.filter((x: string) => x && x.trim() !== "");
         if (validTripPersons && validTripPersons?.length > 0) {
            return true;
         }
         return false;
      }),
      TripLocations: Yup.array(
         Yup.object({
         })).min(1, Resources.Error_TripLocations_Required),
      AssessmentOfHazardsAndSafety: Yup.string().trim(Resources.Error_AssessmentOfHazardsAndSafety_Required).required(Resources.Error_AssessmentOfHazardsAndSafety_Required).test('IsValidHTMLAssesment', Resources.Error_AssessmentOfHazardsAndSafety_Required, (assessmentOfHazardsAndSafety) => {
         return validateHTMLContent(assessmentOfHazardsAndSafety);
      }),
   });
   function validateHTMLContent(str: string | null | undefined) {
      if (str === null || str === undefined || str === '')
         return false;
      else
         str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/ig, '').length > 0;
   }
   useEffect(() => {
      if (tripOid) {
         const getMethodParams: IGetParams = {
            oid: tripOid
         };
         TripService.getTrip(getMethodParams).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setFormValues(mapTripObject(res?.data, copy));
               setTripLocations(res?.data?.tripLocations ?? []);
            }
         })
      }
      TripService.getTripStatusSelectOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setTripStatusOptions(res?.data);
         }
      });
   }, [tripOid, copy]);
   useEffect(() => {
      locationService.getLocationForMapViewPort().then((res) => {
         if (res?.status === StatusCode.Success) {
            const allLocations: IMapLocations[] = (res?.data ?? []).map((x: any) => {
               const latLongs = JSON.parse(x.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
               return {
                  ...x,
                  latLongs: latLongs
               } as IMapLocations;
            })
            setAllLocations(allLocations);
         }
      });
   }, [])
   const saveTrip = () => {
      formValueToSave.TripLocations = [...formValueToSave.TripLocations.filter((x: any) => !Common.stringNullOrEmpty(x.value))];
      formValueToSave.TripPersons = [...formValueToSave.TripPersons.filter((x: string) => x && x.trim() !== "")];
      formValueToSave.TripLocations.forEach((element: any, index: number) => {
         element.Sequence = index + 1;
      });
      TripService.saveTrip(formValueToSave).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }

            serverErrorFunc(Common.ConvertServerErrorToFormError(res.data));
         }
         setShowSaveDialog(false);
      });
   }
   const [serverErrorFunc, setServerErrorFunc] = useState<any>();
   const [formValueToSave, setFormValueToSave] = useState<any>();
   const onSubmit = (values: any, setServerError: any) => {
      setFormValueToSave(values);
      setServerErrorFunc(() => (errors: any) => setServerError(errors));
      setShowSaveDialog(true);
   }
   const GetPersonForTripSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return PersonService.getPersonForTripSelectOptions(params, signal);
   }
   const GetDeviceForTripSelectOptions = (params: any, signal: any) => {
      return DeviceService.getDeviceForTripSelectOptions(params, signal);
   }
   const GetTripLocationSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return LocationService.getTripLocationSelectOptions(params, signal);
   }
   const GetHazardLocationNearestArea = (locationOids: string[]) => {
      hazardLocationService.getHazardLocationNearestArea(locationOids).then((res: any) => {
         let newLocationWiseHazardLocations = {
            hazardLocations: [...res?.data?.hazardLocations ?? []],
            locationOidHazardLocationGeometryOidMap: [...res?.data?.locationOidHazardLocationGeometryOidMap ?? []]
         };
         setLocationHazardLocations({ ...newLocationWiseHazardLocations });
      });
   }
   const previousValue = useRef<any[]>([]);
   useEffect(() => {
      if (tripLocations.filter((x: any) => !Common.stringNullOrEmpty(x)).length === 0) {
         setLocationHazardLocations({
            hazardLocations: [],
            locationOidHazardLocationGeometryOidMap: []
         });
      }
      else {
         let removed = previousValue.current.filter((obj: any) => !tripLocations.some((obj2: any) => obj.value === obj2.value)).map((obj: any) => obj.value).filter((x: any) => !Common.stringNullOrEmpty(x));
         let added = tripLocations.filter((obj: any) => !previousValue.current.some((obj2: any) => obj.value === obj2.value)).map((obj: any) => obj.value).filter((x: any) => !Common.stringNullOrEmpty(x));
         if (added.length > 0) {
            GetHazardLocationNearestArea(tripLocations.filter((x: any) => !Common.stringNullOrEmpty(x.value)).map((item: any) => item.value));
         }
         if (removed.length > 0) {
            let newHazardLocations = { ...locationHazardLocations };
            let securityGeometryToBeRemoved: any[] = [];
            removed.forEach(locationOid => {
               newHazardLocations.locationOidHazardLocationGeometryOidMap.filter((x: any) => x.locationOid === locationOid).forEach((locationHazardLocation: any) => {
                  if (newHazardLocations.locationOidHazardLocationGeometryOidMap.filter((x: any) => x.hazardLocationGeometryOid === locationHazardLocation.hazardLocationGeometryOid).length === 1) {
                     securityGeometryToBeRemoved.push(locationHazardLocation.hazardLocationGeometryOid);
                  }
               })
            });
            securityGeometryToBeRemoved.forEach(hazardLocationGeometryOid => {
               newHazardLocations.hazardLocations.forEach((hazardLocation: any) => {
                  hazardLocation.hazardLocationGeometry = hazardLocation.hazardLocationGeometry.filter((x: any) => x.oid !== hazardLocationGeometryOid);
               });
            });
            removed.forEach(locationOid => {
               newHazardLocations.locationOidHazardLocationGeometryOidMap = newHazardLocations.locationOidHazardLocationGeometryOidMap.filter((x: any) => x.locationOid !== locationOid)
            });
            newHazardLocations.hazardLocations = newHazardLocations.hazardLocations.filter((x: any) => x.hazardLocationGeometry.length > 0);
            setLocationHazardLocations({ ...newHazardLocations });
         }
      }
      previousValue.current = [...tripLocations];
   }, [tripLocations]);  // eslint-disable-line react-hooks/exhaustive-deps
   useEffect(() => {
      let helper: any = {};
      let result = locationHazardLocations.hazardLocations.reduce((r: any, o: any) => {
         var key = o.type

         if (!helper[key]) {
            helper[key] = Object.assign({}, { key, count: 1 }); // create a copy of o
            r.push(helper[key]);
         } else {
            helper[key].count += 1;
         }
         return r;
      }, []);
      setHazardCounts(result);
   }, [locationHazardLocations]);  // eslint-disable-line react-hooks/exhaustive-deps
   const removeTripLocations = (index: number, setFieldValue: any) => {
      tripLocations.splice(index, 1);
      setFieldValue("TripLocations", tripLocations);
      setTripLocations([...tripLocations]);
   }
   const addTripLocation = (selectedTrip: ILocationSelectOption, setFieldValue: any) => {
      if (!Common.stringNullOrEmpty(selectedTrip.value) && tripLocations.filter((x: ILocationSelectOption) => x.value === selectedTrip.value).length === 0) {
         tripLocations.push(selectedTrip);
         setFieldValue("TripLocations", tripLocations);
         setTripLocations([...tripLocations]);
         setFieldValue("Location", null);
      }
   }
   const getLocationHazardCount = (locationOid: string) => {
      const hazardIds = locationHazardLocations.locationOidHazardLocationGeometryOidMap.filter((x: any) => x.locationOid === locationOid).map((hazardLocationMap: any) => hazardLocationMap.hazardLocationGeometryOid);
      return locationHazardLocations.hazardLocations.filter((x: any) => x.hazardLocationGeometry.some((y: any) => hazardIds.indexOf(y.oid) > -1)).length;
   }
   const moveLocation = (selectedIndex: number, movingIndex: number, setFieldValue: any) => {

      const newLocationSeq = update(tripLocations, {
         $splice: [
            [selectedIndex, 1],
            [movingIndex, 0, tripLocations[selectedIndex] as any],
         ],
      });
      setTripLocations([...newLocationSeq]);
      setFieldValue("TripLocations", [...newLocationSeq]);
   }
   const getHazardDetailsByTypeOrLocation = () => {
      if (selectedHazardType)
         return locationHazardLocations.hazardLocations.filter((x: any) => x.type === selectedHazardType);
      if (selectedLocation) {
         const hazardIds = locationHazardLocations.locationOidHazardLocationGeometryOidMap.filter((x: any) => x.locationOid === selectedLocation).map((hazardLocationMap: any) => hazardLocationMap.hazardLocationGeometryOid);
         return locationHazardLocations.hazardLocations.filter((x: any) => x.hazardLocationGeometry.some((y: any) => hazardIds.indexOf(y.oid) > -1))
      }
   }
   const openHazardDetails = (type: string | null, locationOid: string | null, title: string) => {
      setShowHazardDetails(true);
      setSelectedHazardType(type);
      setSelectedLocation(locationOid);
      setShowHazardDetailModelTitle(title);
   }
   const closeHazardDetails = () => {
      setShowHazardDetails(false);
      setSelectedHazardType(null);
      setSelectedLocation(null);
      setShowHazardDetailModelTitle(null);
   }
   const handleClose = (event: any, reason: string) => {
      if (reason && reason === "backdropClick")
         return;
      setShowCancelDialog(true);
      event.preventDefault();
      return;
   }

   const isClientAdmin = (Common.IsInRole("Client Admin"));
   const disabled = edit && !isClientAdmin
      && (formValues.EndTime && new Date(formValues.EndTime).getTime() <= new Date().getTime());

   const onCancelClick = () => {
      if (disabled) {
         close();
         return;
      }
      setShowCancelDialog(true);
   }

   return (
      <Dialog open={open}
         onClose={handleClose} scroll="paper"
         disableEscapeKeyDown={false}
         fullWidth maxWidth="lg">
         {disabled && <Alert severity="warning">
            Attention: This trip has already ended. To maintain the integrity and accuracy of historical data, modifications are only allowed by Client Admins
         </Alert>
         }
         <Formik enableReinitialize={true} initialValues={formValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, setFieldValue, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{tripOid ? !copy ? "Edit Trip" : `Copying Trip : ${formValues.Title}` : "Add Trip"}
                     <IconButton
                        onClick={onCancelClick}
                        sx={{
                           position: 'absolute',
                           right: 8,
                           top: 8,
                           color: (theme) => theme.palette.grey[500],
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <input type="hidden" name="Oid" value={values.Oid} />
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Title"
                              id="Title"
                              inputProps={{
                                 required: true,
                                 name: "Title"
                              }}
                              customErrorMessage={error["title"]?.message}
                              value={values.Title}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomSelect
                              labelText="Status"
                              id="Status"
                              inputProps={{
                                 required: true,
                                 name: "Status"
                              }}
                              customErrorMessage={error["status"]?.message}
                              value={values.Status}
                              options={tripStatusOptions}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomDateTimePicker
                              labelText={"Start Time *"}
                              id="StartTime"
                              inputFormat={Common.DateTimeFormat}
                              inputProps={{
                                 required: true,
                                 name: "StartTime",
                                 InputLabelProps: { shrink: true },
                                 onChange: (value: any): void => {
                                    if (value !== values.StartTime) {
                                       setFieldValue("DeviceOid", "");
                                    }
                                    setFieldValue("StartTime", value);
                                 }
                              }}
                              customErrorMessage={error["startTime"]?.message}
                              value={values.StartTime}
                              openTo="day"
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomDateTimePicker
                              labelText={"End Time *"}
                              id="EndTime"
                              inputFormat={Common.DateTimeFormat}
                              inputProps={{
                                 required: true,
                                 name: "EndTime",
                                 InputLabelProps: { shrink: true },
                                 onChange: (value: any): void => {
                                    if (value !== values.EndTime) {
                                       setFieldValue("DeviceOid", "");
                                    }
                                    setFieldValue("EndTime", value);
                                 }
                              }}
                              customErrorMessage={error["endTime"]?.message}
                              value={values.EndTime}
                              openTo="day"
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <CustomRichInput
                              labelText="Description of the work being undertaken"
                              id="Description"
                              inputProps={{
                                 required: true,
                                 name: "Description"
                              }}
                              customErrorMessage={error["description"]?.message}
                              value={values.Description}
                              setValue={(value: string) => {
                                 setFieldValue("Description", value);
                              }}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Vehicle Registration"
                              id="VehicleRegistration"
                              inputProps={{
                                 required: true,
                                 name: "VehicleRegistration"
                              }}
                              customErrorMessage={error["vehicleRegistration"]?.message}
                              value={values.VehicleRegistration}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <SearchableServerSearch
                              labelText={`Device ID`}
                              id={`DeviceOid`}
                              inputProps={{
                                 required: true,
                                 name: `DeviceOid`,
                                 disableClearable: true,
                                 disabled: (!values.StartTime || !values.EndTime),
                                 getOptionDisabled: (option: any) => option.disabled
                              }}
                              value={values.DeviceOid}
                              onSearchTextChange={(params: ISearchableSelectParams, signal: any) => {
                                 const parameters: any = { ...params };
                                 parameters.StartTime = values.StartTime ? Common.getLocalToUTC(values.StartTime) : null;
                                 parameters.EndTime = values.EndTime ? Common.getLocalToUTC(values.EndTime) : null;
                                 parameters.CurrentTripOid = tripOid;
                                 return GetDeviceForTripSelectOptions(parameters, signal);
                              }}
                              setValue={(value: ISelectOption) => {
                                 setFieldValue("DeviceOid", value?.value);
                              }}
                              helperText={(!values.StartTime || !values.EndTime) ? "You can select device only after start time and end time is selected." : (values.DeviceOid ? "If you change start time or end time, selected device will be cleared" : null)}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Cell Phone Number"
                              id="CellPhoneNumber"
                              inputProps={{
                                 required: true,
                                 name: "CellPhoneNumber"
                              }}
                              customErrorMessage={error["cellPhoneNumber"]?.message}
                              value={values.CellPhoneNumber}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <TripPersons
                              onSearchTextChange={GetPersonForTripSelectOptions}
                              value={values.TripPersons}
                              setValue={(value: string[]) => {
                                 setFieldValue("TripPersons", value)
                              }}
                              customErrorMessage={error["tripPersons"]?.message}
                              inputProps={{
                                 required: true,
                                 name: "TripPersons"
                              }}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <CustomRichInput
                              labelText="Assessment Of Hazards And Safety"
                              id="AssessmentOfHazardsAndSafety"
                              inputProps={{
                                 required: true,
                                 name: "AssessmentOfHazardsAndSafety"
                              }}
                              customErrorMessage={error["assessmentOfHazardsAndSafety"]?.message}
                              value={values.AssessmentOfHazardsAndSafety}
                              setValue={(value: string) => {
                                 setFieldValue("AssessmentOfHazardsAndSafety", value);
                              }}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           {locationHazardLocations?.hazardLocations && locationHazardLocations?.hazardLocations.length > 0 &&
                              <FormHelperText id={`error-nearbyhazardlocation`} error>Warning: {locationHazardLocations?.hazardLocations.length} hazardous {locationHazardLocations?.hazardLocations.length > 1 ? "locations" : "location"}.</FormHelperText>
                           }
                           <Grid container mt={2}>
                              {
                                 hazardsCounts && hazardsCounts.length > 0 && hazardsCounts.map((hazard: any, index: number) =>
                                    <Grid item xs={4} md={3}>
                                       <Badge badgeContent={hazard.count} color="info">
                                          <Button size="sm" color="danger" onClick={() => { openHazardDetails(hazard.key, null, `hazard type : ${hazard.key}`) }} >{hazard.key}</Button>
                                       </Badge>
                                    </Grid>
                                 )
                              }
                           </Grid>
                        </Grid>
                        <Grid item xs={12} md={8}>
                           <MapLocations
                              selectedLocations={values.TripLocations}
                              allLocations={allLocations}
                              hazardLocations={locationHazardLocations?.hazardLocations ?? []}
                              addTripLocation={(value: ILocationSelectOption) => {
                                 addTripLocation(value, setFieldValue);
                              }}
                              removeTripLocation={(index: number) => {
                                 removeTripLocations(index, setFieldValue)
                              }}
                              tripOid={tripOid}
                              disabled={disabled}
                           />
                        </Grid>
                        <Grid item xs={12} md={4}>
                           <SearchableServerSearch
                              labelText={`Search location to add`}
                              id={`TripLocations`}
                              inputProps={{
                                 required: false,
                                 name: `TripLocations`,
                                 disableClearable: false
                              }}
                              onSearchTextChange={GetTripLocationSelectOptions}
                              setValue={(value: ILocationSelectOption) => {
                                 addTripLocation(value, setFieldValue);
                              }}
                              setNewValue={false}
                              disabled={disabled}
                           />
                           <Typography style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              color: "	#28282B",
                              fontSize: "1em",
                              marginLeft: "10px"
                           }} variant="subtitle1" color="textSecondary" component="p">
                              <Security style={{ marginRight: "10px" }} /> <b>Selected Trip Location(s)</b>
                           </Typography>
                           <List>
                              {tripLocations && tripLocations.map((tripLocation: any, index: number) => {
                                 const totalLocation = tripLocations.length;
                                 const hazardCountForLocation = getLocationHazardCount(tripLocation.value);
                                 return (
                                    <ListItem key={`selectedLocation_${index}`}
                                       secondaryAction={
                                          <>
                                             <Tooltip title="Remove">
                                                <IconButton edge="end" aria-label="delete" onClick={() => removeTripLocations(index, setFieldValue)}>
                                                   <Delete />
                                                </IconButton>
                                             </Tooltip>
                                             {hazardCountForLocation > 0 &&
                                                <Tooltip title="No Of Hazardous Location">
                                                   <IconButton edge="end" aria-label="warning" onClick={() => { openHazardDetails(null, tripLocation.value, `trip location : ${tripLocation.label}`) }}>
                                                      <Badge badgeContent={hazardCountForLocation} color="error" >
                                                         <Warning color="primary" />
                                                      </Badge>
                                                   </IconButton>
                                                </Tooltip>
                                             }
                                          </>
                                       } >
                                       <ListItemIcon>
                                          <div style={{ display: "grid" }}>
                                             {index !== 0 &&
                                                <Tooltip title="Move Up">
                                                   <IconButton style={{ padding: 0 }} color="primary" aria-label="Move Up" onClick={() => { moveLocation(index, index - 1, setFieldValue) }}>
                                                      <ArrowCircleUp />
                                                   </IconButton>
                                                </Tooltip>
                                             }
                                             {index < totalLocation - 1 &&
                                                <Tooltip title="Move Down">
                                                   <IconButton style={{ padding: 0 }} color="primary" aria-label="Move Down" onClick={() => { moveLocation(index, index + 1, setFieldValue) }}>
                                                      <ArrowCircleDown />
                                                   </IconButton>
                                                </Tooltip>
                                             }
                                          </div>
                                       </ListItemIcon>
                                       <ListItemText primary={<><strong>{index + 1}</strong> {tripLocation.label}</>} />
                                    </ListItem>
                                 )
                              })
                              }
                           </List>
                        </Grid>
                        <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={onCancelClick}>{disabled ? "Close" : "Cancel"}</Button>
                     {allowSubmit && !disabled &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
         {
            showHazardDetails && (selectedHazardType || selectedLocation) &&
            < HazardDetailModal
               open={showHazardDetails}
               close={closeHazardDetails}
               hazardDetails={getHazardDetailsByTypeOrLocation()}
               title={showHazardDetailModelTitle}
            />
         }
         {showCancelDialog &&
            <ConfirmDialogBox
               show={showCancelDialog}
               onConfirmEvent={() => close()}
               onCancel={() => setShowCancelDialog(false)}
               dialogueText="All unsaved data will be lost. Are you sure you would like to cancel?"
               dialogueTitle="Are you sure want to cancel?" />
         }
         {showSaveDialog &&
            <ConfirmDialogBox
               show={showSaveDialog}
               onConfirmEvent={() => saveTrip()}
               onCancel={() => setShowSaveDialog(false)}
               dialogueText="Emails will be sent to all trip participants and escalation contacts on save, Are you sure you would like to save?"
               dialogueTitle="Are you sure want to save?" />
         }
      </Dialog >
   );
};

export default AddEditTrip;
