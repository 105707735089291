import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { IAddDeviceTestProps } from '../../../types/propType';
import Button from '../../Common/Button';
import { IDeviceEvent } from '../../../types/dataTypes';
import { StatusCode } from '../../../constants/constant';
import { Common } from '../../../constants/common';
import { Resources } from '../../../constants/resources';

import CustomRadioButtonGroup from '../../Common/CustomRadioButtonGroup';
import CustomInput from '../../Common/CustomInput';
import deviceEventService from '../../../services/deviceEvent.service';
import CustomDateTimePicker from '../../Common/CustomDateTimePicker';
import ValidationSummary from '../../Common/ValidationSummary';

const AddDeviceTest: React.FC<IAddDeviceTestProps> = ({ open, close, successCallback, deviceOid, deviceId, isPersonAssigned }) => {
   const initialDeviceEvent: IDeviceEvent = {
      DeviceOid: deviceOid,
      isPersonAssigned: isPersonAssigned,
      EventType: "Test",
      EventResult: "",
      EventNotes: null,
      EventDateTime: Common.getUtcToLocal(new Date()),
   };
   const [error, setError] = useState<any>({});
   const validationSchema = Yup.object().shape({
      EventResult: Yup.string().trim(Resources.Error_DeviceEvent_TestResult_Required).required(Resources.Error_DeviceEvent_TestResult_Required),
      EventNotes: Yup.string().nullable().when("EventResult", {
         is: (value: string) => value !== "Pass" && value !== "No Person Assigned",
         then: Yup.string().nullable().trim(Resources.Error_DeviceEvent_Notes_Required).required(Resources.Error_DeviceEvent_Notes_Required),
      }),
      EventDateTime: Yup.string().trim(Resources.Error_DeviceEvent_DateTime_Required).required(Resources.Error_DeviceEvent_DateTime_Required),
   });
   const onSubmit = (values: IDeviceEvent) => {
      deviceEventService.save(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            setError(res?.data);
         }
      });
   }
   const deviceTestResultOption = Common.DeviceTestResultOptions(false, isPersonAssigned);
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <Formik initialValues={initialDeviceEvent} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">Add Test details for Device: {deviceId}</DialogTitle>
                  <DialogContent dividers>
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <ValidationSummary errors={error["errors"]} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <CustomRadioButtonGroup
                              labelText={"Test Result"}
                              id="EventResult"
                              inputProps={{
                                 name: "EventResult",
                                 InputLabelProps: { shrink: true },
                                 disabled: false
                              }}
                              value={values.EventResult}
                              options={deviceTestResultOption}
                              setValue={(value: any) => {
                                 setFieldValue("EventResult", value);
                              }}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <CustomInput
                              id="EventNotes"
                              labelText={`Notes: ${values.EventResult === 'Fail' ? '(please include reason for failed test)' : ''}`}
                              inputProps={{
                                 required: values.EventResult !== "Pass" && values.EventResult !== "No Person Assigned",
                                 name: "EventNotes",
                                 multiline: true,
                                 maxRows: 10,
                              }}
                              rows={3}
                              value={values.EventNotes}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <CustomDateTimePicker
                              labelText={"Date Time"}
                              id="EventDateTime"
                              openTo="day"
                              inputFormat={Common.DateTimeFormat}
                              inputProps={{
                                 name: "EventDateTime",
                                 InputLabelProps: { shrink: true },
                                 onChange: (value: any): void => {
                                    setFieldValue("EventDateTime", value);
                                 }
                              }}
                              customErrorMessage={error["eventDateTime"]?.message}
                              value={values.EventDateTime}
                           />
                        </Grid>
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     <Button color="primary" type="submit">Save</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddDeviceTest;
