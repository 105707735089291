import React from "react";
import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { Phone, Email, Badge, ErrorOutline, AccessTimeFilled, Visibility, Person, Warning } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { ISftpImportLogsDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";

const SftpImportLogsCard: React.FC<ISftpImportLogsDataProps> = ({ classes, record, onView }) => {
   let personDetails: any = JSON.parse(record.details);
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <Button color="info" justIcon={true} onClick={() => onView(record)}><Visibility /></Button>
               }
               title={
                  <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                     <AccessTimeFilled className={classes.beforeTextIcon} /> {Common.getFormattedDateTime(record.createdOn)}
                  </Typography>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Person className={classes.beforeTextIcon} /> {personDetails?.Firstname + " " + personDetails?.Lastname}
               </Typography>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Email className={classes.beforeTextIcon} /> {personDetails?.Email}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Phone className={classes.beforeTextIcon} /> {personDetails?.PhoneNumber1}
               </Typography>
               {!Common.stringNullOrEmpty(personDetails?.EmployeeID) &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <Badge className={classes.beforeTextIcon} /> {personDetails?.EmployeeID}
                  </Typography>
               }
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Warning className={classes.beforeTextIcon} /> {record.type}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <ErrorOutline className={classes.beforeTextIcon} /> {record.error}
               </Typography>
            </CardContent>

         </Card>
      </Grid >
   )
}

export default withStyles(SftpImportLogsCard, gridTileStyle);
