import Keycloak from 'keycloak-js'
import axios, { HttpStatusCode } from 'axios';

const w: any = window;
const kcUrl = w?._env_?.KC_URL;
const kcRealm = w?._env_?.KC_REALM;
const kcClientId = w?._env_?.KC_CLIENT_ID;
const environment = w?._env_?.ENVIRONMENT;

export const keycloakInstance = new Keycloak({
   url: `${kcUrl}`,
   realm: kcRealm,
   clientId: kcClientId
});

export const initKeycloak = async () => {
   try {
      const redirectUri = window.location.origin + window.location.pathname;// + window.location.search;
      const enableLogging = environment === "LOCAL";
      // console.log(`Loc 240415-0912: Initializing Keycloak ...`);
      // console.log(`Loc 240415-0913: keycloakInstance.url: ${environment.kcUrl}`);
      // console.log(`Loc 240415-0914: keycloakInstance.realm: ${environment.kcRealm}`);
      // console.log(`Loc 240415-0915: keycloakInstance.clientId: ${environment.kcClientId}`);
      // console.log(`Loc 240415-0916: redirectUri: ${redirectUri}`);
      // console.log(`Loc 240415-0917: enableLogging: ${enableLogging}`);
      // init() returns a boolean value indicating whether the user is authenticated or not. See
      // https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter for more
      // details. [Manfred, 15apr2024]
      var isAuthenticated = await keycloakInstance.init({
         onLoad: 'check-sso',
         pkceMethod: 'S256',
         redirectUri: redirectUri,
         enableLogging: enableLogging,
         checkLoginIframe: false,
         scope: "profile impersonator oid email"
      });
      return isAuthenticated;
   }
   catch (error) {
      console.error("Error while intializing Keycloak: ", error);
   }
}

export const refreshToken = async () => {
   try {
      var kcTokenData = getLocalImpersonatorData();
      if (kcTokenData.refreshToken) {
         const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: {
               client_id: environment.kcClientId,
               grant_type: 'refresh_token',
               refresh_token: kcTokenData.refreshToken
            },
            url: `${environment.kcUrl}/realms/${environment.kcRealm}/protocol/openid-connect/token`,
         };
         const response = await axios(options);
         if (response.status === HttpStatusCode.Ok) {
            kcTokenData.token = response.data.access_token;
            kcTokenData.refreshToken = response.data.refresh_token;
            localStorage.setItem("kcToken", JSON.stringify(kcTokenData));
         }
      }
   }
   catch {
      console.warn(`Loc 240415-0920: Error while exchanging refresh token for access token`);
      return false;
   }
}

export const getLocalImpersonatorData = () => {
   const kcTokenData = localStorage.getItem("kcToken");
   let impersonatorData;
   if (kcTokenData) {
      impersonatorData = JSON.parse(kcTokenData);
   }
   return impersonatorData;
}

export const setLocalImpersonatorData = (token: string, refreshToken: string, roles: string[]) => {
   const tokenData = {
      token: token,
      refreshToken: refreshToken,
      roles: roles
   };
   localStorage.setItem("kcToken", JSON.stringify(tokenData));
}

export const removeLocalImpersonatorData = () => {
   localStorage.removeItem("kcToken");
}

export const setKeycloakInstanceData = (token: string, refreshToken: string, roles: string[]) => {
   keycloakInstance.token = token;
   keycloakInstance.authenticated = true;
   keycloakInstance.refreshToken = refreshToken;
   keycloakInstance.realmAccess = {
      roles: roles
   };
}

export const mapKeycloakRole = (role: string): string | null => {
   switch (role) {
      case 'client-admin': return 'Client Admin';
      case 'client-api-user': return 'Client Api User';
      case 'client-audit-user': return 'Client Audit User';
      case 'client-location-editor': return 'Client Location Editor';
      case 'client-user': return 'Client User';
      case 'monitoring-station-admin': return 'Monitoring Station Admin';
      case 'monitoring-station-operator': return 'Monitoring Station Operator';
      case 'sftp-processor': return 'Sftp Processor';
      case 'gas-staff': return 'GAS Staff';
      default: return '';
   }
};
