import { Controllers } from "../constants/constant";
import request from "../utils/request";

class SftpImportLogs {
   searchSftpImportLogs = (params: any) => {
      return request({
         url: `${Controllers.SftpImportLogs}/Search`,
         method: 'get',
         params
      });
   }

   exportSftpImportLogs = (params: any) => {
      return request({
         url: `${Controllers.SftpImportLogs}/Export`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }
}

export default new SftpImportLogs();
