import React from "react";
import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { RequestPage, AttachFile, Error, ErrorOutline, AccessTimeFilled, Visibility, Functions } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IErrorLogDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";

const ErrorLogCard: React.FC<IErrorLogDataProps> = ({ classes, errorLog, onView }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <Button color="info" justIcon={true} onClick={() => onView(errorLog)}><Visibility /></Button>
               }
               title={
                  <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                     <AccessTimeFilled className={classes.beforeTextIcon} /> {Common.getFormattedDateTime(errorLog.createdOn)}
                  </Typography>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Functions className={classes.beforeTextIcon} /> {`${errorLog.functionOrURL}`}
               </Typography>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <AttachFile className={classes.beforeTextIcon} /> {errorLog.fileName}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <RequestPage className={classes.beforeTextIcon} /> {errorLog.requestData}
               </Typography>
               {!Common.stringNullOrEmpty(errorLog.responseData) &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <Error className={classes.beforeTextIcon} /> {errorLog.responseData}
                  </Typography>
               }
               {!Common.stringNullOrEmpty(errorLog.error) &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <ErrorOutline className={classes.beforeTextIcon} /> {errorLog.error}
                  </Typography>
               }
            </CardContent>

         </Card>
      </Grid >
   )
}

export default withStyles(ErrorLogCard, gridTileStyle);
