import React from 'react';
import lodash from "lodash";

import { IDateTimeComparetorProps } from '../../types/propType';
import { Common } from '../../constants/common';

const DateTimeComparetor: React.FC<IDateTimeComparetorProps> = ({ oldValueJson, changedValueJson, compareKey, onlyDate }) => {
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   const formattedChangedValue = changedValue ? onlyDate ? Common.getFormattedDateOnly(changedValue) : Common.getFormattedDateTime(changedValue) : "";
   const formattedOlddValue = oldValue ? onlyDate ? Common.getFormattedDateOnly(oldValue) : Common.getFormattedDateTime(oldValue) : "";
   return (
      <>
         {
            oldValueJson !== null ?
               <>
                  {
                     formattedChangedValue === formattedOlddValue ?
                        <span>{formattedChangedValue}</span> :
                        <span style={{ backgroundColor: "#faa8a8" }}>
                           <del>{formattedOlddValue}</del> <span style={{ backgroundColor: "#bcffbc" }}>{formattedChangedValue}</span>
                        </span>
                  }
               </>
               :
               <span>{formattedChangedValue}</span>
         }
      </>
   );
};

export default DateTimeComparetor;
