import React from 'react';
import { AppBar, Container, Toolbar, Typography } from "@mui/material"
import HeaderLogo from './HeaderLogo';
interface ICommonAppBarProps {
   classes: any;
   isAzureLogin: boolean;
};
const CommonAppBar: React.FC<ICommonAppBarProps> = ({ classes, isAzureLogin }) => {
   return (
      <AppBar position="static" className={classes.appBar}>
         <Container maxWidth="xl">
            <Toolbar disableGutters>
               <HeaderLogo isAzureLogin={isAzureLogin} />
               <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  GAS
               </Typography>
            </Toolbar>
         </Container>
      </AppBar>
   )
}
export default CommonAppBar;
