import React from "react";
import { withStyles } from 'tss-react/mui';
import { Paper } from "@mui/material";
// core components
import MainLayoutStyle from "../assets/jss/layouts/mainLayoutStyle";
import Header from "./Header";
import { IMainLayoutProps } from "../types/propType";

const MainLayout: React.FC<IMainLayoutProps> = ({ component: Component, classes,actionPermission }) => {
   return (
      <main>
         <Header />
         <Paper className={classes.wrapper}>
            <Component actionPermission={actionPermission}/>
         </Paper>
      </main>
   );
}

export default withStyles(MainLayout, MainLayoutStyle);
