import { Theme } from "@mui/material";
import { whiteColor } from "../appStyle";

const mainLayoutStyle = (theme: Theme) => ({
   wrapper: {
      background: whiteColor,
      borderRadius: 6,
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
      margin: theme.spacing(2),
      padding: theme.spacing(2)
   }
});

export default mainLayoutStyle;
