import { Controllers } from "../constants/constant";
import request from "../utils/request";

class ResponseInstructionService {
   getResponseInstructionByOid = (oid?: string) => {
      const params = {
         oid
      }
      return request({
         url: `${Controllers.ResponseInstruction}`,
         method: 'get',
         params
      })
   }
}

export default new ResponseInstructionService();
