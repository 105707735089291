import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { IAddEditLocationProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import MapLocationPicker from '../../Common/MapLocationPicker';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import { ISearchableSelectParams, ITripLocation } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { StatusCode } from '../../../constants/constant';
import LocationService from '../../../services/location.service';
import CustomSelect from '../../Common/CustomSelect';
import { mapLocationObject } from '../../../utils/commonMaps';
import SearchableServerInput from '../../Common/SearchableServerInput';
import { ConnectedFocusError } from '../../Common/FocusError';
import { Common } from '../../../constants/common';
const AddEditLocation: React.FC<IAddEditLocationProps> = ({ open, close, successCallback, locationOid, allowSubmit }) => {
   const [error, setError] = useState<any>({});
   let initialValues: ITripLocation = {
      Title: "",
      Type: "",
      Description: null,
      PhysicalAddress: null,
      GeometryType: "Point",
      CoOrdinates: "[]",
      Id: null
   };
   const [formValues, setFormValues] = useState<ITripLocation>(initialValues);
   useEffect(() => {
      if (locationOid) {
         LocationService.getLocation({ oid: locationOid }).then((res: any) => {
            if (res?.status === StatusCode.Success && res.data) {
               setFormValues(mapLocationObject(res?.data));
            }
         });
      }
   }, [locationOid]);
   const validationSchema = Yup.object().shape({
      Title: Yup.string().trim(Resources.Error_LocationTitle_Required).required(Resources.Error_LocationTitle_Required),
      Type: Yup.string().trim(Resources.Error_Type_Required).required(Resources.Error_Type_Required),
      GeometryType: Yup.string().trim(Resources.Error_LocationGeometry_Required).required(Resources.Error_LocationGeometry_Required),
   });
   const onSubmit = (values: any, setServerError: any) => {
      LocationService.saveLocation(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }
   const getLocationTypeSuggestion = (params: ISearchableSelectParams, signal: any) => {
      return LocationService.getLocationTypeSuggestion(params, signal);
   }
   return (
      <Dialog style={{ zIndex: 2 }} open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <Formik enableReinitialize={true} initialValues={formValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, setFieldValue, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{locationOid !== null ? "Edit" : "Add"} Location</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <input type="hidden" name="Oid" value={values.Oid} />
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Title"
                              id="Title"
                              inputProps={{
                                 required: true,
                                 name: "Title"
                              }}
                              customErrorMessage={error["title"]?.message}
                              value={values.Title}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <SearchableServerInput
                              labelText="Location Type"
                              id="Type"
                              inputProps={{
                                 required: true,
                                 name: "Type"
                              }}
                              customErrorMessage={error["type"]?.message}
                              value={values.Type}
                              setValue={(value: string) => {
                                 setFieldValue("Type", value)
                              }}
                              onTextChange={getLocationTypeSuggestion}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Description"
                              id="Description"
                              inputProps={{
                                 name: "Description",
                                 InputLabelProps: { shrink: values.Description ? true : false }
                              }}
                              customErrorMessage={error["description"]?.message}
                              value={values.Description}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Physical Address"
                              id="PhysicalAddress"
                              inputProps={{
                                 name: "PhysicalAddress",
                                 InputLabelProps: { shrink: values.PhysicalAddress ? true : false }
                              }}
                              customErrorMessage={error["physicalAddress"]?.message}
                              value={values.PhysicalAddress}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomSelect
                              labelText="Geometry Type"
                              id="GeometryType"
                              inputProps={{
                                 required: true,
                                 name: "GeometryType",
                                 onChange: (e: any) => {
                                    setFieldValue("GeometryType", e.target.value);

                                    setFieldValue("CoOrdinates", "[]");
                                 }
                              }}
                              value={values.GeometryType}
                              options={[{ label: "Point", value: "Point" }, { label: "Polygon", value: "Polygon" }]}
                           />
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <CustomInput
                              labelText="Id"
                              id="Id"
                              inputProps={{
                                 required: false,
                                 name: "Id",
                                 disabled: true,
                                 InputLabelProps: { shrink: values.Id ? true : false }
                              }}
                              customErrorMessage={error["id"]?.message}
                              value={values.Id}
                           />
                        </Grid>
                        <Grid item xs={12} md={12}>
                           <MapLocationPicker
                              value={values.CoOrdinates}
                              setValue={(value: any) => {
                                 setFieldValue("CoOrdinates", value);
                              }}
                              geometryType={values.GeometryType}
                           />
                        </Grid>
                        <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     {
                        allowSubmit &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddEditLocation;
