import React from "react";

import { Box, Grid, Menu, MenuItem } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";

import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import TripRow from "../../components/pages/Trips/TripRow";
import TripCard from "../../components/pages/Trips/TripCard";
import AddEditTrip from "../../components/pages/Trips/AddEditTrip";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";

import { ITripPageState } from "../../types/stateType";
import { ITripPageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IDeleteParams, ITripFilter } from "../../types/dataTypes";

import { TableHeader } from "../../constants/tableHeader";
import TripService from "../../services/trip.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import { mapTripObject } from "../../utils/commonMaps";
import { withSnackbar } from "../../components/Common/Snackbar";
import TripAdvanceFilter from "../../components/pages/Trips/TripAdvanceFilter";
import Button from "../../components/Common/Button";
import { Map } from "@mui/icons-material";
import TripMapViewModal from "../../components/pages/Trips/TripMapViewModal";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import copy from "copy-to-clipboard";
class Trips extends React.Component<ITripPageProps, ITripPageState> {
   filterOptions: ITripFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      sortString: "Title"
   }

   state: ITripPageState = {
      trips: [],
      totalRecords: 0,
      openAddEditTripModal: false,
      selectedTrip: null,
      showConfirmDialog: false,
      openAuditLogs: false,
      copy: false,
      cancelTrip: false,
      showMapView: false,
      mapFilters: {},
      edit: false
   };

   componentDidMount() {
      this.searchTrip();
   }

   //#region pagination and searching
   searchTrip = () => {
      let newFilterOptions: ITripFilter = { ...this.filterOptions };
      this.setState({ ...this.state, trips: [] }, () => {
         TripService.searchTrips(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  trips: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.trips.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: ITripFilter = { ...this.filterOptions };
      TripService.searchTrips(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let tripList = [...this.state.trips];
            tripList = tripList.concat(res?.data);
            this.setState({
               ...this.state,
               trips: tripList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchTrip();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchTrip();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchTrip();
      // }
   }

   onAdvanceSearch = (filters: any) => {
      this.filterOptions.Status = filters.Status;
      this.filterOptions.StartDate = filters.StartDate;
      this.filterOptions.EndDate = filters.EndDate;
      this.filterOptions.OrganizerOid = filters.OrganizerOid;
      this.filterOptions.PersonOid = filters.PersonOid;
      this.filterOptions.EscalationPersonOid = filters.EscalationPersonOid;
      this.filterOptions.page = 1;
      this.searchTrip();
   }
   //#endregion

   //#region Add/Edit/Delete Trip

   onToggleAddEditTrip = () => {
      this.setState({ ...this.state, openAddEditTripModal: !this.state.openAddEditTripModal, selectedTrip: null });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditTripModal: false }, () => this.searchTrip());
   }

   onSelectTrip = (selectedTrip: any, isForDelete: boolean = false, isCopy: boolean = false, cancelTrip: boolean = false,
      edit: boolean = false) => {
      if (isForDelete || cancelTrip) {
         this.setState({ ...this.state, selectedTrip: mapTripObject(selectedTrip), showConfirmDialog: true, copy: isCopy, cancelTrip: cancelTrip, edit: edit });
      }
      else
         this.setState({ ...this.state, selectedTrip: mapTripObject(selectedTrip), openAddEditTripModal: true, copy: isCopy, cancelTrip: cancelTrip, edit: edit });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedTrip?.Oid };
      TripService.deleteTrip(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedTrip: null, showConfirmDialog: false, cancelTrip: false }, () => this.searchTrip());
         }
         else {
            this.setState({ ...this.state, selectedTrip: null, showConfirmDialog: false, cancelTrip: false });
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }

   onConfirmCancel = () => {
      const params: IDeleteParams = { oid: this.state.selectedTrip?.Oid };
      TripService.cancelTrip(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedTrip: null, showConfirmDialog: false, cancelTrip: false }, () => this.searchTrip());
         }
         else {
            this.setState({ ...this.state, selectedTrip: null, showConfirmDialog: false, cancelTrip: false });
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }

   onCancelDelete = () => {
      this.setState({ ...this.state, selectedTrip: null, showConfirmDialog: false, cancelTrip: false });
   }

   //#endregion
   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedTrip: null });
   }

   openTripAuditLogModal = (selectedTrip: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedTrip: mapTripObject(selectedTrip) });
   }

   onClickViewTripDetails = (tripOid: string) => {
      const link: string = `${window.location.origin}/tripdetail/${tripOid}`;
      window.open(link, '_blank');
   }
   onClickCopyTripEditLink = (tripOid: string) => {
      const link: string = `${window.location.origin}/tripdetail/edit/${tripOid}`;
      copy(link);
      this.props.snackbarShowMessage("Edit trip link copied to clipboard.");
   }
   onClickMapView = (withFilters: boolean) => {
      const selectedFilter: any = {};
      if (withFilters) {
         selectedFilter.Status = this.filterOptions.Status;
         selectedFilter.StartDate = this.filterOptions.StartDate;
         selectedFilter.EndDate = this.filterOptions.EndDate;
         selectedFilter.OrganizerOid = this.filterOptions.OrganizerOid;
         selectedFilter.PersonOid = this.filterOptions.PersonOid;
      }
      this.setState({ ...this.state, showMapView: true, mapFilters: selectedFilter });
   }
   onCloseMapView = () => {
      this.setState({ ...this.state, showMapView: false });
   }
   //#endregion
   render() {
      const { totalRecords, openAddEditTripModal, selectedTrip, showConfirmDialog, trips, openAuditLogs, copy, cancelTrip, showMapView, mapFilters, edit } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle="Trips" onCreate={this.onToggleAddEditTrip} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["Trip:SearchSuggestions"] ? TripService.getSearchSuggestions : null}
            auditLogs={this.toggleAuditLogModal}
            actionPermission={{
               add: actionPermission["Trip:POST"],
               auditlog: actionPermission["Audit:Trip"],
            }}
            advanceFilter={<TripAdvanceFilter initialValues={{ Status: this.filterOptions.Status, StartDate: this.filterOptions.StartDate, EndDate: this.filterOptions.EndDate, OrganizerOid: this.filterOptions.OrganizerOid, PersonOid: this.filterOptions.PersonOid }} onAdvanceSearch={this.onAdvanceSearch} />}
            customAction={
               actionPermission["Trip:GetFilteredTripDetails"] ?
                  <PopupState variant="popover" popupId="demo-popup-menu">
                     {(popupState) => (
                        <>
                           <Button color={"success"} startIcon={<Map />} {...bindTrigger(popupState)} >
                              Map View
                           </Button>
                           <Menu {...bindMenu(popupState)}>
                              <MenuItem onClick={() => { popupState.close(); this.onClickMapView(true); }}>
                                 With Selected Filters
                              </MenuItem>
                              <MenuItem onClick={() => { popupState.close(); this.onClickMapView(false); }}>
                                 Without Filters
                              </MenuItem>
                           </Menu>
                        </>
                     )}
                  </PopupState>
                  : null
            }
         >
            {/* add edit user modal */}
            {openAddEditTripModal &&
               <AddEditTrip
                  open={openAddEditTripModal}
                  close={this.onToggleAddEditTrip}
                  successCallback={this.onSave}
                  tripOid={selectedTrip?.Oid}
                  copy={copy}
                  edit={edit}
                  allowSubmit={actionPermission["Trip:POST"]} />
            }
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getTripTableHeaders()} pagingInfoText="Trip" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {trips && trips?.map((trip: any, index: number) => {
                     return (
                        <TripRow
                           key={`row_${index}`}
                           trip={trip}
                           edit={edit}
                           onEdit={this.onSelectTrip}
                           onDelete={this.onSelectTrip}
                           onShowAuditLog={this.openTripAuditLogModal}
                           onViewTripDetail={this.onClickViewTripDetails}
                           actionPermission={actionPermission}
                           onClickCopyTripEditLink={this.onClickCopyTripEditLink}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={trips ? trips.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {trips && trips?.map((trip: any, index: number) => {
                        return (
                           <TripCard
                              key={`card_${index}`}
                              trip={trip}
                              onEdit={this.onSelectTrip}
                              onDelete={this.onSelectTrip}
                              onShowAuditLog={this.openTripAuditLogModal}
                              onViewTripDetail={this.onClickViewTripDetails}
                              actionPermission={actionPermission}
                              onClickCopyTripEditLink={this.onClickCopyTripEditLink}
                              edit={false}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete user */}
            {showConfirmDialog && !cancelTrip &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete}

               />
            }
            {showConfirmDialog && cancelTrip &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmCancel}
                  onCancel={this.onCancelDelete}
                  dialogueTitle="Are you sure you want to cancel trip?"
               />
            }
            {
               openAuditLogs && selectedTrip === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Trip} />
            }

            {
               openAuditLogs && selectedTrip !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Trip}
                  oid={selectedTrip?.Oid ?? ""}
                  changedEntityTitle={selectedTrip?.Title} />
            }
            {
               showMapView &&
               <TripMapViewModal
                  open={showMapView}
                  close={this.onCloseMapView}
                  mapFilters={mapFilters} />
            }

         </PageLayout>
      )
   }
}

export default withSnackbar(withParams(Trips));
