import { Controllers } from "../constants/constant";
import request from "../utils/request";
import { IUser, IDeleteParams, IChangePassword, IForgotPassword, IPasswordResetMail } from "../types/dataTypes";

class UserService {
   searchUsers = (params: any) => {
      return request({
         url: `${Controllers.User}/Search`,
         method: 'get',
         params
      });
   }
   saveUser = (data: IUser) => {
      return request({
         url: `${Controllers.User}`,
         method: 'post',
         data,
      })
   }
   deleteUser = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.User}`,
         method: 'delete',
         params
      })
   }
   getPortalRoleOptions = () => {
      return request({
         url: `${Controllers.User}/GetPortalRoleOptions`,
         method: 'get',
      })
   }
   changePassword = (data: IChangePassword) => {
      return request({
         url: `${Controllers.User}/ChangePassword`,
         method: 'post',
         data,
      })
   }
   forgotPassword = (data: IForgotPassword) => {
      return request({
         url: `${Controllers.User}/ForgotPassword`,
         method: 'post',
         data,
      })
   }
   sendPasswordResetLink = (data: IPasswordResetMail) => {
      return request({
         url: `${Controllers.User}/SendPasswordResetLink`,
         method: 'post',
         data,
      })
   }
   getPersonPortalRoleOptions = () => {
      return request({
         url: `${Controllers.User}/GetPersonPortalRoleOptions`,
         method: 'get',
      })
   }
   getSearchSuggestions = (serachText: string, monitoringStationOid: string, signal: any) => {
      return request({
         url: `${Controllers.User}/SearchSuggestions/${serachText}/${monitoringStationOid}`,
         method: 'get',
         signal
      })
   }

   logInAsUser = (userOid: string) => {
      return request({
         url: `Auth/LogInAsUser/${userOid}`,
         method: 'get',
      })
   }

   searchSupportUsers = (params: any) => {
      return request({
         url: `${Controllers.User}/SearchSupportUsers`,
         method: 'get',
         params
      });
   }
   getSearchUserSupportSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.User}/SearchUserSupportSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }
}

export default new UserService();
