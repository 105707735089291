import { Controllers } from "../constants/constant";
import { IClient, IDeleteParams, IEscalation, IResponseInstruction } from "../types/dataTypes";
import request from "../utils/request";

class ClientService {

   getClient = (params: any) => {
      return request({
         url: `${Controllers.Client}`,
         method: 'get',
         params
      })
   }

   searchClients = (params: any, country: string) => {
      return request({
         url: `${Controllers.Client}/Search/${country}`,
         method: 'get',
         params
      });
   }

   getCountrySelectOption = () => {
      return [
         { value: "Australia", label: "Australia" },
         { value: "New Zealand", label: "New Zealand" }
      ];
   }

   saveClient = (data: IClient) => {
      return request({
         url: `${Controllers.Client}`,
         method: 'post',
         data,
      })
   }

   deleteClient = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.Client}`,
         method: 'delete',
         params
      })
   }

   assignDefaultEscalation = (data: IEscalation, clientOid?: string) => {
      return request({
         url: `${Controllers.Client}/AssignDefaultEscalation/${clientOid}`,
         method: 'post',
         data,
      })
   }

   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.Client}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }

   export(params: any) {
      return request({
         url: `${Controllers.Client}/Export`,
         method: 'get',
         params,
         responseType: 'blob',
      })
   }

   responseInstruction = (clientOid?: string) => {
      return request({
         url: `${Controllers.Client}/ResponseInstruction/${clientOid}`,
         method: 'get',
         responseType: 'blob',
      })
   }

   responseEscalationContacts = (clientOid?: string) => {
      return request({
         url: `${Controllers.Client}/ResponseEscalationContacts/${clientOid}`,
         method: 'get',
         responseType: 'blob',
      })
   }

   setResponseInstruction = (data: IResponseInstruction, clientOid?: string) => {
      return request({
         url: `${Controllers.Client}/SetResponseInstruction/${clientOid}`,
         method: 'post',
         data,
      })
   }

   getPersonDeviceCountForClient = (clientOid: string) => {
      return request({
         url: `${Controllers.Client}/GetPersonDeviceCountForClient/${clientOid}`,
         method: 'get',
      })
   }

   getPlansSelectOptions = () => {
      return request({
         url: `${Controllers.Client}/GetPlansSelectOptions`,
         method: 'get',
      })
   }

   getClientsSelectOptionsForDeviceScreen = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Client}/GetClientsSelectOptionsForDeviceScreen`,
         method: 'get',
         params,
         signal
      })
   }

   getClientsSelectOptionsForPersonScreen = (params: any, signal: any) => {
      return request({
         url: `${Controllers.Client}/GetClientsSelectOptionsForPersonScreen`,
         method: 'get',
         params,
         signal
      })
   }
}

export default new ClientService();
