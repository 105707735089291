
const GetDeviceTypeFromModelAndType = (deviceType: string | undefined | null, deviceModel: string | undefined | null) => {
   if (deviceType === "Vehicle") {
      return [deviceType]
   }
   else {
      switch (deviceModel?.toLowerCase()) {
         case "egis":
         case "g7c":
         case "g7c ptt":
         case "p88l":
         case "p99l":
            return ["Cellular Device"];

         case "g7c gas":
         case "g7c gas ptt":
            return ["Cellular Device with Gas Detection"];

         case "safeguard app":
         case "halo on the go":
            return ["Mobile App"];

         case "loner app":
            return ["Mobile App", "Mobile App with DUO"];
         case "garmin 66i":
         case "inreach explorer":
         case "inreach mini":
         case "inreach mini 2":
         case "inreach se":
         case "inreach se+":
         case "montana 700i":
         case "inreach messenger":
         case "gpsmap 66i":
         case "gpsmap 67i":
            return ["Satellite Device (Garmin)"];

         case "g7x":
            return ["Satellite Device (G7x)"];

         case "bridge":
            return ["Satellite Device (G7x)", "Satellite Device (G7x) with Gas Detection"];

         case "g7x gas":
            return ["Satellite Device (G7x) with Gas Detection"];
         case "g7x ptt":
            return ["Satellite Device (G7X)"];

         case "eroad":
         case "tracer trak":
            return ["Cellular Device"];

         default:
            return [""];
      }
   }
}
export default GetDeviceTypeFromModelAndType;
