import React, { useRef, useState } from "react";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Common } from "../../constants/common";
import PropTypes from "prop-types";
import { Autocomplete, CircularProgress, InputBase, Paper } from "@mui/material";
import { StatusCode } from "../../constants/constant";
import { withStyles } from 'tss-react/mui';
import customInputStyle from "../../assets/jss/components/customInputStyle";
import Button from "./Button";
import { Clear, Search } from "@mui/icons-material";
function SearchBar({ ...props }) {
   const {
      id,
      value,
      inputProps,
      onSearchTextChange,
      setFieldValue,
      onSearch,
      sx
   } = props;
   const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
   const previousController = useRef() as any;
   const [processiong, setProcessing] = useState(false);
   const onSuggestionSelect = (value: any) => {
      setFieldValue("searchText", value.label);
      setFilteredSuggestions([]);
      onSearch(value.label);
   }
   const clearSearch = (setFieldValue: any) => {
      setFieldValue("searchText", "");
      setFilteredSuggestions([]);
      onSearch(null);
   }
   const onAutoSearch = async (value: string, setFieldValue: any) => {
      setFieldValue("searchText", value === "" ? null : value);
      if (previousController.current) {
         previousController.current.abort();
      }
      if (value.length >= Common.SearchAfterCount && onSearchTextChange) {
         const controller = new AbortController();
         const signal = controller.signal;
         previousController.current = controller;
         setProcessing(true);
         onSearchTextChange(value, signal).then((res: any) => {
            if (res?.status === StatusCode.Success && value.length >= Common.SearchAfterCount && res?.data) {
               setFilteredSuggestions(res?.data ?? []);
            }
            setProcessing(false);
         });
      }
      else {
         setFilteredSuggestions([]);
      }
   }
   return (
      <Paper sx={sx}>
         <Autocomplete
            id={id}
            sx={{ ml: 1, width: 1 }}
            {...inputProps}
            freeSolo
            options={filteredSuggestions.map((label: string, index: number) => ({ label, index }))}
            onInputChange={(event, newInputValue) => {
               if (event !== null && event.type !== "click" && event.type !== "blur")
                  onAutoSearch(newInputValue, setFieldValue);
            }}
            onChange={(event: any, newValue: any) => {
               onSuggestionSelect(newValue);
            }}
            value={value}
            renderOption={(props, option: any, { inputValue }) => {
               const matches = match(option.label, inputValue);
               const parts = parse(option.label, matches);
               return (
                  <li {...props}>
                     <div>
                        {parts.map((part: any, index: number) => (
                           <span
                              key={index}
                              style={{
                                 fontWeight: part.highlight ? 700 : 400,
                              }}
                           >
                              {part.text}
                           </span>
                        ))}
                     </div>
                  </li>
               );
            }}
            disableClearable={true}
            renderInput={(params) => {
               const { InputLabelProps, InputProps, ...rest } = params;
               return <InputBase
                  placeholder="Search..."
                  autoComplete={"off"}
                  {...params.InputProps}
                  {...rest}
                  endAdornment={(
                     <React.Fragment>
                        {processiong ? (
                           <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {!Common.stringNullOrEmpty(value) &&
                           <Button color="transparent" justIcon={true} onClick={() => clearSearch(setFieldValue)}><Clear /></Button>
                        }
                        <Button onClick={() => setFilteredSuggestions([])} style={{ marginRight: "1em" }} type="submit" color="transparent" justIcon={true}><Search /></Button>
                     </React.Fragment>
                  )}
               />
            }}
            loadingText={"Type text to search..."}
         />
      </Paper >
   )
}
SearchBar.propTypes = {
   id: PropTypes.string,
   inputProps: PropTypes.object,
   value: PropTypes.any,
   onSearchTextChange: PropTypes.any,
   setFieldValue: PropTypes.any,
   onSearch: PropTypes.any,
   sx: PropTypes.any,
};
export default withStyles(SearchBar, customInputStyle);
