import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import auditLogsStyle from '../../../assets/jss/pages/auditLogsStyle';
import { IAuditLogModalProps } from '../../../types/propType';
import Button from '../../Common/Button';
import EntityAuditLogsContents from './EntityAuditLogsContents';

const EntityAuditLogsModal: React.FC<IAuditLogModalProps> = ({ classes, open, close, entityName, oid, changedEntityTitle, logType }) => {
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <DialogTitle>{logType ? logType : "Audit"} logs of <strong>{changedEntityTitle}</strong></ DialogTitle>
         <DialogContent dividers>
            <EntityAuditLogsContents entityName={entityName} oid={oid} />
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Close</Button>
         </DialogActions>
      </Dialog>
   );
};

export default withStyles(EntityAuditLogsModal, auditLogsStyle);


