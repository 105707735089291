import React from 'react';
import accessDeniedIcon from "../assets/img/AccessDenied.png";
import { withStyles } from 'tss-react/mui';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Button from '../components/Common/Button';
import unauthorizedPageStyle from '../assets/jss/pages/unauthorizedPageStyle';
import NavigationLink from '../components/Common/NavigationLink';
import CommonAppbar from '../components/Common/CommonAppBar';
const SessionExpired: React.FC<any> = ({ classes }) => {
   return (
      <>
         <CommonAppbar classes={classes} isAzureLogin={false} />
         <div className={classes.pageContent}>
            <Card sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: 650, p: 4 }}>
               <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="404 Not Found" />
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        Session Expired
                     </Typography>
                     <Typography variant="subtitle1" color="text.secondary" component="div">
                        Your previous session is expired.
                     </Typography>
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        Please login again to continue.
                     </Typography>
                  </CardContent>
                  <CardActions>
                     <NavigationLink to={"/login"}>
                        <Button color="primary">Login again</Button>
                     </NavigationLink>
                  </CardActions>
               </Box>
            </Card>
            <Card sx={{ display: { xs: 'flex', md: 'none', flexDirection: 'column' }, maxWidth: 650, p: 4 }}>
               <CardMedia component="img" sx={{ width: 0.3 }} image={accessDeniedIcon} alt="404 Not Found" />
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        Session Expired
                     </Typography>
                     <Typography variant="subtitle1" color="text.secondary" component="div">
                        Your previous session is expired.
                     </Typography>
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        Please login again to continue.
                     </Typography>
                  </CardContent>
                  <CardActions>
                     <NavigationLink to={"/login"}>
                        <Button color="primary">Login again</Button>
                     </NavigationLink>
                  </CardActions>
               </Box>
            </Card>
         </div>
      </>
   );
};

export default withStyles(SessionExpired, unauthorizedPageStyle);
