import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { mapApiDetails, addInfoWindowForHazardLocation, addInfoWindow, useDeepCompareEffectForMaps } from "./../../Common/MapCommon";
interface IMapLocationPicker {
   trips: any[];
   hazardLocations: any[];
   selectedTripOid: string | null | undefined;
}
const TripMap: React.FC<IMapLocationPicker> = ({ trips, hazardLocations, selectedTripOid }) => {
   return (
      <div style={{ display: "flex", height: "500px" }}>
         <Wrapper {...mapApiDetails} >
            <Map
               zoom={10}
               style={{ flexGrow: "1", height: "100%" }}
               trips={trips}
               hazardLocations={hazardLocations}
               selectedTripOid={selectedTripOid}
            />
         </Wrapper>
      </div>
   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: (map: google.maps.Map) => void;
   children?: React.ReactNode;
   trips: any[];
   hazardLocations: any[];
   selectedTripOid: string | null | undefined;
}

const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   trips,
   hazardLocations,
   selectedTripOid,
   ...options

}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   const [mapPolyline, setMapPolylines] = React.useState<google.maps.Polyline[]>([]);
   const [mapMarkers, setMapMarkers] = React.useState<google.maps.Marker[]>([]);
   const [mapPolygons, setMapPolygons] = React.useState<google.maps.Polyline[]>([]);
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
      }
      if (mapPolyline.length > 0) {
         mapPolyline.forEach((poly) => {
            poly.setMap(null);
         });
      }
      if (mapPolygons.length > 0) {
         mapPolygons.forEach((poly) => {
            poly.setMap(null);
         });
      }
      if (mapMarkers.length > 0) {
         mapMarkers.forEach((marker) => {
            marker.setMap(null);
         });
      }
      if (map) {
         const bounds = new google.maps.LatLngBounds();
         const polyline: google.maps.Polyline[] = [];
         const polygons: google.maps.Polygon[] = [];
         const newMarkers: google.maps.Marker[] = [];
         if (trips && trips.length > 0) {
            trips.forEach((trip: any) => {
               if (trip.oid !== selectedTripOid) {
                  const polyLinepath: google.maps.LatLng[] = [];
                  const colorToBeUse = !selectedTripOid ? "#FF0000" : trip.oid === selectedTripOid ? "#FF0000" : "#808080";
                  trip.tripLocations.forEach((location: any, index: number) => {
                     if (location.geometryType === "Polygon" && location.coOrdinates) {
                        let polygonPath: google.maps.LatLng[] = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
                        const polygon = new google.maps.Polygon({
                           paths: polygonPath,
                           geodesic: true,
                           strokeColor: colorToBeUse,
                           strokeOpacity: 0.8,
                           strokeWeight: 3,
                           map: map,
                        });
                        const polygonBounds = new google.maps.LatLngBounds();
                        for (var i = 0; i < polygonPath.length; i++) {
                           polygonBounds.extend(polygonPath[i]);
                        }
                        const polygonCenter = polygonBounds.getCenter();
                        polyLinepath.push(polygonCenter);
                        addInfoWindow(polygon, map, trip);
                        polygons.push(polygon);
                        if (trip.oid === selectedTripOid) {
                           const mapLabel2 = new google.maps.Marker({
                              position: polygonCenter,
                              map: map,
                              label: {
                                 text: `${index + 1}`,
                                 color: 'white',
                                 fontSize: "12px"
                              },
                           });
                           addInfoWindow(mapLabel2, map, trip);
                           newMarkers.push(mapLabel2);
                        }
                     }
                     else if (location.geometryType === "Point" && location.coOrdinates) {
                        let position: google.maps.LatLng = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) })[0];
                        let newMarker = new google.maps.Marker({
                           position: position,
                           map: map,
                           label: trip.oid === selectedTripOid ? {
                              text: `${index + 1}`,
                              color: 'white',
                              fontSize: "12px",

                           } : null,
                        });
                        polyLinepath.push(position);
                        addInfoWindow(newMarker, map, trip);
                        newMarkers.push(newMarker);
                     }

                  })
                  if (!selectedTripOid) {
                     const polygon = new google.maps.Polyline({
                        path: polyLinepath,
                        geodesic: true,
                        strokeColor: colorToBeUse,
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        map: map,
                     });
                     addInfoWindow(polygon, map, trip);
                     polyline.push(polygon);
                  }
               }
            });
            trips.filter(x => (x.oid === selectedTripOid)).forEach((trip: any) => {
               const polyLinepath: google.maps.LatLng[] = [];
               const colorToBeUse = !selectedTripOid ? "#FF0000" : trip.oid === selectedTripOid ? "#FF0000" : "#808080";
               trip.tripLocations.forEach((location: any, index: number) => {
                  if (location.geometryType === "Polygon" && location.coOrdinates) {
                     let polygonPath: google.maps.LatLng[] = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
                     const polygon = new google.maps.Polygon({
                        paths: polygonPath,
                        geodesic: true,
                        strokeColor: colorToBeUse,
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        map: map,
                     });
                     const polygonBounds = new google.maps.LatLngBounds();
                     for (var i = 0; i < polygonPath.length; i++) {
                        polygonBounds.extend(polygonPath[i]);
                     }
                     const polygonCenter = polygonBounds.getCenter();
                     polyLinepath.push(polygonCenter);
                     addInfoWindow(polygon, map, trip);
                     polygons.push(polygon);
                     if (trip.oid === selectedTripOid) {
                        const mapLabel2 = new google.maps.Marker({
                           position: polygonCenter,
                           map: map,
                           label: {
                              text: `${index + 1}`,
                              color: 'white',
                              fontSize: "12px"
                           },
                        });
                        addInfoWindow(mapLabel2, map, trip);
                        newMarkers.push(mapLabel2);
                     }
                  }
                  else if (location.geometryType === "Point" && location.coOrdinates) {
                     let position: google.maps.LatLng = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) })[0];
                     let newMarker = new google.maps.Marker({
                        position: position,
                        map: map,
                        label: trip.oid === selectedTripOid ? {
                           text: `${index + 1}`,
                           color: 'white',
                           fontSize: "12px",

                        } : null,
                     });
                     polyLinepath.push(position);
                     addInfoWindow(newMarker, map, trip);
                     newMarkers.push(newMarker);
                  }

               })
               const polygon = new google.maps.Polyline({
                  path: polyLinepath,
                  geodesic: true,
                  strokeColor: colorToBeUse,
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  map: map,
               });
               addInfoWindow(polygon, map, trip);
               polyline.push(polygon);
            });
         }
         if (hazardLocations && hazardLocations.length > 0) {
            hazardLocations.forEach((hazardLocation: any) => {
               hazardLocation.hazardLocationGeometry.forEach((location: any) => {
                  let polygonPath: google.maps.LatLng[] = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
                  const polygon = new google.maps.Polygon({
                     paths: polygonPath,
                     geodesic: true,
                     strokeColor: "#FFFF00",
                     strokeOpacity: 0.8,
                     strokeWeight: 3,
                     fillColor: "#FFFC00",
                     fillOpacity: 0.4,
                     map: map,
                  });
                  addInfoWindowForHazardLocation(polygon, map, hazardLocation);
                  polygons.push(polygon);

               })
            });
         }
         newMarkers.forEach((marker: any) => {
            bounds.extend(marker.position);
         });
         for (let j = 0; j < polyline.length; j++) {
            for (let i = 0; i < polyline[j].getPath().getLength(); i++) {
               bounds.extend(polyline[j].getPath().getAt(i));
            }
         }
         for (let j = 0; j < polygons.length; j++) {
            for (let i = 0; i < polygons[j].getPath().getLength(); i++) {
               bounds.extend(polygons[j].getPath().getAt(i));
            }
         }
         setMapPolygons(polygons);
         setMapPolylines(polyline);
         setMapMarkers(newMarkers);
         if (selectedTripOid) {
            const boundsSelected = new google.maps.LatLngBounds();
            if (trips && trips.length > 0) {
               trips.filter((x: any) => x.oid === selectedTripOid).forEach((trip: any) => {
                  trip.tripLocations.forEach((location: any) => {
                     if (location.geometryType === "Polygon" && location.coOrdinates) {
                        let polygonPath: google.maps.LatLng[] = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
                        polygonPath.forEach(element => {
                           boundsSelected.extend(element);
                        });
                     }
                     else if (location.geometryType === "Point" && location.coOrdinates) {
                        let position: google.maps.LatLng = JSON.parse(location.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) })[0];
                        boundsSelected.extend(position);
                     }

                  })
               });
            }
            setMapBound(boundsSelected);
         }
         else {
            setMapBound(bounds);
         }
      }
   }, [ref, map, trips, hazardLocations, selectedTripOid]); // eslint-disable-line react-hooks/exhaustive-deps
   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
      }
   }, [map, options, mapBound]);

   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, onClick, onIdle, mapBound]);

   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
export default TripMap;
