import React from "react";
import { withStyles } from 'tss-react/mui';
import { Phone, Pending, Aod, History, AccessTime, AccessTimeFilled, EmojiTransportation, Description, SafetyCheck, Group, Person, LocationOn, MyLocation, Map, Security, Shield, WrongLocation, Edit, Warning, Visibility } from "@mui/icons-material";
import pagesStyle from '../../assets/jss/pages/tripDetailStyle';
import { ITripDetailPageState } from "../../types/stateType";
import { ITripDetailPageProps } from "../../types/propType";
import { Grid, Typography, FormHelperText, List, ListItem, ListItemText, ListItemIcon, Box, Tooltip, IconButton, Badge } from "@mui/material";
import { Controllers, StatusCode } from "../../constants/constant";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import Button from "../../components/Common/Button";
import TripService from "../../services/trip.service";
import TripMap from "../../components/pages/Trips/TripMap";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import AddEditTrip from "../../components/pages/Trips/AddEditTrip";
import { withSnackbar } from "../../components/Common/Snackbar";
import { IDeleteParams } from "../../types/dataTypes";
import HazardDetailModal from "../../components/pages/Trips/HazardDetailModal";
class TripDetail extends React.Component<ITripDetailPageProps, ITripDetailPageState> {
   state: ITripDetailPageState = {
      tripDetails: null,
      error: null,
      hazardLocations: [],
      locationOidHazardLocationGeometryOidMap: [],
      openAddEditTripModal: false,
      showConfirmDialog: false,
      openAuditLogs: false,
      copy: false,
      cancelTrip: false,
      showHazardDetails: false,
      selectedHazard: null,
      selectedLocation: null,
      showHazardDetailModelTitle: "",
      edit: false
   };
   componentDidMount() {
      const isEdit = window.location.pathname.toLowerCase().indexOf("/edit/") > -1;
      this.getTripDetails(isEdit);
   }
   getTripDetails = (isEdit: boolean = false) => {
      const { tripOid } = this.props.params;
      TripService.getTripDetails(tripOid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            this.setState({ ...this.state, tripDetails: res?.data?.tripDetails, hazardLocations: res?.data?.hazardLocations ?? [], locationOidHazardLocationGeometryOidMap: res?.data?.locationOidHazardLocationGeometryOidMap ?? [], error: null }
               , () => {
                  if (isEdit) {
                     this.onSelectTrip();
                  }
               });
         }
         else {
            this.setState({ ...this.state, tripDetails: null, hazardLocations: [], error: res?.data?.message });
         }
      });
   }
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs });
   }
   openTripAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs });
   }
   closeEditTrip = () => {
      this.removeEditURL();
      this.setState({ ...this.state, openAddEditTripModal: !this.state.openAddEditTripModal });
   }
   removeEditURL = () => {
      const url = window.location.href;
      if (url.toLowerCase().indexOf("/edit/") > -1) {
         window.history.replaceState(this.state, document.title, url.toLowerCase().replace("/edit/", "/"));
      }
   }
   onSave = () => {
      this.removeEditURL();
      this.setState({ ...this.state, openAddEditTripModal: false }, () => this.getTripDetails());
   }

   onSelectTrip = (isForDelete: boolean = false, isCopy: boolean = false, cancelTrip: boolean = false, edit: boolean = false) => {
      if (isForDelete || cancelTrip) {
         this.setState({ ...this.state, showConfirmDialog: true, copy: isCopy, cancelTrip: cancelTrip, edit: edit });
      }
      else {
         if (!isCopy) {
            const url = window.location.href;
            if (url.toLowerCase().indexOf("/edit/") === -1) {
               window.history.replaceState(this.state, document.title, url.toLowerCase().replace("/tripdetail/", "/tripdetail/edit/"));
            }
         }
         this.setState({ ...this.state, openAddEditTripModal: true, copy: isCopy, cancelTrip: cancelTrip, edit: edit });
      }
   }
   onConfirmCancel = () => {
      const params: IDeleteParams = { oid: this.state.tripDetails?.oid };
      TripService.cancelTrip(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, showConfirmDialog: false, cancelTrip: false }, () => this.getTripDetails());
         }
         else {
            this.setState({ ...this.state, showConfirmDialog: false, cancelTrip: false });
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }

   onCancelTripCancelDialogue = () => {
      this.setState({ ...this.state, showConfirmDialog: false, cancelTrip: false });
   }
   getLocationHazardCount = (locationOid: string) => {
      const hazardIds = this.state.locationOidHazardLocationGeometryOidMap.filter((x: any) => x.locationOid === locationOid).map((hazardLocationMap: any) => hazardLocationMap.hazardLocationGeometryOid);
      return this.state.hazardLocations.filter((x: any) => x.hazardLocationGeometry.some((y: any) => hazardIds.indexOf(y.oid) > -1)).length;
   }
   openHazardDetails = (hazardId: string | null, locationOid: string | null, title: string) => {
      this.setState({ ...this.state, showHazardDetails: true, selectedHazard: hazardId, selectedLocation: locationOid, showHazardDetailModelTitle: title });
   }
   closeHazardDetails = () => {
      this.setState({ ...this.state, showHazardDetails: false, selectedHazard: null, selectedLocation: null, showHazardDetailModelTitle: "" });
   }
   getHazardDetailsByTypeOrLocation = () => {
      const { selectedHazard, selectedLocation, hazardLocations, locationOidHazardLocationGeometryOidMap } = this.state;
      if (selectedHazard)
         return hazardLocations.filter((x: any) => x.oid === selectedHazard);
      if (selectedLocation) {
         const hazardIds = locationOidHazardLocationGeometryOidMap.filter((x: any) => x.locationOid === selectedLocation).map((hazardLocationMap: any) => hazardLocationMap.hazardLocationGeometryOid);
         return hazardLocations.filter((x: any) => x.hazardLocationGeometry.some((y: any) => hazardIds.indexOf(y.oid) > -1))
      }
   }
   render() {
      const { classes, actionPermission } = this.props;
      const { tripDetails, error, hazardLocations, openAuditLogs, showConfirmDialog, cancelTrip, openAddEditTripModal, copy, showHazardDetails, selectedHazard, selectedLocation, showHazardDetailModelTitle, edit } = this.state;
      return (
         <>
            {tripDetails &&
               <>
                  <div className={classes.pageBody} data-testid={`tripdetail_body`}>
                     <Grid container spacing={2}>
                        <Grid style={{ display: "flex" }} item xs={12} sm={12} md={12}>
                           <Typography style={{ paddingLeft: 0, float: "left", alignSelf: "center" }} variant="h5" component="h4" className={classes.pageTitle}>
                              {tripDetails.title}
                           </Typography>
                           <Box style={{ marginLeft: "5em" }}>
                              {(actionPermission["Trip:POST"]) &&
                                 <Button color="info" startIcon={<Edit />} onClick={() => this.onSelectTrip(false, false, false, /*edit*/true)}>Edit</Button>
                              }
                              {(actionPermission["Trip:Cancel"]) &&
                                 <Button disabled={tripDetails.status === "Cancelled"} color="danger" startIcon={<WrongLocation />} onClick={() => { this.onSelectTrip(false, false, true) }}>Cancel Trip</Button>
                              }
                              {(actionPermission["Audit:Trip"]) &&
                                 <Button color="success" startIcon={<History />} onClick={() => this.openTripAuditLogModal()}>Audit history</Button>
                              }
                           </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <Pending className={classes.beforeTextIcon} /> Status: <b>{tripDetails.status}</b>
                           </Typography>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <AccessTime className={classes.beforeTextIcon} /> Start Time: {Common.getFormattedDateTime(tripDetails.startTime)}
                           </Typography>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <AccessTimeFilled className={classes.beforeTextIcon} /> End Time: {Common.getFormattedDateTime(tripDetails.endTime)}
                           </Typography>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <Aod className={classes.beforeTextIcon} /> Device: {tripDetails.deviceId}
                           </Typography>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <Phone className={classes.beforeTextIcon} /> Cell Phone Number: {tripDetails.cellPhoneNumber}
                           </Typography>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <EmojiTransportation className={classes.beforeTextIcon} /> Vehicle Registration: {tripDetails.vehicleRegistration}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <Group className={classes.beforeTextIcon} /> <b>Trip Person(s)</b>
                           </Typography>
                           <List>
                              {tripDetails.tripPersons && tripDetails.tripPersons.map((person: any, index: number) => {
                                 return (
                                    <ListItem key={`person_${index}`} disablePadding>
                                       <ListItemIcon>
                                          <Person />
                                       </ListItemIcon>
                                       <ListItemText primary={person.label} />
                                    </ListItem>
                                 )
                              })
                              }

                           </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <Description className={classes.beforeTextIcon} /> <b>Description of the work being undertaken</b>
                           </Typography>
                           <div className="richText" style={{ maxHeight: "500px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: tripDetails.description }}></div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <SafetyCheck className={classes.beforeTextIcon} /> <b>Assessment Of Hazards And Safety</b>
                           </Typography>
                           <div className="richText" style={{ maxHeight: "500px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: tripDetails.assessmentOfHazardsAndSafety }}></div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={12}>
                                 <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                                    <LocationOn className={classes.beforeTextIcon} /> <b>Trip Location(s)</b>
                                 </Typography>
                                 <List>
                                    {tripDetails.tripLocations && tripDetails.tripLocations.map((location: any, index: number) => {
                                       const hazardCountForLocation = this.getLocationHazardCount(location.value);
                                       return (
                                          <ListItem key={`location_${index}`} disablePadding>
                                             <ListItemIcon>
                                                <MyLocation />
                                             </ListItemIcon>
                                             <ListItemText primary={<>
                                                {location.label}
                                                {hazardCountForLocation > 0 &&
                                                   <Tooltip title="No Of Hazardous Location">
                                                      <IconButton edge="end" aria-label="warning" onClick={() => { this.openHazardDetails(null, location.value, `trip location : ${location.label}`) }} >
                                                         <Badge badgeContent={hazardCountForLocation} color="error" >
                                                            <Warning color="primary" />
                                                         </Badge>
                                                      </IconButton>
                                                   </Tooltip>
                                                }
                                             </>
                                             } />
                                          </ListItem>
                                       )
                                    })
                                    }
                                 </List>
                              </Grid>
                              <Grid item xs={12} sm={6} md={12}>
                                 <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                                    <Security className={classes.beforeTextIcon} /> <b>Hazard Location(s)</b>
                                 </Typography>
                                 <List>
                                    {hazardLocations && hazardLocations.map((hazardLocation: any, index: number) => {
                                       return (
                                          <ListItem key={`hazardLocation_${index}`} disablePadding>
                                             <ListItemIcon>
                                                <Shield />
                                             </ListItemIcon>
                                             <ListItemText primary={
                                                <>
                                                   {hazardLocation.type}
                                                   <Tooltip title="View more details">
                                                      <IconButton edge="end" aria-label="warning" onClick={() => { this.openHazardDetails(hazardLocation.oid, null, `hazard location : ${hazardLocation.warning}`) }} >
                                                         <Visibility color="info" />
                                                      </IconButton>
                                                   </Tooltip>
                                                </>
                                             }
                                                secondary={`Security Level: ${hazardLocation.securityLevel}\r\nWarning: ${hazardLocation.warning}`} />
                                          </ListItem>
                                       )
                                    })
                                    }
                                 </List>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.detailText}>
                              <Map className={classes.beforeTextIcon} /> <b>Locations on map</b>
                           </Typography>
                           <TripMap
                              trips={[tripDetails]}
                              hazardLocations={hazardLocations}
                              selectedTripOid={tripDetails?.oid}
                           />
                        </Grid>
                     </Grid>
                  </div>
               </>
            }
            {
               error &&
               <FormHelperText id="error-login" error>{error}</FormHelperText>
            }
            {
               openAuditLogs && tripDetails !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Trip}
                  oid={tripDetails?.oid ?? ""}
                  changedEntityTitle={tripDetails?.title} />
            }
            {showConfirmDialog && cancelTrip &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmCancel}
                  onCancel={this.onCancelTripCancelDialogue}
                  dialogueTitle="Are you sure you want to cancel trip?"
               />
            }
            {openAddEditTripModal &&
               <AddEditTrip
                  open={openAddEditTripModal}
                  close={this.closeEditTrip}
                  successCallback={this.onSave}
                  tripOid={tripDetails?.oid}
                  copy={copy}
                  edit={edit}
                  allowSubmit={actionPermission["Trip:POST"]} />
            }
            {
               showHazardDetails && (selectedHazard || selectedLocation) &&
               <HazardDetailModal
                  open={showHazardDetails}
                  close={this.closeHazardDetails}
                  hazardDetails={this.getHazardDetailsByTypeOrLocation()}
                  title={showHazardDetailModelTitle}
               />
            }
         </>
      )
   }
}

export default withSnackbar(withParams(withStyles(TripDetail, pagesStyle)));
