import { FormHelperText } from '@mui/material';
import React from 'react';
import { dangerColor } from '../../assets/jss/appStyle';

const ValidationSummary: React.FC<any> = ({ errors }) => {
   if (typeof errors === "string")
      return <FormHelperText id={`error`} error>{errors}</FormHelperText>
   const keys = (errors && errors !== null) ? Object.keys(errors) : [];

   return (
      <ul style={{ color: dangerColor[0] }}>
         {
            keys?.map((key: any) => {
               return (
                  <li>{errors[key][0]}</li>
               );
            })
         }
      </ul>
   );
};

export default ValidationSummary;
