import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormHelperText, TextField, FormControl } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { Common } from "../../constants/common";
function CustomInput({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      customErrorMessage,
      rows,
      InputProps,
      autoFocus,
      type,
      defaultValue,
      disabled
   } = props;

   const [field, meta] = useField(inputProps);
   return (
      <FormControl {...formControlProps} fullWidth className={formControlProps?.className + " " + classes.formControl}>
         <TextField
            id={id}
            variant="standard"
            {...field}
            {...inputProps}
            InputProps={InputProps}
            label={labelText}
            autoComplete={"off"}
            error={(meta.touched && meta.error && meta.error.length > 0) || !Common.stringNullOrEmpty(customErrorMessage)}
            multiline={rows > 1}
            minRows={rows}
            autoFocus={autoFocus ? true : false}
            type={type}
            defaultValue={defaultValue}
            disabled={disabled} // Here is where you use the `disabled` prop
         />
         {meta.touched && meta.error && meta.error.length > 0 &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
         {
            !Common.stringNullOrEmpty(customErrorMessage) &&
            <FormHelperText id={`error-${inputProps.name}`} error>{customErrorMessage}</FormHelperText>
         }
      </FormControl>
   );
}

CustomInput.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   rows: PropTypes.number,
   type: PropTypes.string,
   defaultValue: PropTypes.string,
   disabled: PropTypes.bool,  // Declare the new prop here
};
export default withStyles(CustomInput, customInputStyle);
