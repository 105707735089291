import { Controllers } from "../constants/constant";
import request from "../utils/request";

class ClientImageService {

   getClientImages = (clientOid: string) => {
      return request({
         url: `${Controllers.ClientImage}/GetImageList/${clientOid}`,
         method: 'get',
      })
   }

   saveImage(data: FormData) {
      return request({
         url: `${Controllers.ClientImage}/SaveImage`,
         method: 'post',
         data,
         headers:
         {
            'Content-Type': 'multipart/form-data'
         }
      });
   }

   deleteImage(clientOid: string, fileName: string) {
      let params: any = {
         clientOid,
         fileName
      };
      return request({
         url: `${Controllers.ClientImage}/DeleteImage`,
         method: 'delete',
         params
      });
   }
}

export default new ClientImageService();
