import React, { useEffect } from 'react';
import maintenanceIcon from "../assets/img/icon-maintenance.png";
import { withStyles } from 'tss-react/mui';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import unauthorizedPageStyle from '../assets/jss/pages/unauthorizedPageStyle';
import CommonAppbar from '../components/Common/CommonAppBar';
import healthService from '../services/health.service';
import { StatusCode } from '../constants/constant';
import { useNavigate } from "react-router-dom";
import environment from '../environment';
const UnderMaintenance: React.FC<any> = ({ classes }) => {
   const isNetworkIssue = window.location.pathname === "/networkissue";
   const apiURL = environment.apiUrl.replace(/^(.+?)\/*?$/, "$1");
   const navigate = useNavigate();
   useEffect(() => {
      const intervalId = setInterval(() => {
         healthService.checkServerStatus().then((res: any) => {
            if (res?.status === StatusCode.Success) {
               navigate(-1);
            }
         });
      }, 5000);
      return () => clearInterval(intervalId);
   }, [navigate]);
   const content = () => {
      return (
         <>
            <CardMedia component="img" style={{ width: "200px" }} image={maintenanceIcon} alt="maintenance" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
               {!isNetworkIssue ?
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        System is Under Maintenance
                     </Typography>
                     <br />
                     <Typography variant="subtitle1" color="text.secondary" component="div">
                        We will back soon.
                     </Typography>
                     <br />
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        Response instruction for device is still availabe.
                     </Typography>
                  </CardContent>
                  :
                  <CardContent sx={{ flex: '1 0 auto' }}>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        Network Issue
                     </Typography>
                     <Typography variant="subtitle1" component="div">
                        Please check below things.
                     </Typography>
                     <br />
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        1. Check you are connected to internet.
                     </Typography>
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        2. Check our api endpoint "<strong>{apiURL}</strong>" is accessible from you machine. If you are using office / organization network then contact your network team to whitelist "<strong>{apiURL}</strong>" URL.
                     </Typography>
                     <Typography variant="subtitle2" color="text.secondary" component="div">
                        3. Contact Guardian Angel Support team.<a href="mailto:support@guardianangelsafety.co?subject = Network Issue while accessing portal">
                           click here for contact
                        </a>
                     </Typography>
                  </CardContent>
               }
            </Box>
         </>
      )
   }
   return (
      <>
         <CommonAppbar classes={classes} isAzureLogin={false} />
         <div className={classes.pageContent}>
            <Card sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: 750, p: 4 }}>
               {content()}
            </Card>
            <Card sx={{ display: { xs: 'flex', md: 'none', flexDirection: 'column' }, maxWidth: 650, p: 4 }}>
               {content()}
            </Card>
         </div>
      </>
   );
};

export default withStyles(UnderMaintenance, unauthorizedPageStyle);
