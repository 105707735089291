import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '../../Common/Button';
import { IModalProps } from '../../../types/propType';
import { StatusCode } from '../../../constants/constant';
import locationService from '../../../services/location.service';
import { Wrapper } from '@googlemaps/react-wrapper';
import { mapApiDetails, mapInitialMapBound, useDeepCompareEffectForMaps } from "./../../Common/MapCommon";
import { AutoComplete, GoogleMapSearchLocation } from '../../Common/GoogleMapSearchLocation';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import CustomClusterRenderer from '../../Common/CustomClusterRenderer';
interface ILocationMapViewModal extends IModalProps {
   searchText?: string;
   onEditLocation: any;
   numberForMapRefresh: number;
}
const LocationMapViewModal: React.FC<ILocationMapViewModal> = ({ open, close, searchText, onEditLocation, numberForMapRefresh }) => {
   const [locations, setLocations] = useState<any[]>([]);
   const getLocations = (values?: string) => {
      locationService.getLocationsDetails({ searchText: values }).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const allLocations: any[] = (res?.data ?? []).map((x: any) => {
               const latLongs = JSON.parse(x.coOrdinates).map((latLng: any) => { return new google.maps.LatLng(latLng[1], latLng[0]) });
               return {
                  ...x,
                  latLongs: latLongs
               } as any;
            })
            setLocations(allLocations);
         }
      })
   }
   useEffect(() => {
      getLocations(searchText);
   }, [searchText, numberForMapRefresh])
   return (
      <Dialog open={open} style={{ zIndex: 1 }} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle>
            <span>Location map view</span>
         </ DialogTitle>
         <DialogContent dividers>
            <GoogleMapSearchLocation inputId="locationSearch" />
            <br />
            <Wrapper {...mapApiDetails}>
               <MemoizedMap
                  zoom={10}
                  style={{ flexGrow: "1", height: "600px", width: "100%" }}
                  locations={locations}
                  onEditLocation={onEditLocation}
                  numberForMapRefresh={numberForMapRefresh}
               />

            </Wrapper>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Close</Button>
         </DialogActions>
      </Dialog >
   );
};
interface MapProps extends google.maps.MapOptions {
   style: { [key: string]: string };
   onClick?: (e: google.maps.MapMouseEvent) => void;
   onIdle?: (map: google.maps.Map) => void;
   children?: React.ReactNode;
   locations: any[];
   onEditLocation: any;
   numberForMapRefresh: number;
}
const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   locations,
   onEditLocation,
   numberForMapRefresh,
   ...options
}) => {
   const ref = React.useRef<HTMLDivElement>(null);
   const [map, setMap] = React.useState<google.maps.Map>();
   const [mapBound, setMapBound] = React.useState<google.maps.LatLngBounds>(new google.maps.LatLngBounds());
   const [markerCluster, setMarkerCluster] = React.useState<MarkerClusterer>(new MarkerClusterer({ renderer: new CustomClusterRenderer() }));
   const [mapPolygons, setMapPolygons] = React.useState<google.maps.Polygon[]>([]);
   // eslint-disable-next-line react-hooks/exhaustive-deps 
   const DrawMarkers = () => {
      if (!map)
         return;
      const currentMapBounds = map.getBounds();
      if (markerCluster) {
         markerCluster.setMap(null);
         markerCluster.clearMarkers();
      }
      if (mapPolygons.length > 0) {
         mapPolygons.forEach((poly) => {
            poly.setMap(null);
         });
      }
      const newMarkers: google.maps.Marker[] = [];
      const polygons: google.maps.Polygon[] = [];
      const bounds = new google.maps.LatLngBounds();
      let locationToBeRender: any[] = [];
      if (map && (map.getZoom() ?? 0) > 11) {
         locationToBeRender = locations.filter((loc) => !currentMapBounds || currentMapBounds.contains(loc.latLongs[0]));
      }
      else if (locations.length <= 30) {
         locationToBeRender = locations;
      }
      locationToBeRender.forEach((location: any) => {
         if (location.geometryType === "Polygon" && location.coOrdinates) {
            let polygonPath: google.maps.LatLng[] = location.latLongs;
            const polygon = new google.maps.Polygon({
               paths: polygonPath,
               geodesic: true,
               strokeColor: "#FF0000",
               strokeOpacity: 0.8,
               strokeWeight: 3,
               fillOpacity: 0.3,
               map: map,
            });
            let polygonBounds = new google.maps.LatLngBounds();
            for (var i = 0; i < polygonPath.length; i++) {
               polygonBounds.extend(polygonPath[i]);
            }
            const centerLatlong = polygonBounds.getCenter();
            bounds.extend(centerLatlong);
            const newMarker = new google.maps.Marker({
               position: centerLatlong,
            });
            addInfoWindow(newMarker, map, location, onEditLocation);
            newMarkers.push(newMarker);
            addInfoWindow(polygon, map, location, onEditLocation);
            polygons.push(polygon);
         }
         else if (location.geometryType === "Point" && location.coOrdinates) {
            const position: google.maps.LatLng = location.latLongs[0];
            const newMarker = new google.maps.Marker({
               position: position,
            });
            bounds.extend(position);
            addInfoWindow(newMarker, map, location, onEditLocation);
            newMarkers.push(newMarker);
         }
      });
      setMapPolygons(polygons);
      markerCluster.setMap(map);
      markerCluster.addMarkers(newMarkers);
      setMarkerCluster(markerCluster);
   } // eslint-disable-next-line react-hooks/exhaustive-deps 
   React.useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
      }
      if (map) {
         if (locations.length <= 30) {
            const bounds = new google.maps.LatLngBounds();
            locations.forEach((location: any) => {
               if (location.geometryType === "Polygon" && location.coOrdinates) {
                  let polygonPath: google.maps.LatLng[] = location.latLongs;
                  let polygonBounds = new google.maps.LatLngBounds();
                  for (var i = 0; i < polygonPath.length; i++) {
                     polygonBounds.extend(polygonPath[i]);
                  }
                  const centerLatlong = polygonBounds.getCenter();
                  bounds.extend(centerLatlong);
               }
               else if (location.geometryType === "Point" && location.coOrdinates) {
                  const position: google.maps.LatLng = location.latLongs[0];
                  bounds.extend(position);
               }
            });
            setMapBound(bounds);
         }
         else {
            setMapBound(mapInitialMapBound());
         }
         DrawMarkers();
         AutoComplete(map, "locationSearch");
      }
   }, [ref, map, locations, numberForMapRefresh]); // eslint-disable-line react-hooks/exhaustive-deps   
   // because React does not do deep comparisons, a custom hook is used
   // see discussion in https://github.com/googlemaps/js-samples/issues/946
   useDeepCompareEffectForMaps(() => {
      if (map) {
         map.setOptions(options);
      }
   }, [map, options]);

   React.useEffect(() => {
      if (map) {
         map.fitBounds(mapBound);
      }
   }, [map, onClick, mapBound]);
   React.useEffect(() => {
      if (map) {
         google.maps.event.clearListeners(map, "idle");
         map.addListener("idle", () => {
            DrawMarkers();
         });
      }
   }, [map, locations, numberForMapRefresh, DrawMarkers]); // eslint-disable-next-line react-hooks/exhaustive-deps  
   return (
      <>
         <div ref={ref} style={style} />
         {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
               // set the map prop on the child component
               // @ts-ignore
               return React.cloneElement(child, { map });
            }
         })}
      </>
   );
};
const MemoizedMap = React.memo(Map);
const addInfoWindow = (obj: any, map: google.maps.Map, location: any, onEdit: any) => {
   const infowindow = new google.maps.InfoWindow();
   obj.addListener('click', function (event: any) {
      const contentString = getInfoWindowContent(location);
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.open(map);
   });
   infowindow.addListener('domready', () => {
      const editButton = document.getElementById(`edit_${location.oid}`);
      if (editButton) {
         editButton.addEventListener('click',
            () => {
               infowindow.close();
               onEdit(location, false);
            })
      }
      const deleteButton = document.getElementById(`delete_${location.oid}`);
      if (deleteButton) {
         deleteButton.addEventListener('click',
            () => {
               infowindow.close();
               onEdit(location, true);
            })
      }
   });
}
const getInfoWindowContent = (location: any): string => {
   return `<div style="font-size:16px;">
               <b>Title: </b>${location.title}<br/>
               <b>Location Type: </b>${location.type}<br/>
               <b>Description: </b>${location.description}<br/>
               <b>Physical Address: </b>${location.physicalAddress}<br/>
               <b>Id: </b>${location.id}<br/>
               <b>Geometry Type: </b>${location.geometryType}<br/>
               <center>
               <button style="background-color:#4CAF50;  border: none;  color: white;  padding:5px 10px;  text-align: center;  text-decoration: none;  display: inline-block;  font-size: 12px;  margin: 4px 2px;  cursor: pointer;" type="button" id="edit_${location.oid}">Edit</button>
               <button style="background-color:#f44336;  border: none;  color: white;  padding:5px 10px;  text-align: center;  text-decoration: none;  display: inline-block;  font-size: 12px;  margin: 4px 2px;  cursor: pointer;" type="button" id="delete_${location.oid}">Delete</button>
               </center>
            </div>`;
}
export default LocationMapViewModal;


