import React from "react";
import { withMsal } from "@azure/msal-react";
import PageLayout from "../../layouts/PageLayout";
import { ISearchPageState } from "../../types/stateType";
import { ISearchPageProps } from "../../types/propType";
import { IDeviceFilter, IFilterOption, IPersonFilter } from "../../types/dataTypes";
import { StatusCode } from "../../constants/constant";
import { Common } from "../../constants/common";
import { Pages } from '../../constants/constant';

import PersonService from "../../services/person.service";
import ClientService from "../../services/client.service";
import DeviceService from "../../services/device.service";

import Persons from "../Persons/Persons";
import Clients from "../Clients/Clients";
import Devices from "../Devices/Devices";

import NoRecordsFound from "../../components/Common/NoRecordsFound";
import { getActionPermission } from '../../utils/PermissionHelpers';
import withParams from "../../components/Common/withParams";
import Button from "../../components/Common/Button";
import NavigationLink from "../../components/Common/NavigationLink";
class Search extends React.Component<ISearchPageProps, ISearchPageState> {
   filterOptions: IFilterOption = {
      SearchText: "",
      pageSize: 5,
      page: 1
   }
   state: ISearchPageState = {
      searchText: "",
      persons: [],
      devices: [],
      clients: [],
      filterOptions: [],
      totalRecords: 0,
      personsRecordsCount: 0,
      devicesRecordsCount: 0,
      clientsRecordsCount: 0,
      openAuditLogs: false
   };
   //#region search and pagination

   searchAll = () => {
      let newFilterOptions: IFilterOption = { ...this.filterOptions };
      if (newFilterOptions.SearchText == null || newFilterOptions.SearchText.trim() === "") {
         this.setState({
            ...this.state,
            persons: [],
            personsRecordsCount: 0,
            devices: [],
            devicesRecordsCount: 0,
            clients: [],
            clientsRecordsCount: 0,
            searchText: ""
         })
         return;
      }
      this.searchPersons();
   }
   searchPersons = () => {
      let newFilterOptions: IPersonFilter = { ...this.filterOptions, ClientOid: Common.EmptyGuid, RelationshipToClient: "All" };
      this.setState({ ...this.state, persons: [], personsRecordsCount: 0 }, () => {
         PersonService.searchPersons(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  persons: res?.data,
                  personsRecordsCount: res?.data[0]?.totalRecord,
                  searchText: newFilterOptions.SearchText
               }, () => this.searchDevices());
            }
         });
      });
   }
   searchDevices = () => {
      let newFilterOptions: IDeviceFilter = { ...this.filterOptions, MonitoringStation: Common.EmptyGuid, IsShowUnassingned: false, ClientOid: Common.EmptyGuid, frontendURL: window.location.origin };
      newFilterOptions.MonitoringStation = Common.EmptyGuid;
      this.setState({ ...this.state, devices: [], devicesRecordsCount: 0 }, () => {
         DeviceService.searchDevices(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  devices: res?.data,
                  devicesRecordsCount: res?.data[0]?.totalRecord,
               }, () => this.searchClients());
            }
         });
      });
   }

   searchClients = () => {
      let newFilterOptions: IFilterOption = { ...this.filterOptions };
      this.setState({ ...this.state, clients: [], clientsRecordsCount: 0 }, () => {
         ClientService.searchClients(newFilterOptions, "All").then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  clients: res?.data,
                  clientsRecordsCount: res?.data[0]?.totalRecord
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.persons.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IFilterOption = { ...this.filterOptions };
      PersonService.searchPersons(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let personList = [...this.state.persons];
            personList = personList.concat(res?.data);
            this.setState({
               ...this.state,
               persons: personList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchAll();
   }

   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.searchAll();
   }

   //#endregion
   render() {
      const { persons, devices, clients, personsRecordsCount, devicesRecordsCount, clientsRecordsCount, searchText } = this.state;
      const { actionPermission } = this.props;
      const clientActionPermission = getActionPermission(Pages.Clients);
      const personActionPermission = getActionPermission(Pages.Persons);
      const deviceActionPermission = getActionPermission(Pages.Devices);
      return (
         <PageLayout pageTitle="Search" searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={null} actionPermission={actionPermission}>
            {searchText && searchText.trim() !== "" && (!clientsRecordsCount || clientsRecordsCount === 0) && (!personsRecordsCount || personsRecordsCount === 0) && (!devicesRecordsCount || devicesRecordsCount === 0) &&
               <NoRecordsFound />
            }
            {clientsRecordsCount > 0 &&
               <Clients isFromSearch={true} clients={clients} actionPermission={clientActionPermission}></Clients>
            }
            {clientsRecordsCount > 5 &&
               <NavigationLink to={`/clients/${this.filterOptions.SearchText}`}>
                  <Button>See more results</Button>
               </NavigationLink>
            }
            {/* Persons */}
            {personsRecordsCount > 0 &&
               <Persons isFromSearch={true} persons={persons} actionPermission={personActionPermission}></Persons>
            }
            {personsRecordsCount > 5 &&
               <NavigationLink to={`/persons/${this.filterOptions.SearchText}`}>
                  <Button>See more results</Button>
               </NavigationLink>
            }
            {/* Devices */}
            {devicesRecordsCount > 0 &&
               <Devices isFromSearch={true} devices={devices} actionPermission={deviceActionPermission}></Devices>
            }
            {devicesRecordsCount > 5 &&
               <NavigationLink to={`/devices/${this.filterOptions.SearchText}`}>
                  <Button>See more results</Button>
               </NavigationLink>
            }
         </PageLayout>
      )
   }
}

export default withMsal(withParams(Search));
