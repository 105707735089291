import { Theme } from "@mui/material";

const auditLogsStyle = (theme: Theme) => ({
   deleteText: {
      backgroundColor: "#faa8a8"
   },
   createdText: {
      backgroundColor: "#bcffbc"
   },
   updatedText: {
      backgroundColor: "#fad7a3"
   },
   assignText: {
      backgroundColor: "#b7c0ee"
   },
   unassignText: {
      backgroundColor: "#c0b9c0"
   },
   subLogs: {
      padding: theme.spacing(2)
   }
});

export default auditLogsStyle;
