import React from 'react';
import lodash from "lodash";
import { TableCell } from '@mui/material';
import { ITextComparetorProps } from '../../types/propType';
import AuditTextBlockList from './AuditTextBlockList';
const ClientResponseInstructionComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   let changedBlocks: any[] = [];
   try {
      changedBlocks = JSON.parse(changedValue);
   } catch (ex) {
   }
   let oldBlocks: any[] = [];
   try {
      oldBlocks = JSON.parse(oldValue);
   } catch (ex) {
   }
   return (
      <TableCell colSpan={2}>
         <TableCell style={{ verticalAlign: "top" }}>
            <AuditTextBlockList oldBlocks={oldBlocks} changedBlocks={changedBlocks} type="old" />
         </TableCell>
         <TableCell style={{ verticalAlign: "top" }}>
            <AuditTextBlockList oldBlocks={changedBlocks} changedBlocks={oldBlocks} type="new" />
         </TableCell>
      </TableCell>
   );
}
export default ClientResponseInstructionComparetor;
