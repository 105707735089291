import update from 'immutability-helper';
import { TextBlockCard } from './TextBlockCard';

const style = {
   width: "100%",
}

interface ITextBlockContainer {
   instructionTypeTextBlocks: any[];
   setInstructionTypeTextBlocks: any;
   removeTextBlock: any;
   setDeletedTextBlocks: any;
   clientOid: string;
   readOnly: boolean;
}
export const TextBlockContainer: React.FC<ITextBlockContainer> = ({ instructionTypeTextBlocks, setInstructionTypeTextBlocks, removeTextBlock, setDeletedTextBlocks, clientOid, readOnly }) => {
   const moveCard = (dragIndex: number, hoverIndex: number) => {
      setInstructionTypeTextBlocks((prevCards: any[]) =>
         update(prevCards, {
            $splice: [
               [dragIndex, 1],
               [hoverIndex, 0, prevCards[dragIndex] as any],
            ],
         }),
      )
   };
   const handleTextChange = (index: any, p1: any, p2: any) => {
      instructionTypeTextBlocks[index].Text = p1;
      if (p2) {
         instructionTypeTextBlocks[index].Values = p2;
         instructionTypeTextBlocks[index] = { ...instructionTypeTextBlocks[index] }
      }
      setInstructionTypeTextBlocks([...instructionTypeTextBlocks]);
   }
   const fixedCardIndex = instructionTypeTextBlocks.findIndex(card => card.IsFixed === true);
   const renderCard =
      (card: any, index: number) => {
         return (
            <TextBlockCard
               key={index}
               index={index}
               id={index}
               textBlock={card}
               moveCard={moveCard}
               removeTextBlock={() => removeTextBlock(card)}
               restrictMoveDown={index === fixedCardIndex - 1}
               handleTextChange={handleTextChange}
               clientOid={clientOid}
               readOnly={readOnly}
            />
         )
      }
   return (
      <>
         <div style={style}>{[...instructionTypeTextBlocks].map((card, i) => renderCard(card, i))}</div>
      </>
   )
}
