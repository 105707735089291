import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import UserSupportRow from "../../components/pages/UsersSupport/UserSupportRow";
import UserSupportCard from "../../components/pages/UsersSupport/UserSupportCard";
import SupportUserAdvanceFilter from "../../components/pages/UsersSupport/SupportUserAdvanceFilter";
import { IUserSupportPageState } from "../../types/stateType";
import { IUserSupportPageProps } from "../../types/propType";
import { StatusCode } from "../../constants/constant";
import { IUserSupportFilter } from "../../types/dataTypes";
import { TableHeader } from "../../constants/tableHeader";
import UserService from "../../services/user.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import { withSnackbar } from "../../components/Common/Snackbar";

class UserSupport extends React.Component<IUserSupportPageProps, IUserSupportPageState> {
   filterOptions: IUserSupportFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      MonitoringStationOid: Common.EmptyGuid,
      ClientOid: Common.EmptyGuid,
      sortString: "Firstname+Lastname"
   }

   state: IUserSupportPageState = {
      totalRecords: 0,
      users: [],
      openAuditLogs: false
   };

   componentDidMount() {
      this.searchUsers()
   }
   //#region pagination and searching
   searchUsers = () => {
      let newFilterOptions: IUserSupportFilter = { ...this.filterOptions };
      this.setState({ ...this.state, users: [] }, () => {
         UserService.searchSupportUsers(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  users: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.users.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IUserSupportFilter = { ...this.filterOptions };
      UserService.searchSupportUsers(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let userList = [...this.state.users];
            userList = userList.concat(res?.data);
            this.setState({
               ...this.state,
               users: userList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchUsers();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchUsers();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchUsers();
      // }
   }

   onAdvanceSearch = (advanceFilter: any) => {
      this.filterOptions.MonitoringStationOid = advanceFilter.MonitoringStationOid;
      this.filterOptions.ClientOid = advanceFilter.ClientOid;
      this.filterOptions.page = 1;
      this.searchUsers();
   }
   //#endregion
   render() {
      const { totalRecords, users } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle={`User Support`} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["User:SearchUserSupportSuggestions"] ? UserService.getSearchUserSupportSuggestions : null}
            actionPermission={{
               add: false,
               auditlog: false,
            }}
            advanceFilter={<SupportUserAdvanceFilter initialValues={{ MonitoringStationOid: this.filterOptions.MonitoringStationOid, ClientOid: this.filterOptions.ClientOid }} onAdvanceSearch={this.onAdvanceSearch} />}
         >
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getUserSupportTableHeaders()} pagingInfoText="Users" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {users && users?.map((user: any, index: number) => {
                     return (
                        <UserSupportRow
                           key={`row_${index}`}
                           user={user}
                           actionPermission={actionPermission}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={users ? users.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {users && users?.map((user: any, index: number) => {
                        return (
                           <UserSupportCard
                              key={`card_${index}`}
                              user={user}
                              actionPermission={actionPermission}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>
         </PageLayout>
      )
   }
}

export default withSnackbar(withParams(UserSupport));
