import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { ISftpImportLogsDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";
import { Visibility } from "@mui/icons-material";

const SftpImportLogsRow: React.FC<ISftpImportLogsDataProps> = ({ record, onView }) => {
   let personDetails: any = JSON.parse(record.details);
   return (
      <StyledGridRow>
         <StyledGridCell> {Common.getFormattedDateTime(record.createdOn)}  </StyledGridCell>
         <StyledGridCell> {personDetails?.Firstname + " " + personDetails?.Lastname}  </StyledGridCell>
         <StyledGridCell> {personDetails?.Email}</StyledGridCell>
         <StyledGridCell> {personDetails?.PhoneNumber1}  </StyledGridCell>
         <StyledGridCell> {personDetails?.EmployeeID}  </StyledGridCell>
         <StyledGridCell> {record.type}  </StyledGridCell>
         <StyledGridCell> {record.error ? record.error.substring(0, 50) : ""}  </StyledGridCell>
         <StyledGridCell>
            <Button color="info" justIcon={true} onClick={() => onView(record)}>
               <Visibility />
            </Button>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default SftpImportLogsRow;
