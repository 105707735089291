import React, { useEffect, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import auditLogsStyle from '../../../assets/jss/pages/auditLogsStyle';
import { StatusCode } from '../../../constants/constant';
import { IAuditLogs } from '../../../types/dataTypes';
import { IEntityAuditLogsContentsProps } from '../../../types/propType';
import Button from '../../Common/Button';
import AuditService from '../../../services/audit.service';
import AuditLogAccrodian from './AuditLogAccrodian';

const EntityAuditLogsContents: React.FC<IEntityAuditLogsContentsProps> = ({ classes, entityName, oid, isFromBlob }) => {
   const [skip, setSkip] = useState(isFromBlob ? -1 : 0);
   const [continuationToken, setContinuationToken] = useState<string | null>(null);
   const [auditLogs, setAuditLogs] = useState<IAuditLogs[]>([]);
   const [hideShowMore, setHideShowMore] = useState(false);
   useEffect(() => {
      fetchAuditLogs();
   }, [entityName, oid]); // eslint-disable-line react-hooks/exhaustive-deps
   const fetchAuditLogs = () => {
      AuditService.getAuditLogs(entityName, oid, skip, continuationToken).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const auditLogsList = res?.data?.auditLogs;
            const totalRecords = res?.data?.totalRecords ?? 0;
            if (totalRecords > 0)
               setSkip(s => s + (auditLogsList.length ?? 0));
            const combinedList = auditLogs.concat(auditLogsList);
            setAuditLogs(combinedList);
            const continuationToken = res?.data?.continuationToken;
            setContinuationToken(continuationToken)
            if (skip > -1 && (combinedList.length === totalRecords || totalRecords === -1))
               setSkip(-1);
            if ((skip === -1 || totalRecords === -1) && continuationToken === null)
               setHideShowMore(true);
         }
      });
   }

   return (
      <>
         {auditLogs.length > 0 ?
            auditLogs.map((log: IAuditLogs, index: number) => {
               return (
                  <AuditLogAccrodian key={index} classes={classes} index={index} log={log} />
               );
            })
            :
            <span>No records found.</span>
         }
         {!hideShowMore && auditLogs.length > 0 &&
            <div style={{ textAlign: "center", padding: "5px" }}>
               <Button size="sm" color="primary" onClick={() => fetchAuditLogs()}>Load More ...</Button>
            </div>
         }
      </>
   );
};

export default withStyles(EntityAuditLogsContents, auditLogsStyle);


