import React from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import { IAdvanceFilterProps } from '../../../types/propType';
import CustomDatePicker from '../../Common/CustomDatePicker';
import { Common } from '../../../constants/common';
import CustomSelect from '../../Common/CustomSelect';
const SftpMonitoringLogsFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const TypeOptions = [
      { label: "All", value: "All" },
      { label: "Info", value: "Info" },
      { label: "Warning", value: "Warning" },
      { label: "File Moved", value: "File Moved" },
      { label: "Processing", value: "Processing" },
      { label: "Processed", value: "Processed" },
      { label: "Email Sent", value: "Email Sent" },
   ]
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, submitForm, setFieldValue, handleChange }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"From Date"}
                        id="FromDate"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           required: true,
                           name: "FromDate",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("FromDate", Common.getFormattedDateForServer(e));
                                 submitForm();
                              }
                           }
                        }}
                        value={values.FromDate}
                        openTo="day"
                        disableFuture
                        shouldDisableTime={() => true}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"To Date"}
                        id="ToDate"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           required: true,
                           name: "ToDate",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("ToDate", Common.getFormattedDateForServer(e));
                                 if (new Date(Common.getFormattedDateForServer(e)) < new Date(values.FromDate)) {
                                    return;
                                 }
                                 else {
                                    submitForm();
                                 }
                              }
                           }
                        }}
                        value={values.ToDate}
                        openTo="day"
                        disableFuture
                        shouldDisableTime={() => true}
                        customErrorMessage={new Date(values.ToDate) < new Date(values.FromDate) ? "From date can't be older than To date." : null}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomSelect
                        labelText="Type"
                        id="Type"
                        inputProps={{
                           name: "Type",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        value={values.Type}
                        options={TypeOptions}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default SftpMonitoringLogsFilter;
