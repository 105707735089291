import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography, CardActions, Collapse } from '@mui/material';
import { Phone, Notes, Person, Aod } from "@mui/icons-material";
import { Common } from "../../../constants/common";
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { styled } from '@mui/material/styles';
import { IEscalationReportDataProps } from "../../../types/propType";
import EscalationDetailResponseInstruction from "../../../Pages/EscalationDetail/EscalationDetailResponseInstruction";

interface ExpandMoreProps extends IconButtonProps {
   expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
   const { expand, ...other } = props;
   return <IconButton {...other} />;
})(({ theme, expand }) => ({
   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
   marginLeft: 'auto',
   transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
   }),
}));
const EscalationDetail = (person: any, index: number, classes: any) => {
   const [expanded, setExpanded] = React.useState(false);

   const handleExpandClick = () => {
      setExpanded(!expanded);
   };
   return (
      <React.Fragment key={`EscalationDetail_${index}`}>
         <CardActions disableSpacing>
            <b>{index}: {person.firstname} {person.lastname}</b>
            <ExpandMore
               expand={expanded}
               onClick={handleExpandClick}
               aria-expanded={expanded}
               aria-label="show more"
            >
               <ExpandMoreIcon />
            </ExpandMore>
         </CardActions>
         <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                  <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(person.phoneNumber1)}
               </Typography>
               {!Common.stringNullOrEmpty(person.phoneNumber2) && <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                  <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(person.phoneNumber2)}
               </Typography>}
               {!Common.stringNullOrEmpty(person.notes) && <Typography variant="body2" color="textSecondary" component="p" className={classes.cardText}>
                  <Notes className={classes.beforeTextIcon} /> {person.notes}
               </Typography>}
            </CardContent>
         </Collapse>
      </React.Fragment>
   )
}
const ResponseInstructionDetail = (responseInstruction: string) => {
   const [expanded, setExpanded] = React.useState(false);

   const handleExpandClick = () => {
      setExpanded(!expanded);
   };
   return (
      <>
         <CardActions disableSpacing>
            <b>Response Instruction</b>
            <ExpandMore
               expand={expanded}
               onClick={handleExpandClick}
               aria-expanded={expanded}
               aria-label="show more"
            >
               <ExpandMoreIcon />
            </ExpandMore>
         </CardActions>
         <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
               <EscalationDetailResponseInstruction responseInstruction={responseInstruction} />
            </CardContent>
         </Collapse>
      </>
   )
}
const EscalationReportCard: React.FC<IEscalationReportDataProps> = ({ classes, record, tab }) => {
   const { escalationDetails } = record;
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {`${record.name}`}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               {tab === "Person" && record.devices && record.devices.length > 0 && <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Aod className={classes.beforeTextIcon} /> {record.devices.map((device: any, index: number) => {
                     return <>{device}{record.devices.length !== index + 1 ? ", " : ""}</>
                  })}
               </Typography>}
               {tab === "Device" && !Common.stringNullOrEmpty(record.personName) &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <Person className={classes.beforeTextIcon} /> {record.personName}
                  </Typography>
               }
               {escalationDetails && escalationDetails.length > 0 &&
                  escalationDetails?.map((record: any, index: number) => {
                     return EscalationDetail(record, index + 1, classes);
                  })
               }
               {!Common.stringNullOrEmpty(record.responseInstruction) &&
                  ResponseInstructionDetail(record.responseInstruction.toString())
               }
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(EscalationReportCard, gridTileStyle);
