import { Suspense } from 'react';
// import { Suspense, lazy } from 'react'; 
import { MsalProvider } from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";
import { msalInstance } from './utils/azureAuth/azureAuthProvider';
import { PrivateRoute } from './layouts/PrivateRoute';
import { Pages } from './constants/constant';
import Loader from "./components/Common/Loader";
import LazyLoaderProgress from './components/Common/LazyLoaderProgress';
import LoginProcessing from "./Pages/LoginProcessing";
import { KeycloakProvider } from './utils/keycloak/keycloakProvider';
import Clients from './Pages/Clients/Clients';
import Devices from './Pages/Devices/Devices';
import Persons from './Pages/Persons/Persons';
import Search from './Pages/Search/Search';
import Alerts from './Pages/Alerts/Alerts';
import Users from './Pages/Users/Users';
import MonitoringStation from './Pages/MonitoringStation/MonitoringStation';
import Unauthorized from './Pages/Unauthorized';
import NotFound404 from './Pages/NotFound404';
import SessionExpired from './Pages/SessionExpired';
import Login from './Pages/Login/Login';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import EscalationDetail from './Pages/EscalationDetail/EscalationDetail';
import ClientApiKey from './Pages/ClientApiKey/ClientApiKey';
import EscalationReport from './Pages/Reports/EscalationReport';
import UserSupport from './Pages/UserSupport/UserSupport';
import ErrorLog from './Pages/ErrorLog/ErrorLog';
import DevicePersonReport from './Pages/Reports/DevicePersonReport';
import SftpImportLogs from './Pages/SftpImportLogs/SftpImportLogs';
import UnderMaintenance from './Pages/UnderMaintenance';
import Trips from './Pages/Trips/Trips';
import Locations from './Pages/Locations/Locations';
import TripDetail from './Pages/TripDetail/TripDetail';
import HazardLocations from './Pages/HazardLocations/HazardLocations';
import DeviceTestReport from './Pages/Reports/DeviceTestReport';
import SftpMonitoringLogs from './Pages/SftpMonitoringLogs/SftpMonitoringLogs';
// const Clients = lazy(() => import("./Pages/Clients/Clients"));
// const Devices = lazy(() => import("./Pages/Devices/Devices"));
// const Persons = lazy(() => import("./Pages/Persons/Persons"));
// const Search = lazy(() => import("./Pages/Search/Search"));
// const Alerts = lazy(() => import("./Pages/Alerts/Alerts"));
// const Users = lazy(() => import("./Pages/Users/Users"));
// const MonitoringStation = lazy(() => import("./Pages/MonitoringStation/MonitoringStation"));
// const Unauthorized = lazy(() => import("./Pages/Unauthorized"));
// const NotFound404 = lazy(() => import("./Pages/NotFound404"));
// const SessionExpired = lazy(() => import("./Pages/SessionExpired"));
// const Login = lazy(() => import("./Pages/Login/Login"));
// const ResetPassword = lazy(() => import("./Pages/ResetPassword/ResetPassword"));
// const EscalationDetail = lazy(() => import("./Pages/EscalationDetail/EscalationDetail"));
// const ClientApiKey = lazy(() => import("./Pages/ClientApiKey/ClientApiKey"));
// const EscalationReport = lazy(() => import("./Pages/Reports/EscalationReport"));
// const UserSupport = lazy(() => import("./Pages/UserSupport/UserSupport"));
// const ErrorLog = lazy(() => import("./Pages/ErrorLog/ErrorLog"));
// const DevicePersonReport = lazy(() => import("./Pages/Reports/DevicePersonReport"));
// const SftpImportLogs = lazy(() => import("./Pages/SftpImportLogs/SftpImportLogs"));
// const UnderMaintenance = lazy(() => import("./Pages/UnderMaintenance"));
// const Trips = lazy(() => import("./Pages/Trips/Trips"));
// const Locations = lazy(() => import("./Pages/Locations/Locations"));
// const TripDetail = lazy(() => import("./Pages/TripDetail/TripDetail"));
// const HazardLocations = lazy(() => import("./Pages/HazardLocations/HazardLocations"));
// const DeviceTestReport = lazy(() => import("./Pages/Reports/DeviceTestReport"));
// const SftpMonitoringLogs = lazy(() => import("./Pages/SftpMonitoringLogs/SftpMonitoringLogs"));
function App() {
   return (
      <>
         <KeycloakProvider>
            <MsalProvider instance={msalInstance}>
               <Suspense fallback={<LazyLoaderProgress />}>
                  <Routes>
                     <Route path='/resetpassword/:linkid' element={<ResetPassword />} />
                     <Route path='/setupaccount/:linkid' element={<ResetPassword />} />
                     <Route path='/login' element={<Login />} />
                     <Route path='/unauthorized' element={<Unauthorized />} />
                     <Route path='/sessionexpired' element={<SessionExpired />} />
                     <Route path="/" >
                        <Route index element={<PrivateRoute page={Pages.Clients} component={Clients} />} />
                        <Route path="/clients/:searchText" element={<PrivateRoute page={Pages.Clients} component={Clients} />} />
                     </Route>
                     <Route path="/devices" >
                        <Route index element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                        <Route path="/devices/:searchText" element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                        <Route path="/devices/client/:clientOid" element={<PrivateRoute page={Pages.Devices} component={Devices} />} />
                     </Route>
                     <Route path="/persons" >
                        <Route index element={<PrivateRoute page={Pages.Persons} component={Persons} />} />
                        <Route path="/persons/:searchText" element={<PrivateRoute page={Pages.Persons} component={Persons} />} />
                     </Route>
                     <Route path="/search" element={<PrivateRoute page={Pages.Search} component={Search} />} />
                     <Route path="/alerts" element={<PrivateRoute page={Pages.Alerts} component={Alerts} />} />
                     <Route path="/users" element={<PrivateRoute page={Pages.Users} component={Users} />} />
                     <Route path="/users/:monitoringStationOid" element={<PrivateRoute page={Pages.Users} component={Users} />} />
                     <Route path="/monitoringstation" element={<PrivateRoute page={Pages.MonitoringStation} component={MonitoringStation} />} />
                     <Route path="/escalationdetail/:deviceid" element={<PrivateRoute page={Pages.Escalation} component={EscalationDetail} />} />
                     <Route path="/apikeys" element={<PrivateRoute page={Pages.ClientApiKey} component={ClientApiKey} />} />
                     <Route path="/escalationreport" element={<PrivateRoute page={Pages.EscalationReport} component={EscalationReport} />} />
                     <Route path="/usersupport" element={<PrivateRoute page={Pages.UserSupport} component={UserSupport} />} />
                     <Route path="/errorlog" element={<PrivateRoute page={Pages.ErrorLog} component={ErrorLog} />} />
                     <Route path="/devicepersonreport" element={<PrivateRoute page={Pages.DevicePersonReport} component={DevicePersonReport} />} />
                     <Route path="/SftpImportLog" element={<PrivateRoute page={Pages.SftpImportLogs} component={SftpImportLogs} />} />
                     <Route path="/trips" element={<PrivateRoute page={Pages.Trips} component={Trips} />} />
                     <Route path="/triplocations" element={<PrivateRoute page={Pages.Locations} component={Locations} />} />
                     <Route path="/tripdetail/:tripOid" element={<PrivateRoute page={Pages.TripDetail} component={TripDetail} />} />
                     <Route path="/tripdetail/edit/:tripOid" element={<PrivateRoute page={Pages.TripDetail} component={TripDetail} />} />
                     <Route path="/hazardlocations" element={<PrivateRoute page={Pages.HazardLocations} component={HazardLocations} />} />
                     <Route path="/devicetests" element={<PrivateRoute page={Pages.DeviceTestReport} component={DeviceTestReport} />} />
                     <Route path='/undermaintenance' element={<UnderMaintenance />} />
                     <Route path='/networkissue' element={<UnderMaintenance />} />
                     <Route path='/auth.html' element={<LoginProcessing />} />
                     <Route path="/SftpMonitoringLog" element={<PrivateRoute page={Pages.SftpMonitoringLogs} component={SftpMonitoringLogs} />} />
                     <Route path='*' element={<NotFound404 />} />
                  </Routes>
               </Suspense>
            </MsalProvider>
         </KeycloakProvider>
         <Loader />
      </>
   );
}

export default App;
