import { Theme } from "@mui/material";
import { blackColor, whiteColor } from "../appStyle";

const unauthorizedPageStyle = (theme: Theme) => ({
   appBar: {
      backgroundColor: blackColor,
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.14)",
      flexGrow: 1,
      padding: theme.spacing(1)
   },
   wrapper: {
      background: whiteColor,
      borderRadius: 6,
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
      margin: theme.spacing(2),
      padding: theme.spacing(2)
   },
   pageContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(10)
   }
});

export default unauthorizedPageStyle;
