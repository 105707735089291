import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Resources } from '../../../constants/resources';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import personService from '../../../services/person.service';
import { ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import CustomCheckbox from '../../Common/CustomCheckbox';
import Button from '../../Common/Button';
import ConfirmDialogBox from '../../Common/ConfirmDialogBox';
import { StatusCode } from '../../../constants/constant';
import ValidationSummary from '../../Common/ValidationSummary';

interface IFindAndReplaceEscalationContactDialogueProps {
   open: boolean,
   successCallback: any,
   close: any,
   clientOid: string,
   clientName: string
}
interface IFindAndReplaceEscalationContact {
   OldPersonOid: string,
   NewPersonOid: string,
   ReplaceForPerson: boolean,
   ReplaceForDevice: boolean
}
const FindAndReplaceEscalationContactDialogue: React.FC<IFindAndReplaceEscalationContactDialogueProps> = ({ open, successCallback, close, clientOid, clientName }) => {
   const [showConfirmDialog, setShowConfirmDialog] = useState(false);
   const [counts, setCounts] = useState<any>({});
   const [error, setError] = useState<any>({});
   const [initialFindAndReplaceEscalationContact, setInitialFindAndReplaceEscalationContact] = useState<IFindAndReplaceEscalationContact>({
      OldPersonOid: "",
      NewPersonOid: "",
      ReplaceForPerson: false,
      ReplaceForDevice: false
   });
   const validationSchema = Yup.object().shape({
      OldPersonOid: Yup.string().trim(Resources.Error_OldPersonName_Required).required(Resources.Error_OldPersonName_Required),
      NewPersonOid: Yup.string().trim(Resources.Error_NewPersonName_Required).required(Resources.Error_NewPersonName_Required)
         .test("MatchContact1And2", Resources.Error_OldPersonName_NewPersonName_ShouldNotMatching, (value: string | null | undefined, context) => value !== context.parent.OldPersonOid),
      ReplaceForDevice: Yup.bool()
         .when('ReplaceForPerson', {
            is: false, // alternatively: (val) => val == true
            then: Yup.bool().isTrue("Any one of Replace for Persons or Devices should be checked."),
            otherwise: Yup.bool(),
         })
   });
   const searchPersonSelectionOptions = (params: ISearchableSelectParams, signal: any) => {
      return personService.getPersonSelectOptions(clientOid, params, signal);
   }
   const onSubmit = () => {
      setShowConfirmDialog(false);
      if (counts?.person > 0 || counts?.device > 0) {
         personService.findAndReplaceEscalationContact(clientOid, initialFindAndReplaceEscalationContact).then((res: any) => {
            if (res?.status === StatusCode.Success) {
               setShowConfirmDialog(true);
               successCallback();
            }
            else if (res?.status === StatusCode.BadRequest) {
               setError(res.data);
            }
         })
      }
      else {
         successCallback();
      }
   };

   const handleConfirmMsg = (values: any) => {
      setInitialFindAndReplaceEscalationContact(values);
      personService.getCountForPersonUsedInEscalations(clientOid, values).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            setCounts(res?.data);
            setShowConfirmDialog(true);
         }
      })
   };
   return (
      <Dialog open={open} onClose={close}>
         <Formik initialValues={initialFindAndReplaceEscalationContact} validationSchema={validationSchema} onSubmit={handleConfirmMsg}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <DialogTitle>Find & Replace Escalation Contact for {clientName}</DialogTitle>
                  <DialogContent dividers>
                     <ValidationSummary errors={error["errors"]} />
                     {error["message"] &&
                        <FormHelperText id="error-login" error>{error["message"]}</FormHelperText>
                     }
                     <SearchableServerSearch
                        labelText="Old Contact Name"
                        id="OldPersonOid"
                        inputProps={{
                           required: true,
                           name: "OldPersonOid"
                        }}
                        value={values.OldPersonOid}
                        onSearchTextChange={searchPersonSelectionOptions}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("OldPersonOid", value?.value)
                        }}
                     />
                     <SearchableServerSearch
                        labelText="New Contact Name"
                        id="NewPersonOid"
                        inputProps={{
                           required: true,
                           name: "NewPersonOid"
                        }}
                        value={values.NewPersonOid}
                        onSearchTextChange={searchPersonSelectionOptions}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("NewPersonOid", value?.value)
                        }}
                     />
                     <CustomCheckbox
                        labelText={"Replace For Persons"}
                        id="ReplaceForPerson"
                        inputProps={{
                           required: true,
                           name: "ReplaceForPerson"
                        }}
                        checked={values.ReplaceForPerson}
                     />
                     <CustomCheckbox
                        labelText={"Replace For Devices"}
                        id="ReplaceForDevice"
                        inputProps={{
                           required: true,
                           name: "ReplaceForDevice",
                        }}
                        checked={values.ReplaceForDevice}
                     />
                     { }
                     {showConfirmDialog &&
                        <ConfirmDialogBox
                           show={showConfirmDialog}
                           onConfirmEvent={onSubmit}
                           onCancel={() => setShowConfirmDialog(false)}
                           dialogueText={`Yes will update ${counts?.person} person escalation and ${counts?.device} device escalation.`}
                           dialogueTitle={"Are you sure want to proceed?"}
                        />
                     }
                  </DialogContent>
                  <DialogActions>
                     <Button color="danger" type="submit">Proceed</Button>
                     <Button onClick={close} autoFocus>Cancel</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog >
   );
};

export default FindAndReplaceEscalationContactDialogue;





