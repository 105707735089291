import React from 'react';
import { CardHeader } from "@mui/material";
import { Avatar } from '@mui/material';
import Image from "./Image";
import { IAuditTextBlock } from '../../types/propType';
import { Common } from '../../constants/common';

const AuditTextBlock: React.FC<IAuditTextBlock> = ({ index, text, textBlockType, color, borderColor }) => {
   const style = {
      border: `2px solid ${borderColor}`,
      padding: '0.5rem 1rem',
      marginBottom: '.5rem',
      backgroundColor: 'white',
      color: color
   }
   return (
      <div style={{ ...style }}>
         <CardHeader
            avatar={
               <Avatar sx={{ bgcolor: color }} aria-label="">
                  {index + 1}
               </Avatar>
            }
            title={< div style={{ whiteSpace: "pre-line" }}>
               {(textBlockType === "Text" || textBlockType === "FreeText" || textBlockType === "Placeholder") && text}
               {textBlockType === "Image" &&
                  <>
                     {Common.getFileNameFromPathWithOutExtension(text)}
                     <Image
                        src={text.indexOf("http") === 0 ? text : `${Common.ClientImagePath}/${text}`}
                        zoom={true}
                        style={{ height: 50, marginLeft: 25 }}
                     />
                  </>
               }
            </div>}
         />
      </div>
   )
};

export default AuditTextBlock;
