import React from 'react'

const NoRecordsFound: React.FC = () => {
  return (
    <div className="empty-state page-size">
      <h5 className="text-dark medium">No record(s) found</h5>
      <p className="text-lg">We can’t find any results which match your search criteria</p>
    </div>
  );
}

export default NoRecordsFound
