import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { Person } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IDevicePersonReportDataProps } from "../../../types/propType";

const DevicePersonReportCard: React.FC<IDevicePersonReportDataProps> = ({ classes, record }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {`${record.deviceId}`}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Person className={classes.beforeTextIcon} /> {record.personName}
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(DevicePersonReportCard, gridTileStyle);
