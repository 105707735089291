// This should be the same instance you pass to MsalProvider
import { msalInstance, loginRequest } from './azureAuth/azureAuthProvider';
import authService from "../services/auth.service";
import { mapKeycloakRole, keycloakInstance } from './keycloak/keycloakHelpers';
import environment from '../environment';

export const getAuthToken = async () => {
   const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
   const accounts = msalInstance.getAllAccounts();
   const token = localStorage.getItem("token");
   if (token && token.length > 0) {
      return token;
   }
   if (Boolean(environment?.kcEnabled)) {
      return keycloakInstance.token ?? "";
   }
   if (!activeAccount && accounts.length === 0) {
      return "";
   }
   const request: any = {
      scopes: loginRequest.scopes,
      account: activeAccount || accounts[0]
   };
   try {
      const response = await msalInstance.acquireTokenSilent(request);
      return response.idToken;
   } catch (e) {
      console.log(e)
   }
};

export const isLogin = () => {
   return localStorage.getItem("token") ? true : false;
}

const logOutFromAzureAd = () => {
   msalInstance.logoutRedirect().then(() => {
   }).catch((e: any) => {
      console.error(e);
   });
}

export const Logout = () => {
   if (Boolean(environment?.kcEnabled)) {
      localStorage.clear();
      keycloakInstance.logout().then(() => {
         keycloakInstance.clearToken();
      });
   }
   else {
      const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
      const accounts = msalInstance.getAllAccounts();
      if (activeAccount || accounts.length > 0) {
         localStorage.removeItem("permissions");
         localStorage.removeItem("planName");
         if (localStorage.getItem("token")) {
            authService.backToGASStaffLogin().then(() => {
               logOutFromAzureAd();
            });
         }
         else {
            logOutFromAzureAd();
         }
      }
      else {
         authService.logOut();
      }
   }
}

export const azureLogin = async (email: string, Navigate: any) => {
   msalInstance.loginRedirect({ ...loginRequest, loginHint: email });
}

export const getLoggedInPortalRole = (isAzureLogin: boolean) => {
   if (localStorage.getItem("token")) {
      const roles = localStorage.getItem("roles");
      const rolesArray = roles ? JSON.parse(roles) : []
      return rolesArray;
   }
   else if (isAzureLogin) return ["GAS Staff"];
   else if (Boolean(environment?.kcEnabled)) {
      return getLoggedInKeycloakRole();
   }
   else return [""];

}

export const getLoggedInKeycloakRole = () => {
   const roles = keycloakInstance.realmAccess?.roles;
   const rolesArray = roles != null ? roles
      .map((keycloakRole) => mapKeycloakRole(keycloakRole))
      .filter((role) => role !== '') as string[]
      : [];
   return rolesArray;
}
