import { Controllers } from "../constants/constant";
import request from "../utils/request";
import { IMonitoringStation, IDeleteParams } from "../types/dataTypes";

class MonitoringStationService {
   searchMonitoringStations = (params: any) => {
      return request({
         url: `${Controllers.MonitoringStation}/Search`,
         method: 'get',
         params
      });
   }
   saveMonitoringStation = (data: IMonitoringStation) => {
      return request({
         url: `${Controllers.MonitoringStation}`,
         method: 'post',
         data,
      })
   }
   deleteMonitoringStation = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.MonitoringStation}`,
         method: 'delete',
         params
      })
   }
   getMonitoringStationOptions = () => {
      return request({
         url: `${Controllers.MonitoringStation}/GetMonitoringStationOptions`,
         method: 'get',
      })
   }
   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.MonitoringStation}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }
   getMonitoringStationDependencies = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.MonitoringStation}/GetMonitoringStationDependencies`,
         method: 'get',
         params
      })
   }
   getMonitoringStationName = (oid: string) => {
      return request({
         url: `${Controllers.MonitoringStation}/GetMonitoringStationName/${oid}`,
         method: 'get',
      })
   }
}

export default new MonitoringStationService();
