import React from "react";
import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import {  NoteAlt, Info, Attachment, AccessTimeFilled, Visibility } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { ISftpMonitoringLogsDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";

const SftpMonitoringLogsCard: React.FC<ISftpMonitoringLogsDataProps> = ({ classes, record, onView }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <Button color="info" justIcon={true} onClick={() => onView(record)}><Visibility /></Button>
               }
               title={
                  <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                     <AccessTimeFilled className={classes.beforeTextIcon} /> {Common.getFormattedDateTime(record.dateTime)}
                  </Typography>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Info className={classes.beforeTextIcon} /> {record.type}
               </Typography>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Attachment className={classes.beforeTextIcon} /> {record.filename}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <NoteAlt className={classes.beforeTextIcon} /> {record.notes}
               </Typography>
            </CardContent>

         </Card>
      </Grid >
   )
}

export default withStyles(SftpMonitoringLogsCard, gridTileStyle);
