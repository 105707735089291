import React from "react";
import { CheckCircle, Error } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { successColor, dangerColor } from "../../../assets/jss/appStyle";

import { IAlertDataProps } from "../../../types/propType";

const AlertRow: React.FC<IAlertDataProps> = ({ alert }) => {   
   return (
      <StyledGridRow>
         <StyledGridCell> {alert.id}  </StyledGridCell>
         <StyledGridCell> {alert.deviceName}  </StyledGridCell>
         <StyledGridCell> {alert.alert_Type}  </StyledGridCell>
         <StyledGridCell> {`${alert.userFirstName} ${alert.userLastName}`}  </StyledGridCell>         
         <StyledGridCell> 
         { alert.patriotSignalStatus === "Success" ? 
            <CheckCircle style={{ color: successColor[0], float: "left" }} /> 
            : <Error style={{ color: dangerColor[0], float: "left" }} /> } {alert.patriotSignalStatus}  </StyledGridCell>
         <StyledGridCell key={`${alert.date_Ceated}`}>{alert.date_Ceated}</StyledGridCell>         
      </StyledGridRow>
   )
}

export default AlertRow;
