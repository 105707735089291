import React, { useState } from "react";
import Button from "./Button";
import { MoreVert } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import StyledMenu from "./StyledMenu";

type ActionOptionMenuProps = {
   children?: any;
}
const ActionOptionMenu: React.FC<ActionOptionMenuProps> = ({ children }) => {
   const [anchorEl, setAnchorEl] = useState(null);
   const handleClick = (event: any) => {
      if (anchorEl !== event.currentTarget) {
         setAnchorEl(event.currentTarget);
      }
   }
   const handleClose = () => {
      setAnchorEl(null);
   }
   if (Array.isArray(children)) {
      if (children.filter(x => !x).length === children.length) {
         return null;
      }
   }
   return (
      <React.Fragment>
         {children &&
            <>
               <Button
                  aria-haspopup="true"
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  justIcon={true}>
                  <Tooltip title="Options">
                     <MoreVert />
                  </Tooltip>
               </Button>
               <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                     'aria-labelledby': 'demo-customized-button',
                  }}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  onClick={handleClose}
               >
                  {children}
               </StyledMenu>
            </>
         }
      </React.Fragment>
   );
};
export default ActionOptionMenu;
