import React, { useEffect, useState } from 'react';

import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';

import TextComparetor from '../../Common/TextComparetor';
import ClientResponseInstructionComparetor from '../../Common/ClientResponseInstructionComparetor';

import { IAuditLogProps } from '../../../types/propType';
import { Controllers } from '../../../constants/constant';
import DeviceResponseInstructionComparetor from '../../Common/DeviceResponseInstructionComparetor';
import BoolComparetor from '../../Common/BoolComparetor';
import { Common } from '../../../constants/common';
import Image from "../../Common/Image";
import DateTimeComparetor from '../../Common/DateTimeComparetor';
import GeometryComparetor from '../../Common/GeometryComparetor';
import TripPersonAndLocationComparetor from '../../Common/TripPersonAndLocationComparetor';
import HtmlComparetor from '../../Common/HtmlComparetor';
import FileComparetor from '../../Common/FileComparetor';
import HazardLocationGeometryComparetor from '../../Common/HazardLocationGeometryComparetor';
const AuditLogs: React.FC<IAuditLogProps> = ({ auditLog }) => {
   const [oldValues, setOldValues] = useState<any>(null);
   const [changedValues, setChangedValues] = useState<any>(null);
   const auditOn: string = auditLog?.auditOn == null ? "" : auditLog?.auditOn;
   const entityName: string = auditLog?.entityName == null ? "" : (auditOn === "Add image" || auditOn === "Delete image") ? "ClientImage" : auditLog?.entityName;

   useEffect(() => {
      const changedValue = JSON.parse(auditLog.changedValue);
      if (entityName === Controllers.Client && auditOn === "Remove report recipient") {
         changedValue.Client.ClientReportRecipient.push({ ...changedValue.ClientReportRecipient, Type: "Remove" });
      }
      setOldValues(JSON.parse(auditLog.oldValue));
      setChangedValues(changedValue);
   }, [auditLog, auditOn, entityName]);
   const getAuditEntityKeys = (entityName: string | undefined) => {
      switch (entityName) {
         case Controllers.Client:
            return [
               { key: "Client.Name", displayName: "Name" },
               { key: "Client.Email", displayName: "Email" },
               { key: "Client.PhoneNumber", displayName: "PhoneNumber" },
               { key: "Client.Country", displayName: "Country" },
               { key: "AccountOwner", displayName: "Account Owner" },
               { key: "Plan", displayName: "Plan" },
               { key: "Client.NetworkKey", displayName: "Network Key" },
               { key: "Client.ShowDeviceOptionalFields", displayName: "Show Device Optional Fields", type: "boolean" },
               { key: "Client.ShowPersonOptionalFields", displayName: "Show Person Optional Fields", type: "boolean" },
               { key: "Client.SendDeviceTestReminder", displayName: "Send Device Test Reminder", type: "boolean" },
               { key: "Client.DayOfMonthToSendReport", displayName: "Day Of Month To Send Report" },
               { key: "Client.IsPrivacyBlurbRequired", displayName: "Is Privacy Blurb Required", type: "boolean" },
               { key: "Client.PrivacyBlurb", displayName: "Privacy Blurb" },
               { key: "Client.AllowDeviceAndEscalationAssignmentToSelf", displayName: "Allow Users to Assign Device And Person escalation contact to them self", type: "boolean" },
               { key: "Client.EnableTripIntention", displayName: "Enable Safe Trips", type: "boolean" },
               { key: "Client.BlockAllMails", displayName: "Block All Mails", type: "boolean" },
               { key: "Client.AllowSystemNotification", displayName: "Allow System Notification", type: "boolean" },

            ]
         case Controllers.Escalation:
            return [
               { key1: "Person1.Firstname", key2: "Person1.Lastname", key3: "Person1.PhoneNumber1", key4: "Person1.PhoneNumber2", displayName: "Contact 1" },
               { key1: "Person2.Firstname", key2: "Person2.Lastname", key3: "Person2.PhoneNumber1", key4: "Person2.PhoneNumber2", displayName: "Contact 2" },
               { key1: "Person3.Firstname", key2: "Person3.Lastname", key3: "Person3.PhoneNumber1", key4: "Person3.PhoneNumber2", displayName: "Contact 3" },
            ]

         case Controllers.Device:
            return [
               { key: "Device.Client.Name", displayName: "Client name" },
               { key: "Device.DeviceType", displayName: "Device Type" },
               { key: "Device.DeviceId", displayName: "Device ID" },
               { key: "Device.DeviceStatus", displayName: "Device Status" },
               { key: "Device.DeviceMake", displayName: "Device Make" },
               { key: "Device.DeviceModel", displayName: "Device Model" },
               { key: "Device.Platform", displayName: "Platform" },
               { key: "Device.IMEI_RegistationNo", displayName: "IMEI / Vehicle Registration" },
               { key: "Device.ICCID", displayName: "ICCID" },
               { key: "Device.GarminAuthCode", displayName: "Garmin Auth Code" },
               { key: "Device.DeviceSerialNumber", displayName: "Device Serial Number" },
               { key: "Device.DeviceMobileNumber", displayName: "Device Mobile Number" },
               { key: "Device.MonitoringAccountNumber", displayName: "Monitoring Account Number" },
               { key: "MonitoringStation", displayName: "Monitoring Station" },
               { key: "Device.VehicleYear", displayName: "Vehicle Year" },
               { key: "Device.VehicleMakeModel", displayName: "Vehicle Make and Model" },
               { key: "Device.VehicleColour", displayName: "Vehicle Colour" },
               { key: "Device.GasTags", displayName: "Gas Tags" },
               { key: "Device.MonitoredBy", displayName: "Monitored By" },
               { key: "Device.Notes", displayName: "Device Notes" },
               { key: "Device.ActivationDate", displayName: "Activation Date", type: "date" },
               { key: "Device.WarrantyExpiryDate", displayName: "Warranty Expiry Date", type: "date" },
               { key: "Device.PegasusDataLastUpdated", displayName: "Pegasus Data Last Updated", type: "datetime" },
               { key: "Device.PegasusDisabled", displayName: "Pegasus Disabled", type: "boolean", texts: "enableddisabled" },
               { key: "Device.DeviceInAlarm", displayName: "Device In Alarm", type: "boolean" },
               { key: "Device.LastPoll", displayName: "Last Poll", type: "datetime" },
               { key: "Device.DaysOffline", displayName: "Days Offline" },
               { key: "Device.DaysSinceGPSFix", displayName: "Days since GPS Fix" },
               { key: "Device.DaysNotCharged", displayName: "Days Not Charged" },
               { key: "Device.DateLastServiced", displayName: "Date Last Serviced", type: "datetime" },
               { key: "Device.DateLastTested", displayName: "Date Last Tested", type: "datetime" },
               { key: "Device.Person.Firstname", displayName: "Assigned to Person First Name" },
               { key: "Device.Person.Lastname", displayName: "Assigned to Person Last Name" },
            ]

         case Controllers.Person:
            return [
               { key: "Person.Firstname", displayName: "First Name" },
               { key: "Person.Lastname", displayName: "Last Name" },
               { key: "Person.Email", displayName: "Email" },
               { key: "Person.PhoneNumber1", displayName: "Phone Number 1" },
               { key: "Person.PhoneNumber2", displayName: "Phone Number 2" },
               { key: "Person.AllowPortalAccess", displayName: "Allow Portal Access", type: "boolean" },
               { key: "Person.ApplicationUser.PortalRole", displayName: "Portal Role" },
               { key: "Person.Notes", displayName: "Notes" },
               { key: "Person.TimeZone", displayName: "Time Zone" },
               { key: "Person.EmployeeID", displayName: "User Employee ID" },
               { key: "Person.PositionTitle", displayName: "User Position Title" },
               { key: "Person.UserFunction", displayName: "User Function" },
               { key: "Person.Area", displayName: "User Area" },
               { key: "Person.Department", displayName: "User Department" },
               { key: "Person.WorkAddress", displayName: "User Work Address" },
               { key: "Person.EmploymentActive", displayName: "User Employment Active", type: "boolean" },
               { key: "Person.PersonLeader[0].PersonLeader.EmployeeID", displayName: "Leader First Name" },
               { key: "Person.PersonLeader[0].PersonLeader.Firstname", displayName: "Leader First Name" },
               { key: "Person.PersonLeader[0].PersonLeader.Lastname", displayName: "Leader Last Name" },
               { key: "Person.PersonLeader[0].PersonLeader.Email", displayName: "Leader Email" },
               { key: "Person.PersonLeader[0].PersonLeader.PhoneNumber1", displayName: "Leader Work Mobile" },
               { key: "Person.PersonLeader[0].PersonLeader.WorkAddress", displayName: "Leader Work Address" },
            ]

         case Controllers.PersonClientRelation:
            return [
               { key: "Client.Name", displayName: "Client name" },
               { key: "RelationshipToClient", displayName: "Relationship To Client" }
            ]
         case Controllers.User:
            return [
               { key: "Email", displayName: "Email" },
               { key: "Firstname", displayName: "First Name" },
               { key: "Lastname", displayName: "Last Name" },
               { key: "PortalRole", displayName: "Portal Role" },
               { key: "Password", displayName: "Password" },
               { key: "MonitoringStationName", displayName: "Monitoring Station" },
               { key: "ClientName", displayName: "Client" },
            ]
         case Controllers.MonitoringStation:
            return [
               { key: "Name", displayName: "Monitoring Station Name" },
               { key: "URL", displayName: "URL" },
               { key: "Username", displayName: "Username" },
               { key: "Password", displayName: "Password" },
               { key: "PhoneNumber", displayName: "Phone Number" },
            ]
         case Controllers.ResponseInstruction:
            return [
               { key: "Instruction", displayName: "Response Instruction" },
            ]
         case Controllers.Location:
            return [
               { key: "Title", displayName: "Title" },
               { key: "Type", displayName: "Location Type" },
               { key: "Description", displayName: "Description" },
               { key: "PhysicalAddress", displayName: "Physical Address" },
               { key: "GeometryType", displayName: "Geometry Type" },
               { key: "CoOrdinates", displayName: "Co Ordinates", type: "geometry" },
            ]
         case Controllers.Trip:
            return [
               { key: "Title", displayName: "Title" },
               { key: "Status", displayName: "Status" },
               { key: "Description", displayName: "Description", type: "html" },
               { key: "StartTime", displayName: "Start Time", type: "datetime" },
               { key: "EndTime", displayName: "End Time", type: "datetime" },
               { key: "VehicleRegistration", displayName: "Vehicle Registration" },
               { key: "Device.DeviceId", displayName: "Device" },
               { key: "CellPhoneNumber", displayName: "Cell Phone Number" },
               { key: "AssessmentOfHazardsAndSafety", displayName: "Assessment Of Hazards And Safety", type: "html" },
               { key: "OrganizerName", displayName: "Organizer Name" },
            ]
         case Controllers.HazardLocation:
            return [
               { key: "Type", displayName: "Hazard Type" },
               { key: "SecurityLevel", displayName: "Security Level" },
               { key: "SecurityLevelDescription", displayName: "Security Level Description" },
               { key: "Status", displayName: "Status" },
               { key: "Warning", displayName: "Warning" },
               { key: "OnsiteContact", displayName: "Onsite Contact" },
               { key: "OnsiteContactPhoneNumber", displayName: "Onsite Contact Phone Number" },
               { key: "Notes", displayName: "Notes" },
               { key: "Id", displayName: "Id" },
               { key: "UploadedFilePath", displayName: "File", type: "file" },
               { key: "GeometryType", displayName: "Geometry Type" },
            ]
         case Controllers.DeviceEvent:
            return [
               { key: "EventResult", displayName: "Result" },
               { key: "EventNotes", displayName: "EventNotes" },
               { key: "EventDateTime", displayName: "Date Time", type: "datetime" },
            ]
         case Controllers.ClientApiKey:
            return [
               { key: "KeyName", displayName: "Key Name" },
               { key: "ApiKey", displayName: "Api Key" },
            ]
      }
   };
   const renderComparator = (keyObj: any) => {
      switch (keyObj.type) {
         case 'boolean':
            return <BoolComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={keyObj.key} texts={keyObj.texts} />;
         case 'datetime':
         case 'date':
            return <DateTimeComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={keyObj.key} onlyDate={keyObj.type === "date"} />
         case 'geometry':
            return <GeometryComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={keyObj.key} />
         case 'html':
            return <HtmlComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={keyObj.key} />
         case 'file':
            return <FileComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={keyObj.key} />
         default:
            return <TextComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={keyObj.key} />
      }
   }
   if (!changedValues)
      return null;
   return (
      <Table>
         <TableBody>
            {
               getAuditEntityKeys(entityName)?.map((keyObj: any, index: number) => {
                  return <TableRow key={`${keyObj.key}_${index}`}>
                     <TableCell style={{ maxWidth: "200px" }}>{keyObj.displayName}: </TableCell>
                     <TableCell>
                        {renderComparator(keyObj)}
                     </TableCell>
                  </TableRow>
               })
            }
            {
               (entityName === Controllers.Client || entityName === Controllers.Device || entityName === Controllers.Person) &&
               <>
                  <TableRow>
                     <TableCell colSpan={2}><strong>Escalation:</strong></TableCell>
                  </TableRow>
                  {changedValues.Escalation !== null || oldValues?.Escalation !== null ?
                     <>
                        {getAuditEntityKeys(Controllers.Escalation)?.map((keyObj: any, index: number) => {
                           return (
                              <TableRow key={index}>
                                 <TableCell style={{ width: '20%' }}>{keyObj.displayName}: </TableCell>
                                 <TableCell>
                                    <Grid container spacing={2}>
                                       <Grid item xs={12} md={3}>
                                          <TextComparetor oldValueJson={oldValues?.Escalation} changedValueJson={changedValues?.Escalation} compareKey={keyObj.key1} />
                                       </Grid>
                                       <Grid item xs={12} md={3}>
                                          <TextComparetor oldValueJson={oldValues?.Escalation} changedValueJson={changedValues?.Escalation} compareKey={keyObj.key2} />
                                       </Grid>
                                       <Grid item xs={12} md={3}>
                                          <TextComparetor oldValueJson={oldValues?.Escalation} changedValueJson={changedValues?.Escalation} compareKey={keyObj.key3} />
                                       </Grid>
                                       <Grid item xs={12} md={3}>
                                          <TextComparetor oldValueJson={oldValues?.Escalation} changedValueJson={changedValues?.Escalation} compareKey={keyObj.key4} />
                                       </Grid>
                                    </Grid>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </>
                     :
                     <TableRow>
                        <TableCell colSpan={2}>Don't have escalation.</TableCell>
                     </TableRow>
                  }
               </>
            }
            {
               (entityName === Controllers.Client || entityName === Controllers.Device) &&
               <>
                  <TableRow>
                     <TableCell colSpan={2}><strong>Response instruction:</strong></TableCell>
                  </TableRow>
                  {changedValues[entityName]?.ResponseInstruction ?
                     <>
                        {getAuditEntityKeys(Controllers.ResponseInstruction)?.map((keyObj: any, index: number) => {
                           return (
                              <TableRow key={index}>
                                 {entityName === "Person" &&
                                    <>
                                       <TableCell>{keyObj.displayName}: </TableCell>
                                       <TableCell>
                                          <TextComparetor oldValueJson={oldValues ? oldValues[entityName]?.ResponseInstruction : null} changedValueJson={changedValues[entityName]?.ResponseInstruction} compareKey={keyObj.key} />
                                       </TableCell>
                                    </>
                                 }
                                 {entityName === "Client" &&
                                    <ClientResponseInstructionComparetor oldValueJson={oldValues ? oldValues[entityName]?.ResponseInstruction : null} changedValueJson={changedValues[entityName]?.ResponseInstruction} compareKey={keyObj.key} />
                                 }
                                 {entityName === "Device" &&
                                    <DeviceResponseInstructionComparetor oldValueJson={oldValues ? oldValues[entityName]?.ResponseInstruction : null} changedValueJson={changedValues[entityName]?.ResponseInstruction} compareKey={keyObj.key} />
                                 }
                              </TableRow>
                           );
                        })}
                     </>
                     :
                     <TableRow>
                        <TableCell colSpan={2}>Don't have response instruction.</TableCell>
                     </TableRow>
                  }
               </>
            }
            {
               entityName === Controllers.Person &&
               <>
                  <TableRow>
                     <TableCell colSpan={2}><strong>Person client relation:</strong></TableCell>
                  </TableRow>
                  {changedValues.PersonClientRelation !== null ?
                     <>
                        {getAuditEntityKeys(Controllers.PersonClientRelation)?.map((keyObj: any, index: number) => {
                           return (
                              <TableRow key={index}>
                                 <TableCell>{keyObj.displayName}: </TableCell>
                                 <TableCell>
                                    <TextComparetor oldValueJson={oldValues?.PersonClientRelation} changedValueJson={changedValues.PersonClientRelation} compareKey={keyObj.key} />
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </>
                     :
                     <TableRow>
                        <TableCell colSpan={2}>Don't have client relation.</TableCell>
                     </TableRow>
                  }
               </>
            }
            {
               entityName === Controllers.Client && changedValues?.Client?.ClientReportRecipient?.length > 0 &&
               <>
                  <TableRow>
                     <TableCell colSpan={2}><strong>Report recipient:</strong></TableCell>
                  </TableRow>
                  {changedValues?.Client?.ClientReportRecipient !== null ?
                     <>
                        {changedValues?.Client?.ClientReportRecipient?.map((clientReportRecipient: any, index: number) => {
                           let oldValueJson = clientReportRecipient?.Person;
                           let newValueJson = clientReportRecipient?.Person
                           if (clientReportRecipient?.Type === "Remove") {
                              newValueJson = null;
                           }
                           else if (newValueJson?.Oid === changedValues?.ClientReportRecipient?.Person?.Oid) {
                              oldValueJson = {
                                 Firstname: "",
                                 Lastname: "",
                                 Email: ""
                              };
                           }
                           return (
                              <TableRow>
                                 <TableCell key={index} style={{ width: '20%' }}>{"Recipient " + (index + 1)}: </TableCell>
                                 <TableCell>
                                    <Grid container spacing={2}>
                                       <Grid item xs={12} md={3}>
                                          <TextComparetor oldValueJson={oldValueJson} changedValueJson={newValueJson} compareKey={"Firstname"} />
                                       </Grid>
                                       <Grid item xs={12} md={3}>
                                          <TextComparetor oldValueJson={oldValueJson} changedValueJson={newValueJson} compareKey={"Lastname"} />
                                       </Grid>
                                       <Grid item xs={12} md={6}>
                                          <TextComparetor oldValueJson={oldValueJson} changedValueJson={newValueJson} compareKey={"Email"} />
                                       </Grid>
                                    </Grid>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </>
                     :
                     <TableRow>
                        <TableCell colSpan={2}>Don't have report recipient.</TableCell>
                     </TableRow>
                  }
               </>
            }
            {
               entityName === Controllers.Device && (oldValues?.Device.DeviceType === "Vehicle" || changedValues?.Device.DeviceType === "Vehicle") &&
               <>
                  <TableRow>
                     <TableCell colSpan={2}><strong>Vehicle information:</strong></TableCell>
                  </TableRow>
                  {changedValues.Vehicle !== null ?
                     <>
                        {getAuditEntityKeys(Controllers.Vehicle)?.map((keyObj: any, index: number) => {
                           return (
                              <TableRow key={index}>
                                 <TableCell>{keyObj.displayName}: </TableCell>
                                 <TableCell>
                                    <TextComparetor oldValueJson={oldValues?.Vehicle} changedValueJson={changedValues.Vehicle} compareKey={keyObj.key} />
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </>
                     :
                     <TableRow>
                        <TableCell colSpan={2}>Don't have vehicle information.</TableCell>
                     </TableRow>
                  }
               </>
            }
            {
               (auditOn === "Add image" || auditOn === "Delete image") &&
               <>
                  <TableRow>
                     <TableCell colSpan={2}><strong>{changedValues.ImageName}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell colSpan={2}><Image src={`${Common.ClientImagePath}/${changedValues.ImagePath}`} zoom={true} style={{ height: "194px" }} /></TableCell>
                  </TableRow>
               </>
            }
            {
               entityName === Controllers.Trip &&
               <TripPersonAndLocationComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={""} />
            }
            {
               entityName === Controllers.HazardLocation &&
               <HazardLocationGeometryComparetor oldValueJson={oldValues} changedValueJson={changedValues} compareKey={""} />
            }
         </TableBody>
      </Table>
   );
};

export default AuditLogs;
