import React, { useEffect, useRef, useState } from "react";
import { Common } from "../../../constants/common";
import { ILeaderSearchProps } from "../../../types/propType";
import { TextField, FormControl, Autocomplete, CircularProgress } from "@mui/material";
import PersonService from "./../../../services/person.service";
import { StatusCode } from "../../../constants/constant";
import { withStyles } from 'tss-react/mui';
import customInputStyle from "../../../assets/jss/components/customInputStyle";
const LeaderSearch: React.FC<ILeaderSearchProps> = ({ clientOid, classes, setValue, value, disabled }) => {
   const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
   const previousController = useRef() as any;
   const [open, setOpen] = useState(false);
   const loading = open && filteredSuggestions.length === 0;
   const [processiong, setProcessing] = useState(false);
   const [selectedValue, setSelectedValue] = useState<any>({});
   useEffect(() => {
      setSelectedValue({
         oid: value?.Oid,
         employeeID: value?.EmployeeID,
         firstName: value?.FirstName,
         lastName: value?.LastName,
         email: value?.Email,
         phoneNumber1: value?.PhoneNumber1,
         workAddress: value?.WorkAddress,
      });
   }, [value])
   const onAutoSearch = async (value: string) => {
      if (previousController.current) {
         previousController.current.abort();
      }
      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;
      if (value.length >= Common.SearchAfterCount) {
         setProcessing(true);
         PersonService.searchPersonLeaders({ searchText: value, clientOid }, signal).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setFilteredSuggestions(res?.data ?? []);
            }
            setProcessing(false);
         });
      }
      else {
         setFilteredSuggestions([]);
      }
   }

   const onSuggestionSelect = (value: any) => {
      setSelectedValue(value);
      if (value) {
         setValue({
            Oid: value.oid,
            EmployeeID: value.employeeID,
            FirstName: value.firstName,
            LastName: value.lastName,
            Email: value.email,
            PhoneNumber1: value.phoneNumber1,
            WorkAddress: value.workAddress,
         });
      }
      else {
         setValue({
            EmployeeID: "",
            FirstName: "",
            LastName: "",
            Email: "",
            PhoneNumber1: "",
            WorkAddress: "",
         });
      }
   }
   const getOptionLabel = (option: any) => {
      const optionsDetails: string[] = []
      if (!Common.stringNullOrEmpty(option?.employeeID)) {
         optionsDetails.push(option.employeeID);
      }
      if (!Common.stringNullOrEmpty(option?.firstName)) {
         optionsDetails.push(option.firstName);
      }
      if (!Common.stringNullOrEmpty(option?.lastName)) {
         optionsDetails.push(option.lastName);
      }
      if (!Common.stringNullOrEmpty(option?.email)) {
         optionsDetails.push(option.email);
      }
      if (!Common.stringNullOrEmpty(option?.phoneNumber1)) {
         optionsDetails.push(option.phoneNumber1);
      }
      return optionsDetails.join(" ");
   }
   React.useEffect(() => {
      if (!open) {
         setFilteredSuggestions([]);
      }
   }, [open]);
   return (
      <FormControl fullWidth className={classes.formControl}>
         <Autocomplete
            id="UserLeader"
            open={open}
            onOpen={() => {
               setOpen(true);
            }}
            onClose={() => {
               setOpen(false);
            }}
            loading={loading}
            value={selectedValue}
            filterOptions={(x) => x}
            getOptionLabel={(option) => getOptionLabel(option)}
            options={filteredSuggestions}
            onInputChange={(event, newInputValue) => {
               onAutoSearch(newInputValue);
            }}
            onChange={(event: any, newValue: any) => {
               onSuggestionSelect(newValue);
            }}
            disabled={disabled}
            renderInput={(params) => (
               <TextField
                  variant="standard"
                  {...params}
                  label="User Leader"
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                        <React.Fragment>
                           {processiong ? (
                              <CircularProgress color="inherit" size={20} />
                           ) : null}
                           {params.InputProps.endAdornment}
                        </React.Fragment>
                     )
                  }}
               />
            )}
            loadingText={"Type Name / Email / Phone / Id to search..."}
         />
      </FormControl>
   )
}

export default withStyles(LeaderSearch, customInputStyle);
