import React from "react";

import { Box, Grid } from "@mui/material";

import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import copy from 'copy-to-clipboard';
import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import DeviceRow from "../../components/pages/Devices/DeviceRow";
import DeviceCard from "../../components/pages/Devices/DeviceCard";
import AddEditDevice from "../../components/pages/Devices/AddEditDevice";
import DeviceAdvanceFilter from "../../components/pages/Devices/DeviceAdvanceFilter";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
import AssignEscalation from "../../components/pages/Common/AssignEscalation";
import ImportDeviceModal from "../../components/pages/Devices/ImportDeviceModal";
import AssignDeviceToPerson from "../../components/pages/Devices/AssignDeviceToPerson";

import { IDevicePageState } from "../../types/stateType";
import { IDevicePageProps, IDownloadOption, IImportOption } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IDevice, IDeleteParams, IDeviceFilter } from "../../types/dataTypes";
import { mapDeviceObject } from "../../utils/commonMaps";
import { TableHeader } from "../../constants/tableHeader";
import DeviceService from "../../services/device.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import deviceService from "../../services/device.service";
import { withSnackbar } from "../../components/Common/Snackbar";
import SetDeviceResponseInstruction from "../../components/pages/Common/ResponseInstruction/SetDeviceResponseInstruction";
import AddEditPerson from "../../components/pages/Persons/AddEditPerson";
import { allowDeviceAddEdit, allowPersonAddEdit } from "../../utils/PermissionHelpers";
import AddDeviceTest from "../../components/pages/DeviceEvents/AddDeviceTest";
class Devices extends React.Component<IDevicePageProps, IDevicePageState> {
   filterOptions: IDeviceFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      MonitoringStation: Common.EmptyGuid,
      IsShowUnassingned: false,
      ClientOid: Common.EmptyGuid,
      frontendURL: window.location.origin,
      sortString: "Client.Name,DeviceId"
   }

   state: IDevicePageState = {
      devices: [],
      openAddEditDeviceModal: false,
      selectedDevice: null,
      showConfirmDialog: false,
      totalRecords: 0,
      openAuditLogs: false,
      auditLogEntity: null,
      openAssignEscalationModal: false,
      openImportModal: false,
      importModelName: undefined,
      openAssignDeviceToPersonModal: false,
      deleteOnSuccess: false,
      openSetResponseInstructionModal: false,
      openAddEditPersonModal: false,
      personOid: null,
      openDeviceTestModal: false
   };

   componentDidMount() {
      const searchParams = new URLSearchParams(document.location.search)
      const clientOid = searchParams.get('ClientOid');
      if (clientOid) {
         this.filterOptions.ClientOid = clientOid;
      }
      if (!this.props.isFromSearch) {
         this.searchDevices();
      }
   }

   //#region pagination and searching
   searchDevices = () => {
      let newFilterOptions: IDeviceFilter = { ...this.filterOptions };
      this.setState({ ...this.state, devices: [] }, () => {
         DeviceService.searchDevices(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  devices: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.devices.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IDeviceFilter = { ...this.filterOptions };
      DeviceService.searchDevices(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let deviceList = [...this.state.devices];
            deviceList = deviceList.concat(res?.data);
            this.setState({
               ...this.state,
               devices: deviceList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchDevices();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchDevices();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      // if (val.length > Common.SearchAfterCount) {
      this.searchDevices();
      // }
   }

   onAdvanceSearch = (advanceFilter: any) => {
      this.filterOptions.MonitoringStation = advanceFilter.MonitoringStation;
      this.filterOptions.IsShowUnassingned = advanceFilter.IsShowUnassingned;
      this.filterOptions.ClientOid = advanceFilter.ClientOid;
      this.filterOptions.page = 1;
      this.searchDevices();
   }
   //#endregion

   //#region Add/Edit/Delete Device

   onToggleAddEditDevice = () => {
      this.setState({ ...this.state, openAddEditDeviceModal: !this.state.openAddEditDeviceModal, selectedDevice: null, openDeviceTestModal: false });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditDeviceModal: false }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchDevices();
      });
   }

   onSelectDevice = (selectedDevice: any, isForDelete: boolean = false) => {
      if (isForDelete) {
         if (selectedDevice.personOid) {
            this.setState({ ...this.state, selectedDevice: mapDeviceObject(selectedDevice), openAssignDeviceToPersonModal: true, deleteOnSuccess: true });
         }
         else
            this.setState({ ...this.state, selectedDevice: mapDeviceObject(selectedDevice), showConfirmDialog: true });
      }
      else
         this.setState({ ...this.state, selectedDevice: mapDeviceObject(selectedDevice), openAddEditDeviceModal: true });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedDevice?.Oid };
      DeviceService.deleteDevice(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedDevice: null, showConfirmDialog: false }, () => this.searchDevices());
         } else if (res?.status === StatusCode.BadRequest) {
            this.setState({ ...this.state, openAssignDeviceToPersonModal: true, deleteOnSuccess: true });
         }

      });
   }

   onCancelDelete = () => {
      this.setState({ ...this.state, selectedDevice: null, showConfirmDialog: false, deleteOnSuccess: false, openDeviceTestModal: false });
   }
   onTestDeviceClick = (selectedDevice: any) => {
      this.setState({ ...this.state, selectedDevice: mapDeviceObject(selectedDevice), openDeviceTestModal: true });
   }
   //#endregion

   //#region Assign device escalation
   openAssignEscalation = (selectedDevice: any) => {
      const device: IDevice = mapDeviceObject(selectedDevice);
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedDevice: device
      });
   }

   onSaveEscalation = () => {
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedDevice: null
      }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchDevices();
      });
   }

   onCloseAssignEscalationModal = () => {
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedDevice: null
      });
   }

   //#endregion

   //#region Audit Log
   toggleAuditLogModal = (entityName: string | null = null) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedDevice: null, auditLogEntity: entityName });
   }

   openDeviceAuditLogModal = (selectedDevice: any, entityName: string | null = null) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedDevice: mapDeviceObject(selectedDevice), auditLogEntity: entityName });
   }
   //#endregion

   //#region Import

   toggleImportModal = (name: string) => {
      this.setState({ ...this.state, openImportModal: !this.state.openImportModal, importModelName: name });
   }

   onImportDevices = () => {
      this.setState({ ...this.state, openImportModal: false, importModelName: undefined }, () => {
         this.searchDevices();
      });
   }
   //#endregion         

   //#region Download
   onDownload = (exportType: string) => {
      let newFilterOptions: IDeviceFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      DeviceService.exportDeviceListByMonitoringStation(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "Devices";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   //#endregion

   //#region Assign Device to person
   openAssignDeviceToPersonModal = (selectedDevice: any) => {
      this.setState({ ...this.state, selectedDevice: mapDeviceObject(selectedDevice), openAssignDeviceToPersonModal: true, deleteOnSuccess: false });
   }

   onCloseAssignDeviceToPersonModal = () => {
      this.setState({
         ...this.state,
         openAssignDeviceToPersonModal: !this.state.openAssignDeviceToPersonModal,
         selectedDevice: null,
         deleteOnSuccess: false
      });
   }

   onSaveDeviceToPerson = () => {
      if (this.state.deleteOnSuccess) {
         this.setState({ ...this.state, showConfirmDialog: true, openAssignDeviceToPersonModal: false, deleteOnSuccess: false });
      }
      else {
         this.setState({ ...this.state, openAssignDeviceToPersonModal: false, selectedDevice: null }, () => {
            if (this.props.isFromDependenciesDialogue)
               this.props.onChangeDependencies();
            else
               this.searchDevices();
         });
      }
   }
   //#endregion
   //#region Device Escalation Details
   onClickCopyDeviceEscalationText = (deviceOid: string) => {
      deviceService.getResponseInstruction(deviceOid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            copy(res?.data);
            this.props.snackbarShowMessage("Device esclataion details copied to clipboard.");
         }
         else {
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }
   onClickCopyEscalationLink = (deviceOid: string) => {
      const link: string = `${window.location.origin}/escalationdetail/${deviceOid}`;
      copy(link);
      this.props.snackbarShowMessage("Device esclataion link copied to clipboard.");
   }
   onClickViewResponseInstruction = (deviceOid: string) => {
      const link: string = `${window.location.origin}/escalationdetail/${deviceOid}`;
      window.open(link, '_blank');
   }
   //#endregion

   //#region set response instruction
   openResponseInstructionModel = (selectedDevice: any) => {
      const device: IDevice = mapDeviceObject(selectedDevice);
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedDevice: device
      });
   }

   onSaveResponseInstruction = () => {
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedDevice: null
      }, () => {
         this.searchDevices();
      });
   }

   onCloseResponseInstructionModal = () => {
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedDevice: null
      });
   }

   //#endregion

   //#region person dialogue
   onSavePerson = () => {
      this.setState({ ...this.state, openAddEditPersonModal: false, personOid: null }, () => this.searchDevices());
   }
   onToggleAddEditPerson = () => {
      this.setState({ ...this.state, openAddEditPersonModal: !this.state.openAddEditPersonModal, personOid: null });
   }
   onSelectPerson = (personOid: string) => {
      this.setState({ ...this.state, openAddEditPersonModal: true, personOid: personOid });
   }
   onValidateMonitoringAccountNumber = (exportType: string) => {
      let newFilterOptions: IDeviceFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      DeviceService.validateMonitoringAccountNumber(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "Devices_Validated";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   onExportDevicePersonMapping = (exportType: string) => {
      let newFilterOptions: IDeviceFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      DeviceService.exportDevicePersonMapping(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "DevicePersonMapping";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   //#endregion
   render() {
      const { totalRecords, openAddEditDeviceModal, selectedDevice, showConfirmDialog, auditLogEntity,
         openAuditLogs, openAssignEscalationModal, openImportModal, importModelName, openAssignDeviceToPersonModal, deleteOnSuccess, openSetResponseInstructionModal, openAddEditPersonModal, personOid, openDeviceTestModal } = this.state;
      const { isFromSearch, isFromDependenciesDialogue, showOnlyAssignment, showOnlyEscalation, actionPermission, showOnlyEdit } = this.props;
      const { devices } = isFromSearch ? this.props : this.state;
      const { searchText, clientOid } = this.props.params;
      if (searchText && searchText.trim().length > 0) {
         this.filterOptions.SearchText = searchText;
         this.props.params.searchText = null;
         window.history.pushState(null, "", window.location.href.replace("%20", " ").replace(searchText, ""));
      }
      if (clientOid && clientOid.length > 0) {
         this.filterOptions.ClientOid = clientOid;
         this.props.params.clientOid = null;
         window.history.pushState(null, "", window.location.href.replace(`/client/${clientOid}`, ""));
      }
      const deviceImportOptions: IImportOption[] = [];
      if (actionPermission["Device:ImportDevices"] && actionPermission["Device:ExportTemplateDevices"])
         deviceImportOptions.push({ name: "Devices", onClick: this.toggleImportModal });
      if (actionPermission["Device:ImportEscalations"] && actionPermission["Device:ExportTemplateEscalations"])
         deviceImportOptions.push({ name: "Escalations", onClick: this.toggleImportModal });
      if (actionPermission["Person:ImportDevicePersonMapping"] && actionPermission["Person:ExportTemplateDevicePersonMapping"])
         deviceImportOptions.push({ name: "DevicePersonMapping", displayName: "Device Person Mapping", onClick: this.toggleImportModal });
      if (actionPermission["Device:ImportPegasusData"])
         deviceImportOptions.push({ name: "PegasusData", displayName: "Pegasus Data", onClick: this.toggleImportModal });
      const downloadOptions: IDownloadOption[] = [];
      if (actionPermission["Device:ExportDeviceListByMonitoringStation"])
         downloadOptions.push({ name: "Devices", onClick: this.onDownload });
      if (actionPermission["Device:ExportDevicePersonMapping"])
         downloadOptions.push({ name: "Device Person Mapping", onClick: this.onExportDevicePersonMapping });
      if (actionPermission["Device:ValidateMonitoringAccountNumber"])
         downloadOptions.push({ name: "Monitor Account Number Validation", onClick: this.onValidateMonitoringAccountNumber });
      return (
         <PageLayout pageTitle="Devices" onCreate={this.onToggleAddEditDevice} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["Device:SearchSuggestions"] ? DeviceService.getSearchSuggestions : null} auditLogs={this.toggleAuditLogModal} importOptions={deviceImportOptions}
            isFromSerach={isFromSearch} isFromDependenciesDialogue={isFromDependenciesDialogue}
            advanceFilter={<DeviceAdvanceFilter initialValues={{ MonitoringStation: this.filterOptions.MonitoringStation, IsShowUnassingned: this.filterOptions.IsShowUnassingned, ClientOid: this.filterOptions.ClientOid }} onAdvanceSearch={this.onAdvanceSearch} />}
            downloadOptions={downloadOptions}
            actionPermission={{
               add: allowDeviceAddEdit(actionPermission) && (!Common.IsInRole("Monitoring Station Admin") && !Common.IsInRole("Monitoring Station Operator")),
               import: deviceImportOptions.length > 0,
               download: actionPermission["Device:ExportDeviceListByMonitoringStation"],
               auditlog: actionPermission["Audit:Device"],
               vadlidateMonitoringNumbers: actionPermission["Device:ValidateMonitoringAccountNumber"],
            }}
            showClientFilter={this.filterOptions.ClientOid !== Common.EmptyGuid ? true : false}
         >

            {/* assign device escalation to device */}
            {openAssignEscalationModal &&
               <AssignEscalation
                  open={openAssignEscalationModal}
                  close={this.onCloseAssignEscalationModal}
                  successCallback={this.onSaveEscalation}
                  escalationOid={selectedDevice?.EscalationOid}
                  oid={selectedDevice?.Oid}
                  escalationOn={Controllers.Device}
                  selectedEntityName={selectedDevice?.DeviceId}
                  clientOid={selectedDevice?.ClientOid}
                  allowSubmit={actionPermission["Device:AssignDefaultEscalation"] === true}
                  allowApplyAll={actionPermission["Device:AssignDefaultEscalationApplyAll"] === true && actionPermission["Device:GetDeviceCountByClientOid"]}
                  clientName={selectedDevice?.ClientName} />
            }
            {/* set response instruction to device */}
            {openSetResponseInstructionModal &&
               <SetDeviceResponseInstruction
                  open={openSetResponseInstructionModal}
                  close={this.onCloseResponseInstructionModal}
                  successCallback={this.onSaveResponseInstruction}
                  responseInstructionOid={selectedDevice?.ResponseInstructionOid}
                  oid={selectedDevice?.Oid}
                  responseInstructionFor={Controllers.Device}
                  selectedEntityName={selectedDevice?.DeviceId}
                  allowSubmit={actionPermission["Device:SetResponseInstruction"] === true}
                  actualDeviceType={selectedDevice?.DeviceType}
                  actualDeviceModel={selectedDevice?.DeviceModel}
                  clientName={selectedDevice?.ClientName}
               />
            }
            {/* add edit device modal */}
            {openAddEditDeviceModal &&
               <AddEditDevice
                  open={openAddEditDeviceModal}
                  close={this.onToggleAddEditDevice}
                  successCallback={this.onSave}
                  deviceOid={selectedDevice?.Oid}
                  allowEdit={allowDeviceAddEdit(actionPermission)} />
            }

            {
               openAuditLogs && selectedDevice === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Device} />
            }

            {
               openAuditLogs && selectedDevice !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={auditLogEntity ?? Controllers.Device}
                  oid={selectedDevice?.Oid ?? ""}
                  changedEntityTitle={selectedDevice?.DeviceId}
                  logType={auditLogEntity === "DeviceEvent" ? "Event" : undefined} />
            }
            {/* assign device to person which are associated with selected client */}
            {openAssignDeviceToPersonModal &&
               <AssignDeviceToPerson
                  open={openAssignDeviceToPersonModal}
                  close={this.onCloseAssignDeviceToPersonModal}
                  successCallback={this.onSaveDeviceToPerson}
                  deviceOid={selectedDevice?.Oid}
                  clientOid={selectedDevice?.ClientOid}
                  deviceId={selectedDevice?.DeviceId}
                  personOid={selectedDevice?.PersonOid}
                  deleteOnSuccess={deleteOnSuccess} />
            }
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getDeviceTableHeaders()} pagingInfoText="Devices" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords ? totalRecords : devices?.length} onPageChange={isFromSearch ? null : this.onPageChange} filerOptions={this.filterOptions}>
                  {devices && devices?.map((device: any, index: number) => {
                     return (
                        <DeviceRow
                           key={`row_${index}`}
                           device={device}
                           onEdit={this.onSelectDevice}
                           onDelete={this.onSelectDevice}
                           onShowAuditLog={this.openDeviceAuditLogModal}
                           onAssignEscalation={this.openAssignEscalation}
                           onAssignDeviceToPerson={this.openAssignDeviceToPersonModal}
                           showOnlyAssignment={showOnlyAssignment}
                           showOnlyEscalation={showOnlyEscalation}
                           showOnlyEdit={showOnlyEdit}
                           copyEscalationText={this.onClickCopyDeviceEscalationText}
                           copyEscalationLink={this.onClickCopyEscalationLink}
                           actionPermission={actionPermission}
                           onSetResponseInstruction={this.openResponseInstructionModel}
                           onSelectPerson={this.onSelectPerson}
                           viewResponseInstruction={this.onClickViewResponseInstruction}
                           snackbarShowMessage={this.props.snackbarShowMessage}
                           onTestDeviceClick={this.onTestDeviceClick}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={devices ? devices.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {devices && devices?.map((device: any, index: number) => {
                        return (
                           <DeviceCard
                              key={`card_${index}`}
                              device={device}
                              onEdit={this.onSelectDevice}
                              onDelete={this.onSelectDevice}
                              onShowAuditLog={this.openDeviceAuditLogModal}
                              onAssignEscalation={this.openAssignEscalation}
                              onAssignDeviceToPerson={this.openAssignDeviceToPersonModal}
                              showOnlyAssignment={showOnlyAssignment}
                              showOnlyEscalation={showOnlyEscalation}
                              showOnlyEdit={showOnlyEdit}
                              copyEscalationText={this.onClickCopyDeviceEscalationText}
                              copyEscalationLink={this.onClickCopyEscalationLink}
                              actionPermission={actionPermission}
                              onSetResponseInstruction={this.openResponseInstructionModel}
                              onSelectPerson={this.onSelectPerson}
                              viewResponseInstruction={this.onClickViewResponseInstruction}
                              snackbarShowMessage={this.props.snackbarShowMessage}
                              onTestDeviceClick={this.onTestDeviceClick}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete device */}
            {showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete} />
            }

            {
               openImportModal &&
               <ImportDeviceModal
                  open={openImportModal}
                  close={this.toggleImportModal}
                  successCallback={this.onImportDevices}
                  name={importModelName} />
            }

            {
               openAddEditPersonModal &&
               <AddEditPerson
                  open={openAddEditPersonModal}
                  close={this.onToggleAddEditPerson}
                  successCallback={this.onSavePerson}
                  personOid={personOid}
                  allowEdit={allowPersonAddEdit(null)} />
            }

            {
               openDeviceTestModal &&
               <AddDeviceTest
                  open={openDeviceTestModal}
                  close={this.onCancelDelete}
                  successCallback={this.onCancelDelete}
                  deviceOid={selectedDevice?.Oid ?? ""}
                  deviceId={selectedDevice?.DeviceId ?? ""}
                  isPersonAssigned={selectedDevice?.PersonOid ? true : false} />
            }
         </PageLayout>
      )
   }
}

export default withSnackbar(withParams(Devices));
