import { Theme } from "@mui/material";
import { dangerColor, grayColor } from "../appStyle";

const fileUploadStyle = (theme: Theme) => ({
   container: {
      display: "flex",
      justifyContent: "center"
   },
   fileUploadBlock: {
      display: "flex",
      alignItems: "center",
      minWidth: "350px",
      maxWidth: "508px",
      height: "80px",
      border: `dashed 2px`,
      borderColor: "#666",
      padding: theme.spacing(2),
      borderRadius: 5,
      cursor: "pointer",
      flexGrow: 0,
      color: grayColor[0],
      width:"600px"
   },
   success: {
      color: "#4caf50"
   },
   error: {
      color: dangerColor[0],
      fontSize: 15
   },
   content: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1
   },
   fileTypes: {
      fontSize: 12
   },
   fileNameBlock: {
      marginTop: theme.spacing(1)
   }
});

export default fileUploadStyle;
