import { Controllers } from "../constants/constant";
import { IDeviceEvent } from "../types/dataTypes";
import request from "../utils/request";

class DeviceEventService {
   searchDeviceTestReportRecords = (params: any) => {
      return request({
         url: `${Controllers.DeviceEvent}/Search`,
         method: 'get',
         params
      });
   }

   save = (data: IDeviceEvent) => {
      return request({
         url: `${Controllers.DeviceEvent}`,
         method: 'post',
         data
      })
   }

   exportDeviceTestReport(params: any) {
      return request({
         url: `${Controllers.DeviceEvent}/Export`,
         method: 'get',
         responseType: 'blob',
         params
      })
   }
}

export default new DeviceEventService();
