import React from 'react';
import NoRecordsFound from '../NoRecordsFound';
import InfiniteScroll from 'react-infinite-scroll-component';

interface ICustomInfiniteScrollProps {
   hasMore: boolean;
   dataLength: number;
   next: any;
   children: any;
}

const CustomInfiniteScroll: React.FC<ICustomInfiniteScrollProps> = (props) => {
   const { hasMore, dataLength, next, children } = props;
   return (
      <>
         {
            dataLength > 0 ?
               <InfiniteScroll dataLength={dataLength} next={next} hasMore={hasMore} loader={<></>}>
                  {children}
               </InfiniteScroll>
               : <NoRecordsFound />

         }
      </>
   );
}

export default CustomInfiniteScroll;
