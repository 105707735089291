import * as React from 'react';
import CustomSelect from '../../../Common/CustomSelect';
import { Formik } from 'formik';
import { ISelectOption } from '../../../../types/dataTypes';
interface IDeviceTypeSelectionProps {
   deviceType: string;
   setDeviceType: any;
   deviceTypeSelectionList: string[];
}
const DeviceTypeSelection: React.FC<IDeviceTypeSelectionProps> = ({ deviceType, setDeviceType, deviceTypeSelectionList }) => {
   const handleItemClick = (
      selected: string
   ) => {
      setDeviceType(selected);
   };
   const options: ISelectOption[] = deviceTypeSelectionList.map((item: string) => { return { label: item, value: item } });
   const initialValue = {
      DeviceCategory: deviceType
   };
   return (
      <Formik enableReinitialize={true} initialValues={initialValue} onSubmit={() => { }}>
         <CustomSelect
            labelText="Device Category"
            id="DeviceCategory"
            inputProps={{
               required: true,
               name: "DeviceCategory",
               onChange: (e: any) => {
                  handleItemClick(e.target.value);
               }
            }}
            value={initialValue.DeviceCategory}
            options={options}
         />
      </Formik >
   );
}
export default DeviceTypeSelection;
