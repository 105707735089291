import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { IAddEditMonitoringStationProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import { IMonitoringStation } from '../../../types/dataTypes';
import { Resources } from '../../../constants/resources';
import { Common } from '../../../constants/common';
import { StatusCode } from '../../../constants/constant';
import MonitoringStationService from '../../../services/monitoringstation.service';
import { ConnectedFocusError } from '../../Common/FocusError';
const AddEditMonitoringStation: React.FC<IAddEditMonitoringStationProps> = ({ open, close, successCallback, monitoringStation }) => {
   const [error, setError] = useState<any>({});
   let initialValues: IMonitoringStation = {
      Name: "",
      URL: "",
      Username: "",
      Password: "",
      PhoneNumber: "",
   }

   const validationSchema = Yup.object().shape({
      Name: Yup.string().trim(Resources.Error_MonitoringStationName_Required).required(Resources.Error_MonitoringStationName_Required),
      URL: Yup.string().trim(Resources.Error_APIEndPoint_Required).required(Resources.Error_APIEndPoint_Required),
      Username: Yup.string().trim(Resources.Error_Username_Required).required(Resources.Error_Username_Required),
      Password: Yup.string().trim(Resources.Error_Password_Required).required(Resources.Error_Password_Required),
      PhoneNumber: Yup.string().trim(Resources.Error_PhoneNumber_Required)
         .required(Resources.Error_PhoneNumber_Required)
         .matches(Common.PhoneRegex, Common.PhoneValidationMessage)
         .min(Common.PhoneNumberLength, Common.PhoneNumberLengthValidationMessage)
         .test('LengthWithoutSpaces', Common.PhoneNumberLengthValidationMessage, val => !val || val!.replace(/\s/g, "").length >= Common.PhoneNumberLength)
      ,
   });
   const onSubmit = (values: any, setServerError: any) => {
      MonitoringStationService.saveMonitoringStation(values).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }

   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <Formik initialValues={monitoringStation !== null ? monitoringStation : initialValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{monitoringStation !== null ? "Edit" : "Add"} Monitoring Station</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <input type="hidden" name="Oid" value={values.Oid} />
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <CustomInput
                              labelText="Monitoring Station Name"
                              id="name"
                              inputProps={{
                                 required: true,
                                 name: "Name"
                              }}
                              customErrorMessage={error["name"]?.message}
                              value={values.Name}
                           />
                           <CustomInput
                              labelText="Api endpoint"
                              id="uRL"
                              inputProps={{
                                 required: true,
                                 name: "URL"
                              }}
                              customErrorMessage={error["uRL"]?.message}
                              value={values.Name}
                           />
                           <CustomInput
                              labelText="Username"
                              id="username"
                              inputProps={{
                                 required: true,
                                 name: "Username"
                              }}
                              customErrorMessage={error["username"]?.message}
                              value={values.Name}
                           />
                           <CustomInput
                              labelText="Password"
                              id="password"
                              inputProps={{
                                 required: true,
                                 name: "Password"
                              }}
                              customErrorMessage={error["password"]?.message}
                              value={values.Name}
                           />
                           <CustomInput
                              labelText="Phone Number"
                              id="phoneNumber"
                              inputProps={{
                                 required: true,
                                 name: "PhoneNumber"
                              }}
                              customErrorMessage={error["phoneNumber"]?.message}
                              value={values.Name}
                           />
                        </Grid>
                        <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                     </Grid>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     <Button color="primary" type="submit">Save</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AddEditMonitoringStation;
