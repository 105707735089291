import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment } from "@mui/material";
import { IAddEditDeviceProps } from '../../../types/propType';
import Button from '../../Common/Button';
import CustomInput from '../../Common/CustomInput';
import CustomSelect from '../../Common/CustomSelect';
import SearchableSelect from '../../Common/SearchableSelect';
import TagsSelect from '../../Common/TagsSelect';
import CustomValidationSummary from '../../Common/CustomValidationSummary';
import { Resources } from '../../../constants/resources';
import { IDevice, ISelectOption, IDeviceModalSelectOption, IGetParams, IClientSelectOption, ISearchableSelectParams } from '../../../types/dataTypes';
import { StatusCode } from '../../../constants/constant';
import { mapDeviceObject } from "../../../utils/commonMaps";
import DeviceService from '../../../services/device.service';
import ClientService from '../../../services/client.service';
import MonitoringStationService from '../../../services/monitoringstation.service';
import HelpText from '../../Common/HelpText';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import CustomRadioButtonGroup from '../../Common/CustomRadioButtonGroup';
import CustomDateTimePicker from '../../Common/CustomDateTimePicker';
import { Common } from '../../../constants/common';
import CustomDatePicker from '../../Common/CustomDatePicker';
import SearchableServerInput from '../../Common/SearchableServerInput';
import { ConnectedFocusError } from '../../Common/FocusError';
const AddEditDevice: React.FC<IAddEditDeviceProps> = ({ open, close, successCallback, deviceOid, allowEdit }) => {
   const [deviceStatusOptions, setDeviceStatusOptions] = useState([]);
   const [deviceMakeOptions, setDeviceMakeOptions] = useState([]);
   const [deviceModelOptions, setDeviceModelOptions] = useState([]);
   const [platformOptions, setPlatformOptions] = useState([]);
   const [monitoringStations, setMonitoringStations] = useState([]);
   const [error, setError] = useState<any>({});
   const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
   const [gasTagSelectOptions, setGasTagSelectOptions] = useState([]);
   const [monitoredByOptions, setMonitoredByOptions] = useState([]);
   const [showAdditionalFields, setShowAdditionalFields] = useState(false);
   const disable = Common.editMonitoringAccountNumberOnly();

   const getDeviceModelOptionsForMake = async (selectedDeviceMake: string) => {
      try {
         const res = await DeviceService.getFixedSelectOptions({ deviceMake: selectedDeviceMake });
         if (res?.status === StatusCode.Success && res.data) {
            return res?.data?.deviceModelOptions;
         }
      } catch (error) {
         console.error("Error fetching device options:", error);
      }
      return [];
   }

   const getPlatFormOptionsForMake = async (selectedDeviceMake: string) => {
      try {
         const res = await DeviceService.getFixedSelectOptions({ deviceMake: selectedDeviceMake });
         if (res?.status === StatusCode.Success && res.data) {
            return res?.data?.platformOptions;
         }
      } catch (error) {
         console.error("Error fetching device options:", error);
      }
      return [];
   }

   let initialValues: IDevice = {
      DeviceId: "",
      DeviceType: "",
      DeviceStatus: "",
      DeviceMake: "",
      DeviceModel: "",
      Platform: "",
      IMEI_RegistationNo: "",
      ICCID: "",
      GarminAuthCode: "",
      DeviceSerialNumber: "",
      DeviceMobileNumber: "",
      MonitoringAccountNumber: "",
      MonitoringStationOid: null,
      ClientOid: undefined,
      VehicleYear: "",
      VehicleMakeModel: "",
      VehicleColour: "",
      GasTags: "",
      PersonOid: null,
      MonitoredBy: "",
      Notes: "",
      LastPoll: null,
      DaysOffline: null,
      DeviceInAlarm: false,
      PegasusDisabled: false,
      DaysSinceGPSFix: null,
      DaysNotCharged: null,
      DateLastServiced: null,
      DateLastTested: null,
      EventResult: null,
      EventNote: null,
      PegasusDataLastUpdated: null,
      ActivationDate: null,
      WarrantyExpiryDate: null,
      ClientName: null
   }
   const [formValues, setFormValues] = useState<IDevice>(initialValues);
   const validationSchema = Yup.object().shape({
      DeviceType: Yup.string().trim(Resources.Error_DeviceType_Required).required(Resources.Error_DeviceType_Required),
      DeviceStatus: Yup.string().trim(Resources.Error_DeviceStatus_Required).required(Resources.Error_DeviceStatus_Required),
      DeviceId: Yup.string().trim(Resources.Error_DeviceId_Required).required(Resources.Error_DeviceId_Required).when("DeviceType", {
         is: (value: string) => value === "Vehicle",
         then: Yup.string().trim(Resources.Error_DisplayName_Required).required(Resources.Error_DisplayName_Required),
      }),
      DeviceModel: Yup.string().trim(Resources.Error_DeviceModel_Required).required(Resources.Error_DeviceModel_Required).when("DeviceType", {
         is: (value: string) => value === "Vehicle",
         then: Yup.string().trim(Resources.Error_VehicleModel_Required).required(Resources.Error_VehicleModel_Required),
      }),
      IMEI_RegistationNo: Yup.string().when("DeviceModel", {
         is: (value: string) => isIMEIRequired(value),
         then: Yup.string().trim().required(Resources.Error_IMEI_Required),
      }),
      Platform: Yup.string().trim(Resources.Error_Platform_Required).required(Resources.Error_Platform_Required),
      // MonitoringAccountNumber: Yup.string().nullable().trim().matches(Common.MonitoringAccountNumberRegex, "Not a valid monitor account number"),
      MonitoringAccountNumber: Yup.string().nullable().test("length", 'Monitoring Account Number length should be less than 255 characters.', val => !val || val.toString().length <= 255),
      VehicleYear: Yup.string().nullable().when("DeviceType", {
         is: (value: string) => value === "Vehicle",
         then: Yup.string().trim(Resources.Error_VehicleYear_Required).required(Resources.Error_VehicleYear_Required),
      }),
      VehicleMakeModel: Yup.string().nullable().when("DeviceType", {
         is: (value: string) => value === "Vehicle",
         then: Yup.string().trim(Resources.Error_Make_Required).required(Resources.Error_Make_Required),
      }),
      MonitoringStationOid: Yup.string().nullable().trim().when("MonitoredBy", {
         is: (value: string) => value === "Guardian Angel Safety",
         then: Yup.string().trim(Resources.Error_MonitoringStation_Required).required(Resources.Error_MonitoringStation_Required).nullable(),
      }),
      Notes: Yup.string().nullable().test("length", 'Device notes length should be less than 2000 characters.', val => !val || val.toString().length <= 2000),

      DeviceMobileNumber: Yup.string()
         .matches(Common.PhoneRegex, Common.MobileValidationMessage)
         .min(Common.PhoneNumberLength, Common.MobileNumberLengthValidationMessage)
         .test('LengthWithoutSpaces', Common.MobileNumberLengthValidationMessage, val => !val || val!.replace(/\s/g, "").length >= Common.PhoneNumberLength)
   });

   useEffect(() => {
      if (deviceOid) {
         const getMethodParams: IGetParams = {
            oid: deviceOid
         };
         DeviceService.getDevice(getMethodParams).then(async (res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               setFormValues(mapDeviceObject(res?.data));
               setShowAdditionalFields(res?.data?.showAdditionalFields ?? false);
               const updatedDeviceModelOptions = await getDeviceModelOptionsForMake(res?.data?.deviceMake ?? "");
               setDeviceModelOptions(updatedDeviceModelOptions);
               const updatedPlatformOptions = await getPlatFormOptionsForMake(res?.data?.deviceMake ?? "");
               setPlatformOptions(updatedPlatformOptions);
            }
         })
      }
      DeviceService.getFixedSelectOptions({ deviceMake: "" }).then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setMonitoredByOptions(res?.data?.monitoredByOptions);
            setDeviceStatusOptions(res?.data?.statusOptions);
            setDeviceMakeOptions(res?.data?.deviceMakeOptions);
            setDeviceModelOptions(res?.data?.deviceModelOptions);
            setPlatformOptions(res?.data?.platformOptions);
            setDeviceTypeOptions(res?.data?.deviceTypeOptions);
            setGasTagSelectOptions(res?.data?.gasTagSelectOptions);
         }
      });
      MonitoringStationService.getMonitoringStationOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setMonitoringStations(res?.data);
         }
      });
   }, [deviceOid]); // eslint-disable-line react-hooks/exhaustive-deps

   const onSubmit = (values: any, setServerError: any) => {
      if (values.DeviceType !== "Vehicle" && !isGasTagsRequired(values.DeviceModel)) {
         values.GasTags = "";
      }
      DeviceService.saveDevice(values).then((res) => {
         if (res?.status === StatusCode.Success && res.data) {
            successCallback();
         } else if (res?.status === StatusCode.BadRequest) {
            if (res.data?.errors || res.data?.message) {
               setError(res.data);
            }
            else {
               setError({});
            }
            setServerError(Common.ConvertServerErrorToFormError(res.data));
         }
      });
   }
   const isGasTagsRequired = (value: string) => {
      let selectedModel = deviceModelOptions.filter((x: IDeviceModalSelectOption) => x.value === value);
      if (selectedModel.length > 0) {
         return selectedModel[0]["gasTagsRequired"];
      }
      return false;
   }

   const isIMEIRequired = (value: string): boolean => value === "P88L" || value === "P99L";

   const isIMEIDisabled = (value: string): boolean => value === "Halo on the Go";

   const getClientsSelectOptionsForDeviceScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForDeviceScreen(params, signal);
   }
   const getVehicleModelSuggestions = (params: ISearchableSelectParams, signal: any) => {
      return DeviceService.getVehicleModelSuggestions(params, signal);
   }
   const getVehicleMakeSuggestions = (params: ISearchableSelectParams, signal: any) => {
      return DeviceService.getVehicleMakeSuggestions(params, signal);
   }
   const allowSubmit = deviceOid ? (allowEdit ? true : false) : true;

   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <Formik enableReinitialize={true} initialValues={formValues} validationSchema={validationSchema} onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}>
            {({ values, setFieldValue, setFieldTouched, errors: formikErrors }) => (
               <Form noValidate>
                  <DialogTitle>{deviceOid ? (allowEdit ? "Edit" : "") : "Add"} Device</DialogTitle>
                  <DialogContent dividers>
                     <ConnectedFocusError />
                     <fieldset className={!allowSubmit ? "disabled" : ""} disabled={!allowSubmit}>
                        <input type="hidden" name="Oid" value={values.Oid} />
                        <Grid container spacing={2}>
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 disabled={disable}
                                 labelText="Device Type"
                                 id="DeviceType"
                                 inputProps={{
                                    required: true,
                                    name: "DeviceType",
                                    disabled: (deviceOid)
                                 }}
                                 value={values.DeviceType}
                                 options={deviceTypeOptions}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 disabled={disable}
                                 labelText={values.DeviceType === "Vehicle" ? "Display Name" : "Device ID"}
                                 id="DeviceId"
                                 inputProps={{
                                    required: true,
                                    name: "DeviceId",
                                    disabled: (deviceOid && Common.IsArrayContainRoleWithClient())
                                 }}
                                 customErrorMessage={error["deviceId"]?.message}
                                 value={values.DeviceId}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 disabled={disable}
                                 labelText="Device Status"
                                 id="DeviceStatus"
                                 inputProps={{
                                    required: true,
                                    name: "DeviceStatus",
                                 }}
                                 value={values.DeviceStatus}
                                 options={deviceStatusOptions}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment style={{ marginRight: "20px", marginTop: "5px" }} position="end">
                                          <HelpText label="" text={`Operational: In use, monitored\nSpare: In storage, non-monitored\nPending: Misplaced or awaiting commissioning, non-monitored\nAwaiting Repair: Not currently with user, monitored\nOn hold: Blackline/ Everywhere devices on snooze, non-monitored\nDecommissioned: A lost or damaged device that will never be used again, non-monitored`} />
                                       </InputAdornment>
                                    )
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 disabled={disable}
                                 labelText="Device Make"
                                 id="DeviceMake"
                                 inputProps={{
                                    required: false,
                                    name: "DeviceMake",
                                    onChange: async (e: any) => {
                                       const selectedDeviceMake = e.target.value;
                                       setFieldValue("DeviceMake", selectedDeviceMake);
                                       try {
                                          const updatedDeviceModelOptions = await getDeviceModelOptionsForMake(selectedDeviceMake);
                                          setDeviceModelOptions(updatedDeviceModelOptions);
                                          const updatedPlatformOptions = await getPlatFormOptionsForMake(selectedDeviceMake);
                                          setPlatformOptions(updatedPlatformOptions);
                                       } catch (error) {
                                          console.error("Error updating device model options:", error);
                                       }
                                    }
                                 }}
                                 value={values.DeviceMake}
                                 options={deviceMakeOptions}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              {values.DeviceType !== "Vehicle" ?
                                 <CustomSelect
                                    disabled={disable}
                                    labelText="Device Model"
                                    id="DeviceModel"
                                    inputProps={{
                                       required: true,
                                       name: "DeviceModel",
                                       onChange: async (e: any) => {
                                          const selectedDeviceModel = e.target.value;
                                          setFieldValue("DeviceModel", selectedDeviceModel);
                                          if (selectedDeviceModel === "Halo on the Go") {
                                             setFieldValue("IMEI_RegistationNo", '');
                                          }
                                          else {
                                             setFieldValue("IMEI_RegistationNo", values.IMEI_RegistationNo);
                                          }
                                       }
                                    }}
                                    value={values.DeviceModel}
                                    options={deviceModelOptions}
                                 />
                                 :
                                 <SearchableServerInput
                                    disabled={disable}
                                    labelText="Vehicle Model"
                                    id="DeviceModel"
                                    inputProps={{
                                       required: true,
                                       name: "DeviceModel"
                                    }}
                                    value={values.DeviceModel}
                                    setValue={(value: string) => {
                                       setFieldValue("DeviceModel", value)
                                    }}
                                    onTextChange={getVehicleModelSuggestions}
                                 />
                              }
                           </Grid>
                           {
                              isGasTagsRequired(values.DeviceModel) &&
                              <Grid item xs={12} md={6}>
                                 <TagsSelect
                                    disabled={disable}
                                    labelText="Gas Tags"
                                    id="GasTags"
                                    inputProps={{
                                       required: false,
                                       name: "GasTags"
                                    }}
                                    value={values.GasTags ? values.GasTags.split(",") : []}
                                    options={gasTagSelectOptions}
                                    setValue={(value: string[]) => {
                                       setFieldValue("GasTags", value.join(","))
                                    }}
                                 />
                              </Grid>
                           }
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 disabled={disable}
                                 labelText="Platform"
                                 id="Platform"
                                 inputProps={{
                                    required: true,
                                    name: "Platform"
                                 }}
                                 value={values.Platform}
                                 options={platformOptions}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 disabled={disable || isIMEIDisabled(values.DeviceModel)}
                                 labelText={values.DeviceType === "Vehicle" ? "Vehicle Registration" : "IMEI"}
                                 id="IMEI_RegistationNo"
                                 inputProps={{
                                    required: isIMEIRequired(values.DeviceModel),
                                    name: "IMEI_RegistationNo"
                                 }}
                                 value={values.IMEI_RegistationNo}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 disabled={disable}
                                 labelText={<HelpText label="ICCID" text="ICCID is a unique 18-22 digit code that includes a SIM card's country, home network, and identification number." />}
                                 id="ICCID"
                                 inputProps={{
                                    name: "ICCID"
                                 }}
                                 value={values.ICCID}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 disabled={disable}
                                 labelText={<HelpText label="Device Mobile Number" text="For Cellular devices with a SIM installed which allows them to receive SMS and incoming calls." />}
                                 id="DeviceMobileNumber"
                                 inputProps={{
                                    name: "DeviceMobileNumber"
                                 }}
                                 value={values.DeviceMobileNumber}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomSelect
                                 disabled={disable}
                                 labelText="Monitored By"
                                 id="MonitoredBy"
                                 inputProps={{
                                    name: "MonitoredBy"
                                 }}
                                 options={monitoredByOptions}
                                 value={values.MonitoredBy}
                              />
                           </Grid>
                           {values.MonitoredBy === "Guardian Angel Safety" &&
                              <Grid item xs={12} md={6}>
                                 <SearchableSelect
                                    disabled={disable}
                                    labelText="Monitoring Station"
                                    id="MonitoringStationOid"
                                    inputProps={{
                                       name: "MonitoringStationOid",
                                       required: true,
                                       InputLabelProps: { shrink: values.MonitoringStationOid ? true : false }
                                    }}
                                    setValue={(value: ISelectOption) => {
                                       setFieldValue("MonitoringStationOid", value?.value)
                                    }}
                                    options={monitoringStations}
                                    value={values.MonitoringStationOid}
                                    setTouched={() => {
                                       setFieldTouched("MonitoringStationOid", true);
                                    }}
                                 />
                              </Grid>
                           }
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 labelText="Monitoring Account Number"
                                 id="MonitoringAccountNumber"
                                 inputProps={{
                                    name: "MonitoringAccountNumber"
                                 }}
                                 value={values.MonitoringAccountNumber}
                              />
                           </Grid>
                           {values.DeviceMake === "Garmin" &&
                              <Grid item xs={12} md={6}>
                                 <CustomInput
                                    disabled={disable}
                                    labelText="Garmin Auth Code"
                                    id="GarminAuthCode"
                                    inputProps={{
                                       name: "GarminAuthCode"
                                    }}
                                    value={values.GarminAuthCode}
                                 />
                              </Grid>
                           }
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 disabled={disable}
                                 labelText={<HelpText label="Device Serial Number" text="Blackline refer to this as a Unit ID." />}
                                 id="DeviceSerialNumber"
                                 inputProps={{
                                    name: "DeviceSerialNumber"
                                 }}
                                 value={values.DeviceSerialNumber}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomInput
                                 disabled={disable}
                                 labelText="Device Notes"
                                 id="notes"
                                 inputProps={{
                                    required: false,
                                    name: "Notes"
                                 }}
                                 value={values.Notes}
                              />
                           </Grid>
                           {values.DeviceType === "Vehicle" &&
                              <>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       disabled={disable}
                                       labelText="Vehicle Year"
                                       id="VehicleYear"
                                       inputProps={{
                                          name: "VehicleYear",
                                          required: values.DeviceType === "Vehicle",
                                       }}
                                       value={values.VehicleYear}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <SearchableServerInput
                                       disabled={disable}
                                       labelText="Vehicle Make and Model"
                                       id="VehicleMakeModel"
                                       inputProps={{
                                          required: values.DeviceType === "Vehicle",
                                          name: "VehicleMakeModel"
                                       }}
                                       value={values.VehicleMakeModel}
                                       setValue={(value: string) => {
                                          setFieldValue("VehicleMakeModel", value)
                                       }}
                                       onTextChange={getVehicleMakeSuggestions}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       disabled={disable}
                                       labelText="Vehicle Colour"
                                       id="VehicleColour"
                                       inputProps={{
                                          name: "VehicleColour",
                                       }}
                                       value={values.VehicleColour}
                                    />
                                 </Grid>
                              </>
                           }
                           <Grid item xs={12} md={6}>
                              <CustomDatePicker
                                 disabled={disable}
                                 labelText={"Activation Date"}
                                 id="ActivationDate"
                                 inputFormat={Common.DateFormat}

                                 inputProps={{
                                    required: true,
                                    name: "ActivationDate",
                                    InputLabelProps: { shrink: true },
                                    onChange: (e: any): void => {
                                       if (e) {
                                          setFieldValue("ActivationDate", Common.getFormattedDateForServer(e));
                                       }
                                    }
                                 }}
                                 value={values.ActivationDate}
                                 openTo="day"
                                 shouldDisableTime={() => true}
                              />
                           </Grid>
                           <Grid item xs={12} md={6}>
                              <CustomDatePicker
                                 disabled={disable}
                                 labelText={"Warranty Expiry Date"}
                                 id="WarrantyExpiryDate"
                                 inputFormat={Common.DateFormat}
                                 inputProps={{
                                    required: true,
                                    name: "WarrantyExpiryDate",
                                    InputLabelProps: { shrink: true },
                                    onChange: (e: any): void => {
                                       if (e) {
                                          setFieldValue("WarrantyExpiryDate", Common.getFormattedDateForServer(e));
                                       }
                                    }
                                 }}
                                 value={values.WarrantyExpiryDate}
                                 openTo="day"
                                 shouldDisableTime={() => true}
                              />
                           </Grid>
                           {
                              <Grid item xs={12} md={6}>
                                 <label style={{ color: disable ? "rgba(0, 0, 0, 0.38)" : "rgba(0, 0, 0, 0.87)" }}  >Assign device to client:</label>
                                 <SearchableServerSearch
                                    disabled={disable}
                                    labelText="Select Client Name"
                                    id="ClientOid"
                                    inputProps={{
                                       name: "ClientOid",
                                       disabled: values.PersonOid ? true : false
                                    }}
                                    value={values.ClientOid}
                                    onSearchTextChange={getClientsSelectOptionsForDeviceScreen}
                                    setValue={(value: IClientSelectOption) => {
                                       setFieldValue("ClientOid", value?.value)
                                       setShowAdditionalFields(value?.showAdditionalFields === true)
                                    }}
                                    isClientSelection={true}
                                 />
                              </Grid>
                           }
                           {showAdditionalFields &&
                              <>
                                 <Grid item xs={12} md={6}>
                                    <CustomDateTimePicker
                                       disabled={disable}
                                       labelText={"Pegasus Data Last Updated"}
                                       id="PegasusDataLastUpdated"
                                       inputFormat={Common.DateTimeFormat}
                                       inputProps={{
                                          name: "PegasusDataLastUpdated",
                                          InputLabelProps: { shrink: true },
                                          disabled: true,
                                          onChange: (value: any): void => {
                                             setFieldValue("PegasusDataLastUpdated", value);
                                          }
                                       }}
                                       openTo="day"
                                       customErrorMessage={error["pegasusDataLastUpdated"]?.message}
                                       value={values.PegasusDataLastUpdated}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomRadioButtonGroup
                                       disabled={disable}
                                       labelText={"Pegasus"}
                                       id="PegasusDisabled"
                                       inputProps={{
                                          name: "PegasusDisabled",
                                          InputLabelProps: { shrink: true },
                                          disabled: true
                                       }}
                                       value={values.PegasusDisabled}
                                       options={[{ value: false, label: "Enabled" }, { value: true, label: "Disabled" }]}
                                       setValue={(value: any) => {
                                          if (value && typeof value === "string") {
                                             if (value.toLowerCase() === "true") setFieldValue("PegasusDisabled", true);;
                                             if (value.toLowerCase() === "false") setFieldValue("PegasusDisabled", false);;
                                          }
                                          else
                                             setFieldValue("PegasusDisabled", value);
                                       }}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomRadioButtonGroup
                                       disabled={disable}
                                       labelText={"Device In Alarm"}
                                       id="DeviceInAlarm"
                                       inputProps={{
                                          name: "DeviceInAlarm",
                                          InputLabelProps: { shrink: true },
                                          disabled: true
                                       }}
                                       value={values.DeviceInAlarm}
                                       options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                       setValue={(value: any) => {
                                          if (value && typeof value === "string") {
                                             if (value.toLowerCase() === "true") setFieldValue("DeviceInAlarm", true);;
                                             if (value.toLowerCase() === "false") setFieldValue("DeviceInAlarm", false);;
                                          }
                                          else
                                             setFieldValue("DeviceInAlarm", value);
                                       }}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomDateTimePicker
                                       disabled={disable}
                                       labelText={"Last Poll"}
                                       id="LastPoll"
                                       openTo="day"
                                       inputFormat={Common.DateTimeFormat}
                                       inputProps={{
                                          name: "LastPoll",
                                          InputLabelProps: { shrink: true },
                                          disabled: true,
                                          onChange: (value: any): void => {
                                             setFieldValue("LastPoll", value);
                                          }
                                       }}
                                       customErrorMessage={error["lastPoll"]?.message}
                                       value={values.LastPoll}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       disabled={disable}
                                       labelText={"Days Offline"}
                                       id="DaysOffline"
                                       inputProps={{
                                          name: "DaysOffline",
                                          InputLabelProps: { shrink: true },
                                          disabled: true
                                       }}
                                       type="number"
                                       value={values.DaysOffline}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       disabled={disable}
                                       labelText="Days Since GPS Fix"
                                       id="DaysSinceGPSFix"
                                       inputProps={{
                                          name: "DaysSinceGPSFix",
                                          InputLabelProps: { shrink: true },
                                          disabled: true
                                       }}
                                       type="number"
                                       value={values.DaysSinceGPSFix}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomInput
                                       disabled={disable}
                                       labelText={"Days Not Charged"}
                                       id="DaysNotCharged"
                                       inputProps={{
                                          name: "DaysNotCharged",
                                          InputLabelProps: { shrink: true },
                                          disabled: true
                                       }}
                                       type="number"
                                       value={values.DaysNotCharged}
                                    />
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <CustomDateTimePicker
                                       disabled={disable}
                                       labelText={"Date Last Serviced"}
                                       id="DateLastServiced"
                                       inputFormat={Common.DateTimeFormat}
                                       inputProps={{
                                          name: "DateLastServiced",
                                          InputLabelProps: { shrink: true },
                                          onChange: (value: any): void => {
                                             setFieldValue("DateLastServiced", value);
                                          }
                                       }}
                                       customErrorMessage={error["dateLastServiced"]?.message}
                                       value={values.DateLastServiced}
                                       openTo="day"
                                    />
                                 </Grid>
                              </>
                           }
                           {
                              deviceOid && (!values.EventResult) &&
                              <Grid item xs={12} md={6}>
                                 <label className="set-label">Latest Device Test Result : </label>
                                 No Result Yet
                              </Grid>
                           }
                           {
                              <>
                                 {values.EventResult &&

                                    <Grid item xs={12} md={6}>
                                       <CustomInput
                                          disabled={true}
                                          id="EventResult"
                                          labelText={"Test Result"}
                                          inputProps={{
                                             name: "EventResult",
                                             multiline: true,
                                             maxRows: 10,
                                          }}
                                          value={values.EventResult}
                                       />
                                    </Grid>
                                 }
                                 {values.EventNote &&
                                    <Grid item xs={12} md={6}>
                                       <CustomInput
                                          disabled={true}
                                          id="EventNote"
                                          labelText={"Test Notes"}
                                          inputProps={{
                                             name: "EventNote",
                                             multiline: true,
                                             maxRows: 10,
                                          }}
                                          rows={2}
                                          value={values.EventNote}
                                       />
                                    </Grid>
                                 }
                                 {values.DateLastTested != null &&

                                    <Grid item xs={12} md={6}>
                                       <CustomDateTimePicker
                                          disabled={true}
                                          labelText={"Date Last Tested"}
                                          id="DateLastTested"
                                          inputFormat={Common.DateTimeFormat}
                                          inputProps={{
                                             name: "DateLastTested",
                                             InputLabelProps: { shrink: true },
                                             onChange: (value: any): void => {
                                                setFieldValue("DateLastTested", value);
                                             }
                                          }}
                                          openTo="day"
                                          value={values.DateLastTested}
                                       />
                                    </Grid>
                                 }
                              </>
                           }
                           <CustomValidationSummary serverErrors={error} formikErrors={formikErrors} />
                        </Grid>
                     </fieldset>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     {
                        allowSubmit &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog >
   );
};

export default AddEditDevice;
