import React from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import { IAdvanceFilterProps } from '../../../types/propType';
import ClientService from '../../../services/client.service';
import { ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import CustomDatePicker from '../../Common/CustomDatePicker';
import { Common } from '../../../constants/common';

const SftpImportLogsFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, handleChange, submitForm, setFieldValue }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Client Name"
                        id="ClientOid"
                        inputProps={{
                           required: true,
                           name: "ClientOid",
                        }}
                        value={values.ClientOid}
                        onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("ClientOid", value?.value);
                           values.ClientOid = value?.value;
                           onAdvanceSearch(values)
                        }}
                        isClientSelection={true}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"Date"}
                        id="Date"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           required: true,
                           name: "Date",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("Date", Common.getFormattedDateForServer(e));
                                 submitForm();
                              }
                           }
                        }}
                        value={values.Date}
                        openTo="day"
                        disableFuture
                        shouldDisableTime={() => true}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default SftpImportLogsFilter;
