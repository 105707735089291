import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { ISetResponseInstructionProps } from '../../../../types/propType';
import Button from '../../../Common/Button';

import { IResponseInstruction } from '../../../../types/dataTypes';
import { Controllers, StatusCode } from '../../../../constants/constant';
import { Common } from '../../../../constants/common';

import PersonService from '../../../../services/person.service';
import ResponseInstructionService from '../../../../services/responseinstruction.service';
import ClientService from '../../../../services/client.service';
import DeviceService from '../../../../services/device.service';
import { TextBlockContainer } from './TextBlockContainer';
import { DeletedBlockContainer } from './DeletedBlockContainer';
import { PreviewTextBlockCard } from './PreviewTextBlockCard';
import { TextBlocks } from "./ClientResponseInstructionList";
const SetClientResponseInstruction: React.FC<ISetResponseInstructionProps> = ({ open, close, successCallback, responseInstructionOid, oid, responseInstructionFor, selectedEntityName, allowSubmit }) => {
   const initialResponseInstruction: IResponseInstruction = {
      Oid: Common.EmptyGuid,
      Instruction: "",
      InstructionFor: responseInstructionFor
   };
   const [defaultResponseInstruction, setDefaultResponseInstruction] = useState<IResponseInstruction>(initialResponseInstruction);
   const [textBlocks, setTextBlocks] = useState<any[]>([]);
   const [deletedTextBlocks, setDeletedTextBlocks] = useState<any[]>([]);
   const [isPreview, setIsPreview] = useState((!allowSubmit));
   useEffect(() => {
      if (!Common.stringNullOrEmpty(responseInstructionOid) && responseInstructionOid !== Common.EmptyGuid) {
         ResponseInstructionService.getResponseInstructionByOid(responseInstructionOid?.toString()).then((res: any) => {
            const response = res.data;
            if (res?.status === StatusCode.Success && response) {
               defaultResponseInstruction.Oid = response.oid;
               defaultResponseInstruction.InstructionFor = response.instructionFor;
               setDefaultResponseInstruction(defaultResponseInstruction);
               try {
                  const savedBlocks = JSON.parse(response.instruction);
                  setTextBlocks([...savedBlocks]);
               } catch (ex) {
                  setTextBlocks([...TextBlocks]);
               }
            }
         });
      }
      else {
         setTextBlocks([...TextBlocks]);
      }
   }, [responseInstructionOid, responseInstructionFor, oid]);  // eslint-disable-line react-hooks/exhaustive-deps      
   const removeTextBlock = (textBlock: any) => {
      const currentBlocks = [...textBlocks];
      const blockIndex = currentBlocks.indexOf(textBlock);
      currentBlocks.splice(blockIndex, 1);
      textBlockChange(currentBlocks);
   }
   const restoreFromTrash = (textBlock: any) => {
      const insertIndex = textBlocks.findIndex((x) => x.IsFixed === true);
      textBlocks.splice(insertIndex < 0 ? textBlocks.length : insertIndex, 0, textBlock);
      textBlockChange([...textBlocks]);
   }
   const addNewBlock = (textBlock: any) => {
      const insertIndex = textBlocks.findIndex((x) => x.IsFixed === true);
      textBlocks.splice(insertIndex < 0 ? textBlocks.length : insertIndex, 0, textBlock);
      textBlockChange([...textBlocks]);
   }
   const reset = () => {
      setTextBlocks([...TextBlocks]);
   }
   const textBlockChange = (textBlocks: any[]) => {
      setTextBlocks(textBlocks);
   }
   useEffect(() => {
      const deletedBlocks = [...TextBlocks].filter((o1: any) => {
         return !textBlocks.some((o2: any) => {
            return o1.Text === o2.Text;
         });
      });
      setDeletedTextBlocks(deletedBlocks);
   }, [textBlocks])
   const onSubmit = () => {
      const values: IResponseInstruction = defaultResponseInstruction;
      values.Instruction = JSON.stringify(textBlocks);
      if (!Common.stringNullOrEmpty(oid) && oid !== Common.EmptyGuid) {
         switch (responseInstructionFor) {
            case Controllers.Client:
               ClientService.setResponseInstruction(values, oid).then(() => {
                  successCallback();
               });
               break;

            case Controllers.Device:
               DeviceService.setResponseInstruction(values, oid).then(() => {
                  successCallback();
               });
               break;

            case Controllers.Person:
               PersonService.setResponseInstruction(values, oid).then(() => {
                  successCallback();
               });
               break;
         }
      }
   }
   return (
      <Dialog disableEscapeKeyDown open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle id="scroll-dialog-title">Default Response Instruction for Client : {selectedEntityName}</DialogTitle>
         <DialogContent dividers>
            {!isPreview &&
               <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                     <Button style={{ float: "right" }} size="sm" color="primary" type="none" onClick={() => addNewBlock({ Type: "Image", Text: "" })}>Add Image Block</Button>
                     <Button style={{ float: "right", marginRight: "2em" }} size="sm" color="danger" type="none" onClick={() => reset()}>Reset</Button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                     <DndProvider backend={HTML5Backend}>
                        <TextBlockContainer readOnly={false} instructionTypeTextBlocks={[...textBlocks]} setInstructionTypeTextBlocks={textBlockChange} removeTextBlock={removeTextBlock} setDeletedTextBlocks={setDeletedTextBlocks} clientOid={oid ?? ""} />
                     </DndProvider>
                     {deletedTextBlocks && deletedTextBlocks.length > 0 &&
                        <>
                           <h6>Deleted Blocks</h6>
                           <DeletedBlockContainer deletedTextBlocks={[...deletedTextBlocks]} restoreFromTrash={restoreFromTrash} />
                        </>
                     }
                  </Grid>
               </Grid>
            }
            {isPreview &&
               <Grid container spacing={2}>
                  {textBlocks && textBlocks.length > 0 && textBlocks.map((item: any, index: number) => {
                     return <Grid key={index} item xs={12} md={12}>
                        <PreviewTextBlockCard index={index} textBlock={item} />
                     </Grid>
                  })}
               </Grid>
            }
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
            {allowSubmit &&
               <>
                  {isPreview ? <>
                     <Button onClick={() => { setIsPreview(false) }} type="none">Back</Button>
                     <Button color="primary" type="none" onClick={() => onSubmit()}>Save</Button>
                  </> :
                     <Button onClick={() => { setIsPreview(true) }} color="primary" type="none">Preview</Button>
                  }
               </>
            }

         </DialogActions>
      </Dialog>
   );
};

export default SetClientResponseInstruction;
