import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from "@mui/material/styles";
import { Common } from "../../../constants/common";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { IEscalationDetailTableDataProps } from "../../../types/propType";
const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#808080",
      color: "#FFF",
      padding: "4px"
   }
}));
const StyledDetailCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.body}`]: {
      padding: "4px",
   }
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
   }
}));
const EscalationDetailTable: React.FC<IEscalationDetailTableDataProps> = ({ data, classes }) => {
   return (
      <TableContainer component={Paper}>
         <Table aria-label="simple table">
            <TableHead>
               <TableRow>
                  <StyledHeaderCell>Person</StyledHeaderCell>
                  <StyledHeaderCell>Person Name</StyledHeaderCell>
                  <StyledHeaderCell>Phone 1</StyledHeaderCell>
                  <StyledHeaderCell>Phone 2</StyledHeaderCell>
                  <StyledHeaderCell>Notes</StyledHeaderCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {data.map((person: any, index: number) => (
                  <StyledTableRow key={index}>
                     <StyledDetailCell>{index + 1}</StyledDetailCell>
                     <StyledDetailCell >{`${person.firstname} ${person.lastname ? person.lastname : ""}`}</StyledDetailCell>
                     <StyledDetailCell >{Common.formatPhoneNumber(person.phoneNumber1)}</StyledDetailCell>
                     <StyledDetailCell >{!Common.stringNullOrEmpty(person.phoneNumber2) ? Common.formatPhoneNumber(person.phoneNumber2) : ""}</StyledDetailCell>
                     <StyledDetailCell style={{ maxWidth: "200px" }}>{person.notes}</StyledDetailCell>
                  </StyledTableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   )
}
export default EscalationDetailTable;
