import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Alert, Menu, MenuItem } from "@mui/material";
import Button from '../../Common/Button';
import { IFileUploadModalProps } from '../../../types/propType';
import { Common } from '../../../constants/common';
import CustomFileUploader from '../../Common/CustomFileUploader';
import { dangerColor } from '../../../assets/jss/appStyle';
import { StatusCode } from '../../../constants/constant';
import hazardLocationService from '../../../services/hazardLocation.service';
import { GeoJsonLocationPoint, GeoJsonLocationPolygon, GeoJsonHazardMultiPolygon, GeoJsonHazardPoint } from "./GeoJSONSamples";
import locationService from '../../../services/location.service';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
const splitToChunks = (files: any[], chunkSize: number): any[] => {
   let result: any[] = [];
   let chunk = [];
   for (let i = 0; i < files.length; i++) {
      chunk.push(files[i]);
      if ((i + 1) % chunkSize === 0) {
         result.push(chunk);
         chunk = [];
      }
   }
   result.push(chunk);
   return result;
}
const ImportGeoJSONModal: React.FC<IFileUploadModalProps> = ({ open, close, successCallback, name }) => {
   const fileTypes: string[] = ["json", "geojson", "txt"];
   const [file, setFile] = useState<any>(null);
   const [error, setError] = useState<any>(null);
   const [uploadResponse, setUploadResponse] = useState<any[]>([]);
   const onUpload = async () => {
      if (file !== null) {
         const chunks = splitToChunks(file, 10);
         let uploadResult: any[] = [];
         for (let files of chunks) {
            const formData = new FormData();
            files.forEach((onefile: any, i: number) =>
               formData.append("importedFiles", onefile)
            );
            formData.append("frontendURL", window.location.origin)
            let res: any = name === "Location" ? await locationService.import(formData) : await hazardLocationService.import(formData);
            if (res?.status === StatusCode.Success) {
               if (res?.data?.length > 0)
                  uploadResult = [...uploadResult, ...res?.data];
            }
            else if (res?.status === StatusCode.BadRequest) {
               setError(res?.data);
               break;
            }
         }
         if (uploadResult.length > 0)
            setUploadResponse(uploadResult);
         else
            successCallback();
      }
   }
   //#region Download   
   const onDownload = (geometryType: string) => {
      const link = document.createElement("a");
      link.target = "_blank";
      let fileContent = "";
      if (name === "Location") {
         if (geometryType === "Polygon")
            fileContent = GeoJsonLocationPolygon
         else
            fileContent = GeoJsonLocationPoint
      }
      else {
         if (geometryType === "MultiPolygon")
            fileContent = GeoJsonHazardMultiPolygon
         else
            fileContent = GeoJsonHazardPoint
      }
      link.download = `Sample_GeoJSON_${name}_${geometryType}`;
      link.href = URL.createObjectURL(
         new Blob([fileContent], { type: 'application/json' })
      );
      link.click();
   }
   //#endregion

   const setSelectedFiles = (files: any) => {
      if (files.length > 100) {
         setFile(null);
         setError({ fileLength: "Maximum 100 files allowed at a time." });
         return;
      }
      setError(null);
      setFile(files);
   }
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <DialogTitle>{`Import ${name} Geo JSON`}</ DialogTitle>
         <DialogContent dividers>
            <CustomFileUploader
               setFile={setSelectedFiles}
               file={file}
               fileTypes={fileTypes}
               multiple={true} />

            <div style={{ marginTop: 20, color: dangerColor[0] }}>
               {
                  error !== null &&
                  <Alert severity="error">
                     {error?.invalidFile &&
                        <span>Invalid file</span>
                     }
                     {error?.message &&
                        <span>Invalid file : {error?.message} </span>
                     }
                     {error?.fileLength &&
                        <span>{error?.fileLength} </span>
                     }
                  </Alert>
               }
               {uploadResponse.length > 0 &&
                  <>
                     <p>The following file are not imported:</p>
                     <ul>
                        {
                           uploadResponse.map((item: any, index: number) => {
                              return <li key={`invalidfiles_${index}`}>{`${item.fileName} : ${item.error}`}</li>
                           })
                        }
                     </ul>
                  </>
               }
            </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <PopupState variant="popover" popupId="demo-popup-menu">
               {(popupState) => (
                  <>
                     <Button color="info" {...bindTrigger(popupState)} >Download Sample File</Button>
                     <Menu {...bindMenu(popupState)}>
                        {name === "Location" &&
                           <MenuItem onClick={() => { popupState.close(); onDownload("Point") }}>
                              Point
                           </MenuItem>
                        }
                        <MenuItem onClick={() => { popupState.close(); onDownload("Polygon") }}>
                           Polygon
                        </MenuItem>
                        {name === "Hazard Location" &&
                           <MenuItem onClick={() => { popupState.close(); onDownload("MultiPolygon") }}>
                              MultiPolygon
                           </MenuItem>
                        }
                     </Menu>
                  </>
               )}
            </PopupState>
            <Button color="primary" onClick={onUpload} disabled={!Common.stringNullOrEmpty(error) || uploadResponse.length > 0}>Upload</Button>
         </DialogActions>
      </Dialog>
   );
};

export default ImportGeoJSONModal;
