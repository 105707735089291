import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import CustomSelect from '../../Common/CustomSelect';
import { IAdvanceFilterProps } from '../../../types/propType';
import { StatusCode } from '../../../constants/constant';
import ClientService from '../../../services/client.service';
import { ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import personService from '../../../services/person.service';

const PersonAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const [relationshipToClientOptions, setRelationshipToClientOptions] = useState([]);
   useEffect(() => {
      personService.getPersonRelationshipToClientSelectOption().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setRelationshipToClientOptions(res?.data);
         }
      });
   }, []);
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, handleChange, submitForm, setFieldValue }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Client Name"
                        id="ClientOid"
                        inputProps={{
                           name: "ClientOid",
                        }}
                        value={values.ClientOid}
                        onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("ClientOid", value?.value);
                           submitForm();
                        }}
                        isClientSelection={true}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                     <CustomSelect
                        labelText="Relationship To Client"
                        id="RelationshipToClient"
                        inputProps={{
                           name: "RelationshipToClient",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        value={values.RelationshipToClient}
                        options={[{ label: "All", value: "All" }].concat(relationshipToClientOptions)}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default PersonAdvanceFilter;
