import { useState } from 'react';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ISelectOption } from '../../../types/dataTypes';
import { AddBox, Delete } from "@mui/icons-material";
import Button from '../../Common/Button';
import { FormHelperText, Grid } from '@mui/material';
import PropTypes from "prop-types";
import { useField } from 'formik';
import { Common } from '../../../constants/common';
import HelpText from '../../Common/HelpText';
function ReportRecipients({ ...props }) {
   const { value, onSearchTextChange, setValue, inputProps, customErrorMessage } = props;
   const [reportRecipientsList, setReportRecipientsList] = useState<string[]>(value && value.length > 0 ? value : [""]);
   const addNewReportRecipients = () => {
      setReportRecipientsList([...reportRecipientsList, ""]);
      setValue(reportRecipientsList);
   }
   const removeReportRecipients = (index: number) => {
      reportRecipientsList.splice(index, 1);
      setReportRecipientsList([...reportRecipientsList]);
      setValue(reportRecipientsList);
   }
   const setReportRecipients = (index: number, value: string) => {
      reportRecipientsList[index] = value;
      setValue(reportRecipientsList);
   }
   const [field, meta] = useField(inputProps);
   return (
      <Grid container>
         <Grid item xs={12} md={12} style={{ display: "ruby" }}>
            <HelpText label="Report Recipients:" text={`Persons who will receive reports.`} />{reportRecipientsList && reportRecipientsList.length < 42 && <Button color="success" justIcon={true} onClick={() => addNewReportRecipients()}><AddBox /></Button>}
         </Grid>
         {meta.touched && meta.error && meta.error.length > 0 &&
            <Grid item xs={12} md={12}>
               <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
            </Grid>
         }
         {
            !Common.stringNullOrEmpty(customErrorMessage) &&
            <Grid item xs={12} md={12}>
               <FormHelperText id={`error-${inputProps.name}`} error>{customErrorMessage}</FormHelperText>
            </Grid>
         }
         <input {...field} type="hidden"  {...inputProps} />
         {reportRecipientsList && reportRecipientsList.map((reportRecipient, index) => {
            return (
               <Grid key={index} item xs={12} md={6}>
                  <Grid container spacing={1} >
                     <Grid item xs={10} md={10}>
                        <SearchableServerSearch
                           labelText={`Recipient ${index + 1}`}
                           id={`Recipient${index + 1}`}
                           inputProps={{
                              required: false,
                              name: `Recipient${index + 1}`,
                              disableClearable: false
                           }}
                           value={reportRecipient}
                           onSearchTextChange={onSearchTextChange}
                           setValue={(value: ISelectOption) => {
                              setReportRecipients(index, value?.value)
                           }}
                        />
                     </Grid>
                     <Grid item xs={2} md={2}>
                        <Button color="danger" justIcon={true} onClick={() => removeReportRecipients(index)}><Delete /></Button>
                     </Grid>
                  </Grid>
               </Grid>
            )
         })
         }
      </Grid>
   )
}
ReportRecipients.propTypes = {
   value: PropTypes.array,
   setValue: PropTypes.any
};
export default ReportRecipients;
