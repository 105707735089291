import React from 'react';
import lodash from "lodash";
import { TableCell, TableRow } from '@mui/material';
import { ITextComparetorProps } from '../../types/propType';
import AuditTextBlockList from './AuditTextBlockList';
const ClientResponseInstructionComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   let changedInstructions: any[] = [];
   try {
      changedInstructions = JSON.parse(changedValue);
   } catch (ex) {
   }
   let oldInstructions: any[] = [];
   try {
      oldInstructions = JSON.parse(oldValue);
   } catch (ex) {
   }
   return (
      <TableCell colSpan={2}>
         {changedInstructions.map((deviceTypes: any, i1: number) =>
            deviceTypes.InstructionTypes.map((instructionTypes: any, i2: number) => {
               const oldBlocksForDeviceType = oldInstructions.filter((x: any) => x.DeviceType === deviceTypes.DeviceType)
               const oldBlocksForDeviceInstruction = oldBlocksForDeviceType.length === 0 ? [] : oldBlocksForDeviceType[0].InstructionTypes.filter((x: any) => x.InstructionFor === instructionTypes.InstructionFor);
               const oldBlocks = oldBlocksForDeviceInstruction.length > 0 ? oldBlocksForDeviceInstruction[0].TextBlocks : []
               return (
                  <>
                     <TableRow>
                        <TableCell colSpan={2}>
                           <strong>{instructionTypes.InstructionFor} {deviceTypes.DeviceType}</strong>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell style={{ verticalAlign: "top" }}>
                           <AuditTextBlockList oldBlocks={[...oldBlocks]} changedBlocks={[...instructionTypes.TextBlocks]} type="old" />
                        </TableCell>
                        <TableCell style={{ verticalAlign: "top" }}>
                           <AuditTextBlockList oldBlocks={[...instructionTypes.TextBlocks]} changedBlocks={[...oldBlocks]} type="new" />
                        </TableCell>
                     </TableRow>
                  </>
               )
            })
         )}
      </TableCell>
   );
}
export default ClientResponseInstructionComparetor;
