import React from "react";
import { Assignment, Delete, Edit, History, Contacts, SpeakerNotes, Aod, Image, PersonSearch } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { MenuItem, Divider, Tooltip, Link } from '@mui/material';
import { IClientDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import NavigationLink from "../../Common/NavigationLink";
import WarningSign from "../../Common/WarningSign";
const ClientRow: React.FC<IClientDataProps> = ({ client, onEdit, onDelete, onAssignEscalation, onShowAuditLog, onDownloadEscalation, onDownloadEscalationContacts, showOnlyEdit, showOnlyEscalation, actionPermission, onSetResponseInstruction, openImageModal, openFindAndReplaceModal, onClickPersonCount, onClickDeviceCount }) => {
   const showAllActions: boolean = !showOnlyEdit && !showOnlyEscalation;
   return (
      <StyledGridRow>
         <StyledGridCell>
            {client.escalationOid === Common.EmptyGuid &&
               <WarningSign text="Default escalation pending" />
            }
         </StyledGridCell>
         <StyledGridCell> {client.name}  </StyledGridCell>
         <StyledGridCell> {client.planName} </StyledGridCell>
         <StyledGridCell> {client.email}  </StyledGridCell>
         <StyledGridCell> {Common.formatPhoneNumber(client.phoneNumber)}  </StyledGridCell>
         <StyledGridCell> {client.country}  </StyledGridCell>
         <StyledGridCell> {client.accountOwnerName}  </StyledGridCell>
         <StyledGridCell><Link> <p onClick={() => { onClickPersonCount(client.oid) }}>{client.personCount}</p> </Link></StyledGridCell>
         <StyledGridCell><Link><p onClick={() => { onClickDeviceCount(client.oid) }}>{client.deviceCount} </p> </Link></StyledGridCell>
         <StyledGridCell align='right'>
            {showOnlyEscalation && (actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) && (actionPermission["Client:AssignDefaultEscalation"]) &&
               <Button color="rose" justIcon={true} onClick={() => onAssignEscalation(client)}>
                  <Tooltip title="Assign client escalation contact">
                     <Assignment />
                  </Tooltip>
               </Button>
            }
            {(showAllActions || showOnlyEdit) && (actionPermission["Client:POST"] && actionPermission["Person:GetClientSpecificAccountOwnerSelectOptions"] && actionPermission["Person:GetClientReportRecipientSelectOptions"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(client)}><Edit /></Button>
            }
            {showAllActions && !Common.IsInRole("Client User") &&
               <ActionOptionMenu>
                  {(actionPermission["Audit:Client"]) &&
                     <MenuItem disableRipple onClick={() => { onShowAuditLog(client) }}>
                        <History />
                        Audit history
                     </MenuItem>
                  }
                  {(actionPermission["Person:GetPersonSelectOptions"]) && (actionPermission["Escalation:GET"]) &&
                     <MenuItem disableRipple onClick={() => { onAssignEscalation(client) }}>
                        <Assignment />
                        {actionPermission["Client:AssignDefaultEscalation"] ? "Assign" : "View"} client escalation contact
                     </MenuItem>
                  }
                  {(actionPermission["Client:ResponseInstruction"]) &&
                     <MenuItem disableRipple onClick={() => { onDownloadEscalation(client) }}>
                        <Contacts />
                        Download escalation instruction
                     </MenuItem>
                  }
                  {(actionPermission["Client:ResponseEscalationContacts"]) &&
                     <MenuItem sx={{ marginLeft: 4 }} disableRipple onClick={() => { onDownloadEscalationContacts(client) }}>
                        Download escalation contacts
                     </MenuItem>
                  }
                  {actionPermission["ResponseInstruction:GET"] &&
                     <MenuItem disableRipple onClick={() => { onSetResponseInstruction(client) }}>
                        <SpeakerNotes />
                        {actionPermission["Client:SetResponseInstruction"] ? "Edit" : "View"} response instruction
                     </MenuItem>
                  }
                  {actionPermission["ClientImage:GetImageList"] &&
                     <MenuItem disableRipple onClick={() => { openImageModal(client) }}>
                        <Image />
                        {actionPermission["ClientImage:SaveImage"] ? "Add" : "View"} response instruction images
                     </MenuItem>
                  }
                  {actionPermission["Person:FindAndReplaceEscalationContact"] && actionPermission["Person:GetCountForPersonUsedInEscalations"] &&
                     <MenuItem disableRipple onClick={() => { openFindAndReplaceModal(client) }}>
                        <PersonSearch />
                        Find and Replace Escalation Contact
                     </MenuItem>
                  }
                  {(actionPermission["Device:Search"]) &&
                     <NavigationLink to={`/devices/client/${client.oid}`}>
                        <MenuItem disableRipple>
                           <Aod />
                           Device list
                        </MenuItem>
                     </NavigationLink>
                  }
                  {(actionPermission["Client:DELETE"]) &&
                     <div>
                        <Divider />
                        <MenuItem disableRipple onClick={() => { onDelete(client, true) }}>
                           <Delete />
                           Delete
                        </MenuItem>
                     </div>
                  }
               </ActionOptionMenu>
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default ClientRow;
