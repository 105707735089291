import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

interface IExportOptionsProps {
   onDownload: any;
   children: any;
}
const ExportOptions: React.FC<IExportOptionsProps> = ({ children, onDownload }) => {
   return (
      <PopupState variant="popover" popupId="demo-popup-menu">
         {(popupState) => (
            <>
               {children({ ...bindTrigger(popupState) })}
               <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={() => { popupState.close(); onDownload("CSV") }}>
                     <ListItemIcon>
                        <img alt="CSV" style={{ width: "30px" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADWklEQVR4nO2X/U+SURTH+a1/oa1/pF9aW5Py99YP4qYrKG1mW+Wy1Mmsli/Zi2ZaaYrio5YrDcvXlFUCDzVfM3SJlKmgmMlTirwIp92jMHEUiD4XTM52xnPuuVzOh/s99+4RCKIWNX6NNWqBH2fzdi1A32wfPQiWB4AFq5EeBMsDAGebowfB8gRADYL1UwCjq/frweY9ABwNCL4BOL4h+JQQRwOCFgDHFwQNCXF8QtAG4DZB8AJA0wV7HiCh4jKE0wXbtT0NIK7KgFNVGZEH8KCHAaPFDM5VJxgXzVCjbvbJS5uL4ev8NLjBDS63Cz4YhuGsXIo5tX4AlmxWaPzY7p1f2sPg2IT5O/8Aea2PsLBluxU+z+hhxWEHYgVtFZg/LcsCi/U3jmkmBrEoYqrxfsxXq5owHpn54l2zZ5TFsY6R9/wDaA1D+GP5reUYlynr14rVD2CcoyjB2DA/hXFKbQ7GKw4bxpee5nnjk5VXcGzqpwnHbrVX8A8w+WMGf0wiy8T4QsNNeDWkBFnvC2/sdrvB5rTDnc4qv7omsiOW3VSE0vLMJz3DO8DiMocS+lfjESCPEZndaCn1ybd/eoc5ufol3O6oxOeBSR2dJg4GgHhRV7V3t0gjl3TXenMFbeU4zk4MgmKwG59lqrUdpCYhz3afY65BZe9zuNspw5hIguicfCY+SQdGo8D5Jsu8dw1ytBLJLCxZYMxkwPzFhlw6AJ4mzmq6h/H9brnPKVO7XrBylMU4uUaKMSl44zr9kzocX3W5YHpxlt49kPt67Rg1/1qA3vE+4NaPTCILkif/pN3pAMeqE97o1N5CdUa9zzpEMh5rHX5L9yIjeiYnCSmSHIFlyrpNd8VjlIzHxue+QdqzfJ85BNRj5G6hChCspzLXIak6e8vfE4okfp06QKgujAJssHC8hQl3cgf+SwBmmy/1gfLCKMD/LiF2twMwO9gDKYXSvxYcyGNEkodhB5CP1EF8elooAOrY2NR9ESGhrjElHE8+v5XiTUcSkg6ELCE+vFHTArEJSYGLjxM7jsVLDgVVPE0A1qiFMoU8IMBRkeRM0MXTBmCNWrhaXLi9pg03gGpKA5LMrNCbNhLs8InE/cI48XRITRspFhMvORgjEtu23LSRZKRhAzXtH8TEVoEYhfoOAAAAAElFTkSuQmCC" />
                     </ListItemIcon>
                     CSV
                  </MenuItem>
                  <MenuItem onClick={() => { popupState.close(); onDownload("EXCEL") }}>
                     <ListItemIcon>
                        <svg style={{ width: "30px", height: "30px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#4CAF50" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z" /><path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z" /><path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" /><path fill="#FFF" d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z" /></svg>
                     </ListItemIcon>
                     Excel
                  </MenuItem>
               </Menu>
            </>
         )}
      </PopupState>
   )
}
export default ExportOptions;
