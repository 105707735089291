import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import { Grid } from "@mui/material";

import CustomSelect from '../../Common/CustomSelect';
import CustomCheckbox from '../../Common/CustomCheckbox';
import { IAdvanceFilterProps } from '../../../types/propType';
import { StatusCode } from '../../../constants/constant';
import { Common } from '../../../constants/common';
import MonitoringStationService from '../../../services/monitoringstation.service';
import ClientService from '../../../services/client.service';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { ISelectOption, ISearchableSelectParams } from '../../../types/dataTypes';

const DeviceAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const [monitoringStations, setMonitoringStations] = useState([]);
   useEffect(() => {
      if (!Common.IsInRole("Monitoring Station Operator") &&
         !Common.IsInRole("Monitoring Station Admin") &&
         !Common.IsInRole("Client Admin") &&
         !Common.IsInRole("Client User") &&
         !Common.IsInRole("Client Audit User") &&
         !Common.IsInRole("Client Location Editor")) {
         MonitoringStationService.getMonitoringStationOptions().then((res: any) => {
            if (res?.status === StatusCode.Success && res.data) {
               setMonitoringStations(res?.data);
            }
         });
      }
   }, []);
   const getClientsSelectOptionsForPersonScreen = (params: ISearchableSelectParams, signal: any) => {
      return ClientService.getClientsSelectOptionsForPersonScreen(params, signal);
   }
   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onAdvanceSearch(values)}>
         {({ values, handleChange, submitForm, setFieldValue }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Client Name"
                        id="ClientOid"
                        inputProps={{
                           name: "ClientOid",
                        }}
                        value={values.ClientOid}
                        onSearchTextChange={getClientsSelectOptionsForPersonScreen}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("ClientOid", value?.value);
                           submitForm();
                        }}
                        isClientSelection={true}
                     />
                  </Grid>
                  {(!Common.IsInRole("Monitoring Station Operator") &&
                     !Common.IsInRole("Monitoring Station Admin") &&
                     !Common.IsInRole("Client Admin") &&
                     !Common.IsInRole("Client User") &&
                     !Common.IsInRole("Client Audit User") &&
                     !Common.IsInRole("Client Location Editor")
                  )
                     && <Grid item xs={12} sm={6} md={4}>
                        <CustomSelect
                           labelText="Monitoring Station"
                           id="MonitoringStation"
                           inputProps={{
                              name: "MonitoringStation",
                              onChange: (e: any) => {
                                 handleChange(e);
                                 submitForm();
                              }
                           }}
                           value={values.MonitoringStation}
                           options={[{ label: "All", value: Common.EmptyGuid }].concat(monitoringStations)}
                        />
                     </Grid>
                  }
                  <Grid item xs={12} sm={6} md={4}>
                     <CustomCheckbox
                        labelText={"Show unassigned devices only"}
                        id="IsShowUnassingned"
                        inputProps={{
                           required: true,
                           name: "IsShowUnassingned",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        checked={values.IsShowUnassingned}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default DeviceAdvanceFilter;
