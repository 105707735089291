import { Grid, TextField } from '@mui/material';
import type { FC } from 'react'

export interface IPlaceholderTextBlockCardDetails {
   textBlock: any;
   handleTextChange: any;
   blockIndex: number;
}

export const PlaceholderTextBlockCardDetailsDetails: FC<IPlaceholderTextBlockCardDetails> = ({ textBlock, handleTextChange, blockIndex }) => {
   const values = { ...textBlock.Values };
   const valueKeys = Object.keys(values);
   const getPlainText = () => {
      let text = textBlock.Text;
      valueKeys.forEach((key) => {
         text = text.replace(`{{${key}}}`, values[key]);
      });
      return text;
   }
   const handlePlaceholerTextChange = (name: string, text: string) => {
      values[name] = text;
      handleTextChange(blockIndex, textBlock.Text, values);
   }
   return (
      <Grid container spacing={2}>
         <Grid item xs={12} md={12}>
            {getPlainText()}
         </Grid>
         {valueKeys.map((key, index: number) =>
            <Grid item xs={12} md={valueKeys.length > 1 ? 6 : 12}>
               <TextField
                  key={index}
                  style={{ height: "100%", width: "100%" }}
                  id={`outlined-name-${index}`}
                  value={values[key]}
                  name={key}
                  onChange={(event) => {
                     handlePlaceholerTextChange(event.target.name, event.target.value);
                  }}
                  variant="outlined"
                  multiline={key === "Description"}
                  size="small"
                  label={key.replace(/([A-Z])/g, ' $1').trim()}
               />
            </Grid>
         )}
      </Grid>
   )
}
