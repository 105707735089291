import React from "react";

import { Divider, MenuItem } from "@mui/material";
import { Delete, Edit, History, Visibility } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { ILocationDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

const LocationRow: React.FC<ILocationDataProps> = ({ location, onEdit, onDelete, onShowAuditLog, actionPermission }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {location.title}  </StyledGridCell>
         <StyledGridCell> {location.type}  </StyledGridCell>
         <StyledGridCell> {location.description}  </StyledGridCell>
         <StyledGridCell> {location.physicalAddress} </StyledGridCell>
         <StyledGridCell align='right'>
            {(actionPermission["Location:GET"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(location)}>
                  {(actionPermission["Location:POST"])
                     ? <Edit /> : <Visibility />}
               </Button>
            }
            <ActionOptionMenu>
               {(actionPermission["Audit:Location"]) &&
                  <MenuItem disableRipple onClick={() => { onShowAuditLog(location) }}>
                     <History color="primary" />
                     Audit history
                  </MenuItem>
               }
               {(actionPermission["Location:DELETE"]) &&
                  <>
                     <Divider />
                     <MenuItem disableRipple onClick={() => { onDelete(location, true) }}>
                        <Delete />
                        Delete
                     </MenuItem>
                  </>
               }
            </ActionOptionMenu>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default LocationRow;
