import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { ISftpMonitoringLogsDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";
import { Visibility } from "@mui/icons-material";

const SftpMonitoringLogsRow: React.FC<ISftpMonitoringLogsDataProps> = ({ record, onView }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {Common.getFormattedDateTime(record.dateTime)}  </StyledGridCell>
         <StyledGridCell> {record.type}  </StyledGridCell>
         <StyledGridCell> {record.filename}  </StyledGridCell>
         <StyledGridCell> {record.notes}  </StyledGridCell>
         <StyledGridCell>
            <Button color="info" justIcon={true} onClick={() => onView(record)}>
               <Visibility />
            </Button>
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default SftpMonitoringLogsRow;
