import React from 'react';
import { Grid, TableCell, TableRow } from '@mui/material';
import { ITextComparetorProps } from '../../types/propType';
import TextComparetor from './TextComparetor';
const TripPersonAndLocationComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const oldPersons: any[] = oldValueJson?.TripPerson ?? [];
   const newPersons: any[] = changedValueJson.TripPerson;
   const persons = [
      ...newPersons.filter((obj) => oldPersons.some((obj2) => obj.PersonOid === obj2.PersonOid)).map((obj) => { return { ...obj, Type: "" } }),
      ...oldPersons.filter((obj) => !newPersons.some((obj2) => obj.PersonOid === obj2.PersonOid)).map((obj) => { return { ...obj, Type: "Remove" } }),
      ...newPersons.filter((obj) => !oldPersons.some((obj2) => obj.PersonOid === obj2.PersonOid)).map((obj) => { return { ...obj, Type: "Add" } })
   ];
   const oldLocations: any[] = oldValueJson?.TripLocation ?? [];
   const newLocation: any[] = changedValueJson.TripLocation;
   const locations = [
      ...newLocation.filter((obj) => oldLocations.some((obj2) => obj.LocationOid === obj2.LocationOid)).map((obj) => { return { ...obj, Type: "" } }),
      ...oldLocations.filter((obj) => !newLocation.some((obj2) => obj.LocationOid === obj2.LocationOid)).map((obj) => { return { ...obj, Type: "Remove" } }),
      ...newLocation.filter((obj) => !oldLocations.some((obj2) => obj.LocationOid === obj2.LocationOid)).map((obj) => { return { ...obj, Type: "Add" } })
   ];
   return (
      <>
         <TableRow>
            <TableCell colSpan={2}><strong>Person(s):</strong></TableCell>
         </TableRow>
         {persons.length > 0 ?
            <>
               {
                  persons.map((person: any, index: number) => {
                     let oldValueJson = person;
                     let newValueJson = person;
                     if (person?.Type === "Remove") {
                        newValueJson = null;
                     }
                     else if (person?.Type === "Add") {
                        oldValueJson = {
                           Firstname: "",
                           Lastname: "",
                        };
                     }
                     return (
                        <TableRow>
                           <TableCell key={index} style={{ width: '20%' }}>{"Person " + (index + 1)}: </TableCell>
                           <TableCell>
                              <Grid container spacing={2}>
                                 <Grid item xs={12} md={3}>
                                    <TextComparetor oldValueJson={oldValueJson} changedValueJson={newValueJson} compareKey={"Firstname"} />
                                 </Grid>
                                 <Grid item xs={12} md={3}>
                                    <TextComparetor oldValueJson={oldValueJson} changedValueJson={newValueJson} compareKey={"Lastname"} />
                                 </Grid>
                              </Grid>
                           </TableCell>
                        </TableRow>
                     );
                  })
               }
            </>
            :
            <TableRow>
               <TableCell colSpan={2}>Don't have person.</TableCell>
            </TableRow>
         }
         <TableRow>
            <TableCell colSpan={2}><strong>Location(s):</strong></TableCell>
         </TableRow>
         {locations.length > 0 ?
            <>
               {
                  locations.map((location: any, index: number) => {
                     let oldValueJson = location;
                     let newValueJson = location;
                     if (location?.Type === "Remove") {
                        newValueJson = null;
                     }
                     else if (location?.Type === "Add") {
                        oldValueJson = {
                           Title: ""
                        };
                     }
                     return (
                        <TableRow>
                           <TableCell key={index} style={{ width: '20%' }}>{"Location " + (index + 1)}: </TableCell>
                           <TableCell>
                              <Grid container spacing={2}>
                                 <Grid item xs={12} md={3}>
                                    <TextComparetor oldValueJson={oldValueJson} changedValueJson={newValueJson} compareKey={"Title"} />
                                 </Grid>
                              </Grid>
                           </TableCell>
                        </TableRow>
                     );
                  })
               }
            </>
            :
            <TableRow>
               <TableCell colSpan={2}>Don't have location.</TableCell>
            </TableRow>
         }
      </>
   );
}
export default TripPersonAndLocationComparetor;
