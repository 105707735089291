import { Status } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { deepEqual } from "fast-equals";
import React from "react";
import { ReactElement } from "react";
import { Common } from "../../constants/common";
import environment from '../../environment';
const render = (status: Status) => {
   return <h1>{status} </h1>;
};
declare type Libraries = ("drawing" | "geometry" | "localContext" | "marker" | "places" | "visualization")[];

interface IMapApiDetails {
   apiKey: string;
   render?: (status: Status) => ReactElement;
   libraries?: Libraries;
}
export const mapApiDetails: IMapApiDetails = {
   apiKey: environment.mapApiKey,
   render: render,
   libraries: ["places", "drawing"]
};

export const mapInitialMapBound = (): google.maps.LatLngBounds => {
   return new google.maps.LatLngBounds(
      new google.maps.LatLng(-37.394707964784175, 173.3815940434094),
      new google.maps.LatLng(-39.96712043107139, 177.5069602543469)
   )
}
export const addInfoWindow = (obj: any, map: google.maps.Map, trip: any) => {
   const infowindow = new google.maps.InfoWindow();
   google.maps.event.addListener(obj, 'click', function (event: any) {
      const contentString = getInfoWindowContent(trip);
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.open(map);
   });
}

export const addInfoWindowForHazardLocation = (obj: any, map: google.maps.Map, properties: any) => {
   const infowindow = new google.maps.InfoWindow();
   google.maps.event.addListener(obj, 'click', function (event: any) {
      const contentString = getInfoWindowContentForHazardLocation(properties);
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.open(map);
   });
}
const deepCompareEqualsForMaps = (a: any, b: any) => {
   if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
   ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
   }

   // TODO extend to other types

   // use fast-equals for other objects
   return deepEqual(a, b);
}
const useDeepCompareMemoize = (value: any) => {
   const ref = React.useRef();

   if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
   }

   return ref.current;
}

export const useDeepCompareEffectForMaps = (
   callback: React.EffectCallback,
   dependencies: any[]
) => {
   React.useEffect(callback, dependencies.map(useDeepCompareMemoize));  // eslint-disable-line react-hooks/exhaustive-deps
}
export const getInfoWindowContentForHazardLocation = (properties: any, withActions: boolean = false): string => {
   return `
      <div>
         <div style="font-size:16px"><b>Hazard Type: </b>${properties.type}</div><br>
         <b>Warning: </b>${properties.warning}<br>
         <b>Status: </b>${properties.status}<br>
         <b>Notes: </b>${properties.notes}<br><br>
         <b>Onsite Contact: </b>${properties.onsiteContact}<br>
         <b>Onsite Contact Phone Number: </b>${properties.onsiteContactPhoneNumber}<br><br>
         <b>Security Level: </b>${properties.securityLevel}<br>
         <b>Security Level Description: </b>${properties.securityLevelDescription}<br>
         ${properties.id ? '<b>Id: </b>' + properties.id + '<br>' : ""}
         ${withActions ?
         `<center>
         <button style="background-color:#4CAF50;  border: none;  color: white;  padding:5px 10px;  text-align: center;  text-decoration: none;  display: inline-block;  font-size: 12px;  margin: 4px 2px;  cursor: pointer;" type="button" id="edit_${properties.oid}">Edit</button>
         <button style="background-color:#f44336;  border: none;  color: white;  padding:5px 10px;  text-align: center;  text-decoration: none;  display: inline-block;  font-size: 12px;  margin: 4px 2px;  cursor: pointer;" type="button" id="delete_${properties.oid}">Delete</button>
         </center>`
         : ''}
      </div>
   `;
}
const getInfoWindowContent = (trip: any): string => {
   return `
      <div>
         <div style="font-size:16px"><b>Title: </b>${trip.title}</div><br>
         <b>Status: </b>${trip.status}<br>
         <b>Start Time: </b>${Common.getFormattedDateTime(trip.startTime)}<br>
         <b>End Time: </b>${Common.getFormattedDateTime(trip.endTime)}<br><br>
         <b>Trip Persons: </b><br>
         ${trip.tripPersons.map((person: any, index: number) => index + 1 + " " + person.label).join("<br>")}
         <br><br>
         <b>Trip Locations: </b><br>
         ${trip.tripLocations.map((location: any, index: number) => index + 1 + " " + location.label).join("<br>")}
         <br><br>
         <a style="color:blue" href="${window.location.origin}/tripdetail/${trip.oid}" target="_blank">More details</a> 
      </div>
   `;
}
