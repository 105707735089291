import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from './Button';
import { IConfirmDialogProps } from '../../types/propType';

const ConfirmDialogBox: React.FC<IConfirmDialogProps> = ({ show, onConfirmEvent, onCancel, dialogueText, dialogueTitle }) => {
   return (
      <Dialog open={show} onClose={onCancel}>
         <DialogTitle>{dialogueTitle ? dialogueTitle : "Are you sure you want to delete?"} </DialogTitle>
         <DialogContent dividers>
            {dialogueText &&
               <DialogContentText>{dialogueText}</DialogContentText>
            }
            <DialogContentText>You can’t undo this action.</DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button color="danger" onClick={onConfirmEvent}>Yes</Button>
            <Button onClick={onCancel} autoFocus>No</Button>
         </DialogActions>
      </Dialog>
   );
};

export default ConfirmDialogBox;





