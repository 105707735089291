import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import AlertRow from "../../components/pages/Alerts/AlertRow";
import AlertCard from "../../components/pages/Alerts/AlertCard";
import { IAlertPageState } from "../../types/stateType";
import { IAlertPageProps } from "../../types/propType";
import { IAlertFilter, IPageWiseContinuation } from "../../types/dataTypes";
import { StatusCode } from "../../constants/constant";
import { Common } from "../../constants/common";
import { TableHeader } from "../../constants/tableHeader";
import AlertService from "../../services/alert.service";
import withParams from "../../components/Common/withParams";
class Alerts extends React.Component<IAlertPageProps, IAlertPageState> {
   filterOptions: IAlertFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      continuationToken: null,
      pageWiseContinuationTokens: []
   }
   state: IAlertPageState = {
      alerts: [],
      totalRecords: 0
   };

   componentDidMount() {
      this.searchAlerts();
   }
   searchAlerts = () => {
      let newFilterOptions: IAlertFilter = { ...this.filterOptions };
      this.setState({ ...this.state, alerts: [] }, () => {
         AlertService.searchAlerts(newFilterOptions).then((res: any) => {

            if (res?.status === StatusCode.Success && res?.data) {
               let { totalRecords } = this.state;
               let continuationToken = res?.data?.continuationToken;
               if (continuationToken) {
                  const { pageWiseContinuationTokens, page } = this.filterOptions;
                  if (pageWiseContinuationTokens.length < page) {
                     const { pageSize } = this.filterOptions;
                     totalRecords = (page * pageSize) + 1;
                     const pageWiseToken: IPageWiseContinuation = { pageNo: this.filterOptions.page, continuationToken: continuationToken };
                     pageWiseContinuationTokens.push(pageWiseToken);
                     this.filterOptions.pageWiseContinuationTokens = pageWiseContinuationTokens;
                  }
               }
               else {
                  totalRecords = (totalRecords > 0 ? totalRecords - 1 : 0) + res?.data?.values?.length;
               }
               this.setState({
                  ...this.state,
                  alerts: res?.data?.values,
                  totalRecords: totalRecords
               });
            }
         });
      });
   }
   fetchMoreData = () => {
      if (window.innerWidth > 992) {
         return;
      }
      const { pageWiseContinuationTokens, pageSize } = this.filterOptions;
      const pageNumber = this.filterOptions.page + 1;
      this.filterOptions.page = pageNumber;
      if (this.filterOptions.pageSize !== pageSize)
         this.filterOptions.pageWiseContinuationTokens = [];
      this.filterOptions.pageSize = pageSize;
      if (pageNumber === 1)
         this.filterOptions.continuationToken = null;
      else {
         const previousPageDetail = pageWiseContinuationTokens.filter((x) => { return x.pageNo === pageNumber - 1 });
         if (previousPageDetail && previousPageDetail.length > 0)
            this.filterOptions.continuationToken = previousPageDetail[0].continuationToken;
         else
            return;
      }
      let newFilterOptions: IAlertFilter = { ...this.filterOptions };
      AlertService.searchAlerts(newFilterOptions).then((res: any) => {
         let { totalRecords } = this.state;
         let continuationToken = res?.data?.continuationToken;
         if (continuationToken) {
            const { pageWiseContinuationTokens, page } = this.filterOptions;
            if (pageWiseContinuationTokens.length < page) {
               const { pageSize } = this.filterOptions;
               totalRecords = (page * pageSize) + 1;
               const pageWiseToken: IPageWiseContinuation = { pageNo: this.filterOptions.page, continuationToken: continuationToken };
               pageWiseContinuationTokens.push(pageWiseToken);
               this.filterOptions.pageWiseContinuationTokens = pageWiseContinuationTokens;
            }
         } else {
            totalRecords = (totalRecords > 0 ? totalRecords - 1 : 0) + res?.data?.values?.length;
         }
         let alertList = [...this.state.alerts];
         alertList = alertList.concat(res?.data?.values);
         this.setState({
            ...this.state,
            alerts: alertList,
            totalRecords: totalRecords
         });
      });
   }
   //#region search and pagination   
   onPageChange = (pageNumber: number, pageSize: number) => {
      const { pageWiseContinuationTokens } = this.filterOptions;
      this.filterOptions.page = pageNumber;
      if (this.filterOptions.pageSize !== pageSize)
         this.filterOptions.pageWiseContinuationTokens = [];
      this.filterOptions.pageSize = pageSize;
      if (pageNumber === 1)
         this.filterOptions.continuationToken = null;
      else {
         const previousPageDetail = pageWiseContinuationTokens.filter((x) => { return x.pageNo === pageNumber - 1 });
         if (previousPageDetail && previousPageDetail.length > 0)
            this.filterOptions.continuationToken = previousPageDetail[0].continuationToken;
      }
      this.searchAlerts();
   }

   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.searchAlerts();
   }

   //#endregion
   render() {
      const { alerts, totalRecords } = this.state;
      const { actionPermission } = this.props;
      return (
         <PageLayout pageTitle="Alerts" searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} actionPermission={actionPermission} onSearchTextChange={null} >
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getAlertTableHeaders()} pagingInfoText="Alerts"
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {alerts && alerts?.map((alert: any, index: number) => {
                     return (
                        <AlertRow
                           key={`row_${index}`}
                           alert={alert}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>
            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={alerts ? alerts.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {alerts && alerts?.map((alert: any, index: number) => {
                        return (
                           <AlertCard
                              key={`row_${index}`}
                              alert={alert}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>
         </PageLayout>
      )
   }
}

export default withParams(Alerts);
