import { Theme } from "@mui/material";
import { grayColor } from "../appStyle";

const gridTileStyle = (theme: Theme) => ({
   tileHeader: {
      fontWeight: "bold"
   },
   tileText: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      color: grayColor[1]
   },
   beforeTextIcon: {
      marginRight: theme.spacing(2)
   }
});

export default gridTileStyle;
