import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { getLocalImpersonatorData, initKeycloak, keycloakInstance, refreshToken, removeLocalImpersonatorData, setKeycloakInstanceData, setLocalImpersonatorData } from './keycloakHelpers';

interface KeycloakContextProps {
   keycloakAuth: boolean;
   exchangeToken: (token: string, refreshToken: string, roles: string[]) => void;
   handleBackToImpersonator: () => void;
}

interface keycloakProviderProps {
   children: ReactNode;
}

const KeycloakContext = createContext<KeycloakContextProps | undefined>(undefined);
const w: any = window;
const kcEnabled = w?._env_?.KC_ENABLED;

export const KeycloakProvider: React.FC<keycloakProviderProps> = ({ children }) => {
   const [keycloakAuth, setKeycloakAuth] = useState<boolean>(false);
   const [tokenExchange, setTokenExchange] = useState<boolean>(false);
   const exchangeRefreshIntervalId = useRef<NodeJS.Timer | undefined>(undefined);

   useEffect(() => {
      if (Boolean(kcEnabled)) {
         if (getLocalImpersonatorData()) {
            setTokenExchange(true);
         }
         keycloakInstance.onTokenExpired = () => {
            keycloakInstance.updateToken().catch((error) => {
               reset();
               console.error("Error while refreshing Keycloak Token: ", error);
               window.location.href = "/login";
            });
         };
         initKeycloak().then((response) => {
            setKeycloakAuth(response ?? false);
         }).catch(() => {
            reset();
         });
      }
   }, []);

   useEffect(() => {
      if (tokenExchange) {
         exchangeRefreshIntervalId.current = setInterval(refreshToken, 60000);
      }
      return () => {
         if (exchangeRefreshIntervalId.current) {
            clearInterval(exchangeRefreshIntervalId.current);
            exchangeRefreshIntervalId.current = undefined;
         }
      }
   }, [tokenExchange]);

   const exchangeToken = (token: string, refreshToken: string, roles: string[]) => {
      if (keycloakInstance.refreshToken && keycloakInstance.token) {
         setLocalImpersonatorData(keycloakInstance.token, keycloakInstance.refreshToken, keycloakInstance.realmAccess?.roles ?? []);
         setTokenExchange(true);
      }
      setKeycloakInstanceData(token, refreshToken, roles);
      setKeycloakAuth(true);
   }

   const handleBackToImpersonator = () => {
      var impersonatorToken = getLocalImpersonatorData();
      if (impersonatorToken) {
         setKeycloakInstanceData(impersonatorToken.token, impersonatorToken.refreshToken, impersonatorToken.roles);
         removeLocalImpersonatorData();
         setTokenExchange(false);
         setKeycloakAuth(true);
      }
      else {
         keycloakInstance.logout().then(() => {
            reset();
         });
      }
   };

   const reset = () => {
      localStorage.clear();
      keycloakInstance.clearToken();
      setKeycloakAuth(false);
   }

   const contextValue: KeycloakContextProps = {
      keycloakAuth,
      exchangeToken,
      handleBackToImpersonator
   };

   return <KeycloakContext.Provider value={contextValue}>{children}</KeycloakContext.Provider>;
};

export const useKeycloak = () => {
   const context = useContext(KeycloakContext);
   if (!context) {
      throw new Error('useKeycloak must be used within a KeycloakProvider');
   }
   return context;
};
