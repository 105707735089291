import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Alert, FormHelperText } from "@mui/material";
import Button from '../../Common/Button';
import { IFileUploadModalProps } from '../../../types/propType';
import { Common } from '../../../constants/common';
import CustomFileUploader from '../../Common/CustomFileUploader';
import { dangerColor } from '../../../assets/jss/appStyle';
import { StatusCode } from '../../../constants/constant';
import DeviceService from "../../../services/device.service";
import PersonService from "../../../services/person.service";
import ExportOptions from '../../Common/ExportOptions';

const ImportDeviceModal: React.FC<IFileUploadModalProps> = ({ open, close, successCallback, name }) => {
   const fileTypes: string[] = ["CSV"];
   const fileTypeXlsx: string[] = ["xlsx"];
   const [file, setFile] = useState<any>(null);
   const [error, setError] = useState<any>(null);

   const onUpload = () => {
      if (file !== null) {
         const formData = new FormData();
         formData.append("importedFile", file);
         formData.append("frontendURL", window.location.origin)
         if (name === "DevicePersonMapping") {
            PersonService.importDevicePersonMapping(formData).then((res: any) => {
               onUploadResult(res);
            });
         } else {
            DeviceService.import(formData, name).then((res: any) => {
               onUploadResult(res);
            });
         }
      }
   }
   const onUploadResult = (res: any) => {
      if (res?.status === StatusCode.Success) {
         setError(null);
         successCallback();
      }
      else if (res?.status === StatusCode.BadRequest) {
         setError(res?.data)
      }
   }

   //#region Download
   const onDownload = (exportType: string) => {
      if (name === "DevicePersonMapping") {
         PersonService.exportDevicePersonMappingUploadFormat({ exportType }).then((res: any) => {
            onDownloadResult(res, exportType);
         });
      } else {
         DeviceService.exportUploadFormat({ exportType }, name).then((res: any) => {
            onDownloadResult(res, exportType);
         });
      }
   }
   const onDownloadResult = (res: any, exportType: string) => {
      if (res?.status === StatusCode.Success && res?.data) {
         const link = document.createElement("a");
         link.target = "_blank";
         link.download = `${name}_Template`;
         link.href = URL.createObjectURL(
            new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
         );
         link.click();
      }
   }
   //#endregion
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="md">
         <DialogTitle>Import {name === "DevicePersonMapping" ? "Device Person Mapping" : name === "PegasusData" ? "Pegasus Data" : name}</ DialogTitle>
         <DialogContent dividers>
            <CustomFileUploader
               setFile={setFile}
               file={file}
               fileTypes={name === "PegasusData" ? fileTypeXlsx : fileTypes} />

            <div style={{ marginTop: 20, color: dangerColor[0] }}>
               {
                  error !== null &&
                  <Alert severity="error">
                     {error?.invalidFile ?
                        <span>Invalid file</span> :
                        <span>Some records could not be imported. </span>
                     }
                     {error?.invalidDeviceIds && error?.invalidDeviceIds.length > 0 &&
                        <>
                           <p>The following device ids are impacted:</p>
                           <ul>
                              {
                                 error?.invalidDeviceIds?.map((item: any, index: number) => {
                                    return <li key={`invalidDeviceId_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {error?.invalidDeviceOIds && error?.invalidDeviceOIds.length > 0 &&
                        <>
                           <p>The following device OIDs are not valid:</p>
                           <ul>
                              {
                                 error?.invalidDeviceOIds?.map((item: any, index: number) => {
                                    return <li key={`invalidDeviceOIds_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingClients && error?.nonExistingClients.length > 0 &&
                        <>
                           <p>The following clients do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingClients?.map((item: any, index: number) => {
                                    return <li key={`nonExistingClients_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingDeviceTypes && error?.nonExistingDeviceTypes.length > 0 &&
                        <>
                           <p>The following device types do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingDeviceTypes?.map((item: any, index: number) => {
                                    return <li key={`nonExistingDeviceTypes_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingDeviceStatus && error?.nonExistingDeviceStatus.length > 0 &&
                        <>
                           <p>The following device status do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingDeviceStatus?.map((item: any, index: number) => {
                                    return <li key={`nonExistingDeviceStatus_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingDeviceMake && error?.nonExistingDeviceMake.length > 0 &&
                        <>
                           <p>The following device make do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingDeviceMake?.map((item: any, index: number) => {
                                    return <li key={`nonExistingDeviceMake_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingDeviceModels && error?.nonExistingDeviceModels.length > 0 &&
                        <>
                           <p>The following device models do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingDeviceModels?.map((item: any, index: number) => {
                                    return <li key={`nonExistingDeviceModels_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingVehicleModels && error?.nonExistingVehicleModels.length > 0 &&
                        <>
                           <p>The following vehicle models do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingVehicleModels?.map((item: any, index: number) => {
                                    return <li key={`nonExistingVehicleModels_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingPlatforms && error?.nonExistingPlatforms.length > 0 &&
                        <>
                           <p>The following platforms do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingPlatforms?.map((item: any, index: number) => {
                                    return <li key={`nonExistingPlatforms_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingMonitoredBy && error?.nonExistingMonitoredBy.length > 0 &&
                        <>
                           <p>The following monitored by do not exist yet:</p>
                           <ul>
                              {
                                 error?.nonExistingMonitoredBy?.map((item: any, index: number) => {
                                    return <li key={`nonExistingMonitoredBy_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.nonExistingPersonsOid && error?.nonExistingPersonsOid.length > 0 &&
                        <>
                           <p>The following persons oid do not exist yet or invalid:</p>
                           <ul>
                              {
                                 error?.nonExistingPersonsOid?.map((item: any, index: number) => {
                                    return <li key={`nonExistingPersonsOid_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.invalidRowNumbers && error?.invalidRowNumbers.length > 0 &&
                        <>
                           <p>The following record number data are insufficient:</p>
                           <ul>
                              {
                                 error?.invalidRowNumbers?.map((item: any, index: number) => {
                                    return <li key={`invalidRowNumbers_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.invalidMonitoringAccountNumber && error?.invalidMonitoringAccountNumber.length > 0 &&
                        <>
                           <p>The following monitor account numbers are not valid:</p>
                           <ul>
                              {
                                 error?.invalidMonitoringAccountNumber?.map((item: any, index: number) => {
                                    return <li key={`invalidMonitoringAccountNumber_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.invalidGasTags && error?.invalidGasTags.length > 0 &&
                        <>
                           <p>The following gas tags are not valid:</p>
                           <ul>
                              {
                                 error?.invalidGasTags?.map((item: any, index: number) => {
                                    return <li key={`invalidGasTags_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                     {
                        error?.errorsDevice && error?.errorsDevice.length > 0 &&
                        <>
                           <p>The following erros occured while saving delow devices:</p>
                           <ul>
                              {
                                 error?.errorsDevice?.map((item: any, index: number) => {
                                    return <li key={`errorsDevice_${index}`}>{item}</li>
                                 })
                              }
                           </ul>
                        </>
                     }
                  </Alert>
               }
               {name === "PegasusData" &&
                  <FormHelperText id={`error-info`} variant="outlined">Note: This feature is only for "Suncorp" client and update device optional fields only.</FormHelperText>
               }
            </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
            {name !== "PegasusData" &&
               <ExportOptions onDownload={onDownload} children={(bindTrigger: any) =>
                  <Button color="info" {...bindTrigger} >Download Template</Button>
               } />
            }
            <Button color="primary" onClick={onUpload} disabled={!Common.stringNullOrEmpty(error)}>Upload</Button>
         </DialogActions>
      </Dialog>
   );
};

export default ImportDeviceModal;
