// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
import environment from "../../environment";
import { PublicClientApplication } from "@azure/msal-browser";
export const msalInstance = new PublicClientApplication({
   auth: {
      clientId: environment.azure.clientId,
      authority: "https://login.microsoftonline.com/" + environment.azure.tenantId, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: '/auth.html',
      postLogoutRedirectUri: '/login'
   },
   cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
   }
});
// msalInstance.addEventCallback(async (message: EventMessage) => {
//    // Update UI or interact with EventMessage here
//    if (message.eventType === EventType.LOGIN_SUCCESS) {
//       var res = await (async() => { await authService.getFeaturesPermissions() })();
//       window.location.href = window.location.origin;
//    };
// });
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
   scopes: ['openid profile']
};
