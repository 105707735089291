import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, CardHeader, Grid, Typography, MenuItem, Divider } from '@mui/material';
import { Delete, Edit, History, Phone, Http, Person, ManageAccounts } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IMonitoringStationDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { Common } from "../../../constants/common";
import NavigationLink from "../../Common/NavigationLink";

const MonitoringStationCard: React.FC<IMonitoringStationDataProps> = ({ classes, monitoringStation, onEdit, onDelete, onShowAuditLog, actionPermission }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <ActionOptionMenu>
                     {(actionPermission["MonitoringStation:POST"]) &&
                        <>
                           <MenuItem disableRipple onClick={() => { onEdit(monitoringStation) }}>
                              <Edit />
                              Edit
                           </MenuItem>
                           <Divider />
                        </>
                     }
                     {(actionPermission["Audit:MonitoringStation"]) &&
                        <MenuItem disableRipple onClick={() => { onShowAuditLog(monitoringStation) }}>
                           <History />
                           Audit history
                        </MenuItem>
                     }
                     {(actionPermission["User:Search"]) &&
                        <NavigationLink to={`/users/${monitoringStation.oid}`}>
                           <MenuItem disableRipple>
                              <ManageAccounts color="primary" />Manage Users
                           </MenuItem>
                        </NavigationLink>
                     }
                     {(actionPermission["MonitoringStation:DELETE"]) &&
                        <>
                           <Divider />

                           <MenuItem disableRipple onClick={() => { onDelete(monitoringStation, true) }}>
                              <Delete />
                              Delete
                           </MenuItem>
                        </>
                     }
                  </ActionOptionMenu>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {monitoringStation.name}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Http className={classes.beforeTextIcon} /> {monitoringStation.url}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Person className={classes.beforeTextIcon} /> {monitoringStation.username}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Phone className={classes.beforeTextIcon} /> {Common.formatPhoneNumber(monitoringStation.phoneNumber)}
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(MonitoringStationCard, gridTileStyle);
