import React, { useEffect, useState } from 'react';
import { AppBar, Grid, Tab, Tabs, Typography } from "@mui/material";
import { IEscalationDetailResponseInstruction } from '../../types/propType';
import { EscalaltionDetailInstructionBlockList } from './EscalaltionDetailInstructionBlockList';
import { primaryColor } from '../../assets/jss/appStyle';
function a11yProps(tabName: string) {
   return {
      id: `full-width-tab-${tabName}`,
      'aria-controls': `full-width-tabpanel-${tabName}`,
   };
}
const EscalationDetailResponseInstruction: React.FC<IEscalationDetailResponseInstruction> = ({ responseInstruction }) => {
   let instructions: any[] = [];
   let deviceInstruction: any[] = [];
   let clientInstruction: any[] = [];
   const [currentTab, setCurrentTab] = useState("");
   useEffect(() => {
      if (deviceInstruction.length > 0) {
         const firstDeviceInstruction = deviceInstruction[0];
         if (firstDeviceInstruction?.InstructionTypes?.length > 0) {
            setCurrentTab(`${firstDeviceInstruction.DeviceType}_${firstDeviceInstruction?.InstructionTypes[0]?.InstructionFor}`)
         }
      }
   }, [responseInstruction]) // eslint-disable-line react-hooks/exhaustive-deps
   if (responseInstruction) {
      try {
         instructions = JSON.parse(responseInstruction);
         if (instructions.length > 0) {
            if (instructions[0].hasOwnProperty("DeviceType")) {
               deviceInstruction = instructions;
            }
            else if (instructions[0].hasOwnProperty("Text")) {
               clientInstruction = instructions;
            }
         }
      } catch (ex) {
      }
   }
   else {
      return null;
   }
   return (
      <>
         {deviceInstruction.length > 0 && deviceInstruction.map((deviceTypes: any, i1: number) =>
            <Grid key={`deviceInstruction_${i1}`} item xs={12} sm={12} md={12}>
               <Typography variant="h5" gutterBottom>
                  {deviceTypes.DeviceType}
               </Typography>
               <AppBar style={{ marginBottom: "5px", background: primaryColor[0] }} position="sticky">
                  <Tabs
                     value={currentTab}
                     onChange={(event: React.SyntheticEvent, newValue: string) => setCurrentTab(newValue)}
                     indicatorColor="secondary"
                     textColor="inherit"
                     variant="fullWidth"
                  >
                     {deviceTypes.InstructionTypes.map((instructionTypes: any, i2: number) =>
                        <Tab key={`tab_${i2}`} style={{ color: "white", fontWeight: "bolder" }} label={`${instructionTypes.InstructionFor}`} value={`${deviceTypes.DeviceType}_${instructionTypes.InstructionFor}`} {...a11yProps(`${instructionTypes.InstructionFor}`)} />
                     )}
                  </Tabs>
               </AppBar>
               {deviceInstruction.map((deviceTypes: any, i1: number) =>
                  deviceTypes.InstructionTypes.map((instructionTypes: any, i2: number) => {
                     if (`${deviceTypes.DeviceType}_${instructionTypes.InstructionFor}` === currentTab)
                        return <EscalaltionDetailInstructionBlockList blocks={[...instructionTypes.TextBlocks]} />
                     else return null;
                  }
                  )
               )}
            </Grid>
         )}

         {clientInstruction.length > 0 &&
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12} md={12}>
                  <EscalaltionDetailInstructionBlockList blocks={[...clientInstruction]} />
               </Grid>
            </Grid>
         }
         {deviceInstruction.length === 0 && clientInstruction.length === 0 && responseInstruction &&
            <Grid container spacing={2}>
               <Grid item xs={12} sm={12} md={12}>
                  <strong>{responseInstruction}</strong>
               </Grid>
            </Grid>
         }
      </>
   );
}
export default EscalationDetailResponseInstruction;
