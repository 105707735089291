import React from "react";
import { warningColor } from "../../assets/jss/appStyle";
import { withStyles } from 'tss-react/mui';
import { Add, Autorenew, ContentCopy, Api, History } from "@mui/icons-material";
import pagesStyle from '../../assets/jss/pages/escalationDetailStyle';
import { IClientApiKeyPageState } from "../../types/stateType";
import { IClientApiKeyPageProps } from "../../types/propType";
import { Card, CardHeader, CardContent, Grid, Typography, CardActions, FormHelperText, Tooltip } from "@mui/material";
import { Controllers, StatusCode } from "../../constants/constant";
import { Common } from "../../constants/common";
import Button from '../../components/Common/Button';
import clientApiKeyService from "../../services/clientapikey.service";
import { withSnackbar } from "../../components/Common/Snackbar";
import copy from 'copy-to-clipboard';
import environment from './../../environment';
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
class ClientApiKey extends React.Component<IClientApiKeyPageProps, IClientApiKeyPageState> {
   state: IClientApiKeyPageState = {
      apiKeys: [],
      error: null,
      openAuditLogs: false,
      selectedOid: null,
      keyName: null
   };
   componentDidMount() {
      clientApiKeyService.getClientApiKeys().then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            this.setState({ ...this.state, apiKeys: res?.data, error: null });
         }
         else {
            this.setState({ ...this.state, error: res?.data?.message });
         }
      });
   }
   generateApiKey = (oid: string) => {
      clientApiKeyService.generateClientApiKey(oid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const newKey: any = res?.data;
            let apiKeys = this.state.apiKeys;
            let keyIndex = apiKeys.map((item: any) => item.keyName).indexOf(newKey.keyName);
            if (keyIndex > -1) {
               apiKeys[keyIndex] = newKey;
            }
            else {
               apiKeys.push(newKey);
            }
            this.setState({ ...this.state, apiKeys: apiKeys, error: null });
         }
         else {
            this.setState({ ...this.state, error: res?.data?.message });
         }
      });
   }
   onCopyClick = (text: string) => {
      copy(text);
      this.props.snackbarShowMessage("Copied to clipboard.");
   }
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: false, selectedOid: null, keyName: null });
   }
   openAuditLogModal = (oid: string, keyName: string) => {
      this.setState({ ...this.state, openAuditLogs: true, selectedOid: oid, keyName: keyName });
   }
   render() {
      const { classes, actionPermission } = this.props;
      const { apiKeys, error, openAuditLogs, selectedOid, keyName } = this.state;
      return (
         <div>
            <div className={classes.pageHeader} data-testid={`escalationdetail_header`}>
               <Typography variant="h5" component="h2" className={classes.pageTitle}>
                  Api Keys
               </Typography>
            </div>
            <Grid container spacing={2}>
               {apiKeys && apiKeys.length === 0 &&
                  <Grid item>
                     <Button color="primary" startIcon={<Add />} onClick={() => this.generateApiKey(Common.EmptyGuid)}>Add Api Key</Button>
                  </Grid>
               }
               {apiKeys && apiKeys.length > 0 &&
                  apiKeys.map((apiKey: any, index: number) => {
                     return (
                        <Grid key={index} item xs={12}>
                           <Card style={{ border: `2px solid ${warningColor[0]}`, height: "100%" }}>
                              <CardHeader
                                 title={
                                    <>
                                       <Typography variant="h6" component="div">
                                          {apiKey.keyName}
                                          {actionPermission["Audit:ClientApiKey"] &&
                                             <Tooltip style={{ float: "right" }} title="Audit Log">
                                                <Button color="success" justIcon={true} onClick={() => this.openAuditLogModal(apiKey.oid, apiKey.keyName)} >
                                                   <History />
                                                </Button>
                                             </Tooltip>
                                          }

                                       </Typography>

                                    </>
                                 }
                              />
                              <CardContent>
                                 <Typography color="textSecondary" variant="body1" gutterBottom>
                                    <Grid container spacing={2}>
                                       <Grid key={index} item xs={12} md={1}>
                                          <b>Api Key:    </b>
                                       </Grid>
                                       <Grid key={index} item xs={12} md={5}>
                                          {apiKey.apiKey}
                                       </Grid>
                                       <Grid key={index} item xs={12} md={1}>
                                          <Button color="primary" size="sm" startIcon={<ContentCopy />} onClick={() => this.onCopyClick(apiKey.apiKey)}>Copy</Button>
                                       </Grid>
                                    </Grid>
                                 </Typography>
                                 {!Common.stringNullOrEmpty(apiKey.apiKeySecret) &&
                                    <Typography color="textSecondary" variant="body1" gutterBottom>
                                       <Grid container spacing={2}>
                                          <Grid key={index} item xs={12} md={1}>
                                             <b>Api Secret:</b>
                                          </Grid>
                                          <Grid key={index} item xs={12} md={5}>
                                             {apiKey.apiKeySecret}
                                          </Grid>
                                          <Grid key={index} item xs={12} md={1}>
                                             <Button color="primary" size="sm" startIcon={<ContentCopy />} onClick={() => this.onCopyClick(apiKey.apiKeySecret)}>Copy</Button>
                                          </Grid>
                                          <Grid key={index} item xs={12} md={4}>
                                             This api secret will be visible only while generated, You can copy it for future references.
                                          </Grid>
                                       </Grid>
                                    </Typography>
                                 }
                              </CardContent>
                              {actionPermission["ClientApiKey:GenerateClientApiKey"] &&
                                 <CardActions>
                                    <Button color="primary" startIcon={<Autorenew />} onClick={() => this.generateApiKey(apiKey.oid)}>Regenerate Api Key</Button>
                                 </CardActions>
                              }
                           </Card>
                        </Grid>
                     )
                  })
               }
               {apiKeys && apiKeys.length === 1 && actionPermission["ClientApiKey:GenerateClientApiKey"] &&
                  <Grid item>
                     <Button color="primary" startIcon={<Add />} onClick={() => this.generateApiKey(Common.EmptyGuid)}>Add Api Key</Button>
                  </Grid>
               }
            </Grid>
            <Grid mt={2} item>

               <a target="_blank" rel="noreferrer" href={`${environment.apiUrl.replace(/^(.+?)\/*?$/, "$1")}/Swagger/index.html`}>
                  <Button type="submit" size="sm" color="info" startIcon={<Api />} >
                     Api Document
                  </Button>
               </a>



            </Grid>
            {error &&
               <FormHelperText id="error-login" error>{error}</FormHelperText>
            }
            {
               openAuditLogs && selectedOid !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.ClientApiKey}
                  oid={selectedOid ?? ""}
                  changedEntityTitle={keyName}
               />
            }
         </div>
      )
   }
}

export default withSnackbar(withStyles(ClientApiKey, pagesStyle));
