import { Controllers } from "../constants/constant";
import request from "../utils/request";
class ClientApiKey {

   getClientApiKeys = () => {
      return request({
         url: `${Controllers.ClientApiKey}/Search`,
         method: 'get'
      });
   }

   generateClientApiKey = (oid: string) => {
      return request({
         url: `${Controllers.ClientApiKey}/GenerateClientApiKey/${oid}`,
         method: 'get'
      });
   }
}

export default new ClientApiKey();
