import { Controllers } from "../constants/constant";
import request from "../utils/request";

class AuditService {
   getAuditLogs = (entityName: string, oid: string, skip: number, continuationToken: string | null) => {
      return request({
         url: `${Controllers.Audit}/${entityName}/${oid}/${skip}/${continuationToken}`,
         method: 'get',
      })
   }
   getSingleAuditLogs = (entityName: string, auditOn: string, oid: string, isFromBlob: boolean) => {
      return request({
         url: `${Controllers.Audit}/${entityName}/${auditOn}/${oid}/${isFromBlob}/Single`,
         method: 'get',
      })
   }
   getAllDeletedEntityLogs = (entityName: string, skip: number, continuationToken: string | null) => {
      return request({
         url: `${Controllers.Audit}/${entityName}/Deleted/${skip}/${continuationToken}`,
         method: 'get',
      })
   }
}

export default new AuditService();
