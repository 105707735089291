import axios from "axios";
import request from "../utils/request";
import environment from "../environment";


class HealthService {
   checkServerStatus = () => {
      return request({
         url: `Health`,
         method: 'get'
      });
   }

   CheckMonitoringStationApi = () => {
      return request({
         url: 'Health/CheckMonitoringStationApi',
         method: 'get'
      });
   }

   CheckWialonWebhookHealth = () => request({
      url: `Health/PatriotHealthCheck`,
      method: 'get'
   })
}

export default new HealthService();
