import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from "@mui/material";
import { IAdvanceFilterProps } from '../../../types/propType';
import TripService from '../../../services/trip.service';
import { ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import CustomDatePicker from '../../Common/CustomDatePicker';
import { Common } from '../../../constants/common';
import { StatusCode } from '../../../constants/constant';
import CustomSelect from '../../Common/CustomSelect';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import personService from '../../../services/person.service';

const TripAdvanceFilter: React.FC<IAdvanceFilterProps> = ({ initialValues, onAdvanceSearch }) => {
   const [tripStatusOptions, setTripStatusOptions] = useState<any[]>([]);
   const [error, setError] = useState<any>({});
   useEffect(() => {
      TripService.getTripStatusSelectOptions().then((res: any) => {
         if (res?.status === StatusCode.Success && res.data) {
            setTripStatusOptions(res?.data);
         }
      });
   }, []);
   const onFilterSubmit = async (values: any) => {
      const validationErrors: any = {}
      if (values.StartDate && !values.EndDate) {
         validationErrors["EndDate"] = "End date is required.";
      }
      else if (!values.StartDate && values.EndDate) {
         validationErrors["StartDate"] = "Start date is required.";
      }
      else if (values.StartDate && values.EndDate && (new Date(values.EndDate) < new Date(values.StartDate))) {
         validationErrors["EndDate"] = "End date can not less than end date.";
      }
      setError(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
         onAdvanceSearch(values);
      }
   }
   const GetPersonForTripSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return personService.getPersonForTripSelectOptions(params, signal);
   }

   const GetEscalationContactForTripSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return personService.getEscalationContactForTripSelectOptions(params, signal);
   }

   return (
      <Formik initialValues={initialValues} onSubmit={(values) => onFilterSubmit(values)}>
         {({ values, handleChange, submitForm, setFieldValue }) => (
            <Form noValidate>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                     <CustomSelect
                        labelText="Status"
                        id="Status"
                        inputProps={{
                           name: "Status",
                           onChange: (e: any) => {
                              handleChange(e);
                              submitForm();
                           }
                        }}
                        value={values.Status}
                        options={[{ label: "All", value: null }].concat(tripStatusOptions)}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"Start Date"}
                        id="StartDate"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           name: "StartDate",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("StartDate", Common.getLocalToUTC(e), true);
                                 submitForm();
                              }
                           }
                        }}
                        customErrorMessage={error["StartDate"]}
                        value={values.StartDate ? Common.getUtcToLocal(values.StartDate) : null}
                        openTo="day"
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <CustomDatePicker
                        labelText={"End Date"}
                        id="EndDate"
                        inputFormat={Common.DateFormat}
                        inputProps={{
                           name: "EndDate",
                           InputLabelProps: { shrink: true },
                           onChange: (e: any): void => {
                              if (e) {
                                 setFieldValue("EndDate", Common.getLocalToUTC(e), true);
                                 submitForm();
                              }
                           }
                        }}
                        customErrorMessage={error["EndDate"]}
                        value={values.EndDate ? Common.getUtcToLocal(values.EndDate) : null}
                        openTo="day"
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Organizer Name"
                        id="OrganizerOid"
                        inputProps={{
                           name: "OrganizerOid",
                        }}
                        value={values.OrganizerOid}
                        onSearchTextChange={GetPersonForTripSelectOptions}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("OrganizerOid", value?.value);
                           submitForm();
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Person in the trip"
                        id="PersonOid"
                        inputProps={{
                           name: "PersonOid",
                        }}
                        value={values.PersonOid}
                        onSearchTextChange={GetPersonForTripSelectOptions}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("PersonOid", value?.value);
                           submitForm();
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <SearchableServerSearch
                        labelText="Escalation Contact"
                        id="EscalationPersonOid"
                        inputProps={{
                           name: "EscalationPersonOid",
                        }}
                        value={values.EscalationPersonOid}
                        onSearchTextChange={GetEscalationContactForTripSelectOptions}
                        setValue={(value: ISelectOption) => {
                           setFieldValue("EscalationPersonOid", value?.value);
                           submitForm();
                        }}
                     />
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default TripAdvanceFilter;
