import { Controllers } from "../constants/constant";
import request from "../utils/request";

class SftpMonitoringLogs {
   searchSftpMonitoringLogs = (params: any) => {
      return request({
         url: `${Controllers.SftpMonitoringLogs}/Search`,
         method: 'get',
         params
      });
   }

   exportSftpMonitoringLogs = (params: any) => {
      return request({
         url: `${Controllers.SftpMonitoringLogs}/Export`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }
}

export default new SftpMonitoringLogs();
