import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IMonitoringStationDependenciesModalProps } from '../../../types/propType';
import { Pages } from '../../../constants/constant';
import { getActionPermission } from '../../../utils/PermissionHelpers';
import Button from './../../Common/Button';
import Devices from "../../../Pages/Devices/Devices";

const MonitoringStationDependenciesModal: React.FC<IMonitoringStationDependenciesModalProps> = ({ classes, open, close, monitoringStationName, dependencies, onChangeDependencies, onConfirmDelete }) => {
   const deviceActionPermission = getActionPermission(Pages.Devices);
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle>Following device are depends on <strong>{`${monitoringStationName}`}</strong></ DialogTitle>
         <DialogContent dividers>            
            {dependencies?.assignedDeviceToMonitoringStation?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from assignment or assign different monitoring station for below devices.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Devices isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEdit={true} devices={dependencies.assignedDeviceToMonitoringStation} onChangeDependencies={onChangeDependencies} actionPermission={deviceActionPermission}/>
                  </AccordionDetails>
               </Accordion>
            }
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button color="primary" onClick={onConfirmDelete}>Continue</Button>
         </DialogActions>
      </Dialog>
   );
};

export default MonitoringStationDependenciesModal;


