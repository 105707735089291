import axios, { AxiosRequestHeaders } from "axios";
import environment from '../environment';
import { StatusCode } from '../constants/constant';
import { Logout, getAuthToken } from "../utils/LoginHelpers";
import { keycloakInstance } from "./keycloak/keycloakHelpers";
const baseURL: string = `${environment.apiUrl.replace(/^(.+?)\/*?$/, "$1")}/api/v1`; // url = base url + request url
const request = axios.create({
   baseURL: baseURL,
   timeout: 12400000,
   responseType: 'json',
});

let requests: string[] = [];
let conflictRequest: string = "";
let isRefreshing: boolean = false;
let failedQueue: any[] = [];
// Request interceptors Customize based on your need
request.interceptors.request.use(
   async config => {
      config.headers = config.headers as AxiosRequestHeaders;
      if (config.headers) {
         if (!config.headers.hasContentType())
            config.headers.setContentType("application/json");
      }

      if (config?.url && !config?.signal) {
         requests.push(config?.url);
         if (config?.url !== "Health/CheckMonitoringStationApi"
            && config?.url !== "Health/PatriotHealthCheck") {
            showLoader();
         }
      }
      // Add X-Access-Token header to every request, you can add other custom headers here
      const authToken = await getAuthToken();
      if (authToken && config.headers) {
         // Add token to auth
         config.headers.set('Authorization', 'Bearer ' + authToken)
      }

      return config;
   },
   error => {
      alert(error);
      Promise.reject(error);
   }
);

// Response interceptors Customize based on your need
request.interceptors.response.use(
   (response: any) => {
      const { data } = response;
      removeRequest(response.config.url);
      if (data?.code && data?.code !== StatusCode.Success) {
         return Promise.reject(new Error(data.message || "Error"));
      } else {
         return Promise.resolve(response);
      }
   },
   async (error: any) => {
      if (axios.isCancel(error)) {
         return Promise.resolve(error.message);
      }
      removeRequest(error?.config?.url);
      if (error?.code === "ERR_NETWORK" || error?.message === "Network Error") {
         if (window.location.pathname !== "/networkissue")
            window.location.href = "/networkissue";
      }
      switch (error?.response?.status) {
         // Authorization Failed Response can add other status codes here to manage error Logging
         case StatusCode.Forbidden:
            window.location.href = "/unauthorized";
            break;
         case StatusCode.ServiceUnavailable:
            if (error?.config?.url?.indexOf("Health") > -1)
               return Promise.resolve(error.response);
            else
               window.location.href = "/undermaintenance";
            break;
         case StatusCode.Unauthorized:
            if (error.response.headers["token-expired"] === "true") {
               const originalRequest = error?.config;
               originalRequest.headers = {};
               if (isRefreshing) {
                  originalRequest._retry = true;
                  return new Promise(function (resolve, reject) {
                     failedQueue.push({ resolve, reject })
                  }).then(token => {
                     return request(originalRequest);
                  }).catch(err => {
                     return Promise.reject(err);
                  })
               }
               isRefreshing = true;
               if (!originalRequest._retry) {
                  originalRequest._retry = true;
                  try {
                     await refreshToken();
                     return request(originalRequest);
                  } catch (_error) {
                     return Promise.reject(_error);
                  }
               }
            }
            else {
               localStorage.clear();
               if (Boolean(environment?.kcEnabled)) {
                  if (error.response.headers["idp-authentication-failed"] === "true")
                     keycloakInstance.clearToken();
                  else
                     Logout(); // If Keycloak have not enabled in Backend,It should force logout from Kecloak.
               }
               window.location.href = "/unauthorized";
            }
            break;
         case StatusCode.Conflict: // conflicts with existing record.
            conflictRequest = error.response.config.url;
            return Promise.resolve(error.response);
         case StatusCode.BadRequest:
            return Promise.resolve(error.response);
         case StatusCode.InternalServer:
            // ReactDOM.render(
            //    React.createElement(InternalServerError, { hasError: true }, ""),
            //    document.getElementById('custom-error')
            // );
            return Promise.resolve(error.response);
         default:
            break;
      }
      return Promise.reject(error);
   }
);
async function refreshToken() {
   if (Boolean(environment?.kcEnabled)) {
      keycloakInstance.updateToken().catch(() => {
         localStorage.clear();
         keycloakInstance.clearToken();
         processQueue("sessionexpired");
         window.location.href = "/sessionexpired";
      });
   }
   else {
      const data = {
         Token: localStorage.getItem("token"),
         RefreshToken: localStorage.getItem("refreshToken")
      }
      await request({
         url: `Auth/RefreshToken`,
         method: 'post',
         data
      }).then((res: any) => {
         if (res?.status === 200 && res?.data) {
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("roles", JSON.stringify(res?.data?.roles));
            localStorage.setItem("refreshToken", res?.data?.refreshToken);
            localStorage.setItem("clientName", res?.data?.clientName);
            localStorage.setItem("permissions", JSON.stringify(res?.data?.permissions));
            localStorage.setItem("allowDeviceAndEscalationAssignmentToSelf", res?.data?.allowDeviceAndEscalationAssignmentToSelf);
            processQueue(null);
         } else {
            localStorage.clear();
            processQueue("sessionexpired");
            window.location.href = "/sessionexpired";
         }
      });
   }
}

function showLoader() {
   document.body.classList.add('loader-open');
}

function hideLoader() {
   document.body.classList.remove('loader-open');
}

// remove completed request
function removeRequest(req: string) {
   const i = requests.indexOf(req);
   if (i >= 0) {
      requests.splice(i, 1);
   }
   if (requests.length > 0) {
      if (req !== "Health/CheckMonitoringStationApi" && req !== "Health/PatriotHealthCheck") {
         showLoader();
      }
   } else {
      hideLoader();
   }
   if (req === conflictRequest) {
      conflictRequest = "";
      requests = requests.filter(request => request !== req)
   }
}

const processQueue = (error: any, token: any = null) => {
   failedQueue.forEach(prom => {
      if (error) {
         prom.reject(error);
      } else {
         prom.resolve(token);
      }
   });
   failedQueue = [];
   isRefreshing = false;
}
export default request;
