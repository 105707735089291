import { IGridHeaderData } from '../types/dataTypes';

export class TableHeader {
   public static getClientTableHeaders() {
      const ClientTableHeader: IGridHeaderData[] = [
         {
            title: "", name: "", isSortable: false, isAction: true
         },
         {
            title: "Name", name: "Name", isSortable: true, isAction: false
         },
         {
            title: "Plan", name: "Plan", isSortable: true, isAction: false
         },
         {
            title: "Email", name: "Email", isSortable: true, isAction: false,
         },
         {
            title: "Phone number", name: "PhoneNumber", isSortable: true, isAction: false,
         },
         {
            title: "Country", name: "Country", isSortable: true, isAction: false,
         },
         {
            title: "Account Owner", name: "accountOwner", isSortable: false, isAction: false,
         },
         {
            title: "Persons", name: "persons", isSortable: false, isAction: false,
         },
         {
            title: "Devices", name: "devices", isSortable: false, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         },
      ];
      return ClientTableHeader;
   }

   public static getPersonTableHeaders() {
      const PersonTableHeader: IGridHeaderData[] = [
         {
            title: "", name: "", isSortable: false, isAction: true
         },
         {
            title: "Name", name: "Firstname+Lastname", isSortable: true, isAction: false
         },
         {
            title: "Email", name: "Email", isSortable: true, isAction: false
         },
         {
            title: "Phone number", name: "PhoneNumber1", isSortable: true, isAction: false
         },
         // {
         //    title: "Alternate Phone number", name: "phoneNumber2", isSortable: true, isAction: false,
         // },
         {
            title: "Device ID", name: "DeviceId", isSortable: false, isAction: false
         },
         {
            title: "Client Name", name: "PersonClientRelation.Client.Name", isSortable: true, isAction: false
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         },
      ];
      return PersonTableHeader;
   }

   public static getDeviceTableHeaders() {
      const ClientTableHeader: IGridHeaderData[] = [
         {
            title: "", name: "", isSortable: false, isAction: true
         },
         {
            title: "Device Type", name: "DeviceType", isSortable: true, isAction: false
         },
         {
            title: "Device ID", name: "DeviceId", isSortable: true, isAction: false
         },
         {
            title: "Device Model", name: "DeviceModel", isSortable: true, isAction: false,
         },
         {
            title: "Platform", name: "Platform", isSortable: true, isAction: false,
         },
         {
            title: "Client Name", name: "Client.Name", isSortable: true, isAction: false,
         },
         {
            title: "Person Name", name: "Person.Firstname+Person.Lastname", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         },
      ];
      return ClientTableHeader;
   }

   public static getAlertTableHeaders() {
      const AlertTableHeader: IGridHeaderData[] = [
         {
            title: "Alert Id", name: "alertId", isSortable: false, isAction: false
         },
         {
            title: "Device ID", name: "deviceId", isSortable: false, isAction: false
         },
         {
            title: "Alert Type", name: "alerttype", isSortable: false, isAction: false,
         },
         {
            title: "User", name: "user", isSortable: false, isAction: false,
         },
         {
            title: "Signal Status", name: "patriotsignalstatus", isSortable: false, isAction: false,
         },
         {
            title: "Time", name: "time", isSortable: false, isAction: false,
         }
      ];
      return AlertTableHeader;
   }
   public static getUserTableHeaders() {
      const AlertTableHeader: IGridHeaderData[] = [
         {
            title: "Email", name: "Email", isSortable: true, isAction: false
         },
         {
            title: "Name", name: "Firstname+Lastname", isSortable: true, isAction: false
         },
         {
            title: "Portal Role", name: "PortalRole", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return AlertTableHeader;
   }
   public static getMonitoringStationTableHeaders() {
      const MonitoringStationTableHeader: IGridHeaderData[] = [
         {
            title: "Name", name: "Name", isSortable: true, isAction: false
         },
         {
            title: "API End Point", name: "uRL", isSortable: false, isAction: false
         },
         {
            title: "Username", name: "username", isSortable: false, isAction: false
         },
         {
            title: "Phone Number", name: "phoneNumber", isSortable: false, isAction: false
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return MonitoringStationTableHeader;
   }
   public static getEscalationReportTableHeaders(nameColumnLabel: string | undefined) {
      const escalationReportTableHeaders: IGridHeaderData[] = [];
      escalationReportTableHeaders.push({
         title: nameColumnLabel ?? "Name", name: "name", isSortable: false, isAction: false
      });
      if (nameColumnLabel === "Person Name") {
         escalationReportTableHeaders.push({
            title: "Assigned Device(s)", name: "deviceId", isSortable: false, isAction: false
         });
      }
      if (nameColumnLabel === "Device ID") {
         escalationReportTableHeaders.push({
            title: "Assigned Person", name: "personname", isSortable: false, isAction: false
         });
      }
      escalationReportTableHeaders.push({
         title: "Escalation / Response Instruction Details", name: "escalationdetail", isSortable: false, isAction: false
      });
      return escalationReportTableHeaders;
   }
   public static getUserSupportTableHeaders() {
      const UserSupportTableHaader: IGridHeaderData[] = [
         {
            title: "Email", name: "Email", isSortable: true, isAction: false
         },
         {
            title: "Name", name: "Firstname+Lastname", isSortable: true, isAction: false
         },
         {
            title: "Portal Role", name: "PortalRole", isSortable: false, isAction: false,
         },
         {
            title: "Client Name", name: "clientName", isSortable: false, isAction: false
         },
         {
            title: "Monitoring Station", name: "monitoringStation", isSortable: false, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return UserSupportTableHaader;
   }
   public static getErrorLogTableHeaders() {
      const ErrorLogTableHeader: IGridHeaderData[] = [
         {
            title: "Timestamp", name: "ModifiedOn", isSortable: false, isAction: false,
         },
         {
            title: "Function / URL", name: "FunctionOrURL", isSortable: false, isAction: false
         },
         {
            title: "File Name", name: "FileName", isSortable: false, isAction: false
         },
         {
            title: "Request Data", name: "RequestData", isSortable: false, isAction: false,
         },
         {
            title: "Response Data", name: "ResponseData", isSortable: false, isAction: false
         },
         {
            title: "Error", name: "Error", isSortable: false, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return ErrorLogTableHeader;
   }
   public static getSftpImportLogsTableHeaders() {
      const ErrorLogTableHeader: IGridHeaderData[] = [
         {
            title: "Timestamp", name: "ModifiedOn", isSortable: false, isAction: false,
         },
         {
            title: "Name", name: "Name", isSortable: false, isAction: false
         },
         {
            title: "Email", name: "email", isSortable: false, isAction: false
         },
         {
            title: "Phone Number", name: "phoneNumber", isSortable: false, isAction: false
         },
         {
            title: "EmployeeId", name: "employeeId", isSortable: false, isAction: false,
         },
         {
            title: "Type", name: "Type", isSortable: false, isAction: false,
         },
         {
            title: "Error / Warning Text", name: "Error", isSortable: false, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return ErrorLogTableHeader;
   }
   public static getDevicePersonReportTableHeaders() {
      const DevicePersonReportTableHeader: IGridHeaderData[] = [
         {
            title: "Device ID", name: "DeviceId", isSortable: true, isAction: false
         },
         {
            title: "Person Name", name: "Person.Firstname+Person.Lastname", isSortable: true, isAction: false,
         }
      ];
      return DevicePersonReportTableHeader;
   }
   public static getTripTableHeaders() {
      const TripTableHeader: IGridHeaderData[] = [
         {
            title: "Title", name: "Title", isSortable: true, isAction: false
         },
         {
            title: "Status", name: "Status", isSortable: true, isAction: false
         },
         {
            title: "Start Time", name: "StartTime", isSortable: true, isAction: false,
         },
         {
            title: "End Time", name: "EndTime", isSortable: true, isAction: false,
         },
         {
            title: "Organizer", name: "Organizer", isSortable: true, isAction: false,
         },
         {
            title: "Device ID", name: "Device.DeviceId", isSortable: true, isAction: false,
         },
         {
            title: "Vehicle Registration", name: "VehicleRegistration", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return TripTableHeader;
   }

   public static getTripLocationTableHeaders() {
      const TripLocationTableHeader: IGridHeaderData[] = [
         {
            title: "Title", name: "Title", isSortable: true, isAction: false
         },
         {
            title: "Location Type", name: "Type", isSortable: true, isAction: false,
         },
         {
            title: "Description", name: "Description", isSortable: true, isAction: false,
         },
         {
            title: "Physical Address", name: "PhysicalAddress", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return TripLocationTableHeader;
   }

   public static getHazardLocationTableHeaders() {
      const HazardLocationTableHeader: IGridHeaderData[] = [
         {
            title: "Hazard Type", name: "Type", isSortable: true, isAction: false
         },
         {
            title: "Security Level", name: "SecurityLevel", isSortable: true, isAction: false,
         },
         {
            title: "Security Level Description", name: "SecurityLevelDescription", isSortable: true, isAction: false,
         },
         {
            title: "Status", name: "Status", isSortable: true, isAction: false,
         },
         {
            title: "Warning", name: "Warning", isSortable: true, isAction: false,
         },
         {
            title: "Onsite Contact", name: "OnsiteContact", isSortable: true, isAction: false,
         },
         {
            title: "Onsite Contact Phone Number", name: "OnsiteContactPhoneNumber", isSortable: true, isAction: false,
         },
         {
            title: "Notes", name: "Notes", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return HazardLocationTableHeader;
   }
   public static getDeviceTestReportTableHeaders() {
      const DeviceTestReportTableHeader: IGridHeaderData[] = [
         {
            title: "Client Name", name: "ClientName", isSortable: true, isAction: false
         },
         {
            title: "Device ID", name: "DeviceId", isSortable: true, isAction: false
         },
         {
            title: "Device Status", name: "DeviceStatus", isSortable: true, isAction: false
         },
         {
            title: "Test Result", name: "EventResult", isSortable: true, isAction: false,
         },
         {
            title: "Notes", name: "Notes", isSortable: true, isAction: false,
         },
         {
            title: "Date Time", name: "EventDateTime", isSortable: true, isAction: false,
         },
         {
            title: "First Name", name: "FirstName", isSortable: true, isAction: false,
         },
         {
            title: "Last Name", name: "LastName", isSortable: true, isAction: false,
         },
         {
            title: "Phone Number", name: "PhoneNumber", isSortable: true, isAction: false,
         },
         {
            title: "User Employment ID", name: "EmployeeID", isSortable: true, isAction: false,
         },
         {
            title: "User Function", name: "UserFunction", isSortable: true, isAction: false,
         },
         {
            title: "User Area", name: "Area", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return DeviceTestReportTableHeader;
   }
   public static getSftpMonitoringLogsTableHeaders() {
      const DeviceTestReportTableHeader: IGridHeaderData[] = [
         {
            title: "Timestamp", name: "DateTime", isSortable: false, isAction: false,
         },
         {
            title: "Type", name: "Type", isSortable: true, isAction: false
         },
         {
            title: "Filename", name: "Filename", isSortable: true, isAction: false,
         },
         {
            title: "Notes", name: "Notes", isSortable: true, isAction: false,
         },
         {
            title: "", name: "", isSortable: false, isAction: true
         }
      ];
      return DeviceTestReportTableHeader;
   }
}
