export class Resources {
   public static readonly Brand = "Gaurdian Angle Safety";

   // Validation Messages
   public static readonly Created = " has been created successfully";
   public static readonly Updated = " has been updated successfully";
   public static readonly Deleted = " has been deleted successfully";
   public static readonly Saved = " has been saved successfully";

   // Error Messages
   public static readonly Error_Required = " is required.";
   public static readonly Error_Email = "Not a valid email address.";

   public static readonly Error_UserName_Required = "Username" + Resources.Error_Required;
   public static readonly Error_Password_Required = "Password" + Resources.Error_Required;

   public static readonly Error_ClientName_Required = "Client name" + Resources.Error_Required;
   public static readonly Error_ClientEmail_Required = "Client email" + Resources.Error_Required;
   public static readonly Error_ClientPhoneNumber_Required = "Client phone number" + Resources.Error_Required;
   public static readonly Error_ClientCountry_Required = "Client country" + Resources.Error_Required;
   public static readonly Error_ClientPlan_Required = "Client plan" + Resources.Error_Required;
   public static readonly Error_DayOfMonthToSendReport_Required = "Day of month to send report" + Resources.Error_Required;
   public static readonly Error_PrivacyBlurb_Required = "Privacy Blurb" + Resources.Error_Required;
   public static readonly Error_ReportRecipients_Required = "Report Recipients must have at least one person.";

   public static readonly Error_DeviceId_Required = "Device id" + Resources.Error_Required;
   public static readonly Error_DeviceModel_Required = "Device model" + Resources.Error_Required;
   public static readonly Error_Platform_Required = "Platform" + Resources.Error_Required;

   public static readonly Error_PersonFirstname_Required = "Person first name" + Resources.Error_Required;
   public static readonly Error_PersonEmail_Required = "Person email" + Resources.Error_Required;
   public static readonly Error_PersonPhoneNumber_Required = "Person phone number" + Resources.Error_Required;
   public static readonly Error_RelationshipToClient_Required = "Relationship to client" + Resources.Error_Required;
   public static readonly Error_ClientNameSelection_Required = "Client name selection" + Resources.Error_Required;

   public static readonly Error_Contact1_Required = "Contact 1" + Resources.Error_Required;
   public static readonly Error_Self_Escalation_Contact = "Person can not be escalation contact for self.";

   public static readonly Error_Person_Required = "Person selection" + Resources.Error_Required;
   public static readonly Error_Device_Required = "Device selection" + Resources.Error_Required;

   public static readonly Error_DeviceType_Required = "Device type selection" + Resources.Error_Required;
   public static readonly Error_DeviceStatus_Required = "Device status selection" + Resources.Error_Required;
   public static readonly Error_DisplayName_Required = "Display name selection" + Resources.Error_Required;
   public static readonly Error_VehicleYear_Required = "Vehicle Year" + Resources.Error_Required;
   public static readonly Error_Make_Required = "Vehicle Make and Model" + Resources.Error_Required;
   public static readonly Error_VehicleModel_Required = "Vehicle model" + Resources.Error_Required;
   public static readonly Error_IMEI_Required = "IMEI" + Resources.Error_Required;

   public static readonly Error_UserEmail_Required = "User email" + Resources.Error_Required;
   public static readonly Error_UserFirstName_Required = "User first name" + Resources.Error_Required;
   public static readonly Error_PortalRole_Required = "Portal role" + Resources.Error_Required;
   public static readonly Error_UserPassword_Required = "User password" + Resources.Error_Required;

   public static readonly Error_OldPassword_Required = "Old password" + Resources.Error_Required;
   public static readonly Error_NewPassword_Required = "New password" + Resources.Error_Required;
   public static readonly Error_ConfirmPassword_Required = "Confirm password" + Resources.Error_Required;

   public static readonly Error_MonitoringStation_Required = "Monitoring Station" + Resources.Error_Required;

   public static readonly Error_MonitoringStationName_Required = "Monitoring station name" + Resources.Error_Required;
   public static readonly Error_APIEndPoint_Required = "Api endpoint" + Resources.Error_Required;
   public static readonly Error_Username_Required = "Username" + Resources.Error_Required;
   public static readonly Error_PhoneNumber_Required = "Phone number" + Resources.Error_Required;

   public static readonly Error_NewPassword_ConfirmPassword_NotMatching = "Confirm password and new password are not matching.";
   public static readonly Error_PasswordLengthValidation_Message = "New password is too short - should be 6 character minimum.";

   public static readonly Error_ResponseInstruction_Required = "Response instruction" + Resources.Error_Required;
   public static readonly Error_Contact1_Contact2_ShouldNotMatching = "Escalation contact 2 should not be same as contact 1.";
   public static readonly Error_Contact2_Contact3_ShouldNotMatching = "Escalation contact 3 should not be same as contact 1 or contact 2.";

   public static readonly Error_TripTitle_Required = "Title" + Resources.Error_Required;
   public static readonly Error_TripStatus_Required = "Status" + Resources.Error_Required;
   public static readonly Error_TripDescription_Required = "Description" + Resources.Error_Required;
   public static readonly Error_TripStartTime_Required = "Start Time" + Resources.Error_Required;
   public static readonly Error_TripEndTime_Required = "End Time" + Resources.Error_Required;
   public static readonly Error_TripVehicleRegistration_Required = "Vehicle Registration" + Resources.Error_Required;
   public static readonly Error_TripDeviceOid_Required = "Device ID" + Resources.Error_Required;
   public static readonly Error_TripCellPhoneNumber_Required = "Cell Phone Number" + Resources.Error_Required;
   public static readonly Error_TripPersons_Required = "At least one person" + Resources.Error_Required;
   public static readonly Error_TripLocations_Required = "At least one location" + Resources.Error_Required;
   public static readonly Error_AssessmentOfHazardsAndSafety_Required = "Assessment Of Hazards And Safety" + Resources.Error_Required;

   public static readonly Error_TripStartTime_Invalid = "Invalid start time!";
   public static readonly Error_TripEndTime_Invalid = "Invalid end time!";

   public static readonly Error_LocationTitle_Required = "Title" + Resources.Error_Required;
   public static readonly Error_Type_Required = "Location Type" + Resources.Error_Required;
   public static readonly Error_LocationGeometry_Required = "Geometry type" + Resources.Error_Required;

   public static readonly Error_HazardLocationType_Required = "Hazard Type" + Resources.Error_Required;
   public static readonly Error_HazardLocationSecurityLevel_Required = "Security level" + Resources.Error_Required;
   public static readonly Error_HazardLocationSecurityLevelDescription_Required = "Security level description" + Resources.Error_Required;
   public static readonly Error_HazardLocationStatus_Required = "Status" + Resources.Error_Required;
   public static readonly Error_HazardLocationWarning_Required = "Warning" + Resources.Error_Required;
   public static readonly Error_HazardLocationOnsiteContact_Required = "Onsite contact" + Resources.Error_Required;
   public static readonly Error_HazardLocationOnsiteContactPhoneNumber_Required = "Onsite contact phone number" + Resources.Error_Required;
   public static readonly Error_HazardLocationNotes_Required = "Notes" + Resources.Error_Required;
   public static readonly Error_HazardLocationGeometry_Required = "Geometry" + Resources.Error_Required;

   public static readonly Error_OldPersonName_Required = "Old person name" + Resources.Error_Required;
   public static readonly Error_NewPersonName_Required = "New person name" + Resources.Error_Required;
   public static readonly Error_OldPersonName_NewPersonName_ShouldNotMatching = "Old person name should not be same as new person name.";

   public static readonly Error_NotValidNumber = "Enter valid number.";

   public static readonly Error_DeviceEvent_TestResult_Required = "Test result" + Resources.Error_Required;
   public static readonly Error_DeviceEvent_Notes_Required = "Notes" + Resources.Error_Required;
   public static readonly Error_DeviceEvent_DateTime_Required = "Test Datetime" + Resources.Error_Required;

   //Confirm Messages
   public static readonly Confirm = "Are you sure you want to ";

   public static readonly Confirm_Delete_Client = Resources.Confirm + "delete this client?";
}

