import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography, Tooltip } from '@mui/material';
import { MobileFriendly, AccessTime, Notes, CheckCircle, Cancel, PhoneMissed } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IDeviceTestReportDataProps } from "../../../types/propType";
import Button from "../../Common/Button";
import { Common } from "../../../constants/common";

const DeviceTestReportCard: React.FC<IDeviceTestReportDataProps> = ({ classes, record, actionPermission, onOpenDeviceTest }) => {
   const getStatusIcon = (status: string | null | undefined) => {
      switch (status) {
         case "Pass":
            return <CheckCircle color="success" />
         case "Fail":
            return <Cancel color="error" />
         case "No Contact":
            return <PhoneMissed style={{ color: "gray" }} />

         default:
            return <></>
      }
   }
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <>
                     {actionPermission["DeviceEvent:POST"] &&
                        <Button color="info" justIcon={true} onClick={() => onOpenDeviceTest(record)}>
                           <Tooltip title="Add device test result">
                              <MobileFriendly />
                           </Tooltip>
                        </Button>
                     }
                  </>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {`${record.deviceId}`}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  {
                     getStatusIcon(record.eventResult)
                  }
                  {record.eventResult}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Notes className={classes.beforeTextIcon} /> {record.eventNotes}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <AccessTime className={classes.beforeTextIcon} /> {Common.getFormattedDateTime(record.eventDateTime)}
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(DeviceTestReportCard, gridTileStyle);
