import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import UserRow from "../../components/pages/Users/UserRow";
import UserCard from "../../components/pages/Users/UserCard";
import AddEditUser from "../../components/pages/Users/AddEditUser";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
import { IUserPageState } from "../../types/stateType";
import { IUserPageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IUser, IDeleteParams, IUserFilter, IPasswordResetMail } from "../../types/dataTypes";
import { TableHeader } from "../../constants/tableHeader";
import UserService from "../../services/user.service";
import MonitoringStationService from "../../services/monitoringstation.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import { withSnackbar } from "../../components/Common/Snackbar";

class Users extends React.Component<IUserPageProps, IUserPageState> {
   filterOptions: IUserFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      MonitoringStationOid: this.props?.params?.monitoringStationOid ?? Common.EmptyGuid,
      sortString: "Firstname+Lastname"
   }

   state: IUserPageState = {
      users: [],
      totalRecords: 0,
      openAddEditUserModal: false,
      selectedUser: null,
      showConfirmDialog: false,
      openAuditLogs: false,
      monitoringStationName: ""
   };

   componentDidMount() {
      const { MonitoringStationOid } = this.filterOptions;
      MonitoringStationService.getMonitoringStationName(MonitoringStationOid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            this.filterOptions.MonitoringStationOid = res?.data?.value ?? Common.EmptyGuid;
            this.setState({
               ...this.state,
               monitoringStationName: res?.data?.label
            }, () => this.searchUsers());
         }
      });
   }

   mapUserObject = (selectedUser: any): IUser => {
      return {
         Oid: selectedUser.oid,
         Email: selectedUser.email,
         Firstname: selectedUser.firstname,
         Lastname: selectedUser.lastname,
         RoleNames: selectedUser.roleNames,
         MonitoringStationOid: selectedUser.monitoringStationOid
      };
   }

   //#region pagination and searching
   searchUsers = () => {
      let newFilterOptions: IUserFilter = { ...this.filterOptions };
      this.setState({ ...this.state, users: [] }, () => {
         UserService.searchUsers(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  users: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.users.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IUserFilter = { ...this.filterOptions };
      UserService.searchUsers(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let userList = [...this.state.users];
            userList = userList.concat(res?.data);
            this.setState({
               ...this.state,
               users: userList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchUsers();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchUsers();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchUsers();
      // }
   }
   //#endregion

   //#region Add/Edit/Delete User

   onToggleAddEditUser = () => {
      this.setState({ ...this.state, openAddEditUserModal: !this.state.openAddEditUserModal, selectedUser: null });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditUserModal: false }, () => this.searchUsers());
   }

   onSelectUser = (selectedUser: any, isForDelete: boolean = false) => {
      if (isForDelete) {
         if (selectedUser.personOid) {
            this.setState({ ...this.state, selectedUser: this.mapUserObject(selectedUser) });
         }
         else
            this.setState({ ...this.state, selectedUser: this.mapUserObject(selectedUser), showConfirmDialog: true });
      }
      else
         this.setState({ ...this.state, selectedUser: this.mapUserObject(selectedUser), openAddEditUserModal: true });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedUser?.Oid };
      UserService.deleteUser(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedUser: null, showConfirmDialog: false }, () => this.searchUsers());
         } else if (res?.status === StatusCode.BadRequest) {
            this.props.snackbarShowMessage(res?.data?.message, "error");
            this.setState({ ...this.state, selectedUser: this.mapUserObject(res?.data), showConfirmDialog: false });
         }

      });
   }

   onCancelDelete = () => {
      this.setState({ ...this.state, selectedUser: null, showConfirmDialog: false });
   }

   //#endregion
   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedUser: null });
   }

   openUserAuditLogModal = (selectedUser: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedUser: this.mapUserObject(selectedUser) });
   }
   //#endregion

   //#Send Reset Mail
   sendPasswordResetMail = (selectedUser: any) => {
      const params: IPasswordResetMail = {
         UserOid: selectedUser?.oid
      }
      UserService.sendPasswordResetLink(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.props.snackbarShowMessage(res?.data?.message);
         } else if (res?.status === StatusCode.BadRequest) {
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }

      });
   }
   serchSuggestions = (searchText: string, signal: any) => {
      const { MonitoringStationOid } = this.filterOptions;
      return UserService.getSearchSuggestions(searchText, MonitoringStationOid, signal);
   }
   //#endregion
   render() {
      const { totalRecords, openAddEditUserModal, selectedUser, showConfirmDialog, users, openAuditLogs, monitoringStationName } = this.state;
      const { actionPermission } = this.props;
      const { MonitoringStationOid } = this.filterOptions;
      return (
         <PageLayout pageTitle={`Users for ${monitoringStationName}`} onCreate={this.onToggleAddEditUser} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["User:SearchSuggestions"] ? this.serchSuggestions : null}
            auditLogs={this.toggleAuditLogModal} actionPermission={{
               add: actionPermission["User:POST"],
               auditlog: actionPermission["Audit:User"],
            }}
         >
            {/* add edit user modal */}
            {openAddEditUserModal &&
               <AddEditUser
                  open={openAddEditUserModal}
                  close={this.onToggleAddEditUser}
                  successCallback={this.onSave}
                  user={selectedUser}
                  monitoringStationOid={MonitoringStationOid}
                  monitoringStationName={monitoringStationName} />
            }
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getUserTableHeaders()} pagingInfoText="Users" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {users && users?.map((user: any, index: number) => {
                     return (
                        <UserRow
                           key={`row_${index}`}
                           user={user}
                           onEdit={this.onSelectUser}
                           onDelete={this.onSelectUser}
                           onShowAuditLog={this.openUserAuditLogModal}
                           onSendResetMail={this.sendPasswordResetMail}
                           actionPermission={actionPermission}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={users ? users.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {users && users?.map((user: any, index: number) => {
                        return (
                           <UserCard
                              key={`card_${index}`}
                              user={user}
                              onEdit={this.onSelectUser}
                              onDelete={this.onSelectUser}
                              onShowAuditLog={this.openUserAuditLogModal}
                              onSendResetMail={this.sendPasswordResetMail}
                              actionPermission={actionPermission}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete user */}
            {showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete} />
            }
            {
               openAuditLogs && selectedUser === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.User} />
            }

            {
               openAuditLogs && selectedUser !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.User}
                  oid={selectedUser?.Oid ?? ""}
                  changedEntityTitle={`${selectedUser?.Firstname} ${selectedUser?.Lastname}`} />
            }
         </PageLayout>
      )
   }
}

export default withSnackbar(withParams(Users));
