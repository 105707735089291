import { Controllers } from "../constants/constant";
import request from "../utils/request";
import { IHazardLocation, IDeleteParams } from "../types/dataTypes";

class HazardLocationService {
   searchHazardLocations = (params: any) => {
      return request({
         url: `${Controllers.HazardLocation}/Search`,
         method: 'get',
         params
      });
   }
   saveHazardLocation = (data: IHazardLocation) => {
      const body: any = { ...data };
      const geometries = body.HazardLocationGeometry.map((element: any) => {
         return { ...element, CoOrdinates: JSON.stringify(element.CoOrdinates) };
      });
      body.HazardLocationGeometry = [...geometries];
      return request({
         url: `${Controllers.HazardLocation}`,
         method: 'post',
         data: body,
      })
   }
   deleteHazardLocation = (params: IDeleteParams) => {
      return request({
         url: `${Controllers.HazardLocation}`,
         method: 'delete',
         params
      })
   }
   getSearchSuggestions = (serachText: string, signal: any) => {
      return request({
         url: `${Controllers.HazardLocation}/SearchSuggestions/${serachText}`,
         method: 'get',
         signal
      })
   }
   getHazardLocation = (params: any) => {
      return request({
         url: `${Controllers.HazardLocation}`,
         method: 'get',
         params
      })
   }

   import(data: FormData) {
      return new Promise(function (resolve, reject) {
         request({
            url: `${Controllers.HazardLocation}/Import`,
            method: 'post',
            data,
            headers:
            {
               'Content-Type': 'multipart/form-data'
            }
         }).then(res => {
            return resolve(res);
         }).catch(err => {
            return reject(err);
         });
      })
   }

   getHazardLocationNearestArea = (data: any) => {
      return request({
         url: `${Controllers.HazardLocation}/GetHazardLocationNearestArea`,
         method: 'post',
         data
      })
   }

   getHazardTypeSuggestion = (params: any, signal: any) => {
      return request({
         url: `${Controllers.HazardLocation}/GetHazardTypeSuggestion`,
         method: 'get',
         params,
         signal
      })
   }

   getHazardLocationsDetails = (params: any) => {
      return request({
         url: `${Controllers.HazardLocation}/GetHazardLocationsDetails`,
         method: 'get',
         params
      });
   }
}

export default new HazardLocationService();
