import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormHelperText, TextField, FormControl, InputAdornment } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { Common } from "../../constants/common";
import { MobileDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CalendarMonth } from "@mui/icons-material";

function CustomDateTimePicker({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      customErrorMessage,
      InputProps,
      autoFocus,
      inputFormat,
      openTo,
      disableFuture,
      shouldDisableTime,
      disabled
   } = props;

   const [field, meta] = useField(inputProps);
   const newInputProps = {
      ...InputProps,
      endAdornment: (
         <InputAdornment position="start">
            <CalendarMonth className={classes.inputAdornmentIcon} />
         </InputAdornment>
      )
   }

   return (
      <FormControl {...formControlProps} fullWidth className={formControlProps?.className + " " + classes.formControl}>
         <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDateTimePicker
               inputFormat={inputFormat}
               openTo={openTo}
               ampm={false}
               id={id}
               {...field}
               {...inputProps}
               InputProps={newInputProps}
               label={labelText}
               disableFuture={disableFuture}
               shouldDisableTime={shouldDisableTime}
               renderInput={(params: any) =>
                  <TextField
                     {...params}
                     variant="standard"
                     autoComplete={"off"}
                     error={(meta.touched && meta.error && meta.error.length > 0) || !Common.stringNullOrEmpty(customErrorMessage)}
                     autoFocus={autoFocus ? true : false}
                  />
               }
               disabled={disabled}
            />
         </LocalizationProvider>
         {meta.touched && meta.error && meta.error.length > 0 ?
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
            :
            !Common.stringNullOrEmpty(customErrorMessage) ?
               <FormHelperText id={`error-${inputProps.name}`} error>{customErrorMessage}</FormHelperText>
               : null}
      </FormControl>
   );
}

CustomDateTimePicker.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   rows: PropTypes.number,
   type: PropTypes.string,
   defaultValue: PropTypes.string,
};
export default withStyles(CustomDateTimePicker, customInputStyle);
