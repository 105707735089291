import React from "react";
import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";
import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import ClientRow from "../../components/pages/Clients/ClientRow";
import ClientCard from "../../components/pages/Clients/ClientCard";
import AddEditClient from "../../components/pages/Clients/AddEditClient";
import ClientImagesModal from "../../components/pages/Clients/ClientImagesModal";
import AssignEscalation from "../../components/pages/Common/AssignEscalation";
import ClientAdvanceFilter from "../../components/pages/Clients/ClientAdvanceFilter";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";
import SetClientResponseInstruction from "../../components/pages/Common/ResponseInstruction/SetClientResponseInstruction";
import { IClientPageState } from "../../types/stateType";
import { IClientPageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IClient, IClientFilter, IDeleteParams, IFilterOption } from "../../types/dataTypes";
import { Common } from "../../constants/common";
import { TableHeader } from "../../constants/tableHeader";
import withParams from "../../components/Common/withParams";
import { mapClientObject } from "../../utils/commonMaps";
import ClientService from "../../services/client.service";
import FindAndReplaceEscalationContactDialogue from "../../components/pages/Clients/FindAndReplaceEscalationContactDialogue";
class Clients extends React.Component<IClientPageProps, IClientPageState> {
   // params = useParams();
   filterOptions: IClientFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      Country: "All",
      Plan: Common.EmptyGuid,
      sortString: "Name"
   }

   state: IClientPageState = {
      clients: [],
      openAddEditClientModal: false,
      selectedClient: null,
      showConfirmDialog: false,
      openAssignEscalationModal: false,
      openSetResponseInstructionModal: false,
      totalRecords: 0,
      openAuditLogs: false,
      personCount: null,
      deviceCount: null,
      openImageModal: false,
      openFindAndReplaceModal: false
   };

   componentDidMount() {
      if (!this.props.isFromSearch) {
         this.searchClients();
      }
   }
   //#region searching and pagination

   searchClients = () => {
      let newFilterOptions: IFilterOption = { ...this.filterOptions };
      this.setState({ ...this.state, clients: [] }, () => {
         ClientService.searchClients(newFilterOptions, this.filterOptions.Country).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  clients: res?.data,
                  totalRecords: res?.data[0]?.totalRecord
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.clients.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: IFilterOption = { ...this.filterOptions };
      ClientService.searchClients(newFilterOptions, this.filterOptions.Country).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let clientList = [...this.state.clients];
            clientList = clientList.concat(res?.data);
            this.setState({
               ...this.state,
               clients: clientList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchClients();
   }

   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchClients();
   }

   onSearch = (val: string) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchClients();
   }

   onAdvanceSearch = (advanceFilter: any) => {
      this.filterOptions.Country = advanceFilter.Country;
      this.filterOptions.Plan = advanceFilter.Plan;
      this.filterOptions.page = 1;
      this.searchClients();
   }

   //#endregion

   onClickPersonCount = (clientOid: any) => {
      const link: string = `${window.location.origin}/persons?ClientOid=` + clientOid;
      window.open(link, '_blank');
   }

   onClickDeviceCount = (clientOid: any) => {
      const link: string = `${window.location.origin}/devices?ClientOid=` + clientOid;
      window.open(link, '_blank');
   }

   //#region Add/Edit/Delete Client
   onToggleAddEditClient = () => {
      this.setState({ ...this.state, openAddEditClientModal: !this.state.openAddEditClientModal, selectedClient: null });
   }

   onSelectClient = (selectedClient: any, isForDelete: boolean = false) => {
      const client: IClient = mapClientObject(selectedClient);
      if (isForDelete)
         ClientService.getPersonDeviceCountForClient(client.Oid ?? "").then((res: any) => {
            if (res?.status === StatusCode.Success) {
               this.setState({ ...this.state, selectedClient: client, showConfirmDialog: true, personCount: res?.data?.personCount, deviceCount: res?.data?.deviceCount });
            }
         })
      else
         this.setState({ ...this.state, selectedClient: client, openAddEditClientModal: true });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditClientModal: false, selectedClient: null }, () => {
         if (this.props.isFromDependenciesDialogue) {
            this.props.onChangeDependencies();
         }
         else
            this.searchClients();
      });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedClient?.Oid };
      ClientService.deleteClient(params).then((res: any) => {
         this.setState({ ...this.state, selectedClient: null, showConfirmDialog: false, personCount: null }, () => this.searchClients());
      });
   }

   onCancelDelete = () => {
      this.setState({ ...this.state, selectedClient: null, showConfirmDialog: false });
   }

   //#endregion

   //#region Assign default escalation
   openAssignEscalation = (selectedClient: any) => {
      const client: IClient = mapClientObject(selectedClient);
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedClient: client
      });
   }

   onSaveEscalation = () => {
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedClient: null
      }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchClients();
      });
   }

   onCloseAssignEscalationModal = () => {
      this.setState({
         ...this.state,
         openAssignEscalationModal: !this.state.openAssignEscalationModal,
         selectedClient: null
      });
   }

   //#endregion
   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedClient: null });
   }

   openClientAuditLogModal = (selectedClient: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedClient: mapClientObject(selectedClient) });
   }
   //#endregion

   //#endregion
   //#region Download
   onDownload = (exportType: string) => {
      let newFilterOptions: IClientFilter = { ...this.filterOptions };
      newFilterOptions.exportType = exportType;
      ClientService.export(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "Clients";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: exportType === "EXCEL" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' })
            );
            link.click();
         }
      });
   }
   //#endregion

   //#region
   onDownloadEscalation = (selectedClient: any) => {
      ClientService.responseInstruction(selectedClient.oid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "ClientResponseInstruction_" + selectedClient.name + ".txt";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: 'text/plain' })
            );
            link.click();
         }
      });
   }

   onDownloadEscalationContacts = (selectedClient: any) => {
      ClientService.responseEscalationContacts(selectedClient.oid).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = "ClientEscalationContacts_" + selectedClient.name + ".txt";
            link.href = URL.createObjectURL(
               new Blob([res.data], { type: 'text/plain' })
            );
            link.click();
         }
      });
   }
   //#endregion

   //#region set response instruction
   openResponseInstructionModel = (selectedClient: any) => {
      const client: IClient = mapClientObject(selectedClient);
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedClient: client
      });
   }

   onSaveResponseInstruction = () => {
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedClient: null
      }, () => {
         if (this.props.isFromDependenciesDialogue)
            this.props.onChangeDependencies();
         else
            this.searchClients();
      });
   }

   onCloseResponseInstructionModal = () => {
      this.setState({
         ...this.state,
         openSetResponseInstructionModal: !this.state.openSetResponseInstructionModal,
         selectedClient: null
      });
   }

   //#endregion
   //#region image modal
   openImageModal = (selectedClient: any) => {
      const client: IClient = mapClientObject(selectedClient);
      this.setState({
         ...this.state,
         openImageModal: true,
         selectedClient: client
      });
   }

   onCloseImageModal = () => {
      this.setState({
         ...this.state,
         openImageModal: false,
         selectedClient: null
      });
   }

   openFindAndReplaceModal = (selectedClient: any) => {
      const client: IClient = mapClientObject(selectedClient);
      this.setState({
         ...this.state,
         openFindAndReplaceModal: true,
         selectedClient: client
      });
   }

   onCloseFindAndReplaceModal = () => {
      this.setState({
         ...this.state,
         openFindAndReplaceModal: false,
         selectedClient: null
      });
   }
   //#endregion

   render() {
      const { totalRecords, openAssignEscalationModal, openAddEditClientModal, selectedClient,
         showConfirmDialog, openAuditLogs, openSetResponseInstructionModal, personCount, openImageModal, openFindAndReplaceModal, deviceCount } = this.state;
      const { isFromSearch, isFromDependenciesDialogue, showOnlyEdit, showOnlyEscalation, actionPermission } = this.props;
      const { clients } = isFromSearch ? this.props : this.state;
      const { searchText } = this.props.params;
      if (searchText && searchText.trim().length > 0) {
         this.filterOptions.SearchText = searchText;
         this.props.params.searchText = null;
         window.history.pushState(null, "", window.location.href.replace("%20", " ").replace(searchText, ""));
      }
      return (
         <PageLayout pageTitle="Clients"
            onCreate={this.onToggleAddEditClient}
            searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch}
            onSearchTextChange={actionPermission["Client:SearchSuggestions"] ? ClientService.getSearchSuggestions : null}
            advanceFilter={<ClientAdvanceFilter initialValues={{ Country: this.filterOptions.Country, Plan: this.filterOptions.Plan }} onAdvanceSearch={this.onAdvanceSearch} />}
            auditLogs={this.toggleAuditLogModal}
            onDownload={this.onDownload}
            isFromSerach={isFromSearch} isFromDependenciesDialogue={isFromDependenciesDialogue}
            actionPermission={{
               add: actionPermission["Client:POST"],
               auditlog: actionPermission["Audit:Client"],
               download: actionPermission["Client:Export"],
            }}>

            {/* add edit client modal */}
            {openAddEditClientModal &&
               <AddEditClient
                  open={openAddEditClientModal}
                  close={this.onToggleAddEditClient}
                  successCallback={this.onSave}
                  clientOid={selectedClient?.Oid} />
            }

            {/* assign default escalation to client */}
            {openAssignEscalationModal &&
               <AssignEscalation
                  open={openAssignEscalationModal}
                  close={this.onCloseAssignEscalationModal}
                  successCallback={this.onSaveEscalation}
                  escalationOid={selectedClient?.EscalationOid}
                  oid={selectedClient?.Oid}
                  escalationOn={Controllers.Client}
                  selectedEntityName={selectedClient?.Name}
                  clientOid={selectedClient?.Oid}
                  allowSubmit={actionPermission["Client:AssignDefaultEscalation"] === true}
                  allowApplyAll={false}
                  clientName={selectedClient?.Name} />
            }
            {/* set response instruction to device */}
            {openSetResponseInstructionModal &&
               <SetClientResponseInstruction
                  open={openSetResponseInstructionModal}
                  close={this.onCloseResponseInstructionModal}
                  successCallback={this.onSaveResponseInstruction}
                  responseInstructionOid={selectedClient?.ResponseInstructionOid}
                  oid={selectedClient?.Oid}
                  responseInstructionFor={Controllers.Client}
                  selectedEntityName={selectedClient?.Name}
                  allowSubmit={actionPermission["Client:SetResponseInstruction"] === true}
                  clientName={selectedClient?.Name}
                  snackbarShowMessage={() => { }}
               />
            }
            {
               openAuditLogs && selectedClient === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Client} />
            }

            {
               openAuditLogs && selectedClient !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Client}
                  oid={selectedClient?.Oid ?? ""}
                  changedEntityTitle={selectedClient?.Name} />
            }

            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getClientTableHeaders()} pagingInfoText="Clients" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords ? totalRecords : clients?.length} onPageChange={isFromSearch ? null : this.onPageChange} filerOptions={this.filterOptions}>
                  {clients && clients?.map((client: any, index: number) => {
                     return (
                        <ClientRow
                           key={`row_${index}`}
                           client={client}
                           onEdit={this.onSelectClient}
                           onDelete={this.onSelectClient}
                           onAssignEscalation={this.openAssignEscalation}
                           onShowAuditLog={this.openClientAuditLogModal}
                           onDownloadEscalation={this.onDownloadEscalation}
                           onDownloadEscalationContacts={this.onDownloadEscalationContacts}
                           showOnlyEdit={showOnlyEdit}
                           showOnlyEscalation={showOnlyEscalation}
                           actionPermission={actionPermission}
                           onSetResponseInstruction={this.openResponseInstructionModel}
                           openImageModal={this.openImageModal}
                           openFindAndReplaceModal={this.openFindAndReplaceModal}
                           onClickPersonCount={this.onClickPersonCount}
                           onClickDeviceCount={this.onClickDeviceCount}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={clients ? clients.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {clients && clients?.map((client: any, index: number) => {
                        return (
                           <ClientCard
                              key={`card_${index}`}
                              client={client}
                              onEdit={this.onSelectClient}
                              onDelete={this.onSelectClient}
                              onAssignEscalation={this.openAssignEscalation}
                              onShowAuditLog={this.openClientAuditLogModal}
                              onDownloadEscalation={this.onDownloadEscalation}
                              onDownloadEscalationContacts={this.onDownloadEscalationContacts}
                              showOnlyEdit={showOnlyEdit}
                              showOnlyEscalation={showOnlyEscalation}
                              actionPermission={actionPermission}
                              onSetResponseInstruction={this.openResponseInstructionModel}
                              openImageModal={this.openImageModal}
                              openFindAndReplaceModal={this.openFindAndReplaceModal}
                              onClickPersonCount={this.onClickPersonCount}
                              onClickDeviceCount={this.onClickDeviceCount}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete client */}
            {showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete}
                  dialogueText={`${personCount && personCount > 0 ? `${personCount} person,` : ""} ${deviceCount && deviceCount > 0 ? `${deviceCount} device,` : ""} trip locations, trips, hazardeoud locations associated with this client will be deleted.`} />
            }

            {/* add edit image modal */}
            {openImageModal && selectedClient?.Oid &&
               <ClientImagesModal
                  open={openImageModal}
                  close={this.onCloseImageModal}
                  successCallback={this.onCloseImageModal}
                  clientOid={selectedClient?.Oid}
                  clientName={selectedClient?.Name}
                  allowSubmit={actionPermission["ClientImage:SaveImage"] === true}
                  allowDelete={actionPermission["ClientImage:DeleteImage"] === true} />
            }
            {/* add edit image modal */}
            {openFindAndReplaceModal && selectedClient?.Oid &&
               <FindAndReplaceEscalationContactDialogue
                  open={openFindAndReplaceModal}
                  close={this.onCloseFindAndReplaceModal}
                  successCallback={this.onCloseFindAndReplaceModal}
                  clientOid={selectedClient?.Oid}
                  clientName={selectedClient?.Name}
               />
            }
         </PageLayout>
      )
   }
}
export default withParams(Clients);
