import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Chip, Box, Menu, MenuItem } from '@mui/material';
import { ISetResponseInstructionProps } from '../../../../types/propType';
import Button from '../../../Common/Button';

import { IResponseInstruction } from '../../../../types/dataTypes';
import { StatusCode } from '../../../../constants/constant';
import { Common } from '../../../../constants/common';

import ResponseInstructionService from '../../../../services/responseinstruction.service';
import DeviceService from '../../../../services/device.service';
import { TextBlockContainer } from './TextBlockContainer';
import { DeletedBlockContainer } from './DeletedBlockContainer';
import DeviceTypeSelection from "./DeviceTypeSelection";
import InstructionForSelection from "./InstructionForSelection";
import { TextBlocks } from "./DeviceResponseInstructionList";
import { Warning } from '@mui/icons-material';
import GetDeviceTypeFromModelAndType from './GetDeviceTypeFromModelAndType';
import EscalationDetailResponseInstruction from '../../../../Pages/EscalationDetail/EscalationDetailResponseInstruction';
import DeviceResponseInsturctionApplyAllDialogue from './DeviceResponseInsturctionApplyAllDialogue';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { withSnackbar } from '../../../Common/Snackbar';
const SetDeviceResponseInstruction: React.FC<ISetResponseInstructionProps> = ({ open, close, successCallback, responseInstructionOid, oid, responseInstructionFor, selectedEntityName, allowSubmit, actualDeviceType, actualDeviceModel, clientName, snackbarShowMessage }) => {
   const initialResponseInstruction: IResponseInstruction = {
      Oid: Common.EmptyGuid,
      Instruction: "",
      InstructionFor: responseInstructionFor
   };
   const [defaultResponseInstruction, setDefaultResponseInstruction] = useState<IResponseInstruction>(initialResponseInstruction);
   const [deviceType, setDeviceType] = useState<string>("");
   const [instructionFor, setInstructionFor] = useState<string>("");
   const [savedInstructionFor, setSavedInstructionFor] = useState<string[]>([]);
   const [editedInstructionFor, setEditedInstructionFor] = useState<string[]>([]);
   const [instructionTypeTextBlocks, setInstructionTypeTextBlocks] = useState<any[]>([]);
   const [deletedTextBlocks, setDeletedTextBlocks] = useState<any[]>([]);
   const [finalTextBlocks, setFinalTextBlocks] = useState<any[]>([]);
   const [isPreview, setIsPreview] = useState(!allowSubmit);
   const [editedTextBlocks, setEditedTextBlocks] = useState<any[]>([]);
   const [deviceTypeSelectionList, setDeviceTypeSelectionList] = useState<string[]>([]);
   const [showConfirmDialog, setShowConfirmDialog] = useState(false);
   const [applyDialogueType, setApplyDialogueType] = useState<"all" | "specific">("all");
   useEffect(() => {
      const deviceTypeSelectionOption = GetDeviceTypeFromModelAndType(actualDeviceType, actualDeviceModel);
      setDeviceTypeSelectionList(deviceTypeSelectionOption);
      if (!Common.stringNullOrEmpty(responseInstructionOid) && responseInstructionOid !== Common.EmptyGuid) {
         ResponseInstructionService.getResponseInstructionByOid(responseInstructionOid?.toString()).then((res: any) => {
            const response = res.data;
            if (res?.status === StatusCode.Success && response) {
               defaultResponseInstruction.Oid = response.oid;
               defaultResponseInstruction.InstructionFor = response.instructionFor;
               setDefaultResponseInstruction(defaultResponseInstruction);
               try {
                  const savedBlocks = JSON.parse(response.instruction);
                  setFinalTextBlocks([...savedBlocks]);
                  const editedBlocks = JSON.parse(response.instruction);
                  setEditedTextBlocks([...editedBlocks]);
                  if (savedBlocks.length > 0) {
                     const firstBlock = savedBlocks[0];
                     setDeviceType(firstBlock.DeviceType);
                     setInstructionFor(firstBlock.InstructionTypes[0]?.InstructionFor ?? "");
                  }
               } catch (ex) {
               }
            }
         });
      }
      else if (deviceTypeSelectionOption.length === 1) {
         changeDeviceType(deviceTypeSelectionOption[0]);
      }
   }, [responseInstructionOid, responseInstructionFor, oid]);  // eslint-disable-line react-hooks/exhaustive-deps
   const changeDeviceType = (deviceType: string) => {
      setDeviceType(deviceType);
      setFinalTextBlocks([...finalTextBlocks.filter((x) => x.DeviceType === deviceType)]);
      setInstructionFor("");
   }
   const changeInstructionFor = (instructionFor: string) => {
      setInstructionFor(instructionFor);
   }
   useEffect(() => {
      let NewInstructionTypeTextBlocks = getTextBlockForDeviceTypeAndInstructionFor(editedTextBlocks);
      if (NewInstructionTypeTextBlocks.length <= 0)
         NewInstructionTypeTextBlocks = getTextBlockForDeviceTypeAndInstructionFor(TextBlocks);
      textBlockChange([...NewInstructionTypeTextBlocks]);
      setSavedEditedInstructionFor();
   }, [deviceType, instructionFor])  // eslint-disable-line react-hooks/exhaustive-deps
   const getTextBlockForDeviceTypeAndInstructionFor = (mainObject: any[]) => {
      const InstructionFor = mainObject.filter((x: any) => x.DeviceType.toLowerCase() === deviceType.toLowerCase()).map((items: any) => items.InstructionTypes);
      const InstructionTypes = InstructionFor && InstructionFor.length > 0 ? InstructionFor[0].filter((x: any) => x.InstructionFor === instructionFor) : [];
      return InstructionTypes && InstructionTypes.length > 0 ? InstructionTypes[0].TextBlocks : [];
   }
   const saveTextBlock = (showPreview: boolean = false) => {
      let error: string[] = [];
      instructionTypeTextBlocks.forEach((textBlock: any, index: number) => {
         if (textBlock.Type === "Placeholder") {
            const placeholderValues = textBlock.Values;
            Object.keys(placeholderValues).forEach((key) => {
               if (Common.stringNullOrEmpty(placeholderValues[key])) {
                  error.push(`${key.replace(/([A-Z])/g, ' $1').trim()} is required for block number ${index + 1}.`);
               }
            });
         }
      });
      if (error.length > 0) {
         snackbarShowMessage(error.join("\r\n"), "error");
         return;
      }
      const deviceTypeObjects = finalTextBlocks.filter(x => x.DeviceType === deviceType);
      if (deviceTypeObjects.length > 0) {
         const deviceTypeObject = deviceTypeObjects[0];
         const InstructionForObjects = deviceTypeObject.InstructionTypes.filter((x: any) => x.InstructionFor === instructionFor);
         if (InstructionForObjects.length > 0) {
            const InstructionForObject = InstructionForObjects[0];
            InstructionForObject.TextBlocks = instructionTypeTextBlocks;
         }
         else {
            deviceTypeObject.InstructionTypes.push({
               InstructionFor: instructionFor,
               TextBlocks: instructionTypeTextBlocks
            })
         }
      }
      else {
         finalTextBlocks.push({
            DeviceType: deviceType,
            InstructionTypes: [
               {
                  InstructionFor: instructionFor,
                  TextBlocks: instructionTypeTextBlocks
               }
            ]
         });
      }
      setFinalTextBlocks(JSON.parse(JSON.stringify(finalTextBlocks)));
      if (showPreview) {
         setIsPreview(true);
      }
   }
   useEffect(() => {
      setSavedEditedInstructionFor();
   }, [finalTextBlocks])  // eslint-disable-line react-hooks/exhaustive-deps
   const removeTextBlock = (textBlock: any) => {
      const currentBlocks = [...instructionTypeTextBlocks];
      const blockIndex = currentBlocks.indexOf(textBlock);
      currentBlocks.splice(blockIndex, 1);
      textBlockChange(currentBlocks);
   }
   const restoreFromTrash = (textBlock: any) => {
      const insertIndex = instructionTypeTextBlocks.findIndex((x) => x.IsFixed === true);
      instructionTypeTextBlocks.splice(insertIndex < 0 ? instructionTypeTextBlocks.length : insertIndex, 0, textBlock);
      textBlockChange([...instructionTypeTextBlocks]);
   }
   const addNewBlock = (textBlock: any) => {
      const insertIndex = instructionTypeTextBlocks.findIndex((x) => x.IsFixed === true);
      instructionTypeTextBlocks.splice(insertIndex < 0 ? instructionTypeTextBlocks.length : insertIndex, 0, textBlock);
      textBlockChange([...instructionTypeTextBlocks]);
   }
   const reset = () => {
      const initialInstructionTypeTextBlocks = getTextBlockForDeviceTypeAndInstructionFor(TextBlocks);
      textBlockChange([...initialInstructionTypeTextBlocks]);
   }

   const textBlockChange = (textBlocks: any[]) => {
      setInstructionTypeTextBlocks(textBlocks);
   }
   const addNewContactBlock = (typeOfContact: any) => {
      switch (typeOfContact) {
         case "Security Company":
            addNewBlock({
               Type: "Placeholder",
               Text: `Security Company
                        Name: {{Name}}
                        Contact Details: {{ContactDetails}}`,
               Values: {
                  Name: "",
                  ContactDetails: ""
               }
            });
            break;
         case "Site Access Information":
            addNewBlock({
               Type: "Placeholder",
               Text: `Site Access Information
                      {{Description}}`,
               Values: {
                  Description: ""
               }
            });
            break;
         case "Vehicle Break Down":
            addNewBlock({
               Type: "Placeholder",
               Text: `Vehicle Break Down
                      Name: {{Name}}
                      Contact Details: {{ContactDetails}}`,
               Values: {
                  Name: "",
                  ContactDetails: ""
               }
            });
            break;
         default:
            break;
      }
   }
   useEffect(() => {
      if (deviceType !== "" && instructionFor !== "") {
         const deviceTypeObjects = editedTextBlocks.filter(x => x.DeviceType.toLowerCase() === deviceType.toLowerCase());
         if (deviceTypeObjects.length > 0) {
            const deviceTypeObject = deviceTypeObjects[0];
            const InstructionForObjects = deviceTypeObject.InstructionTypes.filter((x: any) => x.InstructionFor === instructionFor);
            if (InstructionForObjects.length > 0) {
               const InstructionForObject = InstructionForObjects[0];
               InstructionForObject.TextBlocks = instructionTypeTextBlocks;
            }
            else {
               deviceTypeObject.InstructionTypes.push({
                  InstructionFor: instructionFor,
                  TextBlocks: instructionTypeTextBlocks
               })
            }
         }
         else {
            editedTextBlocks.push({
               DeviceType: deviceType,
               InstructionTypes: [
                  {
                     InstructionFor: instructionFor,
                     TextBlocks: instructionTypeTextBlocks
                  }
               ]
            });
         }
         setEditedTextBlocks([...editedTextBlocks]);
      }
      if (deviceType !== "" && instructionFor !== "") {
         const editedInstructionTypeTextBlocks = getTextBlockForDeviceTypeAndInstructionFor(editedTextBlocks);
         const initialsInstructionTypeTextBlocks = getTextBlockForDeviceTypeAndInstructionFor(TextBlocks);
         const deletedBlocks = initialsInstructionTypeTextBlocks.filter((o1: any) => {
            return !editedInstructionTypeTextBlocks.some((o2: any) => {
               return o1.Text === o2.Text;
            });
         });
         setDeletedTextBlocks(deletedBlocks);
      }
      setSavedEditedInstructionFor();
   }, [instructionTypeTextBlocks])  // eslint-disable-line react-hooks/exhaustive-deps
   const setSavedEditedInstructionFor = () => {
      if (deviceType !== "") {
         const savedBlocks = finalTextBlocks.filter((x: any) => x.DeviceType.toLowerCase() === deviceType.toLowerCase()).map((items: any) => items.InstructionTypes);
         const editedBlocks = editedTextBlocks.filter((x: any) => x.DeviceType.toLowerCase() === deviceType.toLowerCase()).map((items: any) => items.InstructionTypes);
         const savedInstruction: string[] = [];
         const editedInstruction: string[] = [];
         if (savedBlocks.length > 0) {
            savedBlocks[0].forEach((instructionFor: any) => {
               savedInstruction.push(instructionFor.InstructionFor);
            });
            if (editedBlocks.length > 0) {
               editedBlocks[0].forEach((instructionFor: any) => {
                  if (savedInstruction.indexOf(instructionFor.InstructionFor) > -1) {
                     const savedBlocksInstructionFor = savedBlocks[0].filter((x: any) => x.InstructionFor === instructionFor.InstructionFor);
                     if (savedBlocksInstructionFor.length > 0) {
                        if (JSON.stringify(savedBlocksInstructionFor[0]) !== JSON.stringify(instructionFor)) {
                           editedInstruction.push(instructionFor.InstructionFor);
                        }
                     }
                  }
               });
            }
         }
         setSavedInstructionFor(savedInstruction);
         setEditedInstructionFor(editedInstruction);
      }
   }
   const onSubmit = () => {
      const values: IResponseInstruction = defaultResponseInstruction;
      values.Instruction = JSON.stringify(finalTextBlocks.filter((x) => x.DeviceType.toLowerCase() === deviceType.toLowerCase()));
      if (!Common.stringNullOrEmpty(oid) && oid !== Common.EmptyGuid) {
         DeviceService.setResponseInstruction(values, oid).then(() => {
            successCallback();
         });
      }
   }
   const onSubmitApplyAll = (deviceOid: string, deviceOidList: string[]) => {
      const values: IResponseInstruction = defaultResponseInstruction;
      values.DeviceOidList = deviceOidList;
      values.Instruction = JSON.stringify(finalTextBlocks.filter((x) => x.DeviceType.toLowerCase() === deviceType.toLowerCase()));
      DeviceService.setResponseInstructionApplyAll(values, deviceOid, actualDeviceType, actualDeviceModel).then(() => {
         successCallback();
      });
   }
   const isGasStaff = !Common.IsArrayContainRoleWithClient();
   const isClientAdmin = Common.IsInRole("Client Admin");
   const isInstructionChanged = (editedInstructionFor && editedInstructionFor.length > 0);
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle id="scroll-dialog-title">Response Instruction for Device : {selectedEntityName}
         </DialogTitle>
         <DialogContent dividers>
            {!isPreview &&
               <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                     <Box mb={1}>
                        <strong>Device Type:</strong> {actualDeviceType}
                     </Box>
                     <Box mb={1}>
                        <strong>Device Model:</strong> {actualDeviceModel}
                     </Box>
                     <DeviceTypeSelection deviceType={deviceType} setDeviceType={changeDeviceType} deviceTypeSelectionList={deviceTypeSelectionList} />
                     <InstructionForSelection deviceType={deviceType} instructionFor={instructionFor} setInstructionFor={changeInstructionFor} savedInstructionFor={savedInstructionFor} editedInstructionFor={editedInstructionFor} />
                  </Grid>
                  <Grid item xs={12} md={8}>
                     {!Common.stringNullOrEmpty(deviceType) && !Common.stringNullOrEmpty(instructionFor) &&
                        <Grid container spacing={2}>
                           <Grid item xs={12} md={8}><h5 style={{ marginTop: "10px", marginBottom: "10px" }}>{instructionFor} {deviceType}</h5></Grid>
                           <Grid item xs={12} md={4}>
                              {isInstructionChanged &&
                                 <Chip style={{ float: "right", marginBottom: "5px" }} color="primary" icon={<Warning />} label="Some blocks are unsaved." />
                              }
                           </Grid>
                           <Grid item xs={12} md={12}>
                              <Button style={{ float: "right" }} color="success" size="sm" type="none" onClick={() => saveTextBlock(false)}>Save Instruction</Button>
                              {(isGasStaff) &&
                                 <Button style={{ float: "right", marginRight: "2em" }} size="sm" color="primary" type="none" onClick={() => addNewBlock({ Type: "FreeText", Text: "" })}>Add New Block</Button>
                              }
                              {(isGasStaff || (isClientAdmin)) &&
                                 <>

                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                       {(popupState) => (
                                          <>
                                             <Button style={{ float: "right", marginRight: "2em" }} size="sm" color="info" type="none" {...bindTrigger(popupState)}>Add Contact Block</Button>
                                             <Menu {...bindMenu(popupState)}>
                                                {["Security Company", "Site Access Information", "Vehicle Break Down"].map((type: string) =>
                                                   <MenuItem key={type} onClick={() => { popupState.close(); addNewContactBlock(type) }}>
                                                      {type}
                                                   </MenuItem>
                                                )}
                                             </Menu>
                                          </>
                                       )}
                                    </PopupState>
                                 </>
                              }
                              <Button style={{ float: "right", marginRight: "2em" }} size="sm" color="danger" type="none" onClick={() => reset()}>Reset</Button>
                           </Grid>
                        </Grid>
                     }
                     <DndProvider backend={HTML5Backend}>
                        <TextBlockContainer readOnly={!isGasStaff && instructionFor === "SOS"} instructionTypeTextBlocks={[...instructionTypeTextBlocks]} setInstructionTypeTextBlocks={textBlockChange} removeTextBlock={removeTextBlock} setDeletedTextBlocks={setDeletedTextBlocks} clientOid="" />
                     </DndProvider>
                     {deviceType !== "" && instructionFor !== "" && deletedTextBlocks && deletedTextBlocks.length > 0 &&
                        <>
                           <h6>Deleted Blocks</h6>
                           <DeletedBlockContainer deletedTextBlocks={[...deletedTextBlocks]} restoreFromTrash={restoreFromTrash} />
                        </>
                     }
                  </Grid>
               </Grid>
            }
            {isPreview &&
               <Grid container spacing={2}>
                  {finalTextBlocks && finalTextBlocks.length > 0 &&
                     <EscalationDetailResponseInstruction responseInstruction={JSON.stringify(finalTextBlocks)} />
                  }
                  {(!finalTextBlocks || finalTextBlocks.length === 0) &&
                     <Grid item xs={12} md={12}>
                        <h6 style={{ marginTop: "10px", marginBottom: "10px" }}>
                           Respoonse instruction for this device is not configured yet.
                        </h6>
                     </Grid>
                  }
               </Grid>
            }

         </DialogContent>
         <DialogActions>
            {isInstructionChanged &&
               <>
                  <Chip style={{ float: "right", marginBottom: "5px", marginRight: "5px" }} color="primary" icon={<Warning />} label="Some blocks are unsaved." />
                  <Button onClick={() => { saveTextBlock(true); }} color="success" type="none">Save & Preview</Button>
               </>
            }
            <Button onClick={close}>Cancel</Button>
            {allowSubmit &&
               <>
                  {isPreview ? <>
                     <Button onClick={() => { setIsPreview(false) }} type="button">Back</Button>
                     {finalTextBlocks.length > 0 &&
                        <>
                           <Button color="info" type="button" onClick={() => { setShowConfirmDialog(true); setApplyDialogueType("all"); }}>Apply to All</Button>
                           <Button color="info" type="button" onClick={() => { setShowConfirmDialog(true); setApplyDialogueType("specific"); }}>Apply to Specific</Button>
                           <Button color="primary" type="none" onClick={() => onSubmit()}>Save</Button>
                        </>
                     }
                  </> :
                     !isInstructionChanged ?
                        <Button onClick={() => { setIsPreview(true); }} color="primary" type="none">Preview</Button> : null
                  }
               </>
            }
         </DialogActions>
         {
            showConfirmDialog &&
            <DeviceResponseInsturctionApplyAllDialogue
               show={showConfirmDialog}
               onConfirmEvent={onSubmitApplyAll}
               onCancel={() => setShowConfirmDialog(false)}
               deviceType={actualDeviceType ?? ""}
               deviceModel={actualDeviceModel}
               deviceOid={oid ?? ""}
               isClientUser={isGasStaff}
               type={applyDialogueType}
               deviceID={selectedEntityName ?? ""}
               clientName={clientName}
            />
         }
      </Dialog >
   );
};

export default withSnackbar(SetDeviceResponseInstruction);
