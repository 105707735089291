import React from "react";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import { IDeviceTestReportDataProps } from "../../../types/propType";
import { Common } from "../../../constants/common";
import Button from "../../Common/Button";
import { CheckCircle, MobileFriendly, Cancel, Event, PhoneMissed } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
const DeviceTestReportRow: React.FC<IDeviceTestReportDataProps> = ({ record, actionPermission, onOpenDeviceTest, openDeviceEventLogModal }) => {
   const getStatusIcon = (status: string | null | undefined) => {
      switch (status) {
         case "Pass":
            return <CheckCircle color="success" />
         case "Fail":
            return <Cancel color="error" />
         case "No Contact":
            return <PhoneMissed style={{ color: "gray" }} />

         default:
            return <></>
      }
   }
   return (
      <StyledGridRow>
         <StyledGridCell> {record.clientName} </StyledGridCell>
         <StyledGridCell> {record.deviceId} </StyledGridCell>
         <StyledGridCell> {record.deviceStatus} </StyledGridCell>
         <StyledGridCell> {
            getStatusIcon(record.eventResult)
         }
            <span style={{ verticalAlign: "super" }}> {record.eventResult}</span>
         </StyledGridCell>
         <StyledGridCell> {record.eventNotes} </StyledGridCell>
         <StyledGridCell> {Common.getFormattedDateTime(record.eventDateTime)}</StyledGridCell>
         <StyledGridCell> {record.firstName} </StyledGridCell>
         <StyledGridCell> {record.lastName} </StyledGridCell>
         <StyledGridCell> {record.phoneNumber} </StyledGridCell>
         <StyledGridCell> {record.employeeID} </StyledGridCell>
         <StyledGridCell> {record.userFunction} </StyledGridCell>
         <StyledGridCell> {record.area} </StyledGridCell>
         <StyledGridCell style={{ textAlign: "center" }}>
            {actionPermission["DeviceEvent:POST"] &&

               <Button color="info" justIcon={true} onClick={() => onOpenDeviceTest(record)}>
                  <Tooltip title="Add device test result">
                     <MobileFriendly />
                  </Tooltip>
               </Button>
            }
            {(actionPermission["Audit:DeviceEvent"]) &&
               <Button color="success" justIcon={true} onClick={() => openDeviceEventLogModal(record)}>
                  <Tooltip title="Event logs">
                     <Event />
                  </Tooltip>
               </Button>
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default DeviceTestReportRow;
