import React, { useEffect, useRef, useState } from "react";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Common } from "../../constants/common";
import { ISearchableSelectParams } from "../../types/dataTypes";
import PropTypes from "prop-types";
import { TextField, FormControl, Autocomplete, CircularProgress, FormHelperText } from "@mui/material";
import { StatusCode } from "../../constants/constant";
import { withStyles } from 'tss-react/mui';
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { useField } from "formik";
import { ISelectOption } from "../../types/dataTypes";
function SearchableServerSearch({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      setValue,
      setTouched,
      value,
      onSearchTextChange,
      setNewValue,
      helperText,
      isClientSelection,
      disabled
   } = props;
   const [filteredSuggestions, setFilteredSuggestions] = useState<ISelectOption[]>([]);
   const previousController = useRef() as any;
   const [open, setOpen] = useState(false);
   const loading = open && filteredSuggestions.length === 0;
   const [processiong, setProcessing] = useState(false);
   const [selectedValue, setSelectedValue] = useState<string | null>(null);
   useEffect(() => {
      if (value && value !== "" && value !== Common.EmptyGuid) {
         searchOptions("", value);
         return;
      }
      else if (isClientSelection) {
         if (Common.IsArrayContainRoleWithClient()) {
            const params: ISearchableSelectParams = {
               SearchText: "ClientName",
               Value: Common.EmptyGuid
            };
            onSearchTextChange(params, null).then((res: any) => {
               let newOptions = res?.data;
               if (res?.status === StatusCode.Success && newOptions && newOptions.length > 0) {
                  onSuggestionSelect(newOptions[0]);
               }
            });

            return;
         }
      }
      setSelectedValue(null);

   }, [id, value, isClientSelection]); // eslint-disable-line react-hooks/exhaustive-deps
   const onAutoSearch = (searchText: string) => {
      if (searchText === "undefined")
         return;
      if (previousController.current) {
         previousController.current.abort();
      }
      if (searchText.length >= Common.SearchAfterCount) {
         searchOptions(searchText, Common.EmptyGuid);
      }
      else {
         setFilteredSuggestions([]);
      }
   }
   const onSuggestionSelect = (value: any) => {
      if (setNewValue !== false)
         setSelectedValue(value);
      if (value) {
         setValue(value);
      }
      else {
         setValue({});
      }
   }
   React.useEffect(() => {
      if (!open) {
         setFilteredSuggestions([]);
      }
   }, [open]);
   const searchOptions = (searchText: string, selectedValue: string) => {
      setProcessing(true);
      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;
      const params: ISearchableSelectParams = {
         SearchText: searchText,
         Value: selectedValue
      };
      onSearchTextChange(params, signal).then((res: any) => {
         let newOptions = res?.data;
         if (res?.status === StatusCode.Success && newOptions) {
            setFilteredSuggestions(newOptions ?? []);
            if (selectedValue !== Common.EmptyGuid) {
               let selectedOption = res?.data.filter((x: ISelectOption) => x.value === selectedValue);
               setSelectedValue(selectedOption.length > 0 ? selectedOption[0] : null);
            }
         }
         setProcessing(false);
      });
   }
   const [field, meta] = useField(inputProps);
   return (
      <FormControl variant="standard"
         {...formControlProps}
         fullWidth
         className={formControlProps?.className + " " + classes.formControl}
      >
         <Autocomplete
            id={id}
            {...inputProps}
            open={open}
            onOpen={() => {
               setOpen(true);
            }}
            onClose={() => {
               setOpen(false);
            }}
            loading={loading}
            value={selectedValue}
            disabled={disabled}
            filterOptions={(x) => x}
            // getOptionLabel={(option: ISelectOption) => option.label}
            options={filteredSuggestions}
            onInputChange={(event, newInputValue) => {
               if (event !== null && event.type !== "click")
                  onAutoSearch(newInputValue);
            }}
            onChange={(event: any, newValue: any) => {
               onSuggestionSelect(newValue);
            }}
            onBlur={() => { if (setTouched) setTouched(); }}
            error={meta.touched && meta.error ? meta.error : ""}
            renderOption={(props, option: ISelectOption, { inputValue }) => {
               const matches = match(option.label, inputValue);
               const parts = parse(option.label, matches);

               return (
                  <li {...props}>
                     <div>
                        {parts.map((part: any, index: number) => (
                           <span
                              key={index}
                              style={{
                                 fontWeight: part.highlight ? 700 : 400,
                              }}
                           >
                              {part.text}
                           </span>
                        ))}
                     </div>
                  </li>
               );
            }}
            renderInput={(params) => (
               <TextField
                  variant="standard"
                  {...params}
                  required={inputProps?.required} label={labelText} name={`${field.name}_Text`}
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                        <React.Fragment>
                           {processiong ? (
                              <CircularProgress color="inherit" size={20} />
                           ) : null}
                           {params.InputProps.endAdornment}
                        </React.Fragment>
                     )
                  }}
                  helperText={helperText}
               />
            )}
            loadingText={"Type text to search..."}
            readOnly={disabled}
         />
         {meta.touched && meta.error && meta.error.length > 0 &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
      </FormControl>
   )
}
SearchableServerSearch.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   labelProps: PropTypes.object,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   value: PropTypes.any,
   onSearchTextChange: PropTypes.any,
};
export default withStyles(SearchableServerSearch, customInputStyle);
