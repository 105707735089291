import React from "react";

import { Box, Grid } from "@mui/material";
import CustomInfiniteScroll from "../../components/Common/CustomGrid/CustomInfiniteScroll";

import PageLayout from "../../layouts/PageLayout";
import ConfirmDialogBox from "../../components/Common/ConfirmDialogBox";
import CustomGrid from "../../components/Common/CustomGrid/CustomGrid";
import LocationRow from "../../components/pages/Locations/LocationRow";
import LocationCard from "../../components/pages/Locations/LocationCard";
import AddEditLocation from "../../components/pages/Locations/AddEditLocation";
import CommonAuditLogsModal from "../../components/pages/AuditLogs/CommonAuditLogsModal";
import EntityAuditLogsModal from "../../components/pages/AuditLogs/EntityAuditLogsModal";

import { ILocationPageState } from "../../types/stateType";
import { IImportOption, ILocationPageProps } from "../../types/propType";
import { Controllers, StatusCode } from "../../constants/constant";
import { IDeleteParams, ILocationFilter } from "../../types/dataTypes";

import { TableHeader } from "../../constants/tableHeader";
import LocationService from "../../services/location.service";
import { Common } from "../../constants/common";
import withParams from "../../components/Common/withParams";
import { withSnackbar } from "../../components/Common/Snackbar";
import ImportGeoJSONModal from "../../components/pages/HazardLocations/ImportGeoJSONModal";
import Button from "../../components/Common/Button";
import { Map } from "@mui/icons-material";
import LocationMapViewModal from "../../components/pages/Locations/LocationMapViewModal";
class Locations extends React.Component<ILocationPageProps, ILocationPageState> {
   filterOptions: ILocationFilter = {
      SearchText: "",
      pageSize: Common.GridPageSize(),
      page: 1,
      sortString: "Title"
   }

   state: ILocationPageState = {
      locations: [],
      totalRecords: 0,
      openAddEditLocationModal: false,
      selectedLocation: null,
      showConfirmDialog: false,
      openAuditLogs: false,
      openImportModal: false,
      openMapView: false,
      numberForMapRefresh: Math.random()
   };

   componentDidMount() {
      this.searchLocation();
   }
   //#region pagination and searching
   searchLocation = () => {
      let newFilterOptions: ILocationFilter = { ...this.filterOptions };
      this.setState({ ...this.state, locations: [] }, () => {
         LocationService.searchLocations(newFilterOptions).then((res: any) => {
            if (res?.status === StatusCode.Success && res?.data) {
               this.setState({
                  ...this.state,
                  locations: res?.data,
                  totalRecords: res?.data[0]?.totalRecord,
               });
            }
         });
      });
   }

   fetchMoreData = () => {
      if (this.state.locations.length === this.state.totalRecords || window.innerWidth > 992) {
         return;
      }

      this.filterOptions.page = this.filterOptions.page + 1;
      let newFilterOptions: ILocationFilter = { ...this.filterOptions };
      LocationService.searchLocations(newFilterOptions).then((res: any) => {
         if (res?.status === StatusCode.Success && res?.data) {
            let tripList = [...this.state.locations];
            tripList = tripList.concat(res?.data);
            this.setState({
               ...this.state,
               locations: tripList,
               totalRecords: res?.data[0]?.totalRecord
            });
         }
      });
   }

   onPageChange = (pageNumber: number, pageSize: number) => {
      this.filterOptions.page = pageNumber;
      this.filterOptions.pageSize = pageSize;
      this.searchLocation();
   }
   onSortingChange = (sortString: string) => {
      this.filterOptions.sortString = sortString;
      this.searchLocation();
   }
   onSearch = (val: any) => {
      this.filterOptions.SearchText = val;
      this.filterOptions.page = 1;
      this.searchLocation();
      // }
   }
   //#endregion

   //#region Add/Edit/Delete Location

   onToggleAddEditLocation = () => {
      this.setState({ ...this.state, openAddEditLocationModal: !this.state.openAddEditLocationModal, selectedLocation: null });
   }

   onSave = () => {
      this.setState({ ...this.state, openAddEditLocationModal: false, numberForMapRefresh: Math.random() }, () => this.searchLocation());
   }

   onSelectLocation = (selectedLocation: any, isForDelete: boolean = false) => {
      if (isForDelete) {
         this.setState({ ...this.state, selectedLocation: selectedLocation, showConfirmDialog: true });
      }
      else
         this.setState({ ...this.state, selectedLocation: selectedLocation, openAddEditLocationModal: true });
   }

   onConfirmDelete = () => {
      const params: IDeleteParams = { oid: this.state.selectedLocation?.oid };
      LocationService.deleteLocation(params).then((res: any) => {
         if (res?.status === StatusCode.Success) {
            this.setState({ ...this.state, selectedLocation: null, showConfirmDialog: false, numberForMapRefresh: Math.random() }, () => this.searchLocation());
         }
         else {
            this.setState({ ...this.state, selectedLocation: null, showConfirmDialog: false });
            this.props.snackbarShowMessage(res?.data?.message, "error");
         }
      });
   }

   onCancelDelete = () => {
      this.setState({ ...this.state, selectedLocation: null, showConfirmDialog: false });
   }

   //#endregion
   //#region Audit Log
   toggleAuditLogModal = () => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedLocation: null });
   }

   openLocationAuditLogModal = (selectedLocation: any) => {
      this.setState({ ...this.state, openAuditLogs: !this.state.openAuditLogs, selectedLocation: selectedLocation });
   }
   //#endregion
   toggleImportModal = (name: string) => {
      this.setState({ ...this.state, openImportModal: !this.state.openImportModal, importModelName: name });
   }
   onImportLocation = () => {
      this.setState({ ...this.state, openImportModal: false, importModelName: undefined }, () => {
         this.searchLocation();
      });
   }
   openMapView = () => {
      this.setState({ ...this.state, openMapView: true });
   }
   closeMapView = () => {
      this.setState({ ...this.state, openMapView: false });
   }
   render() {
      const { totalRecords, openAddEditLocationModal, selectedLocation, showConfirmDialog, locations, openAuditLogs, openImportModal, importModelName, openMapView, numberForMapRefresh } = this.state;
      const { actionPermission } = this.props;
      const importOptions: IImportOption[] = [];
      if (actionPermission["Location:Import"])
         importOptions.push({ name: "Location", displayName: "Geo JSON", onClick: this.toggleImportModal });
      return (
         <PageLayout pageTitle="Trip Locations" onCreate={this.onToggleAddEditLocation} searchText={this.filterOptions.SearchText}
            onSearch={this.onSearch} onSearchTextChange={actionPermission["Location:SearchSuggestions"] ? LocationService.getSearchSuggestions : null}
            auditLogs={this.toggleAuditLogModal}
            importOptions={importOptions}
            actionPermission={{
               add: actionPermission["Location:POST"],
               auditlog: actionPermission["Audit:Location"],
               import: importOptions.length > 0,
            }}
            customAction={
               actionPermission["Location:GetLocationsDetails"] ?
                  <Button color={"success"} startIcon={<Map />} onClick={this.openMapView} >
                     Map View
                  </Button>
                  : null
            }
         >
            {/* add edit user modal */}
            {
               openAddEditLocationModal &&
               <AddEditLocation
                  open={openAddEditLocationModal}
                  close={this.onToggleAddEditLocation}
                  successCallback={this.onSave}
                  locationOid={selectedLocation?.oid}
                  allowSubmit={actionPermission["Location:POST"]} />
            }
            {/* list web view */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
               <CustomGrid headers={TableHeader.getTripLocationTableHeaders()} pagingInfoText="Location" onSortingChange={this.onSortingChange}
                  totalRecord={totalRecords} onPageChange={this.onPageChange} filerOptions={this.filterOptions}>
                  {locations && locations?.map((location: any, index: number) => {
                     return (
                        <LocationRow
                           key={`row_${index}`}
                           location={location}
                           onEdit={this.onSelectLocation}
                           onDelete={this.onSelectLocation}
                           onShowAuditLog={this.openLocationAuditLogModal}
                           actionPermission={actionPermission}
                        />
                     );
                  })}
               </CustomGrid>
            </Box>

            {/* list mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
               <CustomInfiniteScroll dataLength={locations ? locations.length : 0} next={this.fetchMoreData} hasMore={true}>
                  <Grid container spacing={2}>
                     {locations && locations?.map((location: any, index: number) => {
                        return (
                           <LocationCard
                              key={`card_${index}`}
                              location={location}
                              onEdit={this.onSelectLocation}
                              onDelete={this.onSelectLocation}
                              onShowAuditLog={this.openLocationAuditLogModal}
                              actionPermission={actionPermission}
                           />
                        );
                     })}
                  </Grid>
               </CustomInfiniteScroll>
            </Box>

            {/* confirm dialog box for delete user */}
            {
               showConfirmDialog &&
               <ConfirmDialogBox
                  show={showConfirmDialog}
                  onConfirmEvent={this.onConfirmDelete}
                  onCancel={this.onCancelDelete}
               />
            }
            {
               openAuditLogs && selectedLocation === null &&
               <CommonAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Location} />
            }

            {
               openAuditLogs && selectedLocation !== null &&
               <EntityAuditLogsModal
                  open={openAuditLogs}
                  close={this.toggleAuditLogModal}
                  entityName={Controllers.Location}
                  oid={selectedLocation?.oid ?? ""}
                  changedEntityTitle={selectedLocation?.description} />
            }
            {
               openImportModal &&
               <ImportGeoJSONModal
                  open={openImportModal}
                  close={this.toggleImportModal}
                  successCallback={this.onImportLocation}
                  name={importModelName} />
            }
            {
               openMapView &&
               <LocationMapViewModal
                  open={openMapView}
                  close={this.closeMapView}
                  searchText={this.filterOptions.SearchText}
                  onEditLocation={this.onSelectLocation}
                  numberForMapRefresh={numberForMapRefresh} />
            }
         </PageLayout >
      )
   }
}

export default withSnackbar(withParams(Locations));
