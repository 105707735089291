import React from "react";
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { Functions , AddAlert, Person, Error, AccessTime, CheckCircle } from "@mui/icons-material";
import { successColor, dangerColor } from "../../../assets/jss/appStyle";
import { withStyles } from 'tss-react/mui';
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IAlertDataProps } from "../../../types/propType";

const AlertCard: React.FC<IAlertDataProps> = ({ classes, alert }) => {
   return (
      <Grid item xs={12} sm={6} md={4}>
         <Card>
            <CardHeader
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {alert.deviceName}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Functions className={classes.beforeTextIcon} /> {alert.id}
               </Typography>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <AddAlert className={classes.beforeTextIcon} /> {alert.alert_Type}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Person className={classes.beforeTextIcon} /> {`${alert.userFirstName} ${alert.userLastName}`}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  {alert.patriotSignalStatus === "Success" ?
                     <CheckCircle className={classes.beforeTextIcon} style={{ color: successColor[0], float: "left" }} />
                     : <Error className={classes.beforeTextIcon} style={{ color: dangerColor[0], float: "left" }} />} {alert.patriotSignalStatus}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <AccessTime className={classes.beforeTextIcon} /> {alert.date_Ceated}
               </Typography>
            </CardContent>
         </Card>
      </Grid>
   )
}

export default withStyles(AlertCard,gridTileStyle);
