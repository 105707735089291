import React, { useState } from 'react';
import lodash from "lodash";

import { ITextComparetorProps } from '../../types/propType';
import Button from './Button';
import HazardLocationGeometryAudit from './HazardLocationGeometryAudit';
import { Common } from '../../constants/common';

const GeometryComparetor: React.FC<ITextComparetorProps> = ({ oldValueJson, changedValueJson, compareKey }) => {
   const [open, setOpen] = useState(false);
   const changedValue = lodash.get(changedValueJson, compareKey);
   const oldValue = lodash.get(oldValueJson, compareKey);
   const geometries: any = [];
   const isEqual = oldValue === changedValue;
   const changedTypeValue = lodash.get(changedValueJson, "GeometryType");
   if (isEqual) {
      geometries.push({
         Type: changedTypeValue,
         CoOrdinates: changedValue,
         ChangeType: "No Change",
         mapPolygonColor: "green"
      });
   }
   else {
      const oldTypeValue = lodash.get(oldValueJson, "GeometryType");
      geometries.push({
         Type: changedTypeValue,
         CoOrdinates: changedValue,
         ChangeType: "Added",
         mapPolygonColor: Common.getMapPolygonColor("Added")
      });
      if (oldTypeValue && oldValue) {
         geometries.push({
            Type: oldTypeValue,
            CoOrdinates: oldValue,
            ChangeType: "Removed",
            mapPolygonColor: Common.getMapPolygonColor("Removed")
         });
      }
   }
   return (
      <>
         {
            oldValueJson !== null ?
               <>
                  {
                     isEqual ?
                        <span>No change</span> :
                        <span style={{ backgroundColor: "#faa8a8" }}>
                           <span style={{ backgroundColor: "#bcffbc" }}>Updated</span>
                        </span>
                  }
               </>
               : <span>Created</span>
         }
         {
            geometries.length > 0 &&
            <Button style={{ marginLeft: "10px" }} size="sm" color="success" onClick={() => setOpen(true)}>View on map</Button>
         }
         {open &&
            <HazardLocationGeometryAudit open={open} close={() => setOpen(false)} geometries={geometries} />
         }
      </>
   );
};

export default GeometryComparetor;
