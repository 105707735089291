import React from 'react';
import { Table, TableContainer, TableHead, TableRow, IconButton, Tooltip } from "@mui/material";
import NoRecordsFound from '../NoRecordsFound';
import StyledGridCell from './StyledGridCell';
import CustomPagination from './CustomPagination';

import { ICustomGridProps } from '../../../types/propType';
import { ArrowDropDown, ArrowDropUp, UnfoldMore, ClearAll } from '@mui/icons-material';
const CustomGrid: React.FC<ICustomGridProps> = (props) => {
   const { headers, filerOptions, totalRecord, pagingInfoText, onPageChange, onSortingChange } = props;
   const { sortString } = filerOptions;
   const sortColumns = sortString?.split(",");
   const sortColumnDetails = sortColumns?.map((columns: any) => columns.split(" ")) ?? [];
   const sortHandler = (columnName: any, currentDirection: any) => {
      let isCurrentColumn = sortColumnDetails?.filter((sortDetail: any) => sortDetail[0] === columnName) ?? [];
      if (isCurrentColumn.length > 0) {
         if (currentDirection === "desc")
            sortColumnDetails.splice(sortColumnDetails.findIndex((x: any) => x[0] === columnName), 1);
         else
            isCurrentColumn[0][1] = currentDirection === "asc" ? "desc" : "asc";
      }
      else {
         sortColumnDetails.push([columnName, "asc"]);
      }
      onSortingChange(sortColumnDetails.map((colDetails: any) => colDetails.join(" ")).join(","));
   }
   const showClearSort = headers?.filter((x: any) => x.isAction !== true && x.isSortable === true).length >= 0
   return (
      <>
         {
            (totalRecord !== undefined && totalRecord > 0) && (
               <TableContainer>
                  <Table>
                     <TableHead>
                        <TableRow key={pagingInfoText}>
                           {
                              headers?.map((item, i) => {
                                 if (item.isAction) {
                                    return <StyledGridCell key={`action_${i}`} align='right'>
                                       {showClearSort && i !== 0 && sortString?.length > 0 &&
                                          <Tooltip title={"Clear Sorting"}>
                                             <IconButton aria-label="Sort" component="label" onClick={() => onSortingChange("")}>
                                                <ClearAll color="primary" />
                                             </IconButton>
                                          </Tooltip>
                                       }
                                    </StyledGridCell>
                                 }
                                 let currentColumnSortDetails = sortColumnDetails?.filter((sortDetail: any) => sortDetail[0] === item.name) ?? [];
                                 let direction: "desc" | "asc" | undefined = undefined;
                                 if (currentColumnSortDetails.length > 0) {
                                    currentColumnSortDetails = currentColumnSortDetails[0];
                                    direction = (currentColumnSortDetails.length >= 1 && currentColumnSortDetails[1] === "desc") ? "desc" : "asc";
                                 }
                                 return <StyledGridCell key={`${item}_${i}`}>
                                    {item.title}
                                    <Tooltip title={direction === 'desc' ? "Remove Sorting" : direction === 'asc' ? "Sort Descending" : "Sort Ascending"}>
                                       <IconButton aria-label="Sort" component="label" onClick={() => sortHandler(item.name, direction)}>
                                          {currentColumnSortDetails.length > 0 ?
                                             direction === 'desc' ? <ArrowDropDown color="primary" /> : <ArrowDropUp color="primary" />
                                             : item.isSortable ? <UnfoldMore style={{ color: "gray" }} /> : null
                                          }
                                       </IconButton>
                                    </Tooltip>
                                 </StyledGridCell>
                              })
                           }
                        </TableRow>
                     </TableHead>
                     <tbody>
                        {props.children}
                     </tbody>
                  </Table>
               </TableContainer>
            )
         }
         {
            (totalRecord === undefined || totalRecord === 0) ?
               // No Record(s) Found
               (<NoRecordsFound />) :
               // Grid Pagination
               <CustomPagination totalRecord={totalRecord} onPageChange={onPageChange} currentPage={filerOptions.page} pageSize={filerOptions.pageSize} />
         }
      </>
   );
}

export default CustomGrid;
