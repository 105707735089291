import { Controllers } from "../constants/constant";
import request from "../utils/request";

class ErrorLogService {
   searchErrorLogs = (params: any) => {
      return request({
         url: `${Controllers.ErrorLog}/Search`,
         method: 'get',
         params
      });
   }
   exportErrorLogs = (params: any) => {
      return request({
         url: `${Controllers.ErrorLog}/Export`,
         method: 'get',
         responseType: 'blob',
         params
      });
   }
}

export default new ErrorLogService();
