import { useField } from "formik";
import PropTypes from "prop-types";
import { withStyles } from 'tss-react/mui';
import { FormControl, FormHelperText, TextField, Autocomplete } from "@mui/material";
// core components
import customInputStyle from "../../assets/jss/components/customInputStyle";
import { useRef, useState } from "react";
import { Common } from "../../constants/common";
import { StatusCode } from "../../constants/constant";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { ISearchableSelectParams } from "../../types/dataTypes";

function SearchableServerInput({ ...props }) {
   const {
      classes,
      formControlProps,
      labelText,
      id,
      inputProps,
      setValue,
      setTouched,
      value,
      onTextChange
   } = props;
   const [field, meta] = useField(inputProps);
   const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
   const previousController = useRef() as any;
   const onAutoSearch = async (value: string) => {
      setValue(value ? value : "");
      if (previousController.current) {
         previousController.current.abort();
      }
      if (value.length >= Common.SearchAfterCount && onTextChange) {
         const controller = new AbortController();
         const signal = controller.signal;
         previousController.current = controller;
         const params: ISearchableSelectParams = {
            SearchText: value,
            Value: ""
         };
         onTextChange(params, signal).then((res: any) => {
            if (res?.status === StatusCode.Success && value.length >= Common.SearchAfterCount && res?.data) {
               setFilteredSuggestions(res?.data ?? []);
            }
         });
      }
      else {
         setFilteredSuggestions([]);
      }
   }
   return (
      <FormControl
         variant="standard"
         {...formControlProps}
         fullWidth
         className={formControlProps?.className + " " + classes.formControl}
      >
         <Autocomplete
            id={id}
            {...inputProps}
            freeSolo
            value={value}
            options={filteredSuggestions.map((label: string, index: number) => ({ label, index }))}
            onInputChange={(event, newInputValue) => {
               if (event !== null && event.type !== "click" && event.type !== "blur")
                  onAutoSearch(newInputValue);
            }}
            onChange={(event: any, newValue: any) => {
               setValue(newValue ? newValue : "");
            }}
            renderOption={(props, option: any, { inputValue }) => {
               const matches = match(option.label, inputValue);
               const parts = parse(option.label, matches);
               return (
                  <li {...props}>
                     <div>
                        {parts.map((part: any, index: number) => (
                           <span
                              key={index}
                              style={{
                                 fontWeight: part.highlight ? 700 : 400,
                              }}
                           >
                              {part.text}
                           </span>
                        ))}
                     </div>
                  </li>
               );
            }}
            onBlur={(event: any) => {
               if (setTouched) setTouched();
               setValue(event.target.value);
            }}
            renderInput={(params) => (
               <TextField variant="standard" {...params} required={inputProps?.required} label={labelText} name={field.name} />
            )}
            error={meta.touched && meta.error ? meta.error : undefined}
         />
         {meta.touched && meta.error &&
            <FormHelperText id={`error-${inputProps.name}`} error>{meta.error}</FormHelperText>
         }
      </FormControl>
   );
}

SearchableServerInput.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
   labelProps: PropTypes.object,
   id: PropTypes.string,
   inputProps: PropTypes.object,
   formControlProps: PropTypes.object,
   onTextChange: PropTypes.any
};

export default withStyles(SearchableServerInput, customInputStyle);
