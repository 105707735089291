import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem } from '@mui/material';
import { IAssignEscalationProps } from '../../../types/propType';
import Button from '../../Common/Button';
import SearchableServerSearch from '../../Common/SearchableServerSearch';
import { IEscalation, ISearchableSelectParams, ISelectOption } from '../../../types/dataTypes';
import { Controllers, StatusCode } from '../../../constants/constant';
import { Common } from '../../../constants/common';
import { Resources } from '../../../constants/resources';

import PersonService from '../../../services/person.service';
import EscalationService from '../../../services/escalation.service';
import ClientService from '../../../services/client.service';
import DeviceService from '../../../services/device.service';
import EscalationApplyAllDialogue from './ResponseInstruction/EscalationApplyAllDialogue';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import ConfirmDialogBox from '../../Common/ConfirmDialogBox';
import CustomValidationSummary from '../../Common/CustomValidationSummary';

const AssignEscalation: React.FC<IAssignEscalationProps> = ({ open, close, successCallback, escalationOid, oid, escalationOn, selectedEntityName, clientOid, allowSubmit, allowApplyAll, clientName }) => {
   const initialEscalation: IEscalation = {
      Oid: Common.EmptyGuid,
      Person1Oid: "",
      Person2Oid: null,
      Person3Oid: null,
      EscalationOn: escalationOn
   };
   const [defaultEscalation, setDefaultEscalation] = useState<IEscalation>(initialEscalation);
   const [showConfirmDialog, setShowConfirmDialog] = useState(false);
   const [applyDialogueType, setApplyDialogueType] = useState<"all" | "specific">("all");
   const [formValues, setFormValues] = useState<any>({});
   const [showResetConfirmDialog, setShowResetConfirmDialog] = useState(false);
   const [error, setError] = useState<any>({});
   const validationSchema = Yup.object().shape({
      Person1Oid: Yup.string().trim(Resources.Error_Contact1_Required).required(Resources.Error_Contact1_Required)
         .test("CheckPerson1NotSelf", Resources.Error_Self_Escalation_Contact, (value) => value?.toLocaleLowerCase() !== oid?.toLowerCase() || escalationOn !== Controllers.Person),
      Person2Oid: Yup.string().nullable().test("MatchContact1And2", Resources.Error_Contact1_Contact2_ShouldNotMatching, (value: string | null | undefined, context) => Common.stringNullOrEmpty(value) || value !== context.parent.Person1Oid)
         .test("CheckPerson2NotSelf", Resources.Error_Self_Escalation_Contact, (value) => value?.toLocaleLowerCase() !== oid?.toLowerCase() || escalationOn !== Controllers.Person),
      Person3Oid: Yup.string().nullable().test("MatchContact1And2With3", Resources.Error_Contact2_Contact3_ShouldNotMatching, (value: string | null | undefined, context) => Common.stringNullOrEmpty(value) || (value !== context.parent.Person1Oid && value !== context.parent.Person2Oid))
         .test("CheckPerson3NotSelf", Resources.Error_Self_Escalation_Contact, (value) => value?.toLocaleLowerCase() !== oid?.toLowerCase() || escalationOn !== Controllers.Person),
   });

   useEffect(() => {
      if (!Common.stringNullOrEmpty(escalationOid) && escalationOid !== Common.EmptyGuid) {
         let escalation: IEscalation = { ...defaultEscalation };
         EscalationService.getEscalationByOid(escalationOid?.toString()).then((res: any) => {
            const response = res.data;
            if (res?.status === StatusCode.Success && response) {
               escalation.Oid = response.oid;
               escalation.Person1Oid = response.person1Oid;
               escalation.Person2Oid = response.person2Oid;
               escalation.Person3Oid = response.person3Oid;
               setDefaultEscalation(escalation);
            }
         });
      }
   }, [clientOid, escalationOid, escalationOn, oid]);  // eslint-disable-line react-hooks/exhaustive-deps
   const searchPersonSelectionOptions = (params: ISearchableSelectParams, signal: any) => {
      return PersonService.getPersonSelectOptions(clientOid, params, signal);
   }
   const onResult = (res: any) => {
      if (res?.status === StatusCode.Success) {
         successCallback();
      } else if (res?.status === StatusCode.BadRequest) {
         if (res.data?.errors || res.data?.message) {
            setError(res.data);
         }
         else {
            setError({});
         }
      }
   }
   const onSubmit = (values: IEscalation) => {
      if (!Common.stringNullOrEmpty(oid) && oid !== Common.EmptyGuid) {
         switch (escalationOn) {
            case Controllers.Client:
               ClientService.assignDefaultEscalation(values, oid).then((res) => {
                  onResult(res);
               });
               break;

            case Controllers.Device:
               DeviceService.assignDefaultEscalation(values, oid).then((res) => {
                  onResult(res);
               });
               break;

            case Controllers.Person:
               PersonService.assignDefaultEscalation(values, oid).then((res) => {
                  onResult(res);
               });
               break;
         }
      }
   }
   const onSubmitApplyAll = (applyToOids: string[]) => {
      if (!Common.stringNullOrEmpty(oid) && oid !== Common.EmptyGuid) {
         formValues.ApplyToOids = applyToOids;
         switch (escalationOn) {
            case Controllers.Device:
               DeviceService.assignDefaultEscalationApplyAll(formValues, clientOid ?? "").then((res) => {
                  onResult(res);
               });
               break;

            case Controllers.Person:
               PersonService.assignDefaultEscalationApplyAll(formValues, clientOid ?? "").then((res) => {
                  onResult(res);
               });
               break;
         }
      }
   }
   const onRemoveAll = () => {
      const resetData = { ...formValues };
      resetData.Person1Oid = Common.EmptyGuid;
      resetData.Person2Oid = null;
      resetData.Person3Oid = null;
      onSubmit(resetData);
   }
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <Formik initialValues={defaultEscalation} enableReinitialize validateOnMount={true} validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)}>
            {({ values, setFieldValue, isValid }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">{allowSubmit ? "Assign" : "View"} {escalationOn.toLowerCase()} escalation contact for {selectedEntityName}</DialogTitle>
                  <DialogContent dividers>
                     <fieldset className={!allowSubmit ? "disabled" : ""} disabled={!allowSubmit}>
                        <input type="hidden" name="Oid" value={values.Oid} />
                        <input type="hidden" name="EscalationOn" value={values.EscalationOn} />
                        <SearchableServerSearch
                           labelText="Contact 1"
                           id="Person1Oid"
                           inputProps={{
                              required: true,
                              name: "Person1Oid",
                              disableClearable: true,
                              disabled: !allowSubmit
                           }}
                           value={values.Person1Oid}
                           onSearchTextChange={searchPersonSelectionOptions}
                           setValue={(value: ISelectOption) => {
                              setFieldValue("Person1Oid", value?.value)
                           }}
                        />
                        <SearchableServerSearch
                           labelText="Contact 2"
                           id="Person2Oid"
                           inputProps={{
                              name: "Person2Oid",
                              disabled: Common.stringNullOrEmpty(values.Person1Oid) || !allowSubmit
                           }}
                           value={values.Person2Oid}
                           onSearchTextChange={searchPersonSelectionOptions}
                           setValue={(value: ISelectOption) => {
                              setFieldValue("Person2Oid", value?.value)
                           }}
                        />
                        <SearchableServerSearch
                           labelText="Contact 3"
                           id="Person3Oid"
                           inputProps={{
                              name: "Person3Oid",
                              disabled: Common.stringNullOrEmpty(values.Person2Oid) || !allowSubmit
                           }}
                           value={values.Person3Oid}
                           onSearchTextChange={searchPersonSelectionOptions}
                           setValue={(value: ISelectOption) => {
                              setFieldValue("Person3Oid", value?.value)
                           }}
                        />
                     </fieldset>
                     <CustomValidationSummary serverErrors={error} formikErrors={{}} />
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     {(!Common.stringNullOrEmpty(escalationOid) && escalationOid !== Common.EmptyGuid) && allowSubmit &&
                        <Button color="danger" type="button" onClick={() => { setFormValues(values); setShowResetConfirmDialog(true); }}>Remove All</Button>
                     }
                     {allowApplyAll &&
                        <PopupState variant="popover" popupId="demo-popup-menu">
                           {(popupState) => (
                              <>
                                 <Button type="button" disabled={isValid === false} color="info" {...bindTrigger(popupState)} >Apply To</Button>
                                 <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => { popupState.close(); setShowConfirmDialog(true); setApplyDialogueType("all"); setFormValues(values); }}>
                                       All
                                    </MenuItem>
                                    <MenuItem onClick={() => { popupState.close(); setShowConfirmDialog(true); setApplyDialogueType("specific"); setFormValues(values); }}>
                                       Specific
                                    </MenuItem>
                                 </Menu>
                              </>
                           )}
                        </PopupState>
                     }
                     {allowSubmit &&
                        <Button color="primary" type="submit">Save</Button>
                     }
                  </DialogActions>
               </Form>
            )}
         </Formik>
         {showConfirmDialog &&
            <EscalationApplyAllDialogue
               show={showConfirmDialog}
               onConfirmEvent={onSubmitApplyAll}
               onCancel={() => setShowConfirmDialog(false)}
               type={applyDialogueType}
               clientName={clientName}
               clientOid={clientOid ?? ""}
               entity={escalationOn}
               entityOid={oid ?? ""}
               entityName={selectedEntityName ?? ""}
            />
         }
         {showResetConfirmDialog &&
            <ConfirmDialogBox
               show={showResetConfirmDialog}
               onConfirmEvent={onRemoveAll}
               onCancel={() => setShowResetConfirmDialog(false)}
               dialogueText={`This will remove current escalation contact, you need to set it again.`} />
         }
      </Dialog>
   );
};

export default AssignEscalation;
