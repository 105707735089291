import React from "react";
import { withStyles } from 'tss-react/mui';
import { Accessibility, Delete, Edit, Email, History, ForwardToInbox } from "@mui/icons-material";
import { MenuItem, Divider, Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IUserDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

const UserCard: React.FC<IUserDataProps> = ({ classes, user, onEdit, onDelete, onShowAuditLog, onSendResetMail, actionPermission }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <ActionOptionMenu>
                     {(actionPermission["User:POST"] && actionPermission["User:GetPortalRoleOptions"]) &&
                        <div>
                           <MenuItem disableRipple onClick={() => { onEdit(user) }}>
                              <Edit />
                              Edit
                           </MenuItem>
                           <Divider />
                        </div>
                     }
                     {(actionPermission["Audit:User"]) &&
                        <MenuItem disableRipple onClick={() => { onShowAuditLog(user) }}>
                           <History />
                           Audit history
                        </MenuItem>
                     }
                     {(actionPermission["User:SendPasswordResetLink"]) &&
                        <MenuItem disableRipple onClick={() => { onSendResetMail(user) }}>
                           <ForwardToInbox />
                           Send password reset link
                        </MenuItem>
                     }
                     {(actionPermission["User:DELETE"]) &&
                        <div>
                           <Divider />

                           <MenuItem disableRipple onClick={() => { onDelete(user, true) }}>
                              <Delete />
                              Delete
                           </MenuItem>
                        </div>
                     }
                  </ActionOptionMenu>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {`${user.firstname} ${user.lastname}`}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Email className={classes.beforeTextIcon} /> {user.email}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Accessibility className={classes.beforeTextIcon} /> {user.roleNames}
               </Typography>
            </CardContent>

         </Card>
      </Grid >
   )
}

export default withStyles(UserCard, gridTileStyle);
