import React from "react";

import { Divider, MenuItem } from "@mui/material";
import { Delete, Edit, History, ForwardToInbox } from "@mui/icons-material";
import StyledGridCell from "../../Common/CustomGrid/StyledGridCell";
import StyledGridRow from "../../Common/CustomGrid/StyledGridRow";
import Button from "../../Common/Button";
import { IUserDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";

const UserRow: React.FC<IUserDataProps> = ({ user, onEdit, onDelete, onShowAuditLog, onSendResetMail, actionPermission }) => {
   return (
      <StyledGridRow>
         <StyledGridCell> {user.email}  </StyledGridCell>
         <StyledGridCell> {user.firstname} {user.lastname}  </StyledGridCell>
         <StyledGridCell> {user.roleNames.join()}  </StyledGridCell>
         <StyledGridCell align='right'>
            {(actionPermission["User:POST"] && actionPermission["User:GetPortalRoleOptions"]) &&
               <Button color="info" justIcon={true} onClick={() => onEdit(user)}><Edit /></Button>
            }
            {(actionPermission["Audit:User"] || actionPermission["User:SendPasswordResetLink"] || actionPermission["User:DELETE"]) &&
               <ActionOptionMenu>
                  {(actionPermission["Audit:User"]) &&
                     <MenuItem disableRipple onClick={() => { onShowAuditLog(user) }}>
                        <History color="primary" />
                        Audit history
                     </MenuItem>
                  }
                  {(actionPermission["User:SendPasswordResetLink"]) &&
                     <MenuItem disableRipple onClick={() => { onSendResetMail(user) }}>
                        <ForwardToInbox />
                        Send password reset link
                     </MenuItem>
                  }
                  {(actionPermission["User:DELETE"]) &&
                     <div>
                        <Divider />
                        <MenuItem disableRipple onClick={() => { onDelete(user, true) }}>
                           <Delete />
                           Delete
                        </MenuItem>
                     </div>
                  }
               </ActionOptionMenu>
            }
         </StyledGridCell>
      </StyledGridRow>
   )
}

export default UserRow;
