import React from "react";
import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { Accessibility, Email, Business, Tv } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { IUserSupportDataProps } from "../../../types/propType";
import LogInAsButton from "./LogInAsButton";
import { Common } from "../../../constants/common";

const UserSupportCard: React.FC<IUserSupportDataProps> = ({ classes, user, actionPermission }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <>
                     {actionPermission["Auth:LogInAsUser"] &&
                        <LogInAsButton userOid={user.oid} />
                     }
                  </>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {`${user.firstname} ${user.lastname}`}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Email className={classes.beforeTextIcon} /> {user.email}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Accessibility className={classes.beforeTextIcon} /> {user.roleNames}
               </Typography>
               {!Common.stringNullOrEmpty(user.clientName) &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <Business className={classes.beforeTextIcon} /> {user.clientName}
                  </Typography>
               }
               {!Common.stringNullOrEmpty(user.monitoringStationName) &&
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                     <Tv className={classes.beforeTextIcon} /> {user.monitoringStationName}
                  </Typography>
               }
            </CardContent>

         </Card>
      </Grid >
   )
}

export default withStyles(UserSupportCard, gridTileStyle);
