import * as React from 'react';
import { Box, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { TextBlocks } from "./DeviceResponseInstructionList";
import { CheckCircleSharp, Error, Warning } from '@mui/icons-material';
interface IInstructionForSelectionProps {
   deviceType: string;
   instructionFor: string;
   setInstructionFor: any;
   savedInstructionFor: string[];
   editedInstructionFor: string[];
}
const InstructionForSelection: React.FC<IInstructionForSelectionProps> = ({ deviceType, instructionFor, setInstructionFor, savedInstructionFor, editedInstructionFor }) => {
   const InstructionFor = TextBlocks.filter((x) => x.DeviceType.toLowerCase() === deviceType.toLowerCase()).map((items) => items.InstructionTypes);
   const InstructionTypes = InstructionFor && InstructionFor.length > 0 ? InstructionFor[0].map((items) => items.InstructionFor) : [];
   const handleItemClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      selected: string,
   ) => {
      setInstructionFor(selected);
   };

   return (
      <>
         <ListItemText primaryTypographyProps={{ style: { fontWeight: "bold" } }} primary={"Instruction For"} />
         <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', height: "100%", border: "1px solid grey" }}>
            <List component="nav" aria-label="main mailbox folders">
               {InstructionTypes && InstructionTypes.map((item: string, index: number) => {
                  return <ListItemButton
                     key={index}
                     selected={instructionFor === item}
                     onClick={(event) => handleItemClick(event, item)}

                  >
                     <ListItemIcon>
                        {editedInstructionFor.indexOf(item) > -1 ? <Warning color='primary' /> : savedInstructionFor.indexOf(item) > -1 ? <CheckCircleSharp color='success' /> : <Error color='error' />}

                     </ListItemIcon>
                     <ListItemText primaryTypographyProps={{ style: { fontSize: "0.75em", fontWeight: "bold" } }} primary={item} />
                  </ListItemButton>
               })}
            </List>
         </Box>
      </>
   );
}
export default InstructionForSelection;
