import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IPersonDependenciesModalProps } from '../../../types/propType';
import { Pages } from '../../../constants/constant';
import { getActionPermission } from '../../../utils/PermissionHelpers';
import Button from './../../Common/Button';
import Persons from "../../../Pages/Persons/Persons";
import Clients from "../../../Pages/Clients/Clients";
import Devices from "../../../Pages/Devices/Devices";

const PersonDependenciesModal: React.FC<IPersonDependenciesModalProps> = ({ classes, open, close, person, dependencies, onChangeDependencies, onConfirmDelete }) => {
   const clientActionPermission = getActionPermission(Pages.Clients);
   const personActionPermission = getActionPermission(Pages.Persons);
   const deviceActionPermission = getActionPermission(Pages.Devices);
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle><strong>{`${person?.Firstname} ${person?.Lastname}`}</strong> is linked to the following</ DialogTitle>
         <DialogContent dividers>
            {dependencies?.clientsWithPersonAsAccountOwner?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from account owner for below clients.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Clients isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEdit={true} clients={dependencies.clientsWithPersonAsAccountOwner} onChangeDependencies={onChangeDependencies} actionPermission={clientActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
            {dependencies?.clientsWithPersonAsOnlyReportRecipiernt?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from report recipient for below clients.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Clients isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEdit={true} clients={dependencies.clientsWithPersonAsOnlyReportRecipiernt} onChangeDependencies={onChangeDependencies} actionPermission={clientActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
            {dependencies?.clientEscalations?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from escalation for below clients.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Clients isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEscalation={true} clients={dependencies.clientEscalations} onChangeDependencies={onChangeDependencies} actionPermission={clientActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
            {dependencies?.personEscalations?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from escalation for below persons.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Persons isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEscalation={true} showOnlyEdit={false} persons={dependencies.personEscalations} onChangeDependencies={onChangeDependencies} actionPermission={personActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
            {dependencies?.deviceAssigned?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from assignment for below devices.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Devices isFromSearch={true} isFromDependenciesDialogue={true} showOnlyAssignment={true} devices={dependencies.deviceAssigned} onChangeDependencies={onChangeDependencies} actionPermission={deviceActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
            {dependencies?.deviceEscalations?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from escalation for below devices.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Devices isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEscalation={true} devices={dependencies.deviceEscalations} onChangeDependencies={onChangeDependencies} actionPermission={deviceActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
            {dependencies?.personAsLeaders?.length > 0 &&
               <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                     <Typography>
                        <span><strong>Please change/remove person from leader for below persons.</strong></span>
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Persons isFromSearch={true} isFromDependenciesDialogue={true} showOnlyEscalation={false} showOnlyEdit={true} persons={dependencies.personAsLeaders} onChangeDependencies={onChangeDependencies} actionPermission={personActionPermission} />
                  </AccordionDetails>
               </Accordion>
            }
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button color="primary" onClick={onConfirmDelete}>Continue</Button>
         </DialogActions>
      </Dialog>
   );
};

export default PersonDependenciesModal;


