import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Card, CardHeader, Avatar, CardContent } from '@mui/material';
import Button from '../../Common/Button';
import { IModalProps } from '../../../types/propType';
interface IHazardDetailModal extends IModalProps {
   hazardDetails: any;
   title: string | null;
}
const HazardDetailModal: React.FC<IHazardDetailModal> = ({ open, close, hazardDetails, title }) => {
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="sm">
         <DialogTitle>
            <span>Hazard Details for {title}</span>
         </ DialogTitle>
         <DialogContent dividers>
            {hazardDetails.map((hazard: any, index: number) =>
               <Card style={{ marginTop: "3px", marginBottom: "3px" }} key={index} >
                  <CardHeader
                     avatar={
                        <Avatar >
                           {index + 1}
                        </Avatar>
                     }
                     title={<>
                        <strong>Hazard Type:</strong> {hazard.type} <br />
                        <strong>Status:</strong> {hazard.status}<br />
                        <strong>Warning:</strong> {hazard.warning}</>}

                  />
                  <CardContent>
                     <Typography variant="body2" color="text.secondary">
                        <strong>Notes:</strong> {hazard.notes}
                     </Typography>
                     <Typography variant="body2" color="text.secondary">
                        <strong> Onsite Contact:</strong> {hazard.onsiteContact}
                     </Typography>
                     <Typography variant="body2" color="text.secondary">
                        <strong>Onsite Contact Phone Number:</strong> {hazard.onsiteContactPhoneNumber}
                     </Typography>
                     <Typography variant="body2" color="text.secondary">
                        <strong>Security Level:</strong> {hazard.securityLevel}
                     </Typography>
                     <Typography variant="body2" color="text.secondary">
                        <strong>Security Level Description:</strong> {hazard.securityLevelDescription}
                     </Typography>
                  </CardContent>
               </Card>
            )}
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Close</Button>
         </DialogActions>
      </Dialog >
   );
};

export default HazardDetailModal;


