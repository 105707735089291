import { Common } from "../constants/common";
import { IPerson, IDevice, IClient, ITrip, IHazardLocation, IHazardLocationGeometry, ITripLocation } from "../types/dataTypes";

export const mapPersonObject = (selectedPerson: any): IPerson => {
   let person: IPerson = {
      Oid: selectedPerson.oid,
      Firstname: selectedPerson.firstname,
      Lastname: selectedPerson.lastname,
      Email: selectedPerson.email,
      PhoneNumber1: selectedPerson.phoneNumber1,
      PhoneNumber2: selectedPerson.phoneNumber2,
      DeviceOids: selectedPerson.deviceIds ? selectedPerson.deviceIds?.map((device: any) => { return device.deviceOid }) : [],
      Notes: selectedPerson.notes,
      EscalationOid: selectedPerson.escalationOid,
      AllowPortalAccess: selectedPerson.allowPortalAccess,
      RoleNames: selectedPerson.roleNames,
      ResponseInstructionOid: selectedPerson.responseInstructionOid,
      TimeZone: selectedPerson.timeZone,
      EmployeeID: selectedPerson.employeeID,
      PositionTitle: selectedPerson.positionTitle,
      UserFunction: selectedPerson.userFunction,
      Area: selectedPerson.area,
      Department: selectedPerson.department,
      WorkAddress: selectedPerson.workAddress,
      EmploymentActive: selectedPerson.employmentActive,
   };

   if (selectedPerson.personClientRelation !== null && selectedPerson.personClientRelation?.personOid !== Common.EmptyGuid) {
      person.PersonClientRelation = {
         PersonOid: selectedPerson.personClientRelation?.personOid,
         ClientOid: selectedPerson.personClientRelation?.clientOid,
         ClientName: selectedPerson.personClientRelation?.clientName,
         RelationshipToClient: selectedPerson.personClientRelation?.relationshipToClient
      }
   }

   if (selectedPerson.personLeader !== null && selectedPerson.personLeader?.oid !== Common.EmptyGuid) {
      person.PersonLeader = {
         Oid: selectedPerson.personLeader?.oid,
         EmployeeID: selectedPerson.personLeader?.employeeID,
         FirstName: selectedPerson.personLeader?.firstName,
         LastName: selectedPerson.personLeader?.lastName,
         Email: selectedPerson.personLeader?.email,
         PhoneNumber1: selectedPerson.personLeader?.phoneNumber1,
         WorkAddress: selectedPerson.personLeader?.workAddress,
      }
   }
   return person;
}
export const mapDeviceObject = (selectedDevice: any): IDevice => {
   return {
      Oid: selectedDevice.oid,
      DeviceId: selectedDevice.deviceId,
      DeviceType: selectedDevice.deviceType,
      DeviceStatus: selectedDevice.deviceStatus,
      DeviceMake: selectedDevice.deviceMake ? selectedDevice.deviceMake : "",
      DeviceModel: selectedDevice.deviceModel,
      Platform: selectedDevice.platform,
      IMEI_RegistationNo: selectedDevice.imeI_RegistationNo ? selectedDevice.imeI_RegistationNo : "",
      ICCID: selectedDevice.iccid ? selectedDevice.iccid : "",
      GarminAuthCode: selectedDevice.garminAuthCode ? selectedDevice.garminAuthCode : "",
      DeviceSerialNumber: selectedDevice.deviceSerialNumber ? selectedDevice.deviceSerialNumber : "",
      DeviceMobileNumber: selectedDevice.deviceMobileNumber ? selectedDevice.deviceMobileNumber : "",
      MonitoringAccountNumber: selectedDevice.monitoringAccountNumber ? selectedDevice.monitoringAccountNumber : "",
      MonitoringStationOid: selectedDevice.monitoringStationOid ? selectedDevice.monitoringStationOid : null,
      ClientOid: selectedDevice.clientOid,
      EscalationOid: selectedDevice.escalationOid,
      VehicleYear: selectedDevice.vehicleYear ? selectedDevice.vehicleYear : "",
      VehicleMakeModel: selectedDevice.vehicleMakeModel ? selectedDevice.vehicleMakeModel : "",
      VehicleColour: selectedDevice.vehicleColour ? selectedDevice.vehicleColour : "",
      GasTags: selectedDevice.gasTags ? selectedDevice.gasTags : "",
      PersonOid: selectedDevice.personOid,
      MonitoredBy: selectedDevice.monitoredBy ? selectedDevice.monitoredBy : "",
      Notes: selectedDevice.notes ? selectedDevice.notes : "",
      ResponseInstructionOid: selectedDevice.responseInstructionOid,
      LastPoll: selectedDevice.lastPoll,
      DaysOffline: selectedDevice.daysOffline,
      DeviceInAlarm: selectedDevice.deviceInAlarm,
      PegasusDisabled: selectedDevice.pegasusDisabled,
      DaysSinceGPSFix: selectedDevice.daysSinceGPSFix,
      DaysNotCharged: selectedDevice.daysNotCharged,
      DateLastServiced: selectedDevice.dateLastServiced ? Common.getUtcToLocal(selectedDevice.dateLastServiced) : null,
      DateLastTested: selectedDevice.dateLastTested ? Common.getUtcToLocal(selectedDevice.dateLastTested) : null,
      PegasusDataLastUpdated: selectedDevice.pegasusDataLastUpdated ? Common.getUtcToLocal(selectedDevice.pegasusDataLastUpdated) : null,
      ActivationDate: selectedDevice.activationDate,
      WarrantyExpiryDate: selectedDevice.warrantyExpiryDate,
      ClientName: selectedDevice?.clientName,
      EventResult: selectedDevice?.deviceEvent ? selectedDevice?.deviceEvent.eventResult : "",
      EventNote: selectedDevice?.deviceEvent ? selectedDevice?.deviceEvent.eventNotes : ""
   };
}
export const mapClientObject = (selectedClient: any): IClient => {
   return {
      Oid: selectedClient.oid,
      Name: selectedClient.name,
      Email: selectedClient.email,
      PhoneNumber: selectedClient.phoneNumber,
      Country: selectedClient.country,
      EscalationOid: selectedClient.escalationOid,
      AccountOwnerOid: selectedClient.accountOwnerOid,
      ReportRecipients: selectedClient.reportRecipients,
      ResponseInstructionOid: selectedClient.responseInstructionOid,
      PlanOid: selectedClient.planOid,
      NetworkKey: selectedClient.networkKey,
      ShowDeviceOptionalFields: selectedClient.showDeviceOptionalFields,
      ShowPersonOptionalFields: selectedClient.showPersonOptionalFields,
      SendDeviceTestReminder: selectedClient.sendDeviceTestReminder,
      DayOfMonthToSendReport: selectedClient.dayOfMonthToSendReport,
      IsPrivacyBlurbRequired: selectedClient.isPrivacyBlurbRequired,
      PrivacyBlurb: selectedClient.privacyBlurb,
      AllowDeviceAndEscalationAssignmentToSelf: selectedClient.allowDeviceAndEscalationAssignmentToSelf,
      BlockAllMails: selectedClient.blockAllMails,
      EnableTripIntention: selectedClient.enableTripIntention,
      AllowSystemNotification: selectedClient.allowSystemNotification,
   };
}
export const mapTripObject = (selectedTrip: any, copy: boolean = false): ITrip => {
   return {
      Oid: copy ? undefined : selectedTrip.oid,
      Title: selectedTrip.title,
      Status: copy ? "Planned" : selectedTrip.status,
      Description: selectedTrip.description,
      StartTime: copy ? null : (selectedTrip.startTime ? Common.getUtcToLocal(selectedTrip.startTime) : null),
      EndTime: copy ? null : (selectedTrip.endTime ? Common.getUtcToLocal(selectedTrip.endTime) : null),
      VehicleRegistration: selectedTrip.vehicleRegistration,
      DeviceOid: selectedTrip.deviceOid,
      CellPhoneNumber: selectedTrip.cellPhoneNumber,
      TripPersons: selectedTrip.tripPersons,
      TripLocations: selectedTrip.tripLocations,
      AssessmentOfHazardsAndSafety: selectedTrip.assessmentOfHazardsAndSafety,
   };
}
export const mapHazardLocationObject = (selectedHazardLocation: any): IHazardLocation => {
   return {
      Oid: selectedHazardLocation.oid,
      Type: selectedHazardLocation.type,
      SecurityLevel: selectedHazardLocation.securityLevel,
      SecurityLevelDescription: selectedHazardLocation.securityLevelDescription,
      Status: selectedHazardLocation.status,
      Warning: selectedHazardLocation.warning,
      OnsiteContact: selectedHazardLocation.onsiteContact,
      OnsiteContactPhoneNumber: selectedHazardLocation.onsiteContactPhoneNumber,
      Notes: selectedHazardLocation.notes,
      Id: selectedHazardLocation.id,
      GeometryType: selectedHazardLocation.geometryType,
      HazardLocationGeometry: mapHazardLocationGeometryObject(selectedHazardLocation.hazardLocationGeometry),
   };
}
const mapHazardLocationGeometryObject = (hazardLocationGeometry: any[]): IHazardLocationGeometry[] => {
   return hazardLocationGeometry.map((item: any) => {
      return {
         Oid: item.oid,
         Type: item.type,
         CenterLat: item.centerLat,
         CenterLong: item.centerLong,
         CoOrdinates: JSON.parse(item.coOrdinates),
      }
   });
}

export const mapLocationObject = (selectedLocation: any): ITripLocation => {
   return {
      Oid: selectedLocation.oid,
      Title: selectedLocation.title,
      Type: selectedLocation.type,
      Description: selectedLocation.description,
      GeometryType: selectedLocation.geometryType,
      CoOrdinates: selectedLocation.coOrdinates,
      PhysicalAddress: selectedLocation.physicalAddress,
      Id: selectedLocation.id
   };
}
