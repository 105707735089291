import React from "react";
import { ISftpMonitoringLogsViewModelProps } from "../../../types/propType";
import Button from "../../Common/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Common } from "../../../constants/common";

const SftpMonitoringLogsViewModel: React.FC<ISftpMonitoringLogsViewModelProps> = ({ open, close, record }) => {
   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="lg">
         <DialogTitle id="scroll-dialog-title">View Error Log Detail</DialogTitle>
         <DialogContent dividers>
            <Grid container spacing={2}>
               <Grid item xs={12} md={12}>
                  <Table>
                     <TableBody>
                        <TableRow>
                           <TableCell>
                              Timestamp
                           </TableCell>
                           <TableCell>
                              {Common.getFormattedDateTime(record.dateTime)}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Type
                           </TableCell>
                           <TableCell>
                           {record.type}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Filename
                           </TableCell>
                           <TableCell>
                           {record.filename}
                           </TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell>
                              Notes
                           </TableCell>
                           <TableCell>
                           {record.notes}
                           </TableCell>
                        </TableRow>                                                
                     </TableBody>
                  </Table>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={close}>Cancel</Button>
         </DialogActions>
      </Dialog>
   )
}

export default SftpMonitoringLogsViewModel;
