import React from "react";

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, CardHeader, Grid, Typography, MenuItem, Divider } from '@mui/material';
import { Delete, Edit, History, Phone, Pending, Aod, AccessTime, AccessTimeFilled, EmojiTransportation, ContentCopy, WrongLocation, ModeOfTravel, CopyAll, Visibility } from "@mui/icons-material";
import gridTileStyle from "../../../assets/jss/components/gridTileStyle";
import { ITripDataProps } from "../../../types/propType";
import ActionOptionMenu from "../../Common/ActionOptionMenu";
import { Common } from "../../../constants/common";

const TripCard: React.FC<ITripDataProps> = ({ classes, trip, onEdit, onDelete, onShowAuditLog, actionPermission, onViewTripDetail, onClickCopyTripEditLink }) => {
   return (
      <Grid item xs={12} md={4}>
         <Card>
            <CardHeader
               action={
                  <ActionOptionMenu>
                     {(actionPermission["Trip:GET"]) &&
                        <>
                           <MenuItem disableRipple onClick={() => { onEdit(trip) }}>
                              {(actionPermission["Trip:POST"])
                                 ? <> <Edit /> Edit</> : <><Visibility /> View</>}
                           </MenuItem>
                           <Divider />
                        </>
                     }
                     {(actionPermission["Trip:POST"]) &&
                        <>
                           <MenuItem disableRipple onClick={() => { onEdit(trip, false, true) }}>
                              <CopyAll />
                              Copy
                           </MenuItem>
                           <Divider />
                        </>
                     }
                     {(actionPermission["Trip:Cancel"]) &&
                        <>
                           <MenuItem disableRipple disabled={trip.status === "Cancelled"} onClick={() => { onEdit(trip, false, false, true) }}>
                              <WrongLocation />
                              Cancel Trip
                           </MenuItem>
                           <Divider />
                        </>
                     }
                     {(actionPermission["Audit:Trip"]) &&
                        <MenuItem disableRipple onClick={() => { onShowAuditLog(trip) }}>
                           <History />
                           Audit history
                        </MenuItem>
                     }
                     {(actionPermission["Trip:GetTripDetails"]) &&
                        <MenuItem disableRipple onClick={() => { onViewTripDetail(trip.oid) }}>
                           <ModeOfTravel />
                           View trip details
                        </MenuItem>
                     }
                     {(actionPermission["Trip:GetTripDetails"] && actionPermission["Trip:POST"]) &&
                        <MenuItem disableRipple onClick={() => { onClickCopyTripEditLink(trip.oid) }}>
                           <ContentCopy />
                           Copy edit trip link
                        </MenuItem>
                     }
                     {(actionPermission["Trip:DELETE"]) &&
                        <>
                           <Divider />
                           <MenuItem disableRipple onClick={() => { onDelete(trip, true) }}>
                              <Delete />
                              Delete
                           </MenuItem>
                        </>
                     }
                  </ActionOptionMenu>
               }
               title={
                  <>
                     <Typography variant="h5" style={{ paddingBottom: "0px" }} component="h2" className={classes.tileHeader}>
                        {trip.title}
                     </Typography>
                  </>
               }
            />
            <CardContent>
               <Typography variant="body1" color="textSecondary" component="p" className={classes.tileText}>
                  <Pending className={classes.beforeTextIcon} /> {trip.status}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <AccessTime className={classes.beforeTextIcon} />{Common.getFormattedDateTime(trip.startTime)}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <AccessTimeFilled className={classes.beforeTextIcon} />{Common.getFormattedDateTime(trip.endTime)}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Aod className={classes.beforeTextIcon} />{trip.deviceId}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <EmojiTransportation className={classes.beforeTextIcon} />{trip.vehicleRegistration}
               </Typography>
               <Typography variant="body2" color="textSecondary" component="p" className={classes.tileText}>
                  <Phone className={classes.beforeTextIcon} />{trip.cellPhoneNumber}
               </Typography>
            </CardContent>
         </Card>
      </Grid >
   )
}

export default withStyles(TripCard, gridTileStyle);
