import { HelperFunctions } from './utils/helperFunctions';

const w: any = window;

const environment = {
   version: "1.0",
   //   apiUrl: 'https://backend-service-dev-5d42d2f6c0.azurewebsites.net/',
   apiUrl: w?._env_?.API_URL,
   environment: w?._env_?.ENVIRONMENT,
   versionFrontend: w?._env_?.VERSION_FRONTEND,
   versionBackend: w?._env_?.VERSION_BACKEND,
   mapApiKey: w?._env_?.MAPAPIKEY,
   kcEnabled: w?._env_?.KC_ENABLED,
   kcUrl: w?._env_?.KC_URL,
   kcClientId: w?._env_?.KC_CLIENT_ID,
   kcRealm: w?._env_?.KC_REALM,
   azure: {
      clientId: '271ac050-ce82-45e7-ab2a-56018b9a4ef3',
      tenantId: '175e11e1-5fa2-4a14-9395-e1972eaa570e'
   },
};

console.log("loc 220509-1123: Environment:", environment);

const newLocal: any = 'runConfig';
// Environment base runtime configuration *************************************
const runConfig = window[newLocal];
HelperFunctions.mergeObj(environment, runConfig);

export default environment;
