import React from 'react';
import { Formik, Form } from 'formik';

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from '../../Common/Button';
import MultipleDeviceSelection from "./MultipleDeviceSelection";

import { IAssignDeviceProps } from '../../../types/propType';
import { IAssignDevicesToPerson, ISearchableSelectParams } from '../../../types/dataTypes';
import { Common } from '../../../constants/common';

import PersonService from '../../../services/person.service';
import DeviceService from '../../../services/device.service';

const AssignDevice: React.FC<IAssignDeviceProps> = ({ open, close, successCallback, clientOid, personOid, deviceOids, personName }) => {

   let initialValues: IAssignDevicesToPerson = {
      ClientOid: clientOid,
      PersonOid: personOid,
      DeviceOids: deviceOids ? deviceOids : []
   };
   const getClientSpecificDeviceSelectOptions = (params: ISearchableSelectParams, signal: any) => {
      return DeviceService.getClientSpecificDeviceSelectOptions(clientOid, personOid, params, signal);
   }
   const onSubmit = (values: IAssignDevicesToPerson) => {
      if (!Common.stringNullOrEmpty(clientOid) && clientOid !== Common.EmptyGuid) {
         values.DeviceOids = values.DeviceOids.filter((x: string) => x && x.trim() !== "")
         PersonService.AssignMultipleDevicesToPerson(values).then(() => {
            successCallback()
         });
      }
   }

   return (
      <Dialog open={open} onClose={close} scroll="paper" fullWidth maxWidth="xs">
         <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
               <Form noValidate>
                  <DialogTitle id="scroll-dialog-title">Assign device to {personName}</DialogTitle>
                  <DialogContent dividers>
                     <input type="hidden" name="ClientOid" value={values.ClientOid} />
                     <input type="hidden" name="PersonOid" value={values.PersonOid} />
                     <MultipleDeviceSelection
                        onSearchTextChange={getClientSpecificDeviceSelectOptions}
                        value={values.DeviceOids}
                        setValue={(value: string[]) => {
                           setFieldValue("DeviceOids", value)
                        }}
                     />
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={close}>Cancel</Button>
                     <Button color="danger" onClick={() => { onSubmit({ ...values, DeviceOids: [] }) }}>Unassign Device</Button>
                     <Button color="primary" type="submit">Save</Button>
                  </DialogActions>
               </Form>
            )}
         </Formik>
      </Dialog>
   );
};

export default AssignDevice;
