import { fixedBlocks } from "./FixedResponseInstuctionBlocks";
const gasDetectionInctructionTypes = [{
   InstructionFor: "High Gas",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Log into Blackline Live portal and acknowledge alert."
      },
      {
         Type: "Text",
         Text: "Call device using pin provided on Blackline Live portal"
      },
      {
         Type: "Text",
         Text: `If no response or help required;
         No response - Contact escalations, confirm with escalation that we have their permission to resolve the alert
         Help required - Operator 1 stays on device call with user, Operator 2 to call escalations or dispatch Fire & Emergency, (user will advise), advise FENZ of gas type.  Once FENZ dispatched, call escalations.
         Once FENZ are onsite, disconnect call and resolve alert`
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "TWA Alert (Time Weighted Average)",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Log into Blackline Live portal and acknowledge alert."
      },
      {
         Type: "Text",
         Text: "Call device using pin provided on Blackline Live portal"
      },
      {
         Type: "Text",
         Text: `If no response or help required;
         No response - Contact escalations, confirm with escalation that we have their permission to resolve the alert
         Help required - Operator 1 stays on device call with user, Operator 2 to call escalations or dispatch Fire & Emergency, (user will advise), advise FENZ of gas type.  Once FENZ dispatched, call escalations.
         Once FENZ are onsite, disconnect call and resolve alert`
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "STEL (Short Term Exposure Limit)",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Log into Blackline Live portal and acknowledge alert."
      },
      {
         Type: "Text",
         Text: "Call device using pin provided on Blackline Live portal"
      },
      {
         Type: "Text",
         Text: `If no response or help required;
         No response - Contact escalations, confirm with escalation that we have their permission to resolve the alert
         Help required - Operator 1 stays on device call with user, Operator 2 to call escalations or dispatch Fire & Emergency, (user will advise), advise FENZ of gas type.  Once FENZ dispatched, call escalations.
         Once FENZ are onsite, disconnect call and resolve alert`
      },
      ...fixedBlocks
   ]
}];
const cellularDeviceInstructions = [{
   InstructionFor: "SOS",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Call through to device using supplied PIN code"
      },
      {
         Type: "Text",
         Text: `Is there answer?  Yes : Move to step 3
         Listen silently for 10 seconds.  Is an altercation taking place?  Yes = Operator 2 to dispatch police.
         No = “This is Guardian Angel – do you need assistance?”
         If still no answer through device, call users mobile number (allocated devices only).  If not allocated, call escalations in order.  Leave messages if no reply until someone is reached.
         If there is no contact with user or escalations, dispatch police.`
      },
      {
         Type: "Text",
         Text: "Determine what help user needs.  Operator 1 stays on the device call while Operator 2 dispatches the appropriate emergency service and contacts escalations.  Operator 1 stays on the device call until help is with user."
      },
      {
         Type: "Text",
         Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
      },
      {
         Type: "Text",
         Text: "Once help is with user OR false alarm is established, terminate device call"
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "Fall Detection / No Movement",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Call through to device using supplied PIN code"
      },
      {
         Type: "Text",
         Text: `Is there answer?  Yes : Move to step 3
         Listen silently for 10 seconds.  Is an altercation taking place?  Yes = Operator 2 to dispatch police, advising this is a No Motion or Fall Detected alert.
         No = “This is Guardian Angel – do you need assistance?”
         If still no answer through device, call users mobile number (allocated devices only).  If not allocated, call escalations in order.  Leave messages if no reply until someone is reached.
         If there is no contact with user or escalations, dispatch police.`
      },
      {
         Type: "Text",
         Text: `Determine what help user needs. Operator 1 stays on the device call while Operator 2 dispatches the appropriate emergency service and contacts escalations.
         Use link to go to Blackline Live platform and check if device is moving.`
      },
      {
         Type: "Text",
         Text: "If not allocated, call escalations in order.  Leave messages if no reply until someone is reached."
      },
      {
         Type: "Text",
         Text: "No contact with user or escalations:  Dispatch Police advising this was a Fall Detection / No Movement alarm (could indicate medical event)"
      },
      {
         Type: "Text",
         Text: "Operator 1 stays on the device line until help is with user  /  Operator 2 keeps trying escalations"
      },
      {
         Type: "Text",
         Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
      },
      {
         Type: "Text",
         Text: "Once help is with user OR false alarm is established, terminate device call"
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "Missed Check-In",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Call through to device using supplied PIN code"
      },
      {
         Type: "Text",
         Text: "Is there an answer?  Yes:  See below"
      },
      {
         Type: "Text",
         Text: "Listen in silently for 10 sec:  Is an altercation taking place?  Yes = get operator 2 to dispatch Police   No = “This is Guardian Angel – do you need assistance?”  Establish what help is required"
      },
      {
         Type: "Text",
         Text: "No contact with user through the device, call user mobile phone (if allocated device)"
      },
      {
         Type: "Text",
         Text: "No contact with user through device or mobile phone, call escalations, leaving messages"
      },
      {
         Type: "Text",
         Text: "Use link to go to mapping platform to view the device and confirm if it is moving"
      },
      {
         Type: "Text",
         Text: "No contact with user or escalations:  Repeat call cycle to user and escalations"
      },
      {
         Type: "Text",
         Text: "Dispatch Police"
      },
      {
         Type: "Text",
         Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
      },
      ...fixedBlocks
   ]
}];
const sattelitDeviceG7xInstruction = [{
   InstructionFor: "SOS",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Acknowledge alarm on portal"
      },
      {
         Type: "Text",
         Text: "Try users mobile number (allocated devices only)"
      },
      {
         Type: "Text",
         Text: "Use link to go mapping portal to view the device and confirm if it is moving"
      },
      {
         Type: "Text",
         Text: "Send message to device “SOS received, what emergency service do you need?”"
      },
      {
         Type: "Text",
         Text: "Operator 1 dispatch requested emergency service – if no response from user, dispatch police.  Operator 2 call escalations leaving messages if no answer."
      },
      {
         Type: "Text",
         Text: "Call escalations and hand over incident management,  recording all their details to transfer to report"
      },
      {
         Type: "Text",
         Text: "If no answer from escalations, dispatch Police"
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "Fall Detection / No Movement",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Try users mobile number (allocated devices only): establish incident and dispatch Police or Ambulance"
      },
      {
         Type: "Text",
         Text: "Use link to go to Blackline platform to view the device and confirm if it is moving"
      },
      {
         Type: "Text",
         Text: "Send message to device “Fall or No Motion detected, do you need medical assistance?”  If yes, dispatch ambulance"
      },
      {
         Type: "Text",
         Text: "Operator 1 Despatch Police / Operator 2 call escalations, leaving messages if no answer"
      },
      {
         Type: "Text",
         Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
      },
      {
         Type: "Text",
         Text: "Call escalations and hand over incident management, recording all their details to transfer to report"
      },
      {
         Type: "Text",
         Text: "If no answer from escalations, dispatch Police"
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "Missed Check-In",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Try users mobile (if allocated device)"
      },
      {
         Type: "Text",
         Text: "Use link to go to mapping portal, establish if a) the person is in a residential area or remote area and b) are they moving?"
      },
      {
         Type: "Text",
         Text: "Send message to device “you have failed to check in.  Are you ok?"
      },
      {
         Type: "Text",
         Text: "No contact with user, call escalations, leaving messages."
      },
      {
         Type: "Text",
         Text: "If incident handed over to escalation, note details of this person."
      },
      {
         Type: "Text",
         Text: "No contact with user or escalations, repeat call cycle for 30mins."
      },
      {
         Type: "Text",
         Text: "dispatch Police."
      },
      ...fixedBlocks
   ]
},
{
   InstructionFor: "Help (non life threatening)",
   TextBlocks: [
      {
         Type: "Text",
         Text: "Try users mobile (allocated devices only)"
      },
      {
         Type: "Text",
         Text: "Message user:  “help message received”.  Do you require medical assistance?  If yes, dispatch Ambulance.  If no, message user “your escalations are being contacted"
      },
      {
         Type: "Text",
         Text: "Contact escalations, leaving messages."
      },
      {
         Type: "Text",
         Text: "If no contact with user or escalations, try again all above instructions again in 15min."
      },
      {
         Type: "Text",
         Text: "Check mapping portal:  is the person in remote or suburban area?"
      },
      {
         Type: "Text",
         Text: "If person is in remote area; try user mobile and escalations again, 15min intervals for 1 hour."
      },
      {
         Type: "Text",
         Text: "Check mapping portal: Is the person moving?"
      },
      {
         Type: "Text",
         Text: "If no contact with user or escalations after 1 hour, dispatch Police"
      }
   ]
}];
export const TextBlocks: {
   DeviceType: string,
   InstructionTypes: {
      InstructionFor: string,
      TextBlocks: {
         Type: string,
         Text: string,
         IsRequired?: boolean,
         IsFixed?: boolean,
      }[]
   }[]
}[] = [
      {
         DeviceType: "Cellular Device",
         InstructionTypes: [
            ...cellularDeviceInstructions
         ]
      },
      {
         DeviceType: "Cellular Device with Gas Detection",
         InstructionTypes: [
            ...cellularDeviceInstructions,
            ...gasDetectionInctructionTypes
         ]
      },
      {
         DeviceType: "Mobile App",
         InstructionTypes: [
            {
               InstructionFor: "SOS",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Call user on their mobile phone:"
                  }
                  ,
                  {
                     Type: "Text",
                     Text: `Is there answer?  Yes : Move to step 3
                     Listen silently for 10 seconds.  Is an altercation taking place?  Yes = Operator 2 to dispatch police.
                     No = “This is Guardian Angel – do you need assistance?”.  Establish what help is required.
                     If no answer on users mobile, call escalations in order.  Leave messages if no reply until someone is reached.
                     If there is no contact with user or escalations, dispatch police.`
                  },

                  {
                     Type: "Text",
                     Text: "Determine what help user needs.  Operator 1 stays on the call while Operator 2 dispatches the appropriate emergency service and contacts escalations.  Operator 1 stays on the call until help is with user."
                  },

                  {
                     Type: "Text",
                     Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
                  },

                  {
                     Type: "Text",
                     Text: "Once help is with user OR false alarm is established, terminate call"
                  },

                  ...fixedBlocks
               ]
            },
            {
               InstructionFor: "Missed Check-In",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Call users mobile"
                  },
                  {
                     Type: "Text",
                     Text: "Is there an answer?  Yes:  See below"
                  },
                  {
                     Type: "Text",
                     Text: "Listen in silently for 10 sec:  Is an altercation taking place?  Yes = get operator 2 to dispatch Police   No = “This is Guardian Angel – do you need assistance?”  Establish what help is required"
                  },
                  {
                     Type: "Text",
                     Text: "No contact with user on phone, call escalations, leaving messages"
                  },
                  {
                     Type: "Text",
                     Text: "Use link to go to mapping platform to confirm if user is moving"
                  },
                  {
                     Type: "Text",
                     Text: "If no contact with user or escalations, repeat call cycle to user and escalations for 30mins."
                  },
                  {
                     Type: "Text",
                     Text: "Dispatch Police"
                  },
                  {
                     Type: "Text",
                     Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
                  },
                  ...fixedBlocks
               ]
            }
         ]
      },
      {
         DeviceType: "Mobile App with DUO",
         InstructionTypes: [
            {
               InstructionFor: "SOS",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Call user on their mobile phone:"
                  }
                  ,
                  {
                     Type: "Text",
                     Text: `Is there an answer?  Yes:  See below
                           Listen in silently for 10 sec:  Is an altercation taking place?  Yes = get operator 2 to dispatch Police   No = “This is Guardian Angel – do you need assistance?”  Establish what help is required
                           No answer on their phone:  
                           Call escalations in order.  Leave messages if no reply until someone is reached.
                           No contact with user or escalations:  Dispatch Police`
                  },

                  {
                     Type: "Text",
                     Text: "Operator 1 stays on the phone until help is with user  /  Operator 2 keeps trying escalations"
                  },

                  {
                     Type: "Text",
                     Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
                  },

                  {
                     Type: "Text",
                     Text: "Once help is with user OR false alarm is established, terminate call"
                  },

                  ...fixedBlocks
               ]
            },
            {
               InstructionFor: "Fall Detection / No Movement",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Call through to users mobile phone"
                  },

                  {
                     Type: "Text",
                     Text: `Is there an answer?  Yes:  See below
                              Listen in silently for 10 sec:  Is an altercation taking place?  Yes = get operator 2 to dispatch Police, advising this was a Fall Detection / No Movement alarm   No = “This is Guardian Angel – do you need assistance?”  Establish what help is required.  Fall Detection / No Movement alarm could indicate medical event: dispatch Ambulance
                              No answer on users phone:  
                              Call escalations in order.  Leave messages if no reply until someone is reached.`
                  },
                  {
                     Type: "Text",
                     Text: "Use link to go to Blackline platform to view the device and confirm if it is moving"
                  },
                  {
                     Type: "Text",
                     Text: "No contact with user or escalations:  Dispatch Police advising this was a Fall Detection / No Movement alarm (could indicate medical event)"
                  },
                  {
                     Type: "Text",
                     Text: "Operator 1 stays on the device line until help is with user  /  Operator 2 keeps trying escalations"
                  },
                  {
                     Type: "Text",
                     Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
                  },
                  {
                     Type: "Text",
                     Text: "Once help is with user OR false alarm is established, terminate device call"
                  },
                  ...fixedBlocks,
               ]
            },
            {
               InstructionFor: "Missed Check-In",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Call users mobile"
                  },
                  {
                     Type: "Text",
                     Text: "Is there an answer?  Yes:  See below"
                  },
                  {
                     Type: "Text",
                     Text: "Listen in silently for 10 sec:  Is an altercation taking place?  Yes = get operator 2 to dispatch Police   No = “This is Guardian Angel – do you need assistance?”  Establish what help is required"
                  },
                  {
                     Type: "Text",
                     Text: "No contact with user on phone, call escalations, leaving messages"
                  },
                  {
                     Type: "Text",
                     Text: "Use link to go to mapping platform to confirm if user is moving"
                  },
                  {
                     Type: "Text",
                     Text: "No contact with user or escalations:  Repeat call cycle to user and escalations for 1 hour"
                  },
                  {
                     Type: "Text",
                     Text: "Dispatch Police"
                  },
                  {
                     Type: "Text",
                     Text: "Operator 1 also stays on mapping portal to track movement of user to ensure help is sent to current location"
                  },
                  ...fixedBlocks
               ]
            }
         ]
      },
      {
         DeviceType: "Satellite Device (Garmin)",
         InstructionTypes: [
            {
               InstructionFor: "SOS",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Acknowledge alarm on portal"
                  },
                  {
                     Type: "Text",
                     Text: "Try users mobile number (allocated devices only)"
                  },
                  {
                     Type: "Text",
                     Text: "Use link to go mapping portal to view the device and confirm if it is moving"
                  },
                  {
                     Type: "Text",
                     Text: "Message device through hub “SOS received, what emergency service do you need?”  Await reply and dispatch appropriate service.  Meanwhile Operator 2 calls escalations leaving messages if no answer.  If no answer from user, dispatch police."
                  },
                  {
                     Type: "Text",
                     Text: "Call escalations and hand over incident management,  recording all their details to transfer to report"
                  },
                  {
                     Type: "Text",
                     Text: "If no answer from escalations, dispatch Police"
                  },
                  ...fixedBlocks
               ]
            },
            {
               InstructionFor: "Missed Check-In",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Try users mobile (if allocated device)"
                  },
                  {
                     Type: "Text",
                     Text: "Use link to go to mapping portal, establish if a) the person is in a residential area or remote area and b) are they moving?"
                  },
                  {
                     Type: "Text",
                     Text: "Send message to device “you have failed to check in.  Are you ok?"
                  },
                  {
                     Type: "Text",
                     Text: "No contact with user, call escalations, leaving messages."
                  },
                  {
                     Type: "Text",
                     Text: "If incident handed over to escalation, note details of this person."
                  },
                  {
                     Type: "Text",
                     Text: "No contact with user or escalations, repeat call cycle for 1 hour."
                  },
                  {
                     Type: "Text",
                     Text: "dispatch Police."
                  },
                  ...fixedBlocks
               ]
            },
            {
               InstructionFor: "Help (non life threatening)",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Try users mobile (allocated devices only)"
                  },
                  {
                     Type: "Text",
                     Text: "Message user:  “help message received”.  Do you require medical assistance?  If yes, dispatch Ambulance.  If no, message user “your escalations are being contacted"
                  },
                  {
                     Type: "Text",
                     Text: "Contact escalations, leaving messages."
                  },
                  {
                     Type: "Text",
                     Text: "If no contact with user or escalations, try again all above instructions again in 15min."
                  },
                  {
                     Type: "Text",
                     Text: "Check mapping portal:  is the person in remote or suburban area?"
                  },
                  {
                     Type: "Text",
                     Text: "If person is in remote area; try user mobile and escalations again, 15min intervals for 1 hour."
                  },
                  {
                     Type: "Text",
                     Text: "Check mapping portal: Is the person moving?"
                  },
                  {
                     Type: "Text",
                     Text: "If no contact with user or escalations after 1 hour, dispatch Police"
                  },
               ]
            }
         ]
      },
      {
         DeviceType: "Satellite Device (G7x)",
         InstructionTypes: [
            ...sattelitDeviceG7xInstruction
         ]
      },
      {
         DeviceType: "Satellite Device (G7x) with Gas Detection",
         InstructionTypes: [
            ...sattelitDeviceG7xInstruction,
            ...gasDetectionInctructionTypes
         ]
      },
      {
         DeviceType: "Vehicle",
         InstructionTypes: [
            {
               InstructionFor: "Break Down",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Try users mobile (allocated devices only)"
                  },
                  {
                     Type: "Text",
                     Text: "Message user:  “break down message received.  Is it a flat tyre?  If yes, send appropriate service provider.  (free type entry field to insert service provider phone number"
                  },
                  {
                     Type: "Text",
                     Text: "If no, message user:  Is it a mechanical break down?  If yes, send appropriate service provider.  (free type entry field to insert service provider phone number)"
                  },
                  {
                     Type: "Text",
                     Text: "If no, send message:  “do you require assistance”"
                  },
                  {
                     Type: "Text",
                     Text: "Call escalations, leaving messages until someone is reached"
                  },
                  {
                     Type: "Text",
                     Text: "If no answers from device or escalations, close job"
                  },
                  ...fixedBlocks
               ]
            },
            {
               InstructionFor: "Impact / Roll Over Alert",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "Use the link to go to appropriate mapping portal"
                  },
                  {
                     Type: "Text",
                     Text: "Is the vehicle moving?"
                  },
                  {
                     Type: "Text",
                     Text: "Call users mobile phone number"
                  },
                  {
                     Type: "Text",
                     Text: "If user also has a device allocated to them, try contact through this"
                  },
                  {
                     Type: "Text",
                     Text: "If false alarm confirmed, close job."
                  },
                  {
                     Type: "Text",
                     Text: "If incident confirmed, dispatch Emergency Services and contact escalations leaving messages."
                  },
                  {
                     Type: "Text",
                     Text: "If incident confirmed, continue trying to reach escalations until someone is reached for 1 hour."
                  },
                  ...fixedBlocks
               ]
            },
            {
               InstructionFor: "Panic Button",
               TextBlocks: [
                  {
                     Type: "Text",
                     Text: "This service is not available yet."
                  }
               ]
            }
         ]
      }
   ];
